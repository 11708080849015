import { ProjectsListRequestProps } from 'services/api/clients';
import { DefaultParams } from 'types/api';
import {
  ClientProjectRequestProps,
  ProjectListRequestParamsProps,
  ProjectResourcesProps,
  ProjectUsersListRequestProps,
  TeamProgressRequestProps,
} from '.';

type ProjectAllListRequestParamsProps = {
  params: DefaultParams;
};

export type ProjectAllListRequestProps = ReturnType<
  typeof projectQueryKeys.listAll
>;

export const projectQueryKeys = {
  all: { scope: 'project' } as const,
  listBase: () => ({ ...projectQueryKeys.all, entity: 'list' } as const),
  list: (params: ProjectListRequestParamsProps['params']) =>
    ({ ...projectQueryKeys.listBase(), params } as const),
  singleBase: () => ({ ...projectQueryKeys.all, entity: 'single' } as const),
  single: (params: ClientProjectRequestProps['params']) =>
    ({ ...projectQueryKeys.singleBase(), params } as const),
  resourcesBase: () =>
    ({ ...projectQueryKeys.all, entity: 'resources' } as const),
  resources: (params: ProjectResourcesProps['params']) =>
    ({ ...projectQueryKeys.resourcesBase(), params } as const),
  listAllBase: () => ({ ...projectQueryKeys.all, entity: 'list-all' } as const),
  listAll: (params: ProjectsListRequestProps['params']) =>
    ({ ...projectQueryKeys.listAllBase(), params } as const),
  listUsersBase: () =>
    ({ ...projectQueryKeys.all, entity: 'list-users' } as const),
  listUsers: (params: ProjectUsersListRequestProps['params']) =>
    ({ ...projectQueryKeys.listUsersBase(), params } as const),
  teamProgressBase: () =>
    ({ ...projectQueryKeys.all, entity: 'team-progress' } as const),
  teamProgress: (params: TeamProgressRequestProps['params']) =>
    ({ ...projectQueryKeys.teamProgressBase(), params } as const),
};
