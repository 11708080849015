import { incubatePlaybookTaskEnum } from 'common/enums';
import { allSlugs, Slugs } from 'common/ventureBuilderConsts';
import {
  AssignableContentPhase,
  IncubatePlaybookPhase,
  IncubatePlaybookTask,
  IncubatePlaybookTaskActivity,
  IncubatePlaybookTaskConcept,
  IncubatePlaybookTaskWithVideo,
  IncubatePlaybookUnit,
  IncubatePlaybookUnitData,
  PlaybookVideoForProgress,
  PrimitiveAssignedContentTypeArray,
  StartEndPhase,
} from 'types/incubatePlaybook';
import { VentureBuilderPageData } from 'types/ventureBuilder';

export const getAdaptedData = (
  phases?: IncubatePlaybookPhase[],
  isEnterprise: boolean = false
) => {
  const taskOrderList: Array<{
    taskId: string;
    taskSlug: string;
    unitId: string;
    unitSlug: string;
  }> = [];
  const firstTaskOrderList: string[] = [];
  const unitOrderList: Array<IncubatePlaybookUnitData> = [];
  const ventureBuilderSections: { [key: string]: VentureBuilderPageData } = {};
  const vBsectionsArray: Slugs[] = [];
  const playbookVideos: PlaybookVideoForProgress[] = [];
  const allPhases: StartEndPhase[] = [];

  const assignableContentTemp: { [key: string]: boolean } = {};

  phases?.forEach(
    (
      { modules, _id: phaseId, title: phaseTitle, icon, slug: phaseSlug },
      phaseIndex
    ) => {
      allPhases.push({
        phaseId,
        phaseTitle,
        start: undefined,
        end: undefined,
      });
      modules?.forEach(
        ({ units, title: moduleTitle, _id: moduleId, slug: moduleSlug }) => {
          return units?.forEach((unit) => {
            const { _id: unitId } = unit;
            const adaptedTasks = getTasks(isEnterprise, unit.tasks);
            unitOrderList.push({
              ...unit,
              phaseId,
              phaseTitle,
              phaseSlug: phaseSlug.current,
              moduleId,
              moduleTitle,
              moduleSlug: moduleSlug.current,
              tasks: adaptedTasks,
              assignableTasks: getAssignableTasks(adaptedTasks),
            });
            adaptedTasks.forEach((task, i) => {
              const { _id: taskId, slug: taskSlug, _type } = task;
              taskOrderList.push({
                taskId,
                taskSlug: taskSlug.current,
                unitId: unit._id,
                unitSlug: unit.slug.current,
              });
              if (i === 0) {
                firstTaskOrderList.push(taskId);
              }

              if (
                _type === incubatePlaybookTaskEnum.taskConcept ||
                _type === incubatePlaybookTaskEnum.taskActivity
              ) {
                if (task.ventureBuilders) {
                  task.ventureBuilders.forEach((ventureBuilder) => {
                    const slug = ventureBuilder?.slug;
                    if (
                      !vBsectionsArray.includes(slug?.current) &&
                      allSlugs.includes(slug?.current)
                    ) {
                      ventureBuilderSections[phaseId] = ventureBuilderSections[
                        phaseId
                      ] || {
                        phaseId,
                        phaseTitle,
                        icon,
                        sections: [],
                      };
                      ventureBuilderSections[phaseId].sections.push(
                        slug.current
                      );
                      vBsectionsArray.push(slug.current);
                    }
                  });
                }
                // add to list of videos
                if (task.video) {
                  const playbookVideo = {
                    name: task.title,
                    phaseTitle,
                    phaseId,
                    moduleTitle,
                    key: `${task.video._id}|${phaseId}`,
                    videoKey: task.video._id,
                    taskId,
                    taskSlug: taskSlug.current,
                    unitId: unit._id,
                    unitSlug: unit.slug.current,
                    unitTitle: unit.title,
                  };

                  playbookVideos.push(playbookVideo);

                  if (allPhases[phaseIndex].start === undefined)
                    allPhases[phaseIndex].start = playbookVideo;

                  allPhases[phaseIndex].end = playbookVideo;

                  assignableContentTemp[phaseId] = true;
                  assignableContentTemp[moduleId] = true;
                  assignableContentTemp[unitId] = true;
                  assignableContentTemp[taskId] = true;
                }
              }
            });
          });
        }
      );
    }
  );

  const phasesWithStartAndEndVideo = allPhases?.filter(
    (phase): phase is Required<StartEndPhase> => phase.start !== undefined
  );

  unitOrderList.forEach((u, i) => {
    if (i !== 0) {
      unitOrderList[i - 1].nextTitle = u.title;
      unitOrderList[i - 1].nextId = u._id;
    }
    if (i !== unitOrderList.length - 1) {
      unitOrderList[i + 1].prevTitle = u.title;
      unitOrderList[i + 1].prevId = u._id;
    }
  });
  const allUnitData = unitOrderList.reduce<
    Record<IncubatePlaybookUnit['_id'], IncubatePlaybookUnitData>
  >((a, b) => {
    a[b._id] = b;

    return a;
  }, {});

  const assignableContent = (phases || [])
    .map(({ modules, _id: phaseId, title: phaseTitle, icon }) => {
      if (!assignableContentTemp[phaseId]) return null;

      const children = modules
        ?.map(({ units, title: moduleTitle, _id: moduleId }) => {
          if (!assignableContentTemp[moduleId]) return null;

          const children = units
            ?.map(({ _id: unitId, title: unitTitle, tasks }) => {
              if (!assignableContentTemp[unitId]) return null;

              const tasksToAssign = tasks
                ?.map((task) => {
                  const { _id: taskId } = task;

                  if (!assignableContentTemp[taskId]) {
                    return null;
                  } else return task;
                })
                .filter(
                  (
                    el
                  ): el is
                    | IncubatePlaybookTaskActivity
                    | IncubatePlaybookTaskConcept => el !== null
                );

              return {
                title: unitTitle,
                id: unitId,
                tasks: tasksToAssign,
              };
            })
            .filter(Boolean);

          return {
            id: moduleId,
            title: moduleTitle,
            children,
          };
        })
        .filter(Boolean);

      return {
        title: phaseTitle,
        id: phaseId,
        icon,
        children,
      };
    })
    .filter((el): el is AssignableContentPhase => el !== null);

  const assignableContentIndexMap: Record<string, number> = {};

  // Helper function to traverse the tree and populate the node index map
  function traverseTree(nodes: PrimitiveAssignedContentTypeArray) {
    if (!nodes) return;

    for (const node of nodes) {
      assignableContentIndexMap[node.id] = Object.keys(
        assignableContentIndexMap
      ).length;
      if (node.children) {
        traverseTree(node.children);
      }
    }
  }

  // Populate the node index map
  traverseTree(
    assignableContent as unknown as PrimitiveAssignedContentTypeArray
  );

  return {
    allUnitData,
    taskOrderList,
    firstTaskOrderList,
    ventureBuilderSections,
    playbookVideos,
    phasesWithStartAndEndVideo,
    assignableContent,
    assignableContentIndexMap,
  };
};

const getTasks = (isEnterprise: boolean, tasks: IncubatePlaybookTask[] = []) =>
  tasks.map((task) =>
    task._type === incubatePlaybookTaskEnum.taskActivity ||
    task._type === incubatePlaybookTaskEnum.taskConcept
      ? {
          ...task,
          resources:
            task.resources?.filter((t) => !!t.isEnterprise === isEnterprise) ??
            [],
        }
      : task
  ) ?? [];
const getAssignableTasks = (tasks: IncubatePlaybookTask[]) =>
  tasks.reduce<IncubatePlaybookTaskWithVideo[]>((acc, task) => {
    if (
      (task._type === incubatePlaybookTaskEnum.taskConcept ||
        task._type === incubatePlaybookTaskEnum.taskActivity) &&
      task.video
    ) {
      acc.push(task as IncubatePlaybookTaskWithVideo);
    }

    return acc;
  }, []);
