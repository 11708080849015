import { Box } from '@mui/material';
import { PlaybookFooter } from 'components/Footer';
import { PlaybookHeader } from 'components/Header';
import { IntroductionPlaybookModal } from 'components/Modal/IncubatePlaybook/IntroductionPlaybookModal';
import { PlaybookSidebar } from 'components/Playbook';
import { useCustomMediaQuery, useSavePlaybookInfo } from 'hooks';
import { usePlaybookResources } from 'hooks/usePlaybookResources';
import { ReactNode } from 'react';
import { useGetAllFrequentlyAssignedNext } from 'services/api/product/assignContent/useGetAllFrequentlyAssignedNext';
import { useGetAllHiddenFrequentlyAssignedNext } from 'services/api/product/assignContent/useGetAllHiddenFrequentlyAssignedNext';
import { useGetAllWatchedVideos } from 'services/api/product/assignContent/useGetAllWatchedVideos';
import { useGetIncubatePlaybookFavoriteIds } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookFavoriteIds';
import { useGetIncubatePlaybookInProgress } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookInProgress';
import { useGetIncubatePlaybookQuiz } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookQuiz';
import { useGetIncubatePlaybookVideosWatchedTime } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookVideosWatchedTime';
import { useGetIncubatePlaybookVisited } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookVisited';
import { useSessionData } from 'services/api/useSessionData';

export const PlaybookLayout = ({ children }: { children: ReactNode }) => {
  const { permissions } = useSessionData({});
  useGetIncubatePlaybookFavoriteIds({});
  useGetIncubatePlaybookInProgress({});
  useGetIncubatePlaybookVisited({});
  useGetIncubatePlaybookQuiz({});
  usePlaybookResources();
  useGetIncubatePlaybookVideosWatchedTime({});
  useSavePlaybookInfo();
  useGetAllFrequentlyAssignedNext({
    enabled: permissions.canCreateAssignments,
  });
  useGetAllHiddenFrequentlyAssignedNext({
    enabled: permissions.canCreateAssignments,
  });
  useGetAllWatchedVideos({ enabled: permissions.canViewTeamProgress });

  const { lessThenSm } = useCustomMediaQuery();

  return (
    <>
      <Box display="flex" bgcolor="background.playbook" overflow="auto">
        <IntroductionPlaybookModal />
        <PlaybookSidebar />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width={playbookContentWidth}
        >
          <Box width="100%" mb="40px">
            <PlaybookHeader />
            <Box sx={contentBoxSx(lessThenSm)}>{children}</Box>
          </Box>
          <PlaybookFooter />
        </Box>
      </Box>
    </>
  );
};

const playbookContentWidth = {
  minHeight: '100vh',
  xs: '100%',
  sm: 'calc(100% - 80px)',
  md: '100%',
};

const contentBoxSx = (lessThenSm: boolean) => ({
  mt: lessThenSm ? '95px' : 0,
  width: '100%',
});
