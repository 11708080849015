import CheckIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, Typography } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { Link } from 'components/Inputs/Link';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookSanity,
  IncubatePlaybookTaskActivity,
  IncubatePlaybookTaskSummary,
  IncubatePlaybookUnitData,
} from 'types/incubatePlaybook';
import { getBaseLink } from 'utils/incubatePlaybook';
import { FeedbackModule } from '../FeedbackModule';
import { TaskContactExpert } from './TaskContactExpert';
import { TaskResources } from './TaskResources';
import { TaskWrapper } from './TaskWrapper';

type PlaybookSummaryTaskProps = {
  unitData: IncubatePlaybookUnitData;
  title: string;
  allResources: IncubatePlaybookTaskActivity['resources'];
  allObjectives: Array<
    Pick<IncubatePlaybookTaskSummary, 'title' | 'objectives' | '_id' | 'slug'>
  >;
  helpLink: IncubatePlaybookSanity['contactUrl'];
};

export const PlaybookSummaryTask = ({
  unitData,
  allResources,
  allObjectives,
  helpLink,
  title,
}: PlaybookSummaryTaskProps) => {
  const { keyTakeawaysLabel } = useCommonTranslations();

  return (
    <Box display="flex" flexDirection="column" gap="30px">
      <TaskWrapper image={unitData.image}>
        <Box display="flex" flexDirection="column" gap="25px">
          <ObjectivesHeading />
          <UnitObjectives objectives={unitData.objectives} />
          {!!allObjectives.length && (
            <TaskObjectives
              allObjectives={allObjectives}
              unitSlug={unitData.slug.current}
            />
          )}
        </Box>
      </TaskWrapper>

      {unitData.keyTakeaways && (
        <TaskWrapper>
          <Box pt="22px">
            <Typography fontSize={20} fontWeight={600} mb="30px">
              {keyTakeawaysLabel}
            </Typography>
            <PortableText
              value={unitData.keyTakeaways}
              components={{
                list: {
                  number: ({ children }) => <ol style={olStyle}>{children}</ol>,
                },
              }}
            />
          </Box>
        </TaskWrapper>
      )}

      <TaskResources
        resources={allResources}
        moduleTitle={unitData.moduleTitle}
        phaseTitle={unitData.phaseTitle}
      />

      <FeedbackModule
        pageType="Summary Task"
        page={`${unitData.title} - ${title}`}
      />

      <TaskContactExpert helpLink={helpLink} />
    </Box>
  );
};

const ObjectivesHeading = () => {
  const {
    objectivesWithSingularLabel,
    hasYourTeamCompletedFollowingObjectivesQuestionLabel,
  } = useCommonTranslations();

  return (
    <Box>
      <Typography fontSize={20} fontWeight={600} mb="10px">
        {objectivesWithSingularLabel()}
      </Typography>

      <Typography fontSize={14}>
        {hasYourTeamCompletedFollowingObjectivesQuestionLabel}
      </Typography>
    </Box>
  );
};

const UnitObjectives = ({
  objectives,
}: {
  objectives: IncubatePlaybookUnitData['objectives'];
}) => (
  <Box display="flex" flexDirection="column" gap="19px">
    {objectives?.map((o) => (
      <Box key={o} display="flex" gap="12px">
        <CheckIcon sx={iconStyle} />
        <Typography>{o}</Typography>
      </Box>
    ))}
  </Box>
);

const TaskObjectives = ({
  allObjectives,
  unitSlug,
}: {
  allObjectives: PlaybookSummaryTaskProps['allObjectives'];
  unitSlug: string;
}) => {
  const router = useRouter();

  const onClick = useCallback(
    (slug: string) => () => {
      const baseLink = getBaseLink(router.asPath);
      router.push(`${baseLink}/${unitSlug}/task/${slug}`);
    },
    [router, unitSlug]
  );

  return (
    <ul style={ulStyle}>
      {allObjectives?.map(({ title, objectives, slug }, i) =>
        objectives?.map((o) => (
          <li key={o} style={liStyle}>
            <Typography mb="8px">{o}</Typography>
            <Link
              onClick={onClick(slug.current)}
              display="inline-block"
              color="secondary"
            >
              <Typography fontSize={14} sx={opacityStyle}>
                {title}
              </Typography>
            </Link>
          </li>
        ))
      )}
    </ul>
  );
};

const iconStyle = { opacity: 0.54 };
const olStyle = { lineHeight: '24px' };
const opacityStyle = { opacity: 0.8 };
const ulStyle = { margin: '0 0 -20px 0', paddingLeft: '55px' };
const liStyle = { marginBottom: '20px' };
