import { IncubatePlaybookUnitData } from 'types/incubatePlaybook';

export const getAllObjectives = (tasks: IncubatePlaybookUnitData['tasks']) => {
  if (!tasks) return [];

  return tasks
    .filter(({ objectives }) => objectives)
    .map(({ objectives, title, _id, slug }) => ({
      objectives: objectives?.filter(Boolean),
      title,
      _id,
      slug,
    }));
};
