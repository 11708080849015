import { useQuery } from '@tanstack/react-query';
import { IncubatePlaybookProps } from 'services/api/product/incubatePlaybook';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { AllWatchedVideos, AllWatchedVideosSchema } from 'types/AssignContent';
import { SocketHandlerProps } from 'types/socket';
import { getAllWatchedVideosRequest } from '.';
import { assignmentsQueryKeys } from './assignmentsQueryKeys';

const schema = AllWatchedVideosSchema;

type Request = IncubatePlaybookProps['params'];
type Response = AllWatchedVideos;

export const useGetAllWatchedVideos = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<void, Response>) => {
  const { playbookInfo, isPlaybookInfoValid } = useGetPlaybookInfo({});

  const successHandler = (result?: Response) => {
    onSuccess?.(result);
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<Request, void>({
    topic: socketTopics.GET_ALL_WATCHED_VIDEOS,
    request: getAllWatchedVideosRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [assignmentsQueryKeys.allWatchedVideos(playbookInfo)],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled: enabled && isPlaybookInfoValid,
    }
  );

  return { allWatchedVideos: data ?? [], isLoading };
};
