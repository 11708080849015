import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/lab';
import TreeItem from '@mui/lab/TreeItem';
import { Box, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { SearchFieldPlaybook } from 'components/Inputs/SearchFieldModern';
import { MuiIcon } from 'components/MuiIcon';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import {
  AssignableContent,
  AssignableContentType,
  PrimitiveAssignedContentTypeArray,
  SelectedUnits,
} from 'types/incubatePlaybook';

type SearchableTreeProps = {
  content: AssignableContent;
  selectedUnits: SelectedUnits;
  setSelectedUnits: Dispatch<SetStateAction<SelectedUnits>>;
  expandedNodes: string[];
};

const PlaybookSearchableTree = ({
  content,
  selectedUnits,
  setSelectedUnits,
  expandedNodes,
}: SearchableTreeProps) => {
  const [searchText, setSearchText] = useState('');
  const { searchLabel } = useCommonTranslations();

  const handleToggle = (node: AssignableContentType) => {
    setSelectedUnits((oldSelectedUnits) => {
      const currentIndex = oldSelectedUnits[node.id];
      const newChecked = { ...oldSelectedUnits };

      if (currentIndex) {
        delete newChecked[node.id];
      } else {
        newChecked[node.id] = {
          ...node,
          tasks: node?.tasks?.map((task) => task._id),
        };
      }

      return newChecked;
    });
  };

  const handleChange = (value: string) => {
    setSearchText(value);
  };

  const filteredData = useMemo(
    () => filterData(content as PrimitiveAssignedContentTypeArray, searchText),
    [searchText, content]
  );

  const renderTree = (nodes: AssignableContentType) => {
    const isTopLevel = nodes.hasOwnProperty('icon');
    const isLeaf = nodes.hasOwnProperty('tasks');

    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          <Box
            height={isTopLevel ? '60px' : '48px'}
            display="flex"
            alignItems="center"
          >
            {isTopLevel && <MuiIcon iconName={nodes.icon} sx={iconStyle} />}
            <Typography
              variant={isTopLevel ? 'body1' : 'body2'}
              ml={isTopLevel || isLeaf ? 1 : 0}
            >
              {nodes.title}
            </Typography>
          </Box>
        }
        endIcon={
          isLeaf && (
            <Checkbox
              color="secondary"
              edge="end"
              checked={selectedUnits[nodes.id] !== undefined}
              tabIndex={-1}
              disableRipple
            />
          )
        }
        onClick={() => isLeaf && handleToggle(nodes)}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <>
      <SearchFieldPlaybook
        label={searchLabel}
        autoFocus={false}
        onSearch={handleChange}
        searchOnChange={true}
        innerStyle={{ mx: 0 }}
        debounceTime={0}
        searchLength={0}
        collapseOnSm={false}
      />
      <TreeView
        sx={treeSx}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={expandedNodes}
      >
        {filteredData?.map((item) => renderTree(item))}
      </TreeView>
    </>
  );
};

export default PlaybookSearchableTree;

const treeSx = {
  flexGrow: 1,
  mt: '10px',
  width: '100%',
  height: 'calc(100% - 57px)',
  overflowY: 'auto',
};

const iconStyle = { color: 'action.active' };

const filterData = (
  data: PrimitiveAssignedContentTypeArray,
  searchText: string
) => {
  if (!searchText) {
    return data;
  }

  return data?.reduce(
    (accumulator: PrimitiveAssignedContentTypeArray, item) => {
      const found = item.title.toLowerCase().includes(searchText.toLowerCase());

      if (found && (!item.children || item.children.length === 0)) {
        accumulator?.push(item);
      } else if (
        item.hasOwnProperty('children') &&
        Array.isArray(item.children)
      ) {
        const filteredChildren = filterData(item.children, searchText);
        if (filteredChildren && filteredChildren.length > 0) {
          accumulator?.push({ ...item, children: filteredChildren });
        }
      }

      return accumulator;
    },
    []
  );
};
