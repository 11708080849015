import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { clientQueryKeys } from 'services/api/client/clientQueryKeys';
import { useGetClient } from 'services/api/client/useGetClient';
import { useGetClientInfo } from 'services/api/client/useGetClientInfo';
import { queryKeys } from 'services/api/queryKeys';
import { DefaultListResponse } from 'types/api';
import { Client } from 'types/Client';

type ClientList = InfiniteData<DefaultListResponse<Client>>;

export const useClient = () => {
  const queryClient = useQueryClient();

  const { client, isLoadingClient } = useGetClient({});
  const { clientInfo, isLoadingClientInfo } = useGetClientInfo({});

  const setClient = (result: Client) => {
    if (clientInfo) {
      queryClient.setQueryData([clientQueryKeys.clientInfo()], () => result);
    }
    queryClient.setQueryData(
      [clientQueryKeys.single({ clientId: result._id })],
      () => result
    );
    queryClient.setQueriesData<ClientList>(
      [queryKeys.clientsBase()],
      (infiniteData) => {
        if (!infiniteData) return;

        return {
          ...infiniteData,
          pages: infiniteData.pages.map((page) => ({
            ...page,
            items: page.items.map((item) =>
              item._id === result._id ? result : item
            ),
          })),
        };
      }
    );
  };

  const refreshClient = () => {
    queryClient.invalidateQueries([clientQueryKeys.clientInfo()]);
    queryClient.invalidateQueries([clientQueryKeys.singleBase()]);
    queryClient.resetQueries([queryKeys.clientsBase()]);
  };

  return {
    client: clientInfo || client,
    setClient,
    refreshClient,
    isClientLoading: isLoadingClient || isLoadingClientInfo,
  };
};
