import ReplayIcon from '@mui/icons-material/Replay';
import { Box, IconButton } from '@mui/material';
import { LoadingBar } from 'components/Loading';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { BaseReactPlayerProps, OnProgressProps } from 'react-player/base';
import { default as ReactPlayer } from 'react-player/vimeo';
import { useGetUserOnProject } from 'services/api/client/project/useGetUserOnProject';
import { useGetIncubatePlaybookVideosWatchedTime } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookVideosWatchedTime';
import { useSetIncubatePlaybookVideosWatchedTime } from 'services/api/product/incubatePlaybook/useSetIncubatePlaybookVideoViewed';
import { PlaybookVideo as VideoType } from 'types/incubatePlaybook';
import PlaybookVideoMetadata from './PlaybookVideoMetadata';

type VideoState = {
  played: number;
  playedSeconds: number;
};

type VideoEmbedParams = {
  url?: string;
  video?: VideoType | null;
  transcriptName?: string;
  trackData?: {
    taskId: string;
    taskName: string;
    unitId: string;
    unitName: string;
    phaseId: string;
    phaseName: string;
  };
  hideSpeakerAndTranscript?: boolean;
  children?: ReactNode;
};

export const PlaybookVideo = ({
  url,
  video,
  transcriptName,
  trackData,
  hideSpeakerAndTranscript = false,
  children,
}: VideoEmbedParams) => {
  const { setVideosWatchedTime } = useSetIncubatePlaybookVideosWatchedTime({});
  const { data, isVideosWatchedTimeLoading } =
    useGetIncubatePlaybookVideosWatchedTime({});
  const { isCurrentUserOnProject } = useGetUserOnProject();

  const playing = useRef<boolean>(false);
  const [view, setView] = useState<'play' | 'replay'>('play');

  const lastWatchedTime = useRef<Record<string, number>>({});
  const videoState = useRef<VideoState | null>(null);
  const currentTrackingVideo = useRef<string | null>(null);

  const videoId = video?._id;
  const lastVideoData = data && videoId ? data[videoId] : undefined;
  const isTrackingEnabled = !!trackData && isCurrentUserOnProject && videoId;

  const onReady = ({ seekTo }: BaseReactPlayerProps) => {
    if (!isTrackingEnabled || !lastVideoData) return;

    if (!!lastVideoData.completedAt) {
      setView('replay');
    } else if (lastVideoData.progress < WATCHED_PERCENTAGE) {
      seekTo(lastVideoData.watchedSeconds, 'seconds');
    }
  };
  const onProgress = ({ played, playedSeconds }: OnProgressProps) => {
    if (
      !isTrackingEnabled ||
      !playedSeconds ||
      videoId !== currentTrackingVideo.current
    )
      return;

    videoState.current = { played, playedSeconds };

    const isWatched =
      played > WATCHED_PERCENTAGE &&
      isWatchedTimeValid(lastWatchedTime.current[videoId]);

    if (isWatched && url && videoId) {
      setVideosWatchedTime(
        {
          videoId,
          watchedSeconds: playedSeconds,
          progress: 1,
          ...trackData,
        },
        getISODate()
      );
      lastWatchedTime.current[videoId] = getTime();
    }
  };
  const onPlay = () => {
    if (isTrackingEnabled) {
      currentTrackingVideo.current = videoId;
      if (!lastVideoData && !videoState.current) {
        setVideosWatchedTime(
          {
            videoId,
            watchedSeconds: 0.3,
            progress: 0.00095,
            ...trackData,
          },
          getISODate()
        );
      }
    }
  };
  const onEnded = () => {
    if (isTrackingEnabled) {
      setView('replay');
    }
  };
  const videoReplayHandler = () => {
    setView('play');
    playing.current = true;
  };

  useEffect(() => {
    const trackVideo = () => {
      if (!trackData || !url || !videoId || !videoState.current) return;

      const lessThanWatched = videoState.current.played < WATCHED_PERCENTAGE;
      const moreThanWatched = videoState.current.played > WATCHED_PERCENTAGE;
      const isValid =
        lessThanWatched ||
        (moreThanWatched &&
          isWatchedTimeValid(lastWatchedTime.current[videoId]));
      if (!isValid) return;

      let completedAt;
      let progress = videoState.current.played;
      if (moreThanWatched) {
        completedAt = getISODate();
        progress = 1;
        lastWatchedTime.current[videoId] = getTime();
      }

      setVideosWatchedTime(
        {
          videoId,
          watchedSeconds: videoState.current.playedSeconds,
          progress,
          ...trackData,
        },
        completedAt
      );

      videoState.current = null;
      currentTrackingVideo.current = null;
    };

    window.addEventListener('beforeunload', trackVideo);

    return () => {
      window.removeEventListener('beforeunload', trackVideo);
      trackVideo();
      setView('play');
      playing.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (isTrackingEnabled && isVideosWatchedTimeLoading) {
    return <VideoLoading />;
  }

  return (
    <>
      {url && (
        <div style={wrapperStyle} key={url}>
          <ReactPlayer
            className="video"
            url={`${url}&transparent=0&portrait=0`}
            style={iframeStyle}
            width="100%"
            height="100%"
            controls
            playing={playing.current}
            onReady={onReady}
            onProgress={onProgress}
            onPlay={onPlay}
            onEnded={onEnded}
          />
          {view === 'replay' && <VideoReplay onClick={videoReplayHandler} />}
        </div>
      )}
      {video && (
        <PlaybookVideoMetadata
          video={video}
          transcriptName={transcriptName}
          hideSpeakerAndTranscript={hideSpeakerAndTranscript}
        >
          {children}
        </PlaybookVideoMetadata>
      )}
    </>
  );
};

const VideoLoading = () => (
  <Box sx={wrapperStyle}>
    <Box position="absolute" width="100%" top={12}>
      <LoadingBar />
    </Box>
  </Box>
);

const VideoReplay = ({ onClick }: { onClick: () => void }) => (
  <Box
    width="100%"
    height="100%"
    position="absolute"
    display="flex"
    justifyContent="center"
    alignItems="center"
    bgcolor="rgba(0, 0, 0, 0.38)"
  >
    <IconButton onClick={onClick}>
      <ReplayIcon fontSize="large" />
    </IconButton>
  </Box>
);

const wrapperStyle = {
  paddingBottom: '56.25%',
  position: 'relative' as const,
  width: '100%',
};
const iframeStyle = {
  position: 'absolute' as const,
  top: 0,
  left: 0,
  border: 0,
};

const getTime = () => new Date().getTime();
const isWatchedTimeValid = (lastWatched: number | null) => {
  if (!lastWatched) return true;

  return getTime() - lastWatched > TIME_BETWEEN_WATCHED_UPDATE;
};
const getISODate = () => new Date().toISOString();

const TIME_BETWEEN_WATCHED_UPDATE = 20 * 1000; // 20 seconds
const WATCHED_PERCENTAGE = 0.9; // 90%
