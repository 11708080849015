import { SearchFieldBasic } from './SearchFieldBasic';

type HeaderSearchFieldProps = {
  label: string;
  loading?: boolean;
  debounceTime?: number;
  autoFocus?: boolean;
  onSearch: (value: string) => void;
  innerStyle?: {
    mx?: string | number;
  };
};

export const HeaderSearchField = ({
  innerStyle = {},
  ...props
}: HeaderSearchFieldProps) => (
  <SearchFieldBasic {...props} innerStyle={innerStyle} style={searchStyle} />
);

const searchStyle = {
  width: 400,
  height: 42,
  borderRadius: '4px',
  primaryBgColor: 'white',
  borderColor: 'white',
};
