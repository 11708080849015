import { useQuery } from '@tanstack/react-query';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import {
  IncubatePlaybookFavoriteIds,
  IncubatePlaybookFavoriteIdsSchema,
} from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import {
  IncubatePlaybookProps,
  getIncubatePlaybookFavoriteIdsRequest,
} from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

const schema = IncubatePlaybookFavoriteIdsSchema;

export type ResponseData = IncubatePlaybookFavoriteIds;
type Request = IncubatePlaybookProps['params'];

export const useGetIncubatePlaybookFavoriteIds = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<void, ResponseData>) => {
  const { playbookInfo, isPlaybookInfoValid } = useGetPlaybookInfo({});

  const successHandler = (result?: ResponseData) => {
    onSuccess?.(result);
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.FAVORITE_IDS_STATE,
    request: getIncubatePlaybookFavoriteIdsRequest,
    shouldNotifyOnRestError: false,
    schema,
  });

  const { data } = useQuery(
    [incubatePlaybookQueryKeys.favoriteIds(playbookInfo)],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled: enabled && isPlaybookInfoValid,
    }
  );

  return { favoriteIds: data ?? ({} as IncubatePlaybookFavoriteIds) };
};
