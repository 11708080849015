import { Box } from '@mui/material';
import { ReactNode } from 'react';

export const PlaybookUnitContentWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box
      width="100%"
      maxWidth="920px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {children}
    </Box>
  );
};
