import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { ButtonContained, ButtonOutlined } from 'components/Inputs/Button';
import { useCustomMediaQuery } from 'hooks';
import { useCommonTranslations } from 'translations/hooks';
import { ModalStyled } from '../ModalStyled';

type DeleteModalProps = {
  title: string;
  alertTitle?: string;
  text: string;
  open: boolean;
  loading?: boolean;
  onDelete: () => void;
  onClose: () => void;
  cancelBtnColor?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'success'
    | 'info'
    | 'warning';
};

export const DeleteModal = ({
  title,
  alertTitle,
  text,
  open,
  loading,
  onClose,
  onDelete,
  cancelBtnColor = 'primary',
}: DeleteModalProps) => {
  const { cancelLabel, deleteLabel } = useCommonTranslations();
  const { lessThenSm } = useCustomMediaQuery();

  const style = lessThenSm ? { p: '40px 20px' } : { minWidth: 727 };

  return (
    <ModalStyled title={title} open={open} onClose={onClose} style={style}>
      <Box>
        <Alert
          severity="error"
          icon={
            <Box my="auto">
              <ErrorOutlineIcon />
            </Box>
          }
        >
          {alertTitle && (
            <AlertTitle sx={alertTitleStyle}>{alertTitle}</AlertTitle>
          )}
          <Typography>{text}</Typography>
        </Alert>
        <Box
          mt="90px"
          display="flex"
          gap="16px"
          justifyContent={lessThenSm ? 'space-between' : 'end'}
        >
          <ButtonOutlined
            onClick={onClose}
            disabled={loading}
            color={cancelBtnColor}
          >
            {cancelLabel}
          </ButtonOutlined>
          <ButtonContained loading={loading} color="error" onClick={onDelete}>
            {deleteLabel}
          </ButtonContained>
        </Box>
      </Box>
    </ModalStyled>
  );
};

const alertTitleStyle = { fontWeight: 500, fontSize: '20px' };
