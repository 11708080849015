import TimerIcon from '@mui/icons-material/TimerOutlined';
import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useCommonTranslations } from 'translations/hooks';
import { convertMilliseconds } from 'utils';
import { millisecondsIn } from 'utils/time';
import { TimeEstimateComponent } from '../TimeEstimateComponent';

type TimeEstimateProps = {
  hideClock?: boolean;
  variant?: Variant;
  task: any; // wanted to use @ts-nocheck || @ts-expect-error but TS docs actually state 'any' should be prefered
}; // TS was complaining about task.video below not existing on all possible task types
export const TaskTimeEstimate = ({
  task,
  hideClock,
  variant = 'body2',
}: TimeEstimateProps) => {
  const { minuteLabel } = useCommonTranslations();
  if (task.estimatedTimeCustom)
    return (
      <Box display="flex" alignItems="center" mb="5px">
        <TimeEstimateComponent
          variant={variant}
          justValue
          hideClock={hideClock}
          value={task.estimatedTimeCustom}
        />
      </Box>
    );

  if (task.estimatedTime)
    return (
      <Box display="flex" alignItems="center" mb="5px">
        <TimeEstimateComponent
          variant={variant}
          justValue
          hideClock={hideClock}
          value={task.estimatedTime}
        />
      </Box>
    );

  const videoTime = task.video?.source?.duration
    ? task.video?.source?.duration * 1000
    : null;

  if (videoTime)
    return (
      <Box
        display="flex"
        gap="8px"
        alignItems="center"
        mb="5px"
        sx={hideClock ? boxStyle : {}}
      >
        {!hideClock && <TimerIcon />}
        <Typography variant={variant}>
          {minuteLabel(
            convertMilliseconds(videoTime, millisecondsIn.minutes, 'up')
          )}
        </Typography>
      </Box>
    );

  return <></>;
};
const boxStyle = { color: 'text.secondary' };
