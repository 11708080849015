import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { ButtonText } from 'components/Inputs/Button';
import { useBrand, usePermissions } from 'hooks';
import Head from 'next/head';
import Img from 'next/image';
import { useRouter } from 'next/router';
import { CSSProperties, ReactNode, useCallback, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useClient } from 'store/rq/clientData';
import { getProcessEnv } from 'utils';
import { HeaderItem } from '../HeaderItem';
import { ProfileMenu } from '../ProfileMenu';

const processEnv = getProcessEnv();

enum PageKey {
  projects = 'projects',
  users = 'users',
  products = 'products',
  settings = 'settings',
  profile = 'profile',
}
type Page = { key: PageKey; value: string };

export const ClientHeader = ({ children }: { children: ReactNode }) => {
  const {
    permissions,
    isUserPlatformAdmin,
    isPlatformUser,
    isUserClientAdmin,
  } = usePermissions();

  const intl = useIntl();
  const router = useRouter();
  const { client } = useClient();
  const { brand } = useBrand();

  const clientId = router.query.clientId;
  const currentPage: PageKey = router.route.split('/')[3] as PageKey;

  const routerPushHandler = useCallback(
    (link: PageKey) => () => router.push(`/client/${clientId}/${link}`),
    [router, clientId]
  );

  const onGoBack = useCallback(() => {
    const url = isUserPlatformAdmin ? '/projects' : '/clients';
    router.push(url);
  }, [isUserPlatformAdmin, router]);

  // TODO: M149-1982 - how to fix header menu conditional
  const pages = useMemo(
    () =>
      getPages(
        intl,
        isUserPlatformAdmin,
        isPlatformUser,
        permissions.canListProjects,
        permissions.canUpdateWhiteLabeling
      ),
    [
      intl,
      isUserPlatformAdmin,
      isPlatformUser,
      permissions.canListProjects,
      permissions.canUpdateWhiteLabeling,
    ]
  );

  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href={brand?.logos?.smallLogo || processEnv.NEXT_PUBLIC_S3_MARK}
        />
      </Head>

      <AppBar
        color="navigation"
        elevation={24}
        component="nav"
        position="sticky"
        enableColorOnDark
        sx={appBarSx}
      >
        <Toolbar sx={toolbarStyle}>
          <Box display="flex">
            {isPlatformUser && (
              <ButtonText
                sx={arrowBackStyle}
                onClick={onGoBack}
                startIcon={<ArrowBackIosNewIcon />}
              />
            )}
            <ClientLogo
              title={client?.name}
              logoLink={brand?.logos?.largeLogo}
            />
          </Box>

          {(isUserClientAdmin || isPlatformUser) && (
            <Box display="flex" flexGrow={1}>
              {pages.map((page) => (
                <HeaderItem
                  key={page.key}
                  activePage={currentPage === page.key}
                  onClick={routerPushHandler(page.key)}
                  dataTestId={`client-nav-${page.value.replaceAll(' ', '-')}`}
                >
                  {page.value}
                </HeaderItem>
              ))}
            </Box>
          )}
          <Box>
            <ProfileMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Container>
        <Box my={9}>{children}</Box>
      </Container>
    </>
  );
};

const getPages = (
  intl: IntlShape,
  isPlatformAdmin: boolean,
  isPlatformUser: boolean,
  canViewProjects?: boolean,
  canViewSettings?: boolean
): Page[] =>
  [
    {
      key: PageKey.projects,
      value: intl.formatMessage({
        id: 'projects',
        defaultMessage: 'Projects',
      }),
      hide: !canViewProjects || isPlatformAdmin,
    },
    {
      key: PageKey.users,
      value: intl.formatMessage({
        id: 'users',
        defaultMessage: 'Users',
      }),
      hide: isPlatformAdmin,
    },
    {
      key: PageKey.products,
      value: intl.formatMessage({ id: 'products', defaultMessage: 'Products' }),
      hide: isPlatformAdmin,
    },
    {
      key: PageKey.settings,
      value: isPlatformUser
        ? `${intl.formatMessage({
            id: 'client',
            defaultMessage: 'Client',
          })} ${intl.formatMessage({
            id: 'common.settings',
            defaultMessage: 'Settings',
          })}`
        : intl.formatMessage({
            id: 'common.settings',
            defaultMessage: 'Settings',
          }),

      hide: !canViewSettings || isPlatformAdmin,
    },
  ].filter((tab) => !tab.hide);

const ClientLogo = ({
  title,
  logoLink,
}: {
  title?: string;
  logoLink?: string;
}) => (
  <Box
    minWidth="230px"
    maxWidth="320px"
    fontSize={24}
    height="40px"
    gap="10px"
    display="flex"
    alignContent="center"
    marginRight="20px"
  >
    {logoLink ? (
      <Box width={190} height={40} position="relative">
        <Img src={logoLink} alt="logo" fill style={imgStyle} priority />
      </Box>
    ) : (
      <Typography noWrap mt="2px" fontSize={24}>
        {title}
      </Typography>
    )}
  </Box>
);

const imgStyle = {
  objectFit: 'contain',
  objectPosition: 'left',
} as CSSProperties;

const toolbarStyle = { justifyContent: 'space-between' };
const appBarSx = { boxShadow: 'none' };
const arrowBackStyle = { mr: '40px', cursor: 'pointer' };
