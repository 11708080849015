import { ChallengeStatement } from 'components/Playbook/VentureBuilder/ChallengeStatement/ChallengeStatement';
import { Currency } from 'components/Playbook/VentureBuilder/Currency';
import { FundingRequest } from 'components/Playbook/VentureBuilder/FundingRequest';
import { HypothesisBacklog } from 'components/Playbook/VentureBuilder/HypothesisBacklog';
import { MarketSize } from 'components/Playbook/VentureBuilder/MarketSize';
import { PrioritizedPainPoints } from 'components/Playbook/VentureBuilder/PrioritizedPainPoints';
import { StakeholderMap } from 'components/Playbook/VentureBuilder/StakeholderMap';
import { TopPrototypes } from 'components/Playbook/VentureBuilder/TopPrototypes';
import { TopValuePropositions } from 'components/Playbook/VentureBuilder/TopValuePropositions';
import { WhoMattersMost } from 'components/Playbook/VentureBuilder/WhoMattersMost';
import { useMemo } from 'react';
import { useProductTranslations } from 'translations/hooks';
import { HypothesisStatusOption } from 'types/ventureBuilder';

export enum SectionModes {
  PAGE = 'page',
  TASK = 'task',
}

export enum Slugs {
  WHO_MATTERS_MOST = 'who-matters-most',
  CHALLENGE_STATEMENT = 'challenge-statement',
  STAKEHOLDER_MAP = 'stakeholder-map',
  HYPOTHESIS_BACKLOG = 'hypothesis-backlog',
  TOP_VALUE_PROPOSITIONS = 'top-value-propositions',
  PRIORITIZED_PAIN_POINTS = 'prioritized-pain-points',
  TOP_PROTOTYPE = 'top-prototypes',
  CURRENCY = 'currency',
  MARKET_SIZE = 'market-size',
  FUNDING_REQUEST = 'funding-request',
}

export enum HypothesisBacklogStatus {
  validated = 'VALIDATED',
  invalidated = 'INVALIDATED',
  'currently-testing' = 'CURRENTLY TESTING',
}

export const useGetHypothesisStatusOptions = (): HypothesisStatusOption[] => {
  const { resolveHypothesisBacklogStatusLabel } = useProductTranslations();

  return useMemo(
    () =>
      Object.entries(HypothesisBacklogStatus).map(([value]) => ({
        value,
        label: resolveHypothesisBacklogStatusLabel(
          value as HypothesisBacklogStatus
        ),
      })),
    [resolveHypothesisBacklogStatusLabel]
  );
};

export const allSlugs = Object.values(Slugs);

export const slugToSectionMapper = {
  [Slugs.WHO_MATTERS_MOST]: (mode: SectionModes) => (
    <WhoMattersMost key={Slugs.WHO_MATTERS_MOST} mode={mode} />
  ),
  [Slugs.CHALLENGE_STATEMENT]: (mode: SectionModes) => (
    <ChallengeStatement key={Slugs.CHALLENGE_STATEMENT} mode={mode} />
  ),
  [Slugs.STAKEHOLDER_MAP]: () => <StakeholderMap key={Slugs.STAKEHOLDER_MAP} />,
  [Slugs.HYPOTHESIS_BACKLOG]: (mode: SectionModes) => (
    <HypothesisBacklog key={Slugs.HYPOTHESIS_BACKLOG} mode={mode} />
  ),
  [Slugs.TOP_VALUE_PROPOSITIONS]: (mode: SectionModes) => (
    <TopValuePropositions key={Slugs.TOP_VALUE_PROPOSITIONS} mode={mode} />
  ),
  [Slugs.PRIORITIZED_PAIN_POINTS]: (mode: SectionModes) => (
    <PrioritizedPainPoints key={Slugs.PRIORITIZED_PAIN_POINTS} mode={mode} />
  ),
  [Slugs.TOP_PROTOTYPE]: (mode: SectionModes) => (
    <TopPrototypes key={Slugs.TOP_PROTOTYPE} mode={mode} />
  ),
  [Slugs.CURRENCY]: (mode: SectionModes) => (
    <Currency key={Slugs.CURRENCY} mode={mode} />
  ),
  [Slugs.MARKET_SIZE]: (mode: SectionModes) => (
    <MarketSize key={Slugs.MARKET_SIZE} mode={mode} />
  ),
  [Slugs.FUNDING_REQUEST]: (mode: SectionModes) => (
    <FundingRequest key={Slugs.FUNDING_REQUEST} mode={mode} />
  ),
};
