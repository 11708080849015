import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Paper,
  Slide,
  Typography,
  alpha,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from 'common/enums';
import { SearchFieldPlaybook } from 'components/Inputs/SearchFieldModern';
import { MuiIcon } from 'components/MuiIcon';
import { useTheme } from 'next-themes';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCommonTranslations } from 'translations/hooks';
import {
  AssignableContent,
  IncubatePlaybookTaskWithVideo,
  PrimitiveAssignedContentType,
  PrimitiveAssignedContentTypeArray,
  SelectedUnits,
} from 'types/incubatePlaybook';
import { AssignableContentDetails } from './AssignableContentDetails';
import { AssignableUnitCard } from './AssignableUnitCard';
import { FrequentlyAssignedNextModalSection } from './FrequentlyAssignedNextModalSection';
import { TaskPreview } from './TaskPreview';
import TeamMembersSelectMobile from './TeamMembersSelectMobile';

type ExpandedPhase = {
  id: string;
  title: string;
} | null;

type SearchableListProps = {
  mobileModalStep: string;
  setMobileModalStep: Dispatch<SetStateAction<string>>;
  content: AssignableContent;
  selectedUnits: SelectedUnits;
  setSelectedUnits: Dispatch<SetStateAction<SelectedUnits>>;
  isPreviewTaskSelected: boolean;
  previewTask: IncubatePlaybookTaskWithVideo | null;
  setPreviewTask: Dispatch<
    SetStateAction<IncubatePlaybookTaskWithVideo | null>
  >;
  label: string;
};

export const PlaybookSearchableList = ({
  mobileModalStep,
  setMobileModalStep,
  content,
  selectedUnits,
  setSelectedUnits,
  previewTask,
  isPreviewTaskSelected,
  setPreviewTask,
  label,
}: SearchableListProps) => {
  const {
    searchLabel,
    videosLabel,
    selectedLabel,
    frequentlyAssignedNextLabel,
  } = useCommonTranslations();
  const { theme } = useTheme();
  const [searchText, setSearchText] = useState('');
  const [expandedPhase, setExpandedPhase] = useState<ExpandedPhase>(null);
  const { reset } = useFormContext();

  const handleChange = (value: string) => {
    setSearchText(value);
  };

  const unitsToShow = useMemo(
    () =>
      findLeafNodes(
        content as PrimitiveAssignedContentTypeArray,
        expandedPhase?.id,
        []
      )?.filter(
        (unit) => !searchText || unit.title.toLowerCase().includes(searchText)
      ),
    [content, expandedPhase?.id, searchText]
  );

  return (
    <>
      <Paper elevation={1} sx={paperSx(theme as Theme)}>
        <DialogTitle
          sx={{ px: 0 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          {label}
          <SearchFieldPlaybook
            label={searchLabel}
            autoFocus={false}
            onSearch={handleChange}
            searchOnChange={true}
            innerStyle={searchInnerStyle}
            debounceTime={0}
            searchLength={0}
            collapseOnSm={true}
            colorBasedOnTheme={false}
            searchOnClear={true}
          />
        </DialogTitle>
        <Divider />
      </Paper>
      <Box position="relative">
        <Slide
          direction="left"
          in={
            (!!expandedPhase || searchText !== '') &&
            mobileModalStep === 'assignmentVideos'
          }
          mountOnEnter
          unmountOnExit
        >
          <Box>
            {!!expandedPhase && (
              <Box
                display="flex"
                py="15px"
                alignItems="center"
                sx={cursorPointerSx}
              >
                <ArrowBackIcon
                  sx={iconStyle}
                  onClick={() => setExpandedPhase(null)}
                />
                <Typography variant="body1" ml={1}>
                  {expandedPhase?.title}
                </Typography>
              </Box>
            )}
            {(!!expandedPhase || searchText) &&
              unitsToShow?.map((unit) => (
                <AssignableUnitCard
                  key={unit.id}
                  unitId={unit.id}
                  selectedUnits={selectedUnits}
                  setSelectedUnits={setSelectedUnits}
                  setPreviewTask={setPreviewTask}
                />
              ))}
          </Box>
        </Slide>
        <Slide
          direction="right"
          in={
            !expandedPhase &&
            searchText === '' &&
            mobileModalStep === 'assignmentVideos'
          }
          mountOnEnter
          unmountOnExit
        >
          <List>
            {content?.map((item) => {
              const selectedVideosNum = selectedVideoNumInPhase(
                selectedUnits,
                // @ts-ignore
                findLeafNodes(content, item.id)
              );

              return (
                <ListItem
                  key={item.id}
                  onClick={() =>
                    setExpandedPhase({ id: item.id, title: item.title })
                  }
                  sx={listItemStyle}
                >
                  <Box display="flex" alignItems="center">
                    <MuiIcon iconName={item.icon} sx={iconStyle} />
                    <Box ml={1}>
                      <Typography variant="body1">{item.title}</Typography>
                      {!!selectedVideosNum && (
                        <Typography variant="caption" color="text.secondary">
                          {videosLabel(selectedVideosNum)} {selectedLabel}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <ArrowForwardIcon sx={iconStyle} />
                </ListItem>
              );
            })}
            <StyledListItem
              key="frequently-assigned-next"
              onClick={() => setMobileModalStep('frequentlyAssignedNext')}
            >
              <Typography variant="body1">
                {frequentlyAssignedNextLabel}
              </Typography>
              <ArrowForwardIcon sx={iconStyle} />
            </StyledListItem>
          </List>
        </Slide>
        <Slide
          direction="left"
          in={mobileModalStep === 'assignmentDetails'}
          mountOnEnter
          unmountOnExit
        >
          <Box position="absolute" top={0} width="100%">
            {mobileModalStep === 'assignmentDetails' && (
              <>
                <Box
                  display="flex"
                  py="15px"
                  alignItems="center"
                  sx={cursorPointerSx}
                >
                  <ArrowBackIcon
                    sx={iconStyle}
                    onClick={() => setMobileModalStep('assignmentVideos')}
                  />
                </Box>
                <AssignableContentDetails
                  setMobileModalStep={setMobileModalStep}
                />
              </>
            )}
          </Box>
        </Slide>
        <Slide
          direction="left"
          in={mobileModalStep === 'assignmentAssignees'}
          mountOnEnter
          unmountOnExit
        >
          <Box position="absolute" top={0} width="100%">
            {mobileModalStep === 'assignmentAssignees' && (
              <>
                <Box
                  display="flex"
                  py="15px"
                  alignItems="center"
                  sx={cursorPointerSx}
                >
                  <ArrowBackIcon
                    sx={iconStyle}
                    onClick={() => {
                      setMobileModalStep('assignmentDetails');
                      reset();
                    }}
                  />
                </Box>
                <TeamMembersSelectMobile />
              </>
            )}
          </Box>
        </Slide>
        <Slide
          direction="left"
          in={mobileModalStep === 'frequentlyAssignedNext'}
          mountOnEnter
          unmountOnExit
        >
          <Box position="absolute" top={0} width="100%">
            {mobileModalStep === 'frequentlyAssignedNext' && (
              <>
                <Box
                  display="flex"
                  py="15px"
                  alignItems="center"
                  sx={cursorPointerSx}
                >
                  <ArrowBackIcon
                    sx={iconStyle}
                    onClick={() => {
                      setMobileModalStep('assignmentVideos');
                      reset();
                    }}
                  />
                  <Typography variant="body1" ml={1}>
                    {frequentlyAssignedNextLabel}
                  </Typography>
                </Box>
                <FrequentlyAssignedNextModalSection
                  hideTitle={true}
                  selectedUnits={selectedUnits}
                  setSelectedUnits={setSelectedUnits}
                  setPreviewTask={setPreviewTask}
                />
              </>
            )}
          </Box>
        </Slide>
      </Box>
      {previewTask && (
        <TaskPreview
          task={previewTask}
          setPreviewTask={setPreviewTask}
          selected={isPreviewTaskSelected}
        />
      )}
    </>
  );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  ...listItemStyle,
  backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
  },
}));

const selectedVideoNumInPhase = (
  selectedUnits: SelectedUnits,
  unitsFromPhase: PrimitiveAssignedContentTypeArray
) => {
  return unitsFromPhase?.reduce((accumulator, unit) => {
    return accumulator + (selectedUnits[unit.id]?.tasks?.length ?? 0);
  }, 0);
};

const iconStyle = { color: 'action.active' };

const listItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '70px',
  backgroundColor: 'background.paper',
  p: '20px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'action.hover',
  },
};

const searchInnerStyle = { mx: 0, position: 'absolute' };

const cursorPointerSx = { cursor: 'pointer' };

const paperSx = (theme: Theme) => ({
  position: 'sticky',
  top: 0,
  zIndex: 2,
  borderRadius: 0,
  boxShadow: 'none',
  backgroundColor:
    theme === Theme.light ? 'background.default' : 'background.paper',
  mx: '-24px',
  px: '24px',
});

const findChildrenOfNodeById = (
  nodes: PrimitiveAssignedContentTypeArray,
  nodeId: string
): PrimitiveAssignedContentTypeArray | undefined => {
  if (!nodes) return;

  if (!nodeId) return nodes;

  for (let node of nodes) {
    if (node.id === nodeId) {
      return node.children; // Found the node with the given ID
    }

    if (node.children) {
      const childNodeChildren = findChildrenOfNodeById(node.children, nodeId); // Recursively search for the node in the children of this node
      if (childNodeChildren) {
        return childNodeChildren; // Found the node in the children of this node
      }
    }
  }
};

export function findNodeById(
  nodes: PrimitiveAssignedContentTypeArray,
  nodeId: string | undefined
): PrimitiveAssignedContentType | undefined {
  if (!nodes) return;

  if (!nodeId) return;

  for (let node of nodes) {
    if (node.id === nodeId) {
      return node; // Found the node with the given ID
    }

    if (node.children) {
      const childNode = findNodeById(node.children, nodeId); // Recursively search for the node in the children of this node
      if (childNode) {
        return childNode; // Found the node in the children of this node
      }
    }
  }

  return; // Didn't find the node with the given ID
}

function findLeafNodes(
  nodes: PrimitiveAssignedContentTypeArray,
  rootNodeId: string | undefined,
  leafNodes: PrimitiveAssignedContentTypeArray = []
): PrimitiveAssignedContentTypeArray {
  if (!nodes) return leafNodes;

  if (!rootNodeId) {
    for (let node of nodes) {
      findLeafNodes(nodes, node.id, leafNodes); // Recursively search for leaf nodes in each node in the tree
    }

    return leafNodes; // Return the leaf nodes found so far
  }

  const rootNode = findNodeById(nodes, rootNodeId); // Find the root node
  if (!rootNode) {
    return leafNodes; // Return the leaf nodes found so far
  }

  const children = rootNode.children || []; // Get the children of the root node, or an empty array if it has none

  if (children.length === 0) {
    leafNodes.push(rootNode); // If the root node has no children, it is a leaf node
  } else {
    for (let child of children) {
      findLeafNodes(nodes, child.id, leafNodes); // Recursively search for leaf nodes in the children of this node
    }
  }

  return leafNodes; // Return the leaf nodes found so far
}
