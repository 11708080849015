import { Box, Typography } from '@mui/material';
import { ButtonOutlined } from 'components/Inputs/Button/ButtonOutlined';
import { useCommonTranslations } from 'translations/hooks';

export const TaskContactExpert = ({
  helpLink,
}: {
  helpLink?: string | null;
}) => {
  const { stillNeedHelpQuestionLabel, contactShiftExpertLabel } =
    useCommonTranslations();

  if (!helpLink) return null;

  return (
    <Box
      my="80px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px="20px"
      color="secondary"
    >
      <Typography mb="30px" fontWeight={600} fontSize={20} my="auto" mr="30px">
        {stillNeedHelpQuestionLabel}
      </Typography>
      <Box>
        <a href={helpLink} target="_blank" rel="noreferrer">
          <ButtonOutlined color="secondary" sx={btnStyle}>
            {contactShiftExpertLabel}
          </ButtonOutlined>
        </a>
      </Box>
    </Box>
  );
};

const btnStyle = {
  borderRadius: '80px',
};
