import { LoadingButtonProps } from '@mui/lab';
import { ButtonContained } from 'components/Inputs/Button';

export const PlaybookButton = ({
  children,
  sx,
  ...props
}: LoadingButtonProps) => {
  return (
    <ButtonContained color="secondary" sx={getBtnStyle(sx)} {...props}>
      {children}
    </ButtonContained>
  );
};

const getBtnStyle = (sx: LoadingButtonProps['sx']) => ({
  borderRadius: '80px',
  paddingLeft: '20px',
  paddingRight: '20px',
  height: '42px',
  fontSize: '15px',
  ...sx,
});
