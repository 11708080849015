import { Box, Grid, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { SectionModes } from 'common/ventureBuilderConsts';
import {
  InputCurrencyField,
  InputCurrencyFieldOnChangeProps,
} from 'components/Inputs/InputCurrencyField';
import { useCustomMediaQuery } from 'hooks';
import { useCallback, useMemo, useRef } from 'react';
import { useCurrencyData } from 'services/api/product/ventureBuilder/currency/useCurrencyData';
import { useFundingRequestData } from 'services/api/product/ventureBuilder/fundingRequest/useFundingRequestData';
import { useProductTranslations } from 'translations/hooks';
import { currenciesMap } from '../Currency';

export const FundingRequest = ({ mode }: { mode: SectionModes }) => {
  const updateTimeoutRef = useRef<number>();
  const { lessThenSm } = useCustomMediaQuery();

  const isTaskViewMode = mode === SectionModes.TASK;

  const { currency } = useCurrencyData();

  const { fundingRequest, updateFundingRequest } = useFundingRequestData();

  const { fundingRequestLabel } = useProductTranslations();

  const prefixSign = useMemo(
    () => currenciesMap.find((cur) => cur.id === currency)?.sign ?? '',
    [currency]
  );

  const handleUpdate: any = useCallback(
    ({ key, value }: InputCurrencyFieldOnChangeProps) => {
      clearTimeout(updateTimeoutRef.current);

      updateTimeoutRef.current = window.setTimeout(() => {
        if (value || value === undefined) {
          updateFundingRequest({
            payload: {
              action: VentureBuilderItemAction.EDIT,
              data: { [key]: value ?? null },
            },
          });
        }
      }, debounceTime);
    },
    [updateFundingRequest]
  );

  return (
    <Box mt="30px">
      <Typography variant="h6" fontWeight="600">
        {fundingRequestLabel}
      </Typography>

      <Box padding="10px 0" overflow="scroll">
        <Grid
          container
          item
          sx={wrapperInputsStyle(isTaskViewMode, lessThenSm)}
        >
          <Grid item sm={getInputWidthInGrid(isTaskViewMode)}>
            <InputCurrencyField
              label={fundingRequestLabel}
              name="fundingRequest"
              prefix={prefixSign}
              onChange={handleUpdate}
              fullWidth
              value={fundingRequest?.fundingRequest || ''}
              disabled={!currency}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const debounceTime = 500;

const getInputWidthInGrid = (isTaskView: boolean) => {
  if (isTaskView) return 4.5;

  return 3.8;
};

const wrapperInputsStyle = (isTaskView: boolean, lessThenSm: boolean) => ({
  flexFlow: isTaskView || lessThenSm ? 'column' : 'row',
  columnGap: isTaskView || lessThenSm ? 0 : 2,
  rowGap: isTaskView || lessThenSm ? 4.5 : 0,
  justifyContent: 'space-between',
});
