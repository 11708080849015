import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { IncubatePlaybookInProgress } from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import {
  IncubatePlaybookInProgressUpdateParams,
  IncubatePlaybookInProgressUpdateProps,
  setIncubatePlaybookInProgressRequest,
} from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

export type ResponseData = IncubatePlaybookInProgress;
type Request = IncubatePlaybookInProgressUpdateProps['params'];

export const useSetIncubatePlaybookInProgress = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<void, ResponseData>) => {
  const queryClient = useQueryClient();
  const { playbookInfo } = useGetPlaybookInfo({});

  const successHandler = (result?: ResponseData) => {
    onSuccess?.(result);
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFnMutation } = useFetch<Request, ResponseData>({
    topic: socketTopics.PROGRESS_UPDATE,
    request: setIncubatePlaybookInProgressRequest,
    shouldNotifyOnRestError: false,
  });

  const { mutate } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const setInProgress = useCallback(
    (params: IncubatePlaybookInProgressUpdateParams) => {
      queryClient.invalidateQueries([
        incubatePlaybookQueryKeys.recentsListBase(),
      ]);
      queryClient.setQueriesData(
        [incubatePlaybookQueryKeys.inProgress(playbookInfo)],
        () => ({
          taskId: params.taskId,
          unitId: params.unitId,
          phaseId: params.phaseId,
        })
      );
      mutate({ defaultParams: playbookInfo, params });
    },
    [playbookInfo, mutate, queryClient]
  );

  return { setInProgress };
};
