import { useMediaQuery, useTheme } from '@mui/material';

export const useCustomMediaQuery = () => {
  const theme = useTheme();

  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'));
  const lessThenSm = useMediaQuery(theme.breakpoints.down('sm'));
  const greaterThenSm = useMediaQuery(theme.breakpoints.up('sm'));
  const lessThenMd = useMediaQuery(theme.breakpoints.down('md'));
  const greaterThenMd = useMediaQuery(theme.breakpoints.up('md'));
  const lessThen850 = useMediaQuery('(max-width:850px)');

  return {
    onlyXs,
    lessThenSm,
    greaterThenSm,
    lessThenMd,
    greaterThenMd,
    lessThen850,
  };
};
