import { DefaultParams } from 'types/api';

export type ClientsKey = ReturnType<typeof queryKeys.clients>;
export type ProductsKey = ReturnType<typeof queryKeys.products>;
export type RolesKey = ReturnType<typeof queryKeys.roles>;

export const queryKeys = {
  all: { scope: 'basic-info' } as const,
  featureFlags: () =>
    ({
      ...queryKeys.all,
      entity: 'feature-flags',
      params: undefined,
    } as const),
  productsBase: () =>
    ({
      ...queryKeys.all,
      entity: 'products',
    } as const),
  products: (filterValue = '') =>
    ({
      ...queryKeys.productsBase(),
      params: { filterValue },
    } as const),
  roles: () =>
    ({ ...queryKeys.all, entity: 'roles', params: undefined } as const),
  content: () =>
    ({ ...queryKeys.all, entity: 'content', params: undefined } as const),
  feature: () =>
    ({ ...queryKeys.all, entity: 'feature', params: undefined } as const),
  sessionData: () =>
    ({ ...queryKeys.all, entity: 'session-data', params: undefined } as const),
  clientsBase: () => ({ ...queryKeys.all, entity: 'clients' } as const),
  clients: (params: DefaultParams) =>
    ({ ...queryKeys.clientsBase(), params } as const),
  clientsBasePagination: () =>
    ({ ...queryKeys.all, entity: 'clients-pagination' } as const),
  clientsPagination: (params: DefaultParams) =>
    ({ ...queryKeys.clientsBasePagination(), ...params } as const),
  projectsBase: () => ({ ...queryKeys.all, entity: 'projects' } as const),
  projects: (params: DefaultParams) =>
    ({ ...queryKeys.projectsBase(), params } as const),
  projectsBasePagination: () =>
    ({ ...queryKeys.all, entity: 'projects-pagination' } as const),
  projectsPagination: (params: DefaultParams) =>
    ({ ...queryKeys.projectsBasePagination(), ...params } as const),
};
