import { Box, Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useGetIncubatePlaybookInProgress } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookInProgress';
import { useGetIncubatePlaybookVisited } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookVisited';
import { useSetIncubatePlaybookInProgress } from 'services/api/product/incubatePlaybook/useSetIncubatePlaybookInProgress';
import { useSetIncubatePlaybookVisited } from 'services/api/product/incubatePlaybook/useSetIncubatePlaybookVisited';
import { IncubatePlaybookUnitData } from 'types/incubatePlaybook';
import { urlForThumbnail } from 'utils/imageUrlBuilder';
import { getBaseLink } from 'utils/incubatePlaybook';
import { PlaybookFavoriteButton } from '../PlaybookFavoriteButton';

type PlaybookUnitHeaderProps = {
  unitData: IncubatePlaybookUnitData;
  taskId: string;
};

export const PlaybookUnitHeader = ({
  unitData,
  taskId,
}: PlaybookUnitHeaderProps) => {
  const router = useRouter();

  const { inProgress } = useGetIncubatePlaybookInProgress({});
  const { setInProgress } = useSetIncubatePlaybookInProgress({});
  const { visited, isVisitedLoading } = useGetIncubatePlaybookVisited({});
  const { setVisited } = useSetIncubatePlaybookVisited({});

  const task = useMemo(
    () => unitData.tasks?.find(({ _id }) => _id === taskId),
    [taskId, unitData.tasks]
  );

  useEffect(() => {
    if (task && inProgress.taskId !== task._id) {
      setInProgress({
        taskId: task._id,
        taskName: task.title,
        unitId: unitData._id,
        unitName: unitData.title,
        phaseId: unitData.phaseId,
        phaseName: unitData.phaseTitle,
        moduleName: unitData.moduleTitle,
        imageURL: urlForThumbnail(unitData.image) ?? '',
      });
    }

    const itemId = `${unitData._id}-${taskId}`;
    if (!visited?.[itemId] && !isVisitedLoading) {
      setVisited({ itemId });
    }
  }, [
    inProgress,
    isVisitedLoading,
    setInProgress,
    setVisited,
    task,
    taskId,
    unitData,
    visited,
  ]);

  const goToPhaseHome = useCallback(() => {
    router.push(`${getBaseLink(router.asPath)}/phase/${unitData.phaseSlug}`);
  }, [router, unitData.phaseSlug]);
  const goToPhaseHomeModule = useCallback(() => {
    router.push(
      `${getBaseLink(router.asPath)}/phase/${unitData.phaseSlug}#${
        unitData.moduleSlug
      }`
    );
  }, [router, unitData.moduleSlug, unitData.phaseSlug]);

  return (
    <Box width="100%" mb={unitHeaderMarginBottom}>
      <Container>
        <Box
          data-testid="breadcrumbs"
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          my="20px"
        >
          <Typography sx={phaseTitleStyle} onClick={goToPhaseHome}>
            {unitData.phaseTitle}
          </Typography>
          <Typography sx={opacityStyle}>&nbsp;/&nbsp;</Typography>
          <Typography sx={onClickStyle} onClick={goToPhaseHomeModule}>
            {unitData.moduleTitle}
          </Typography>
          <Typography>&nbsp;/&nbsp;</Typography>
          <Typography>{unitData.title}</Typography>
        </Box>
      </Container>

      <Paper elevation={1}>
        <Container>
          <Box
            minHeight="116px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius="4px"
            flexWrap="wrap"
            py="16px"
          >
            <Box display="flex" flexDirection="column">
              <Typography sx={opacityStyle}>{unitData.moduleTitle}</Typography>
              <Typography fontSize={24} fontWeight={700} my="10px">
                {unitData.title}
              </Typography>
            </Box>
            <PlaybookFavoriteButton
              itemId={unitData._id}
              itemName={unitData.title}
              itemPhaseName={unitData.phaseTitle}
              itemModuleName={unitData.moduleTitle}
              itemImageURL={urlForThumbnail(unitData.image) ?? ''}
              itemType="unit"
              itemLink=""
              dataTestId="save-to-favorites-unit"
            />
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};

const opacityStyle = { opacity: 0.6 };
const onClickStyle = {
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
};
const phaseTitleStyle = {
  ...opacityStyle,
  ...onClickStyle,
};

const unitHeaderMarginBottom = { xs: '14px', md: '30px' };
