import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useLastRoute } from 'store/useLastRoute';

export const useGoBackHandler = () => {
  const router = useRouter();
  const { lastRoute } = useLastRoute();

  const goBackHandler = useCallback(
    (fallbackRoute: string = '/') => {
      if (lastRoute) {
        router.back();
      } else {
        router.push(fallbackRoute);
      }
    },
    [lastRoute, router]
  );

  return { goBackHandler };
};
