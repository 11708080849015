import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetIncubatePlaybookQuiz } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookQuiz';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useSetIncubatePlaybookQuiz } from 'services/api/product/incubatePlaybook/useSetIncubatePlaybookQuiz';
import { IncubatePlaybookTaskQuiz } from 'types/incubatePlaybook';
import { adaptQuestions, getBaseLink } from 'utils/incubatePlaybook';
import { PlaybookQuizActiveScreen } from './ActiveScreen';
import { PlaybookQuizReview } from './ReviewScreen';
import { PlaybookQuizStartScreen } from './StartScreen';
import { PlaybookQuizStartScreenTaken } from './StartScreenTaken';

type PlaybookQuizTaskProps = {
  data: IncubatePlaybookTaskQuiz;
  unitTitle: string;
  unitId: string;
  unitSlug: string;
};

export type PlaybookQuizForm = ReturnType<typeof adaptQuestions>;
type PlaybookQuizView = 'start' | 'active' | 'review';

export const PlaybookQuizTask = ({
  data,
  unitTitle,
  unitId,
  unitSlug,
}: PlaybookQuizTaskProps) => {
  const router = useRouter();

  const { taskQuiz } = router.query;

  const { playbookInfo } = useGetPlaybookInfo({});
  const { quizState } = useGetIncubatePlaybookQuiz({});
  const { setQuiz } = useSetIncubatePlaybookQuiz({});

  const { clientId, projectId, productId } = playbookInfo;

  const [form, setForm] = useState(adaptQuestions(data.questions));
  const [view, setView] = useState<PlaybookQuizView>('start');
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);

  const baseLink = useMemo(() => getBaseLink(router.asPath), [router]);
  const itemId = `${unitId}-${data._id}`;

  useEffect(() => {
    setView('start');
  }, [clientId, projectId, productId]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [currentQuestion]);

  useEffect(() => {
    if (view === 'active' && taskQuiz !== 'active') {
      router.replace(
        `${baseLink}/${unitSlug}/task/${data.slug.current}?taskQuiz=active`,
        undefined,
        { shallow: true }
      );
    }
    if (view !== 'active' && router.asPath.includes('?')) {
      router.replace(
        `${baseLink}/${unitSlug}/task/${data.slug.current}`,
        undefined,
        {
          shallow: true,
        }
      );
    }
  }, [baseLink, data._id, data.slug, router, taskQuiz, unitSlug, view]);

  const onStartQuiz = useCallback(() => {
    setForm(adaptQuestions(data.questions));
    setView('active');
    setCurrentQuestion(1);
  }, [data.questions]);

  const onExitQuiz = useCallback(() => {
    setView('start');
    setCurrentQuestion(0);
  }, []);

  const onFinishQuiz = useCallback(() => {
    const correct = form.filter(({ answer }) => !answer?.incorrect).length;
    setQuiz({
      itemId: `${itemId}`,
      total: form.length,
      correct,
    });
    setView('review');
    setCurrentQuestion(-1);
  }, [form, itemId, setQuiz]);

  switch (view) {
    case 'start': {
      if (quizState[itemId]) {
        return (
          <PlaybookQuizStartScreenTaken
            onClick={onStartQuiz}
            unitTitle={unitTitle}
            total={quizState[itemId].total}
            correct={quizState[itemId].correct}
          />
        );
      }

      return (
        <PlaybookQuizStartScreen onClick={onStartQuiz} unitTitle={unitTitle} />
      );
    }
    case 'active': {
      return (
        <PlaybookQuizActiveScreen
          form={form}
          setForm={setForm}
          onExitQuiz={onExitQuiz}
          onFinishQuiz={onFinishQuiz}
          updateCurrentQuestion={setCurrentQuestion}
        />
      );
    }
    case 'review': {
      return (
        <PlaybookQuizReview
          form={form}
          unitId={unitId}
          onExitQuiz={onExitQuiz}
          onRetakeQuiz={onStartQuiz}
        />
      );
    }
  }
};
