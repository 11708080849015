import { HocuspocusProvider } from '@hocuspocus/provider';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { EditorView } from 'prosemirror-view';
import { useEffect } from 'react';
import styles from 'styles/CollaborativeEditor.module.scss';
import { CollaborationUser } from 'types/User';
import MenuBar from './MenuBar/MenuBar';

// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-1160802996
const oldUpdateState = EditorView.prototype.updateState;

EditorView.prototype.updateState = function (state) {
  // This prevents the matchesNode error on hot reloads
  // @ts-ignore
  if (!this.docView) {
    return;
  }

  oldUpdateState.call(this, state);
};

const CollaborativeEditor = ({
  user,
  websocketProvider,
  roomName,
}: {
  user: CollaborationUser | null;
  websocketProvider: HocuspocusProvider;
  roomName: string;
}) => {
  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          // The Collaboration extension comes with its own history handling
          history: false,
        }),
        Underline,
        Collaboration.configure({
          document: websocketProvider.document,
        }),
        CollaborationCursor.configure({
          provider: websocketProvider,
        }),
      ],
    },
    [roomName]
  );

  useEffect(() => {
    if (editor && !editor?.isDestroyed && user) {
      editor.chain().updateUser(user).run();
    }
  }, [editor, user]);

  return (
    <div className={styles['editor']}>
      {editor && <MenuBar editor={editor} />}
      <EditorContent className={styles['editor__content']} editor={editor} />
    </div>
  );
};

export default CollaborativeEditor;
