import { Box, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useGetAllFrequentlyAssignedNext } from 'services/api/product/assignContent/useGetAllFrequentlyAssignedNext';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookTaskWithVideo,
  SelectedUnits,
} from 'types/incubatePlaybook';
import { AssignableUnitCard } from './AssignableUnitCard';

type FrequentlyAssignedNextModalSectionProps = {
  hideTitle?: boolean;
  selectedUnits: SelectedUnits;
  setSelectedUnits: Dispatch<SetStateAction<SelectedUnits>>;
  setPreviewTask: Dispatch<
    SetStateAction<IncubatePlaybookTaskWithVideo | null>
  >;
};

export const FrequentlyAssignedNextModalSection = ({
  hideTitle = false,
  selectedUnits,
  setSelectedUnits,
  setPreviewTask,
}: FrequentlyAssignedNextModalSectionProps) => {
  const { frequentlyAssignedNextLabel, noFrequentlyAssignedNextTopicsLabel } =
    useCommonTranslations();
  const { frequentlyAssignedNext } = useGetAllFrequentlyAssignedNext({});
  const { allUnitData, assignableContentIndexMap } = useIncubatePlaybookData();

  const sortedSelectedUnitsIds = useMemo(
    () =>
      Object.keys(selectedUnits).sort(
        (a, b) => assignableContentIndexMap[a] - assignableContentIndexMap[b]
      ),
    [selectedUnits, assignableContentIndexMap]
  );

  const nextUnit =
    allUnitData[sortedSelectedUnitsIds[sortedSelectedUnitsIds.length - 1]]
      ?.nextId;

  return (
    <Box mt="30px" mb="10px">
      {!hideTitle &&
      (!Object.keys(selectedUnits).length || nextUnit !== undefined) ? (
        <Typography
          variant="body2"
          color="text.secondary"
          fontWeight="700"
          gutterBottom
        >
          {frequentlyAssignedNextLabel}
        </Typography>
      ) : null}
      {!!Object.keys(selectedUnits).length && nextUnit !== undefined && (
        <AssignableUnitCard
          unitId={
            allUnitData[
              sortedSelectedUnitsIds[sortedSelectedUnitsIds.length - 1]
            ].nextId!
          }
          selectedUnits={selectedUnits}
          setSelectedUnits={setSelectedUnits}
          setPreviewTask={setPreviewTask}
        />
      )}
      {!Object.keys(selectedUnits).length ? (
        frequentlyAssignedNext.length ? (
          frequentlyAssignedNext
            .slice(0, 2)
            .map(({ unitId }) => (
              <AssignableUnitCard
                key={unitId}
                unitId={unitId}
                selectedUnits={selectedUnits}
                setSelectedUnits={setSelectedUnits}
                setPreviewTask={setPreviewTask}
              />
            ))
        ) : (
          <Typography variant="body2" textAlign="center" mt={4}>
            {noFrequentlyAssignedNextTopicsLabel}
          </Typography>
        )
      ) : null}
    </Box>
  );
};
