import { headerSocketTopic } from 'common/consts';
import { userRegistration } from 'common/enums';
import { ClientUserInvitation } from 'types/Client';
import { DefaultParams, DefaultRequest } from 'types/api';
import { API } from './utils';

export type ClientListRequestProps = DefaultRequest<DefaultParams>;

export type ClientCreateProduct = {
  productId: string;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
} | null;
export type ClientCreateRequestProps = DefaultRequest<{
  name: string;
  users: ClientUserInvitation[];
  userRegistration: userRegistration;
  products: ClientCreateProduct[];
}>;

export type ProjectsListRequestProps = DefaultRequest<DefaultParams>;

export const getClientsListRequest = ({
  params,
  topic,
}: ClientListRequestProps) => {
  return API.get('/v1/clients', {
    params,
    headers: { [headerSocketTopic]: topic },
  });
};

export const createClientRequest = ({
  params,
  topic,
}: ClientCreateRequestProps) =>
  API.post('/v2/clients', params, { headers: { [headerSocketTopic]: topic } });

export const getProjectsListRequest = ({
  params,
  topic,
}: ProjectsListRequestProps) =>
  API.get('/v1/projects', {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export const getProjectsAllListRequest = ({
  params,
  topic,
}: ProjectsListRequestProps) =>
  API.get('/v2/projects', {
    params,
    headers: { [headerSocketTopic]: topic },
  });
