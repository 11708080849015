import { headerSocketTopic } from 'common/consts';
import { DefaultParams, DefaultRequest } from 'types/api';
import { FeedbackPageType, FeedbackRatingStatus } from 'types/incubatePlaybook';
import { API } from '../../utils';

export type DefaultPlaybookParams = {
  clientId: string;
  projectId: string;
  productId: string;
};
export type IncubatePlaybookProps = DefaultRequest<DefaultPlaybookParams>;
export type IncubatePlaybookParams<T> = {
  defaultParams: IncubatePlaybookProps['params'];
  params: T;
};

const getRouteBase = ({
  clientId,
  projectId,
  productId,
}: IncubatePlaybookProps['params']) =>
  `/v1/clients/${clientId}/projects/${projectId}/products/${productId}`;

export const getIncubatePlaybookDataRequest = ({
  params,
  topic,
}: IncubatePlaybookProps) =>
  API.get(`${getRouteBase(params)}/content`, {
    headers: { [headerSocketTopic]: topic },
  });

export const getIncubatePlaybookFavoriteIdsRequest = ({
  params,
  topic,
}: IncubatePlaybookProps) =>
  API.get(`${getRouteBase(params)}/favorites?responseType=ids`, {
    headers: { [headerSocketTopic]: topic },
  });

export const getIncubatePlaybookInProgressRequest = ({
  params,
  topic,
}: IncubatePlaybookProps) =>
  API.get(`${getRouteBase(params)}/progressState`, {
    headers: { [headerSocketTopic]: topic },
  });

export const getIncubatePlaybookVisitedRequest = ({
  params,
  topic,
}: IncubatePlaybookProps) =>
  API.get(`${getRouteBase(params)}/visitedTasks`, {
    headers: { [headerSocketTopic]: topic },
  });

export const getIncubatePlaybookVideosWatchedTimeRequest = ({
  topic,
}: DefaultRequest) =>
  API.get('v2/users/video-progress', {
    headers: { [headerSocketTopic]: topic },
  });

export const getIncubatePlaybookQuizRequest = ({
  params,
  topic,
}: IncubatePlaybookProps) =>
  API.get(`${getRouteBase(params)}/quizzesState`, {
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookFavoriteUpdateParams = {
  isFavorite: boolean;
  itemId: string;
  itemName: string;
  itemPhaseName: string;
  itemType: string;
  itemLink: string;
};
export type IncubatePlaybookFavoriteUpdateProps = DefaultRequest<
  IncubatePlaybookParams<IncubatePlaybookFavoriteUpdateParams>
>;
export const setIncubatePlaybookFavoriteRequest = ({
  params: { defaultParams, params },
  topic,
}: IncubatePlaybookFavoriteUpdateProps) =>
  API.patch(`${getRouteBase(defaultParams)}?actionType=favorite`, params, {
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookInProgressUpdateParams = {
  taskId: string;
  taskName: string;
  unitId: string;
  unitName: string;
  phaseId: string;
  phaseName: string;
  moduleName: string;
  imageURL: string;
};
export type IncubatePlaybookInProgressUpdateProps = DefaultRequest<
  IncubatePlaybookParams<IncubatePlaybookInProgressUpdateParams>
>;
export const setIncubatePlaybookInProgressRequest = ({
  params: { defaultParams, params },
  topic,
}: IncubatePlaybookInProgressUpdateProps) =>
  API.patch(`${getRouteBase(defaultParams)}?actionType=inProgress`, params, {
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookVisitedUpdateParams = {
  itemId: string;
};
export type IncubatePlaybookVisitedUpdateProps = DefaultRequest<
  IncubatePlaybookParams<IncubatePlaybookVisitedUpdateParams>
>;
export const setIncubatePlaybookVisitedRequest = ({
  params: { defaultParams, params },
  topic,
}: IncubatePlaybookVisitedUpdateProps) =>
  API.patch(`${getRouteBase(defaultParams)}?actionType=visit`, params, {
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookVideosWatchedTimeUpdateParams = {
  videoId: string;
  watchedSeconds: number;
  progress: number;
  clientId: string;
  projectId: string;
  productId: string;
  taskId: string;
  taskName: string;
  unitId: string;
  unitName: string;
  phaseId: string;
  phaseName: string;
};
export type IncubatePlaybookVideosWatchedTimeUpdateProps =
  DefaultRequest<IncubatePlaybookVideosWatchedTimeUpdateParams>;
export const setIncubatePlaybookVideosWatchedTimeRequest = ({
  params,
  topic,
}: IncubatePlaybookVideosWatchedTimeUpdateProps) =>
  API.patch('v2/users/video-progress', params, {
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookQuizUpdateParams = {
  itemId: string;
  correct: number;
  total: number;
};
export type IncubatePlaybookQuizUpdateProps = DefaultRequest<
  IncubatePlaybookParams<IncubatePlaybookQuizUpdateParams>
>;
export const setIncubatePlaybookQuizRequest = ({
  params: { defaultParams, params },
  topic,
}: IncubatePlaybookQuizUpdateProps) =>
  API.patch(`${getRouteBase(defaultParams)}?actionType=quiz`, params, {
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookFavoriteListParams = DefaultRequest<
  IncubatePlaybookParams<Omit<DefaultParams, 'status'>>
>;
export const getIncubatePlaybookFavoriteListRequest = ({
  params: { defaultParams, params },
  topic,
}: IncubatePlaybookFavoriteListParams) =>
  API.get(`${getRouteBase(defaultParams)}/favorites?responseType=list`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export type SearchParams = {
  limit: number;
  offset: number;
  name?: string;
};
export type IncubatePlaybookSearchListParams = DefaultRequest<
  IncubatePlaybookParams<SearchParams>
>;
export const getIncubatePlaybookSearchListRequest = ({
  params: { defaultParams, params },
  topic,
}: IncubatePlaybookSearchListParams) =>
  API.get(`${getRouteBase(defaultParams)}/search`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookRecentsListParams = DefaultRequest<
  IncubatePlaybookParams<Omit<DefaultParams, 'status'>>
>;
export const getIncubatePlaybookRecentsListRequest = ({
  params: { defaultParams, params },
  topic,
}: IncubatePlaybookRecentsListParams) =>
  API.get(`${getRouteBase(defaultParams)}/recents`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookRecentsResources = IncubatePlaybookProps;
export const getIncubatePlaybookResourcesRequest = ({
  params: { clientId, projectId, productId },
  topic,
}: IncubatePlaybookRecentsResources) =>
  API.get(
    `/v1/clients/${clientId}/projects/${projectId}/groups/${productId}/resources`,
    { headers: { [headerSocketTopic]: topic } }
  );

export type IncubatePlaybookRecentsEnterpriseResources = DefaultRequest<{
  clientId: string;
  productId: string;
}>;
export const getIncubatePlaybookEnterpriseResourcesRequest = ({
  params: { clientId, productId },
  topic,
}: IncubatePlaybookRecentsEnterpriseResources) =>
  API.get(`/v2/clients/${clientId}/enterprise-resources/${productId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export type IncubatePlaybookFeedbackParams = {
  feedback: string | null;
  ratingValue: FeedbackRatingStatus;
  pageType: FeedbackPageType;
  page: string;
};
export type IncubatePlaybookFeedbackProps =
  IncubatePlaybookParams<IncubatePlaybookFeedbackParams>;
export const sendIncubatePlaybookFeedbackRequest = ({
  defaultParams,
  params,
}: IncubatePlaybookFeedbackProps) =>
  API.post(`${getRouteBase(defaultParams)}/feedback`, params, {
    headers: { [headerSocketTopic]: 'playbook|info|set' }, // topic not used - this is just for consistency
  });

export type PlaybookInfoProps = DefaultRequest<{
  playbookSlug: string;
}>;
export const getPlaybookInfoRequest = ({
  params: { playbookSlug },
  topic,
}: PlaybookInfoProps) =>
  API.get(`v2/users/last-visited-info/${playbookSlug}`, {
    headers: { [headerSocketTopic]: topic },
  });

export type SetPlaybookInfoProps = {
  playbookSlug: string;
  params: {
    clientId: string;
    projectId: string;
    productId: string;
  };
};
export const setPlaybookInfoRequest = ({
  playbookSlug,
  params,
}: SetPlaybookInfoProps) =>
  API.post(`v2/users/last-visited-info/${playbookSlug}`, params, {
    headers: { [headerSocketTopic]: 'playbook|info|set' }, // topic not used - this is just for consistency
  });

export const getLastPlaybookInfoRequest = ({ topic }: DefaultRequest) =>
  API.get('v2/users/last-visited-info', {
    headers: { [headerSocketTopic]: topic },
  });
