import axios from 'axios';
import { getLocalStorageOktaAccessToken } from 'common/localStorage';
import { getProcessEnv } from 'utils';

const processEnv = getProcessEnv();

const client = axios.create({
  baseURL: processEnv.NEXT_PUBLIC_AUTH_API_URL,
  responseType: 'json',
});

client.interceptors.request.use((config) => {
  const oktaAccessToken = getLocalStorageOktaAccessToken();
  if (oktaAccessToken) {
    config.headers['Authorization'] = `Bearer ${oktaAccessToken}`;
  }

  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const isUserInvalid = error.response?.status === 401;

    if (isUserInvalid) {
      console.log('Status 401', error.response);
    }

    return Promise.reject(error);
  }
);

export const AuthAPI = client;
