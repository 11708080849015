import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import { ButtonContained, ButtonOutlined } from 'components/Inputs/Button';
import { useCommonTranslations } from 'translations/hooks';

type ConfirmModalType = {
  name?: string;
  cancel: () => void;
  proceed: () => void;
};

const ConfirmDeleteModal = ({ name, cancel, proceed }: ConfirmModalType) => {
  const { areYouSureYouWantToDeleteNameLabel, cancelLabel, deleteLabel } =
    useCommonTranslations();

  return (
    <Dialog open={true} onClose={cancel}>
      <Box p="40px">
        <DialogContent>
          <Typography variant="h6" mb="10px">
            {areYouSureYouWantToDeleteNameLabel(name)}
          </Typography>
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" gap="8px" mt="20px">
          <ButtonOutlined color="secondary" onClick={cancel}>
            {cancelLabel}
          </ButtonOutlined>
          <ButtonContained variant="contained" color="error" onClick={proceed}>
            {deleteLabel}
          </ButtonContained>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
