import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { FundingRequest, VentureBuilderChange } from 'types/ventureBuilder';
import { useGetFundingRequest } from './useGetFundingRequest';
import { useUpdateFundingRequestData } from './useUpdateFundingRequestData';

export const useFundingRequestData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.FUNDING_REQUEST,
  };

  const {
    fundingRequest,
    isLoading: isGetting,
    setFundingRequest,
  } = useGetFundingRequest({
    defaultParams,
  });

  const { isLoading: isPosting, updateFundingRequestData } =
    useUpdateFundingRequestData({});

  type UpdateFundingRequest = {
    payload: VentureBuilderChange<FundingRequest>;
  };

  const updateFundingRequest = ({ payload }: UpdateFundingRequest) => {
    const oldData = { ...fundingRequest };

    setFundingRequest({ ...oldData, ...payload.data });
    updateFundingRequestData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setFundingRequest(oldData);
        },
      }
    );
  };

  return {
    fundingRequest,
    updateFundingRequest,
    isGetting,
    isPosting,
  };
};
