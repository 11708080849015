import type {
  PortableTextBlock,
  PortableTextListItemBlock,
} from '@portabletext/types';
import {
  EnterpriseResourceTypes,
  ProductType,
  ResourceTypes,
  SanityPopoverTypeEnum,
  incubatePlaybookTaskEnum,
} from 'common/enums';
import { Slugs } from 'common/ventureBuilderConsts';
import { WithRequired } from 'utils';
import { adaptIncubatePlaybookData } from 'utils/incubatePlaybook';
import { z } from 'zod';

export type DefaultIncubatePlaybookParams = {
  clientId: string;
  productId: string;
  projectId: string;
};

const SlugSchema = z.object({
  _type: z.literal('slug').optional(),
  current: z.string(),
});
const StandardSanityOptionsSchema = z.object({
  _id: z.string(),
  title: z.string(),
  estimatedTime: z.number().nullable().optional(),
  estimatedTimeCustom: z.string().nullable().optional(),
  objectives: z.string().array().nullable().optional(),
  content: z.any().optional().describe('PortableTextBlock'),
  slug: SlugSchema,
});

export type StandardSanityOptions = Omit<
  z.infer<typeof StandardSanityOptionsSchema>,
  'content'
> & { content?: PortableTextBlock };
export const StandardSanityImageOptionsSchema = z.object({
  image: z
    .object({
      asset: z.object({
        _ref: z.string(),
      }),
      _type: z.string(),
    })
    .optional(),
  imageAspectRatio: z.number().nullable().optional(),
  imageHeight: z.number().nullable().optional(),
  imageUrl: z.string().nullable().optional(),
  imageWidth: z.number().nullable().optional(),
});

export type StandardSanityImageOptions = z.infer<
  typeof StandardSanityImageOptionsSchema
>;

const SpeakerSchema = z
  .object({
    name: z.string(),
    title: z.string(),
    profileImg: z.object({
      asset: z.object({
        url: z.string(),
      }),
    }),
  })
  .partial();

export const PlaybookVideoSchema = z.object({
  _id: z.string(),
  source: z.object({
    embedUrl: z.string(),
    height: z.number(),
    width: z.number(),
    posterImage: z.string(),
    description: z.string().nullable().optional(),
    name: z.string(),
    duration: z.number(),
  }),
  transcript: z.any().describe('PortableTextListItemBlock'),
  speakers: SpeakerSchema.array(),
});
export type PlaybookVideo = Omit<
  z.infer<typeof PlaybookVideoSchema>,
  'transcript'
> & { transcript?: PortableTextListItemBlock };

export const VentureBuilderSchema = z.object({
  slug: z.object({
    _type: z.literal('slug').optional(),
    current: z.nativeEnum(Slugs),
  }),
});
export type VentureBuilder = z.infer<typeof VentureBuilderSchema>;

export const SanityPopoverSchema = z.object({
  activated: z.boolean(),
  content: z.any().describe('PortableTextBlock'),
  image: StandardSanityImageOptionsSchema.shape.image,
  type: z.nativeEnum(SanityPopoverTypeEnum),
});
export type SanityPopover = Omit<
  z.infer<typeof SanityPopoverSchema>,
  'content'
> & { content?: PortableTextBlock };

export const ResourceSchema = z.object({
  _id: z.string(),
  slug: SlugSchema,
  thumbnail: z
    .object({
      asset: z.object({
        url: z.string(),
      }),
    })
    .nullable(),
  title: z.string(),
  isEnterprise: z.boolean().optional(),
  resourceType: z.object({
    title: z.union([
      z.nativeEnum(ResourceTypes),
      z.nativeEnum(EnterpriseResourceTypes),
    ]),
    description: z.any().describe('PortableTextBlock'),
  }),
});
export type Resource = Omit<z.infer<typeof ResourceSchema>, 'resourceType'> & {
  resourceType: {
    title: ResourceTypes | EnterpriseResourceTypes;
    description?: PortableTextBlock;
  };
};

// export const IncubatePlaybookTaskGeneralSchema =
//   StandardSanityOptionsSchema.merge(
//     z.object({
//       _type: z.literal(incubatePlaybookTaskEnum.taskGeneral),
//     })
//   );
// export type IncubatePlaybookTaskGeneral = z.infer<
//   typeof IncubatePlaybookTaskGeneralSchema
// >;
export const IncubatePlaybookTaskIntroductionSchema =
  StandardSanityOptionsSchema.merge(
    z.object({
      _type: z.literal(incubatePlaybookTaskEnum.taskIntroduction),
    })
  );
export type IncubatePlaybookTaskIntroduction = z.infer<
  typeof IncubatePlaybookTaskIntroductionSchema
>;
export const IncubatePlaybookTaskConceptSchema =
  StandardSanityOptionsSchema.merge(
    z.object({
      _type: z.literal(incubatePlaybookTaskEnum.taskConcept),
      audiences: z.unknown().array().nullable().optional(),
      favoritable: z.boolean(),
      cdStages: z.unknown().array(),
      topics: z.unknown().array().nullable().optional(),
      video: PlaybookVideoSchema.nullable().optional(),
      resources: ResourceSchema.array().nullable().optional(),
      ventureBuilders: VentureBuilderSchema.array().nullable().optional(),
    })
  );
export type IncubatePlaybookTaskConcept = z.infer<
  typeof IncubatePlaybookTaskConceptSchema
>;
export const IncubatePlaybookTaskActivitySchema =
  StandardSanityOptionsSchema.merge(
    z.object({
      _type: z.literal(incubatePlaybookTaskEnum.taskActivity),
      instructions: z.any().optional().describe('PortableTextListItemBlock'),
      audiences: z.unknown().array().optional(),
      resources: ResourceSchema.array().nullable().optional(),
      topics: z.unknown().array().optional(),
      cdStages: z.unknown().array().optional(),
      video: PlaybookVideoSchema.nullable().optional(),
      ventureBuilders: VentureBuilderSchema.array().nullable().optional(),
    })
  );
export type IncubatePlaybookTaskActivity = Omit<
  z.infer<typeof IncubatePlaybookTaskActivitySchema>,
  'instructions'
> & { instructions?: PortableTextListItemBlock };

export const IncubatePlaybookTaskQuizSchema = StandardSanityOptionsSchema.merge(
  z.object({
    _type: z.literal(incubatePlaybookTaskEnum.taskQuiz),
    content: z.any().describe('PortableTextBlock'),
    audiences: z.unknown().array().optional(),
    questions: z.array(
      z.object({
        taskConcept: z.object({ _ref: z.string() }).optional(),
        content: z.string(),
        questionOptions: z.array(
          z.object({
            answerOption: z.string(),
            answerOptionId: z.string(),
            desc: z.string(),
            isCorrect: z.boolean().optional(),
          })
        ),
      })
    ),
  })
);
export type IncubatePlaybookTaskQuiz = Omit<
  z.infer<typeof IncubatePlaybookTaskQuizSchema>,
  'content'
> & { content?: PortableTextBlock };
export const IncubatePlaybookTaskSummarySchema =
  StandardSanityOptionsSchema.merge(
    z.object({
      _type: z.literal(incubatePlaybookTaskEnum.taskSummary),
    })
  );
export type IncubatePlaybookTaskSummary = z.infer<
  typeof IncubatePlaybookTaskSummarySchema
>;

export const IncubatePlaybookTaskSchema = z.discriminatedUnion('_type', [
  // IncubatePlaybookTaskGeneralSchema,
  IncubatePlaybookTaskIntroductionSchema,
  IncubatePlaybookTaskConceptSchema,
  IncubatePlaybookTaskActivitySchema,
  IncubatePlaybookTaskQuizSchema,
  IncubatePlaybookTaskSummarySchema,
]);
export type IncubatePlaybookTask = z.infer<typeof IncubatePlaybookTaskSchema>;

export type IncubatePlaybookTaskWithVideo = WithRequired<
  IncubatePlaybookTaskActivity | IncubatePlaybookTaskConcept,
  'video'
>;

export const IncubatePlaybookUnitSchema = StandardSanityOptionsSchema.merge(
  StandardSanityImageOptionsSchema
).merge(
  z.object({
    title: z.string(),
    tasks: IncubatePlaybookTaskSchema.array().optional(),
    summary: z.any().optional().describe('PortableTextBlock'),
    keyTakeaways: z.any().describe('PortableTextBlock'),
    objectives: z.string().array().nullable().optional(),
  })
);
export type IncubatePlaybookUnit = Omit<
  z.infer<typeof IncubatePlaybookUnitSchema>,
  'summary' | 'keyTakeaways'
> & { summary?: PortableTextBlock; keyTakeaways?: PortableTextBlock };

export const IncubatePlaybookModuleSchema = StandardSanityOptionsSchema.merge(
  z.object({
    title: z.string(),
    units: IncubatePlaybookUnitSchema.array().optional(),
  })
);
export type IncubatePlaybookModule = z.infer<
  typeof IncubatePlaybookModuleSchema
>;

export const IncubatePlaybookPhaseSchema = StandardSanityOptionsSchema.merge(
  StandardSanityImageOptionsSchema
).merge(
  z.object({
    title: z.string(),
    modules: IncubatePlaybookModuleSchema.array().optional(),
    overview: z.any().optional().describe('PortableTextBlock'),
    icon: z.string().optional(),
    refVideo: PlaybookVideoSchema.nullable().optional(),
  })
);
export type IncubatePlaybookPhase = Omit<
  z.infer<typeof IncubatePlaybookPhaseSchema>,
  'overview'
> & { overview?: PortableTextBlock };

export const IncubatePlaybookSanitySchema = StandardSanityOptionsSchema.merge(
  StandardSanityImageOptionsSchema
).merge(
  z.object({
    title: z.string(),
    phases: IncubatePlaybookPhaseSchema.array().optional(),
    overview: z.any().optional().describe('PortableTextBlock'),
    video: PlaybookVideoSchema.nullable().optional(),
    contactUrl: z.string().nullable().optional(),
    popovers: SanityPopoverSchema.array().optional(),
  })
);
export type IncubatePlaybookSanity = Omit<
  z.infer<typeof IncubatePlaybookSanitySchema>,
  'overview'
> & { overview?: PortableTextBlock };

export type IncubatePlaybook = ReturnType<
  ReturnType<typeof adaptIncubatePlaybookData>
>;

export const PlaybookContentSchema = z.object({
  sanity: IncubatePlaybookSanitySchema,
});
export type PlaybookOriginalContent = z.infer<typeof PlaybookContentSchema>;

export type IncubatePlaybookUnitData = IncubatePlaybookUnit & {
  phaseId: string;
  phaseTitle: string;
  phaseSlug: string;
  moduleId: string;
  moduleTitle: string;
  moduleSlug: string;
  prevTitle?: string;
  prevId?: string;
  nextTitle?: string;
  nextId?: string;
  assignableTasks: IncubatePlaybookTaskWithVideo[];
};

export const IncubatePlaybookInProgressSchema = z.object({
  taskId: z.string(),
  phaseId: z.string(),
  unitId: z.string(),
});
export type IncubatePlaybookInProgress = z.infer<
  typeof IncubatePlaybookInProgressSchema
>;
export const IncubatePlaybookFavoriteIdsSchema = z.record(z.boolean());
export type IncubatePlaybookFavoriteIds = z.infer<
  typeof IncubatePlaybookFavoriteIdsSchema
>;
export const IncubatePlaybookVisitedSchema = z.record(z.boolean());
export type IncubatePlaybookVisited = z.infer<
  typeof IncubatePlaybookVisitedSchema
>;
export const IncubatePlaybookVideosWatchedTimeSchema = z.record(
  z.object({
    watchedSeconds: z.number(),
    progress: z.number(),
    completedAt: z.string().optional(),
  })
);
export type IncubatePlaybookVideosWatchedTime = {
  [videoId: string]: {
    watchedSeconds: number;
    progress: number;
    completedAt?: string;
  };
};
export const IncubatePlaybookResourcesSchema = z.record(z.string());
export type IncubatePlaybookResources = z.infer<
  typeof IncubatePlaybookResourcesSchema
>;
export const IncubatePlaybookQuizSchema = z.record(
  z.object({
    correct: z.number(),
    total: z.number(),
    attempts: z.number(),
  })
);
export type IncubatePlaybookQuiz = z.infer<typeof IncubatePlaybookQuizSchema>;

export const IncubatePlaybookFavoriteSchema = z.object({
  isFavorite: z.boolean().optional(),
  itemId: z.string(),
  itemName: z.string(),
  itemPhaseName: z.string(),
  itemModuleName: z.string(),
  itemUnitName: z.string().optional(),
  itemImageURL: z.string().optional(),
  itemType: z.string(),
  itemLink: z.string(),
  dateAdded: z.string(),
});
export type IncubatePlaybookFavorite = z.infer<
  typeof IncubatePlaybookFavoriteSchema
>;

export const IncubatePlaybookSearchSchema = z.object({
  documentId: z.string(),
  documentName: z.string(),
  documentType: z.string(),
  parentId: z.string(),
  phaseName: z.string(),
  playbookId: z.string(),
});
export type IncubatePlaybookSearch = z.infer<
  typeof IncubatePlaybookSearchSchema
>;

export const IncubatePlaybookRecentsSchema = z.object({
  unitId: z.string(),
  unitName: z.string(),
  phaseName: z.string(),
  dateViewed: z.string(),
});
export type IncubatePlaybookRecents = z.infer<
  typeof IncubatePlaybookRecentsSchema
>;

export type FeedbackRatingStatus = 1 | 2 | 3 | 4 | 5;
export type FeedbackPageType =
  | 'Concept Task'
  | 'Activity Task'
  | 'Summary Task'
  | 'Search';

export type PlaybookVideoForProgress = {
  key: string;
  moduleTitle: string;
  name: string;
  phaseTitle: string;
  phaseId: string;
  videoKey: string;
  taskId: string;
  taskSlug: string;
  unitId: string;
  unitSlug: string;
  unitTitle: string;
};

export type StartEndPhase = {
  phaseTitle: string;
  phaseId: string;
  start?: PlaybookVideoForProgress;
  end?: PlaybookVideoForProgress;
};

export const PlaybookInfoDataSchema = z.object({
  clientId: z.string(),
  projectId: z.string(),
  productId: z.string(),
  productType: z.nativeEnum(ProductType),
});
export type PlaybookInfoData = z.infer<typeof PlaybookInfoDataSchema>;

export const PlaybookLastInfoDataSchema = PlaybookInfoDataSchema.merge(
  z.object({ slugId: z.string() })
).nullable();
export type PlaybookLastInfoData = z.infer<typeof PlaybookLastInfoDataSchema>;

// assign content

export type SelectedUnit = {
  id: string;
  title: string;
  tasks?: string[];
};

export type SelectedUnits = {
  [key: string]: SelectedUnit;
};

export type AssignableContentUnit = {
  title: string;
  id: string;
  tasks: (IncubatePlaybookTaskActivity | IncubatePlaybookTaskConcept)[];
};

export type AssignableContentModule = {
  title: string;
  id: string;
  children: AssignableContentUnit[];
};

export type AssignableContentPhase = {
  title: string;
  id: string;
  icon: string;
  children: AssignableContentModule[];
};

export type AssignableContent = AssignableContentPhase[] | undefined;

export type AssignableContentType = PrimitiveAssignedContentType & {
  icon?: string;
  tasks?: (IncubatePlaybookTaskActivity | IncubatePlaybookTaskConcept)[];
};

export type PrimitiveAssignedContentType = {
  title: string;
  id: string;
  children: PrimitiveAssignedContentType[];
};

export type PrimitiveAssignedContentTypeArray =
  | PrimitiveAssignedContentType[]
  | undefined;
