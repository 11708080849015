import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { Stakeholder, VentureBuilderChange } from 'types/ventureBuilder';
import { useGetWhoMattersMostData } from './useGetWhoMattersMostData';
import { useUpdateWhoMattersMostData } from './useUpdateWhoMattersMostData';

export const useWhoMattersMostData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.WHO_MATTERS_MOST,
  };

  const {
    stakeholders,
    setStakeholders,
    isLoading: isGetting,
  } = useGetWhoMattersMostData({
    defaultParams,
  });

  const { isLoading: isPosting, updateWhoMattersMostData } =
    useUpdateWhoMattersMostData({});

  type UpdateWhoMattersMostProps = {
    payload: VentureBuilderChange<Stakeholder>;
    newStakeholders: Stakeholder[];
  };

  const updateWhoMattersMost = ({
    payload,
    newStakeholders,
  }: UpdateWhoMattersMostProps) => {
    const oldStakeholders = [...stakeholders];

    setStakeholders(newStakeholders);

    updateWhoMattersMostData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setStakeholders(oldStakeholders);
        },
      }
    );
  };

  return {
    stakeholders,
    updateWhoMattersMost,
    isGetting,
    isPosting,
  };
};
