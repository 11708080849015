import CheckIcon from '@mui/icons-material/Check';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Avatar, Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { Link } from 'components/Inputs/Link';
import { Tooltip } from 'components/Inputs/Tooltip';
import { format, parseISO } from 'date-fns';
import { useCustomMediaQuery, useModalState } from 'hooks';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, ReactNode, SetStateAction, useRef, useState } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import { AssignmentProgress } from 'types/AssignContent';
import { IncubatePlaybookUnitData } from 'types/incubatePlaybook';
import { getInitials } from 'utils';
import { getBaseLink, sumVideosDuration } from 'utils/incubatePlaybook';
import { getUTCTimeDiffTextFromNow } from 'utils/time';
import { PlaybookButton } from '../PlaybookButton';
import { AssignmentsMobileDetails } from './AssignmentsMobileDetails';
import { EditMenu } from './EditMenu';

type AssignedCardTask = { taskId: string; isVideoWatched: boolean };
type AssignedCardProps = {
  assignmentId: string;
  unit: IncubatePlaybookUnitData;
  tasks: AssignedCardTask[];
  notes: string;
  dueDate: string;
  assignedBy: {
    name: string;
    profileImg?: string;
  };
  teamProgress: number;
  progress: AssignmentProgress;
  onExpand: Dispatch<SetStateAction<number | undefined>>;
};

export const AssignedCard = ({
  assignmentId,
  unit,
  tasks: _tasks,
  notes,
  dueDate,
  assignedBy,
  teamProgress,
  progress,
  onExpand,
}: AssignedCardProps) => {
  const { lessThenSm } = useCustomMediaQuery();
  const gridRef = useRef<HTMLDivElement>(null);

  const tasks = _tasks.map((task) => {
    const currentTask = unit.tasks?.find((t) => t._id === task.taskId);

    return {
      ...task,
      name: currentTask?.title ?? '',
      taskSlug: currentTask?.slug.current ?? '',
    };
  });

  const numOfVideos =
    unit?.assignableTasks?.filter((x) => tasks.some((s) => s.taskId === x._id))
      ?.length ?? 0;

  const {
    assignedVideoStatusLabel,
    teamProgressLabel,
    assignedByLabel,
    teamProgressInfoLabel,
    yourProgressLabel,
  } = useCommonTranslations();

  const expandHandler = () => {
    window.setTimeout(() => {
      onExpand(gridRef.current?.offsetHeight);
    }, 10);
  };

  return (
    <Grid item md={6} xs={12} ref={gridRef}>
      <Paper sx={paperStyle(lessThenSm)}>
        {lessThenSm ? (
          <MobileAssignedCard
            assignmentId={assignmentId}
            unit={unit}
            tasks={tasks}
            dueDate={dueDate}
            numOfVideos={numOfVideos}
            onExpand={expandHandler}
            assignedBy={assignedBy}
            teamProgress={teamProgress}
            progress={progress}
            notes={notes}
          />
        ) : (
          <Box display="flex" minHeight={429} height="100%">
            <Box display="flex" flexDirection="column" width="58%">
              <CardHeadingTooltip unit={unit} videos={numOfVideos} />
              <Divider />
              <CardTasks
                tasks={tasks}
                onExpand={expandHandler}
                unitSlug={unit.slug.current}
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box display="flex" flexDirection="column" width="41%">
              <Box display="flex" pr={1} pt={1} justifyContent="end">
                <EditMenu
                  assignmentId={assignmentId}
                  unitId={unit._id}
                  unitName={unit.title}
                />
              </Box>
              <Box display="flex" flexDirection="column" px={2.5} pb={1.5}>
                <DueDateCardItem dueDate={dueDate} />
                <CardItem name={assignedByLabel} mt={2}>
                  <Box display="flex" gap={1.15}>
                    <Avatar src={assignedBy.profileImg} sx={avatarStyle}>
                      {getInitials(assignedBy.name)}
                    </Avatar>
                    <Typography my="auto" variant="body2">
                      {assignedBy.name}
                    </Typography>
                  </Box>
                </CardItem>
                <NotesCardItem notes={notes} onExpand={expandHandler} />
                <CardItem name={teamProgressInfoLabel}>
                  <Typography variant="body2">
                    {teamProgressLabel(
                      Math.round(teamProgress * 100).toString()
                    )}
                  </Typography>
                </CardItem>
                <CardItem name={yourProgressLabel}>
                  <Typography variant="body2">
                    {assignedVideoStatusLabel(progress)}
                  </Typography>
                </CardItem>
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
    </Grid>
  );
};

const paperStyle = (lessThenSm: boolean) => ({
  boxShadow: '0px 30px 40px 4px rgba(0, 0, 0, 0.1)',
  mb: lessThenSm ? 10 : 7,
});
const avatarStyle = { width: 32, height: 32 };

export const CardHeadingTooltip = ({
  unit,
  videos,
  showTooltip = true,
}: {
  unit: IncubatePlaybookUnitData;
  videos: number;
  showTooltip?: boolean;
}) => {
  const { asPath } = useRouter();

  const { videosLabel, videosTimeInTotalLabel } = useCommonTranslations();

  const subtitle = (unit.objectives ?? []).join(' ');
  const videosTime = sumVideosDuration(unit);

  const baseLink = getBaseLink(asPath);
  const link = `${baseLink}/${unit.slug.current}/task/${unit.tasks?.[0]?.slug.current}`;

  return (
    <Box p={2} pt={5}>
      <NextLink href={link}>
        <Typography variant="h6" sx={linkHoverStyle}>
          {unit.title}
        </Typography>
      </NextLink>
      <Tooltip
        title={subtitle}
        placement="top"
        enterNextDelay={1500}
        enabled={showTooltip}
      >
        <Typography
          variant="body2"
          my={2}
          color="primary.subtitle"
          noWrap={showTooltip}
        >
          {subtitle}
        </Typography>
      </Tooltip>
      <Box display="flex" gap={1.5} color="text.body1">
        <TimerOutlinedIcon />
        <Typography variant="body2" my="auto">
          {videosLabel(videos)}, {videosTimeInTotalLabel(videosTime)}
        </Typography>
      </Box>
    </Box>
  );
};

export const CardHeadingShowMore = ({
  unit,
  videos,
  onExpand,
}: {
  unit: IncubatePlaybookUnitData;
  videos: number;
  onExpand?: () => void;
}) => {
  const { asPath } = useRouter();

  const { videosLabel, videosTimeInTotalLabel, seeMoreLabel, seeLessLabel } =
    useCommonTranslations();

  const subtitle = (unit.objectives ?? []).join(' ');
  const videosTime = sumVideosDuration(unit);

  const baseLink = getBaseLink(asPath);
  const link = `${baseLink}/${unit.slug.current}/task/${unit.tasks?.[0]?.slug.current}`;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((o) => !o);
    onExpand?.();
  };

  return (
    <Box p={2} pt={5}>
      <NextLink href={link}>
        <Typography variant="h6" sx={linkHoverStyle}>
          {unit.title}
        </Typography>
      </NextLink>
      <Typography variant="body2" mt={2} color="primary.subtitle">
        {showMore || subtitle.length < 150
          ? subtitle
          : subtitle.slice(0, 150) + '...'}
      </Typography>
      {subtitle.length > 150 &&
        (showMore ? (
          <Link variant="body2" onClick={toggleShowMore} color="secondary">
            {seeLessLabel}
          </Link>
        ) : (
          <Link variant="body2" onClick={toggleShowMore} color="secondary">
            {seeMoreLabel}
          </Link>
        ))}
      <Box display="flex" gap={1.5} color="text.body1" mt={2}>
        <TimerOutlinedIcon />
        <Typography variant="body2" my="auto">
          {videosLabel(videos)}, {videosTimeInTotalLabel(videosTime)}
        </Typography>
      </Box>
    </Box>
  );
};

const linkHoverStyle = { '&:hover': { textDecoration: 'underline' } };

const CardTasks = ({
  tasks,
  onExpand,
  unitSlug,
}: {
  tasks: Array<AssignedCardTask & { name: string; taskSlug: string }>;
  onExpand: () => void;
  unitSlug: string;
}) => {
  const { asPath } = useRouter();

  const [showMoreList, setShowMoreList] = useState(false);
  const showMoreListToggle = () => {
    setShowMoreList((o) => !o);
    onExpand();
  };

  const { watchedLabel, seeLessLabel, seemoreWithNumInParenthesesLabel } =
    useCommonTranslations();

  const linkBase = `${getBaseLink(asPath)}/${unitSlug}/task/`;

  return (
    <Box
      mt={2}
      mr={1.5}
      mb={6}
      ml={3}
      display="flex"
      flexDirection="column"
      gap={3}
    >
      {tasks
        .slice(0, tasks.length > 3 && !showMoreList ? 2 : tasks.length)
        .map((task) => (
          <Box key={task.taskId} display="flex" gap={1.5} alignItems="center">
            <Box width={20} minWidth={20} display="flex" alignItems="center">
              {task.isVideoWatched && (
                <Tooltip title={watchedLabel} placement="left">
                  <CheckIcon fontSize="small" color="success" />
                </Tooltip>
              )}
            </Box>
            <NextLink href={linkBase + task.taskSlug}>
              <Typography
                variant="body2"
                my="auto"
                color="secondary"
                sx={linkHoverStyle}
              >
                {task.name}
              </Typography>
            </NextLink>
          </Box>
        ))}
      {tasks.length > 3 && (
        <Box ml={4}>
          <Link onClick={showMoreListToggle} color="text">
            <Typography variant="body2" color="secondary">
              {showMoreList
                ? seeLessLabel
                : seemoreWithNumInParenthesesLabel(tasks.length - 2)}
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export const CardItem = ({
  name,
  mt = 4,
  children,
}: {
  name: string;
  mt?: number | string;
  children: ReactNode;
}) => (
  <Box mt={mt}>
    <Typography variant="caption" color="text.secondary" mb={0.4}>
      {name}
    </Typography>
    {children}
  </Box>
);

export const DueDateCardItem = ({ dueDate }: { dueDate: string }) => {
  const { locale = 'en-US' } = useRouter();

  const { dueDateLeftLabel, dueDateLabel } = useCommonTranslations();

  const dueDateFormatted = format(parseISO(dueDate), 'LLL dd, yyyy');
  const dueDateLeft = getUTCTimeDiffTextFromNow({
    locale,
    date: new Date(dueDate),
    addSuffix: false,
    strict: true,
  });

  return (
    <CardItem name={dueDateLabel} mt={0}>
      <Box display="flex" gap={1}>
        <Typography variant="body2">
          {dueDateFormatted}{' '}
          <Typography variant="caption" color="text.secondary">
            {` ${dueDateLeftLabel(dueDateLeft)}`}
          </Typography>
        </Typography>
      </Box>
    </CardItem>
  );
};

export const NotesCardItem = ({
  notes,
  onExpand,
}: {
  notes: string;
  onExpand: () => void;
}) => {
  const [showMoreNotes, setShowMoreNotes] = useState(false);
  const showMoreNotesToggle = () => {
    onExpand();
    setShowMoreNotes((o) => !o);
  };

  const { seeMoreLabel, seeLessLabel, notesLabel } = useCommonTranslations();

  const notesContent = notes.slice(
    0,
    showMoreNotes && notes.length > 47 ? notes.length : 40
  );
  const notesDots = !showMoreNotes && notes.length > 47 ? '...' : '';

  return (
    <CardItem name={notesLabel}>
      <Typography variant="body2">
        {`${notesContent} ${notesDots}`}
        {notes.length > 47 && (
          <Link onClick={showMoreNotesToggle} color="text">
            {` ${showMoreNotes ? seeLessLabel : seeMoreLabel}`}
          </Link>
        )}
      </Typography>
    </CardItem>
  );
};

type MobileAssignedCardProps = {
  assignmentId: string;
  unit: IncubatePlaybookUnitData;
  tasks: Array<AssignedCardTask & { name: string; taskSlug: string }>;
  dueDate: string;
  numOfVideos: number;
  onExpand: () => void;
  assignedBy: {
    name: string;
    profileImg?: string;
  };
  teamProgress: number;
  progress: AssignmentProgress;
  notes: string;
};
const MobileAssignedCard = ({
  assignmentId,
  unit,
  tasks,
  dueDate,
  numOfVideos,
  assignedBy,
  teamProgress,
  progress,
  onExpand,
  notes,
}: MobileAssignedCardProps) => {
  const { viewAssignmentDetailsLabel } = useCommonTranslations();

  const {
    arr: [isNewModalOpen, openNewModal, closeNewModal],
  } = useModalState();

  return (
    <Box>
      <AssignmentsMobileDetails
        open={isNewModalOpen}
        onClose={closeNewModal}
        assignmentId={assignmentId}
        unit={unit}
        dueDate={dueDate}
        numOfVideos={numOfVideos}
        assignedBy={assignedBy}
        teamProgress={teamProgress}
        progress={progress}
        notes={notes}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        mb={3}
        justifyContent="center"
      >
        <CardHeadingShowMore
          unit={unit}
          videos={numOfVideos}
          onExpand={onExpand}
        />
        <Divider />
        <CardTasks
          tasks={tasks}
          onExpand={onExpand}
          unitSlug={unit.slug.current}
        />
        <Divider />
        <Box px={3}>
          <DueDateCardItem dueDate={dueDate} />
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" pb={5} px={3}>
        <PlaybookButton fullWidth onClick={openNewModal} sx={btnStyle}>
          {viewAssignmentDetailsLabel}
        </PlaybookButton>
      </Box>
    </Box>
  );
};

const btnStyle = { px: 2 };
