import { useState, useCallback } from 'react';
import { PAGE_SIZE, TableSortModel } from 'components/Table';
import { defaultSortModel } from 'common/consts';
import { ClientStatus } from 'common/enums';

type DefaultValues = {
  page?: number;
  sort?: TableSortModel;
  search?: string;
  pageSize?: number;
  status?: ClientStatus | 'all';
};

export const useTableProps = (
  {
    page = 0,
    sort = defaultSortModel,
    search = '',
    pageSize = PAGE_SIZE,
    status = 'all',
  }: DefaultValues,
  callback?: () => void
) => {
  const [pageValue, setPageValue] = useState<number>(page);
  const [sortValue, setSortValue] = useState<TableSortModel>(sort);
  const [searchValue, setSearchValue] = useState<string>(search);
  const [pageSizeValue, setPageSizeValue] = useState<number>(pageSize);
  const [statusValue, setStatusValue] = useState<ClientStatus | 'all'>(status);

  const pageHandler = useCallback(
    (newPage: number) => {
      callback?.();
      setPageValue(newPage);
    },
    [callback]
  );

  const sortHandler = useCallback(
    (newSort: TableSortModel) => {
      callback?.();
      newSort.length
        ? setSortValue(newSort)
        : setSortValue(([{ field, sort }]) => [
            { field, sort: sort === 'asc' ? 'desc' : 'asc' },
          ]);
    },
    [callback]
  );

  const searchHandler = useCallback(
    (newSearch: string) => {
      callback?.();
      setSearchValue(newSearch);
      setPageValue(0);
    },
    [callback]
  );

  const statusHandler = useCallback(
    (newStatus: ClientStatus | 'all') => {
      callback?.();
      setStatusValue(newStatus);
    },
    [callback]
  );

  const pageSizeHandler = useCallback(
    (newPageSize: number) => {
      callback?.();
      setPageSizeValue(newPageSize);
    },
    [callback]
  );

  return {
    page: { value: pageValue, setValue: pageHandler },
    sort: { value: sortValue, setValue: sortHandler },
    search: { value: searchValue, setValue: searchHandler },
    pageSize: { value: pageSizeValue, setValue: pageSizeHandler },
    status: { value: statusValue, setValue: statusHandler },
  };
};
