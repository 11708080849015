import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useSessionData } from 'services/api/useSessionData';
import { useCommonTranslations } from 'translations/hooks';
import { AssignmentProgress } from 'types/AssignContent';
import { IncubatePlaybookUnitData } from 'types/incubatePlaybook';
import { getInitials } from 'utils';
import { CardHeadingShowMore, CardItem, DueDateCardItem } from './AssignedCard';
import { EditMenu } from './EditMenu';

type AssignmentsMobileDetailsProps = {
  open: boolean;
  onClose: () => void;
  assignmentId: string;
  unit: IncubatePlaybookUnitData;
  dueDate: string;
  numOfVideos: number;
  assignedBy: {
    name: string;
    profileImg?: string;
  };
  teamProgress: number;
  progress: AssignmentProgress;
  notes: string;
};

export const AssignmentsMobileDetails = ({
  open,
  onClose,
  assignmentId,
  unit,
  dueDate,
  numOfVideos,
  assignedBy,
  teamProgress,
  progress,
  notes,
}: AssignmentsMobileDetailsProps) => {
  const { permissions } = useSessionData({});
  const handleClose = () => {
    onClose();
  };

  const {
    assignedVideoStatusLabel,
    teamProgressLabel,
    assignedByLabel,
    teamProgressInfoLabel,
    yourProgressLabel,
    notesLabel,
  } = useCommonTranslations();

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={dialogPaperProps}>
      <IconButton aria-label="close" onClick={onClose} sx={closeButtonSx}>
        <CloseIcon />
      </IconButton>
      <CardHeadingShowMore unit={unit} videos={numOfVideos} />
      <Divider sx={dividerSx} />
      <DialogContent>
        <Box display="flex" flexDirection="column" px={1}>
          <DueDateCardItem dueDate={dueDate} />
          <CardItem name={assignedByLabel} mt={2}>
            <Box display="flex" gap={1.15}>
              <Avatar src={assignedBy.profileImg} sx={avatarStyle}>
                {getInitials(assignedBy.name)}
              </Avatar>
              <Typography my="auto" variant="body2">
                {assignedBy.name}
              </Typography>
            </Box>
          </CardItem>
          <CardItem name={notesLabel}>
            <Typography variant="body2">{notes}</Typography>
          </CardItem>
          <CardItem name={teamProgressInfoLabel}>
            <Typography variant="body2">
              {teamProgressLabel(Math.round(teamProgress * 100).toString())}
            </Typography>
          </CardItem>
          <CardItem name={yourProgressLabel}>
            <Typography variant="body2" fontStyle="italic">
              {assignedVideoStatusLabel(progress)}
            </Typography>
          </CardItem>
        </Box>
      </DialogContent>
      {(permissions.canEditAssignments || permissions.canDeleteAssignments) && (
        <DialogActions sx={dialogActionsSx}>
          <EditMenu
            assignmentId={assignmentId}
            unitId={unit._id}
            unitName={unit.title}
            onSuccess={handleClose}
            mode="mobile"
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

const avatarStyle = { width: 32, height: 32 };
const dialogActionsSx = {
  display: 'flex',
  justifyContent: 'space-between',
  p: '20px 28px',
};
const dialogPaperProps = { sx: { borderRadius: '10px' } };
const closeButtonSx = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'text.secondary',
};
const dividerSx = { mx: '10px' };
