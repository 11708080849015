import p from 'pluralize';

type PluralizeProps = {
  word: string;
  count?: number;
  showCount?: boolean;
};

export const pluralize = ({ word, count, showCount = false }: PluralizeProps) =>
  p(word, count, showCount);
