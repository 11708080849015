import { Theme } from 'common/enums';

const hexToRgb = (hex: string) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const getTextColor = (
  color?: string,
  theme?: string,
  primary: boolean = false
) => {
  const textColor = primary ? 'text.primary' : 'text.secondary';
  const textContrastColor = primary
    ? 'text.defaultContrast'
    : 'text.secondaryContrast';

  if (color === undefined) return textColor;

  const rgb = hexToRgb(color);
  if (rgb === null) return textColor;
  const brightness = Math.round(
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
  );

  const lightTextColor = brightness > 125 ? textColor : textContrastColor;
  const darkTextColor = brightness > 125 ? textContrastColor : textColor;

  return theme === Theme.light ? lightTextColor : darkTextColor;
};
