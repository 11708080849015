// import { Palette } from '@mui/material/styles';

export const light: any = {
  // MAYBE-TODO: adjust type
  primary: {
    main: '#22E77F',
    contrastText: '#fff',
  },
  secondary: {
    main: '#22E77F',
    contrastText: '#fff',
  },
  navigation: {
    main: '#fff',
  },
  text: {
    default: '#000',
    defaultContrast: '#fff',
    secondaryContrast: 'rgba(255, 255, 255, 0.7)',
    subtitle: 'rgba(0, 0, 0, 0.87)',
    body1: 'rgba(0, 0, 0, 0.6)',
    primaryText: '#00B660',
  },
  border: {
    main: '#E0E0E0',
  },
  quiz: {
    correct: 'rgba(46, 125, 50, 0.1)',
    correctText: 'rgb(200,227,198)',
    incorrect: 'rgba(237, 108, 2, 0.1)',
    incorrectText: 'rgb(249,221,174)',
  },
  background: {
    paper: '#fff',
    default: '#fafafa',
    changePassword: '#fafafa',
    changePasswordInput: '#fff',
    playbook: '#fafafa',
    icon: '#F3F4F6',
    cardActionsWrapper: 'rgba(56, 74, 95, 0.04)',
    playbookSearch: '#F6F6F6',
    playbookSearchBorder: '#F6F6F6',
    autofill: '#fff',
    clientOwners: '#FAFAFA',
    videoInProgress: 'rgb(34, 231, 127)',
    videoWatched: 'rgb(0, 182, 96)',
    videoNotStarted: '#E1E1E1',
    videoAssignedNotStarted: '#B2BECA',
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    navigation: Palette['primary'];
  }

  interface PaletteOptions {
    navigation?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    navigation: true;
  }
}
