import { documentFileTypes } from 'common/documentFileUpload';
import { ChangeEvent } from 'react';

type DocumentUploadInputProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  accept?: string[];
};

export const DocumentUploadInput = ({
  onChange,
  accept = documentFileTypes,
}: DocumentUploadInputProps) => (
  <input hidden accept={accept.join(', ')} type="file" onChange={onChange} />
);
