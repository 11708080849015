import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import { projectQueryKeys } from 'services/api/client/project/projectQueryKeys';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { AssignmentInfo } from 'types/AssignContent';
import { SocketHandlerProps } from 'types/socket';
import { UpdateAssignmentProps, updateAssignmentRequest } from '.';
import { useGetPlaybookInfo } from '../incubatePlaybook/useGetPlaybookInfo';
import { assignmentsQueryKeys } from './assignmentsQueryKeys';

type Request = UpdateAssignmentProps['params'];
type ResponseData = AssignmentInfo;

export const useUpdateAssignment = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<
  Partial<Pick<Request, 'assignmentId'>>,
  ResponseData
>) => {
  const { notify } = useNotifications();
  const queryClient = useQueryClient();
  const { playbookInfo } = useGetPlaybookInfo({});

  const assignmentId = defaultParams?.assignmentId;

  const refreshAssignments = useCallback(() => {
    if (assignmentId) {
      queryClient.refetchQueries([
        assignmentsQueryKeys.single({ assignmentId }),
      ]);
    }
    queryClient.invalidateQueries([assignmentsQueryKeys.singleFromTaskBase()]);
    queryClient.invalidateQueries([assignmentsQueryKeys.assignmentsListBase()]);
    queryClient.invalidateQueries([
      assignmentsQueryKeys.allAssignmentsListBase(),
    ]);
    queryClient.invalidateQueries([
      assignmentsQueryKeys.frequentlyAssignedNextBase(),
    ]);
    queryClient.invalidateQueries([projectQueryKeys.teamProgressBase()]);
  }, [assignmentId, queryClient]);

  const successHandler = useCallback(
    (result?: ResponseData) => {
      notify(NotificationType.ASSIGNMENT_UPDATED);
      onSuccess?.(result);
      refreshAssignments();
    },
    [notify, onSuccess, refreshAssignments]
  );
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFnMutation } = useFetch<Request, ResponseData>({
    topic: socketTopics.UPDATE_ASSIGNMENT,
    request: updateAssignmentRequest,
  });

  const { mutate, isLoading } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const updateAssignment = (params: Request['params']) => {
    if (assignmentId) {
      mutate({ assignmentId, params, defaultParams: playbookInfo });
    }
  };

  return {
    isUpdating: isLoading,
    updateAssignment,
  };
};
