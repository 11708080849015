import { DropResult } from '@hello-pangea/dnd';
import VentureBuilderIcon from '@mui/icons-material/BuildOutlined';
import { Box, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { SectionModes } from 'common/ventureBuilderConsts';
import { ReactNode, useMemo, useState } from 'react';
import { useTopPrototypesData } from 'services/api/product/ventureBuilder/topPrototypes/useTopPrototypesData';
import { useWhoMattersMostData } from 'services/api/product/ventureBuilder/whoMattersMost/useWhoMattersMostData';
import { useProductTranslations } from 'translations/hooks';
import { TopPrototype } from 'types/ventureBuilder';
import { removeEmptyKeys } from 'utils';
import TopValuePropositionsList from './TopPrototypesList';
import { TEMP_ID } from './TopPrototypesListItem';

export type TopPrototypesListState =
  | {
      type: VentureBuilderItemAction.VIEW;
    }
  | {
      type: VentureBuilderItemAction.EDIT;
      data: TopPrototype;
      index: number;
    }
  | {
      type: VentureBuilderItemAction.ADD;
      data: TopPrototype;
      index: number;
    };

export const TopPrototypes = ({ mode }: { mode: SectionModes }) => {
  const { notify } = useNotifications();

  const { topPrototypes, isGetting, updateTopPrototypes } =
    useTopPrototypesData();

  const { stakeholders } = useWhoMattersMostData();

  const [state, setState] = useState<TopPrototypesListState>({
    type: VentureBuilderItemAction.VIEW,
  });

  const topPrototypesLocal = useMemo(() => {
    const tmpTopPrototypes = [...topPrototypes];

    if (state.type === VentureBuilderItemAction.VIEW) return tmpTopPrototypes;

    const currentIndex =
      state.index > tmpTopPrototypes.length
        ? tmpTopPrototypes.length
        : state.index;

    if (state.type === VentureBuilderItemAction.ADD) {
      tmpTopPrototypes.splice(currentIndex, 0, state.data);

      return tmpTopPrototypes;
    }

    if (state.type === VentureBuilderItemAction.EDIT) {
      const isDeleted = !tmpTopPrototypes.some(
        (hb) => hb._id === state?.data?._id
      );
      if (isDeleted)
        setState(
          (prevState) =>
            ({
              ...prevState,
              type: VentureBuilderItemAction.ADD,
            } as TopPrototypesListState)
        );
      tmpTopPrototypes.splice(currentIndex, 1, state.data);

      return tmpTopPrototypes;
    }

    return tmpTopPrototypes;
  }, [topPrototypes, state]);

  // handlers
  const handleAdd = (addNewShouldBeOnTopOfTheList: boolean) => () => {
    setState({
      type: VentureBuilderItemAction.ADD,
      data: {
        _id: TEMP_ID,
        prototypeName: '',
        stakeholderId: '',
        averageNps: undefined,
      },
      index: addNewShouldBeOnTopOfTheList ? 0 : topPrototypesLocal.length,
    });
  };

  const handleEdit = (topPrototype: TopPrototype) => {
    setState({
      type: VentureBuilderItemAction.EDIT,
      data: topPrototype,
      index: topPrototypes.findIndex((tvp) => tvp._id === topPrototype._id),
    });
  };

  const handleCancel = () => {
    setState({ type: VentureBuilderItemAction.VIEW });
  };

  const handleDelete = (topPrototype: TopPrototype) => {
    const newData = topPrototypes.filter((tvp) => tvp._id !== topPrototype._id);

    updateTopPrototypes({
      payload: {
        action: VentureBuilderItemAction.DELETE,
        data: topPrototype,
      },
      newData,
    });
  };

  const stakeholderHasError = (topPrototype: TopPrototype): boolean => {
    if (state.type === VentureBuilderItemAction.VIEW) return true;
    const cleanupString = (string: string) => string?.toLowerCase().trim();

    const topPrototypeExists = topPrototypes.some(
      (tvp) =>
        tvp._id !== state.data._id &&
        !topPrototype.stakeholderId &&
        cleanupString(tvp.prototypeName) ===
          cleanupString(topPrototype?.prototypeName)
    );

    const topPrototypeForStakeholderExists = topPrototypes.some(
      (tvp) =>
        tvp._id !== state.data._id &&
        tvp.stakeholderId === topPrototype.stakeholderId &&
        cleanupString(tvp?.prototypeName) ===
          cleanupString(topPrototype?.prototypeName)
    );

    if (topPrototypeExists) {
      notify(NotificationType.TOP_PROTOTYPE_EXISTS);
    }

    if (topPrototypeForStakeholderExists) {
      notify(NotificationType.TOP_PROTOTYPE_FOR_STAKEHOLDER_EXISTS);
    }

    return topPrototypeExists || topPrototypeForStakeholderExists;
  };

  const handleSave = (topPrototype: TopPrototype) => {
    if (stakeholderHasError(topPrototype)) return false;

    // remove empty keys
    const tmpPayload = removeEmptyKeys(topPrototype);

    const newData = [...topPrototypes];
    if (state.type === VentureBuilderItemAction.ADD) {
      newData.push({ ...topPrototype });
    } else if (state.type === VentureBuilderItemAction.EDIT) {
      newData.splice(state.index, 1, topPrototype);
    }

    updateTopPrototypes({
      payload: {
        action: state.type,
        data: tmpPayload,
      },
      newData,
    });

    setState({ type: VentureBuilderItemAction.VIEW });

    return true;
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    const noChanges =
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index);

    if (noChanges) return;

    const newTopProto = [...topPrototypesLocal];
    const draggedTopProto = topPrototypesLocal.find(
      (tp) => tp._id === draggableId
    );
    newTopProto.splice(source.index, 1);
    newTopProto.splice(destination.index, 0, draggedTopProto as TopPrototype);

    if (state.type === VentureBuilderItemAction.VIEW) {
      updateTopPrototypes({
        payload: {
          action: VentureBuilderItemAction.EDIT,
          data: {
            ...draggedTopProto,
            newOrderIndex: destination.index,
          } as TopPrototype,
        },
        newData: newTopProto,
      });
    }
  };

  const renderList = (
    <TopValuePropositionsList
      isLoading={isGetting}
      collapseList={mode === SectionModes.TASK}
      data={topPrototypesLocal}
      stakeholders={stakeholders}
      state={state}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onCancel={handleCancel}
      onDelete={handleDelete}
      onSave={handleSave}
      onDragEnd={handleDragEnd}
    />
  );

  return mode === SectionModes.TASK ? (
    <SectionTaskWrapper>{renderList}</SectionTaskWrapper>
  ) : (
    <Box margin="32px 0">{renderList}</Box>
  );
};

const SectionTaskWrapper = ({ children }: { children: ReactNode }) => {
  const {
    objectiveDocumentSectionInVBLabel,
    topPrototypesLabel,
    documentAllTopPrototypesInVBLabel,
  } = useProductTranslations();

  return (
    <>
      <Box display="flex" alignItems="center" gap="10px">
        <VentureBuilderIcon />
        <Typography fontSize="20px">
          {objectiveDocumentSectionInVBLabel(topPrototypesLabel)}
        </Typography>
      </Box>
      <Typography margin="10px 0 30px 0">
        {documentAllTopPrototypesInVBLabel}
      </Typography>
      {children}
    </>
  );
};
