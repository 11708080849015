import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton } from '@mui/material';
import { ButtonOutlined, ButtonText } from 'components/Inputs/Button';
import { Dropdown } from 'components/Inputs/Dropdown';
import { Link } from 'components/Inputs/Link';
import { DeleteAssignmentModal } from 'components/Modal/Delete/DeleteAssignmentModal';
import { useModalState } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { useSessionData } from 'services/api/useSessionData';
import { useCommonTranslations } from 'translations/hooks';
import { AssignContentModal } from './AssignContentModal';

type EditMenuProps = {
  assignmentId: string;
  unitId: string;
  unitName: string;
  mode?: 'task' | 'dropdown' | 'mobile';
  onSuccess?: () => void;
};

export const EditMenu = ({
  assignmentId,
  unitId,
  unitName,
  mode = 'dropdown',
  onSuccess,
}: EditMenuProps) => {
  const { permissions } = useSessionData({});
  const { editLabel, deleteLabel } = useCommonTranslations();

  const {
    arr: [isEditModalOpen, openEditModal, closeEditModal],
  } = useModalState();
  const {
    arr: [isDeleteModalOpen, openDeleteModal, closeDeleteModal],
  } = useModalState();

  const [activeAssignmentId, setActiveAssignmentId] = useState<string | null>(
    null
  );

  const onEdit = useCallback(() => {
    openEditModal();
    setActiveAssignmentId(assignmentId);
  }, [assignmentId, openEditModal]);
  const onDelete = useCallback(() => {
    setActiveAssignmentId(assignmentId);
    openDeleteModal();
  }, [assignmentId, openDeleteModal]);
  const onClose = useCallback(() => {
    setActiveAssignmentId(null);
    closeEditModal();
    closeDeleteModal();
  }, [closeDeleteModal, closeEditModal]);
  const onDeleted = () => {
    onClose();
    onSuccess?.();
  };

  const list = useMemo(
    () => [
      { title: editLabel, onClick: onEdit },
      {
        title: deleteLabel,
        onClick: onDelete,
        sx: deleteItemStyle,
      },
    ],
    [editLabel, onEdit, deleteLabel, onDelete]
  );

  return permissions.canEditAssignments || permissions.canDeleteAssignments ? (
    <>
      {isEditModalOpen &&
        activeAssignmentId &&
        permissions.canEditAssignments && (
          <AssignContentModal
            open={isEditModalOpen}
            onClose={onClose}
            onSuccess={onSuccess}
            assignmentId={assignmentId}
          />
        )}
      {isDeleteModalOpen &&
        activeAssignmentId &&
        permissions.canDeleteAssignments && (
          <DeleteAssignmentModal
            assignmentId={assignmentId}
            unitId={unitId}
            unitName={unitName}
            open={isDeleteModalOpen}
            onClose={onClose}
            onDeleted={onDeleted}
          />
        )}
      {mode === 'dropdown' && (
        <Dropdown
          dropdownEl={
            <IconButton sx={iconButtonStyle}>
              <MoreVertIcon />
            </IconButton>
          }
          list={list}
        />
      )}
      {mode === 'task' && (
        <Box display="flex" justifyContent="space-between" width="100%">
          {permissions.canEditAssignments && (
            <ButtonText onClick={onEdit}>{editLabel}</ButtonText>
          )}
          {permissions.canDeleteAssignments && (
            <ButtonText onClick={onDelete} color="error">
              {deleteLabel}
            </ButtonText>
          )}
        </Box>
      )}
      {mode === 'mobile' && (
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          {permissions.canDeleteAssignments && (
            <Link onClick={onDelete} color="error">
              {deleteLabel}
            </Link>
          )}
          {permissions.canEditAssignments && (
            <ButtonOutlined
              onClick={onEdit}
              color="secondary"
              sx={mobileButtonSx}
            >
              {editLabel}
            </ButtonOutlined>
          )}
        </Box>
      )}
    </>
  ) : (
    <></>
  );
};

const iconButtonStyle = { p: 0.5 };
const deleteItemStyle = { color: 'error.main' };
const mobileButtonSx = { borderRadius: '35px' };
