import Airtable from 'assets/Resources/Icons/Airtable.svg';
import Excel from 'assets/Resources/Icons/Excel.svg';
import GoogleDoc from 'assets/Resources/Icons/GoogleDoc.svg';
import GoogleDrive from 'assets/Resources/Icons/GoogleDrive.svg';
import GoogleSheet from 'assets/Resources/Icons/GoogleSheet.svg';
import GoogleSlide from 'assets/Resources/Icons/GoogleSlide.svg';
import Miro from 'assets/Resources/Icons/Miro.svg';
import Pdf from 'assets/Resources/Icons/PDF.svg';
import PowerPoint from 'assets/Resources/Icons/PowerPoint.svg';
import Word from 'assets/Resources/Icons/Word.svg';
import { EnterpriseResourceTypes, ResourceTypes } from 'common/enums';

export const PlaybookResourceIcon = ({
  type,
}: {
  type: ResourceTypes | EnterpriseResourceTypes;
}) => {
  const {
    AIRTABLE,
    MIRO,
    GOOGLE_DOC,
    GOOGLE_SHEET,
    GOOGLE_SLIDE,
    GOOGLE_DRIVE,
  } = ResourceTypes;
  const { PDF, WORD, EXCEL, POWERPOINT } = EnterpriseResourceTypes;

  switch (type) {
    case GOOGLE_DOC:
      return <GoogleDoc />;
    case MIRO:
      return <Miro />;
    case AIRTABLE:
      return <Airtable />;
    case GOOGLE_SHEET:
      return <GoogleSheet />;
    case GOOGLE_SLIDE:
      return <GoogleSlide />;
    case GOOGLE_DRIVE:
      return <GoogleDrive />;
    case PDF:
      return <Pdf />;
    case WORD:
      return <Word />;
    case EXCEL:
      return <Excel />;
    case POWERPOINT:
      return <PowerPoint />;
    default:
      return null;
  }
};
