import { useQuery, useQueryClient } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import useSocket from 'common/useSocket';
import { useCallback, useMemo } from 'react';
import { useFetch, useLiveUpdate } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { SocketHandlerProps } from 'types/socket';
import { HypothesisList, HypothesisListSchema } from 'types/ventureBuilder';
import {
  GetVentureBuilderRequestParams,
  getVentureBuilderDataRequest,
} from '..';
import { ventureBuilderQueryKeys } from '../ventureBuilderQueryKeys';

const schema = HypothesisListSchema;

type ResponseData = HypothesisList;
type Request = GetVentureBuilderRequestParams['params'];

export const useGetHypothesisBacklogData = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<
  Omit<Request, 'vbSocketId'>,
  ResponseData['content']
>) => {
  const { notify } = useNotifications();
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  const successHandler = useCallback(
    (result?: ResponseData['content']) => {
      onSuccess?.(result);
    },
    [onSuccess]
  );
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const params = useMemo(
    () => ({ ...defaultParams!, vbSocketId: socket!.id }),
    [defaultParams, socket]
  );

  const setHypothesisBacklog = useCallback(
    (data: ResponseData['content']) => {
      queryClient.setQueryData(
        [ventureBuilderQueryKeys.hypothesisBacklog(params)],
        () => data
      );
    },
    [queryClient, params]
  );

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.GET_HYPOTHESIS_BACKLOG_DATA,
    ignoreTopicSuffix: true,
    request: getVentureBuilderDataRequest,
    adaptFn,
    schema,
  });

  const { data, isLoading } = useQuery(
    [ventureBuilderQueryKeys.hypothesisBacklog(params)],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useLiveUpdate<ResponseData>({
    topic: socketTopics.HYPOTHESIS_BACKLOG_LIVE_UPDATES,
    queryKeys: [ventureBuilderQueryKeys.hypothesisBacklog(params)],
    adaptFn,
    schema,
  });

  return {
    isLoading,
    hypothesisBacklog: data ?? [],
    setHypothesisBacklog,
  };
};

const adaptFn = (result?: ResponseData) => result?.content ?? null;
