import { Box, Button, Grid, Typography } from '@mui/material';
import { useValidateImage } from 'hooks';
import Img from 'next/image';
import { useCommonTranslations } from 'translations/hooks';
import { ButtonOutlined } from '../Button';
import { outlinedButtonStyle } from '../Button/ButtonOutlined';
import { ImageUploadInput } from './ImageUploadInput';

type ImageSize = {
  width: number;
  height: number;
};
type ImageUploadProps = {
  fileUrl?: string;
  size: ImageSize;
  loading?: boolean;
  disabled?: boolean;
  onUpload: (image: File) => void;
};

export const ImageUpload = ({
  fileUrl,
  size,
  loading,
  disabled,
  onUpload,
}: ImageUploadProps) => {
  const { validateImgInputHandler } = useValidateImage({
    onSuccess: ({ image }) => {
      onUpload(image);
    },
  });

  const { logoPreviewLabel, uploadLabel } = useCommonTranslations();

  return (
    <Grid container>
      <Grid item xs={5}>
        <Box sx={previewStyle}>
          {fileUrl ? (
            <Img
              src={fileUrl}
              alt="Logo preview"
              fill
              style={imgStyle}
              priority
            />
          ) : (
            <Typography>{logoPreviewLabel}</Typography>
          )}
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={7}
        flexDirection="column"
        justifyContent="center"
        spacing={3}
      >
        <Grid item>
          {loading ? (
            <ButtonOutlined loading={loading}>{uploadLabel}</ButtonOutlined>
          ) : (
            <Button
              variant="outlined"
              sx={outlinedButtonStyle}
              disabled={disabled}
              component="label"
            >
              {uploadLabel}
              <ImageUploadInput onChange={validateImgInputHandler} />
            </Button>
          )}
        </Grid>
        <Grid item>
          <HelpText width={size.width} height={size.height} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const HelpText = ({ width, height }: ImageSize) => {
  const { uploadPhotoBySizeLabel } = useCommonTranslations();

  return (
    <Typography fontSize="16px">
      {uploadPhotoBySizeLabel(width, height)}
    </Typography>
  );
};

const previewStyle = {
  width: '200px',
  height: '130px',
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
};

const imgStyle = { objectFit: 'contain' } as const;
