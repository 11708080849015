import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { Theme } from 'common/enums';
import { usePermissions } from 'hooks';
import { useTheme } from 'next-themes';
import Head from 'next/head';
import Img from 'next/image';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { getProcessEnv } from 'utils';
import { HeaderItem } from '../HeaderItem';
import { ProfileMenu } from '../ProfileMenu';

const processEnv = getProcessEnv();

enum PageKey {
  clients = 'clients',
  users = 'users',
  products = 'products',
  content = 'content',
  profile = 'profile',
  projects = 'projects',
}

type Page = { key: PageKey; value: string; hide?: boolean };

const getPages = (
  intl: IntlShape,
  isPlatformAdmin: boolean,
  isSuperAdmin: boolean,
  canViewProducts?: boolean
): Page[] => {
  return [
    {
      key: PageKey.clients,
      value: intl.formatMessage({ id: 'clients', defaultMessage: 'Clients' }),
      hide: isPlatformAdmin,
    },
    {
      key: PageKey.users,
      value: intl.formatMessage({
        id: 'users.usersShift',
        defaultMessage: 'Users',
      }),
      hide: isPlatformAdmin,
    },
    {
      key: PageKey.products,
      value: intl.formatMessage({ id: 'products', defaultMessage: 'Products' }),
      hide: !canViewProducts || isPlatformAdmin,
    },
    {
      key: PageKey.content,
      value: intl.formatMessage({ id: 'content', defaultMessage: 'Content' }),
      hide: isPlatformAdmin,
    },
    {
      key: PageKey.projects,
      value: intl.formatMessage({
        id: 'myProjects',
        defaultMessage: 'My Projects',
      }),
      hide: !isSuperAdmin,
    },
  ].filter(({ hide }) => !hide);
};

export const MainHeader = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { theme } = useTheme();
  const intl = useIntl();
  const { permissions, isUserPlatformAdmin, roles } = usePermissions();
  const currentPage: PageKey = router.asPath
    .split('/')[1]
    ?.split('?')[0] as PageKey;

  const routerPushHandler = useCallback(
    (link: PageKey) => () => router.push(`/${link}`),
    [router]
  );

  // TODO: M149-1982 - how to fix header menu conditional
  const pages = useMemo(
    () =>
      getPages(
        intl,
        isUserPlatformAdmin,
        roles.isUserPlatformSuperAdmin,
        permissions.canSeePlatformProducts
      ),
    [
      intl,
      isUserPlatformAdmin,
      permissions.canSeePlatformProducts,
      roles.isUserPlatformSuperAdmin,
    ]
  );

  const logo = useMemo(
    () =>
      theme === Theme.light
        ? processEnv.NEXT_PUBLIC_S3_LOGO
        : processEnv.NEXT_PUBLIC_S3_LOGO_DARK,
    [theme]
  );

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={processEnv.NEXT_PUBLIC_S3_MARK} />
      </Head>
      <AppBar
        color="navigation"
        elevation={24}
        component="nav"
        position="sticky"
        enableColorOnDark
        sx={appBarSx}
      >
        <Toolbar>
          <Box mr="100px">
            {logo && (
              <Img src={logo} alt="logo" height={35} width={114.05} priority />
            )}
          </Box>

          <Box display="flex" flexGrow={1}>
            {pages.map((item) => (
              <HeaderItem
                key={item.key}
                activePage={currentPage === item.key}
                onClick={routerPushHandler(item.key)}
                dataTestId={`mach-nav-${item.value.replaceAll(' ', '-')}`}
              >
                {item.value}
              </HeaderItem>
            ))}
          </Box>

          <Box>
            <ProfileMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Container>
        <Box my={9}>{children}</Box>
      </Container>
    </>
  );
};

const appBarSx = { boxShadow: 'none' };
