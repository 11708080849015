import { useQuery } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useTableProps } from 'hooks';
import { useCallback } from 'react';
import { formatTableParams, useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { ProjectSchema } from 'types/Client';
import { defaultListResponse } from 'types/api';
import { SocketHandlerProps } from 'types/socket';
import { z } from 'zod';
import { ProjectsListRequestProps, getProjectsListRequest } from '../clients';
import { projectQueryKeys } from './project/projectQueryKeys';

const schema = defaultListResponse(ProjectSchema);

type ResponseData = z.infer<typeof schema>;

export const useGetProjectsList = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<
  { limit?: number; forContextSwitching?: boolean },
  ResponseData
>) => {
  const { notify } = useNotifications();

  const { page, sort, search, pageSize } = useTableProps({});

  const successHandler = useCallback(() => {
    onSuccess?.();
  }, [onSuccess]);
  const failureHandler = useCallback(
    (message_key: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const params = formatTableParams({
    page: page.value,
    pageSize: pageSize.value,
    search: search.value,
    sort: sort.value,
    limit: defaultParams?.limit,
  });

  const { fetchFn } = useFetch<
    ProjectsListRequestProps['params'],
    ResponseData
  >({
    topic: socketTopics.CLIENT_PROJECTS_LIST,
    request: getProjectsListRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [
      projectQueryKeys.listAll({
        ...params,
        forContextSwitching: defaultParams?.forContextSwitching,
      }),
    ],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled,
    }
  );

  return {
    isLoading,
    data,
    page,
    pageSize,
    sort,
    search,
  };
};
