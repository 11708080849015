import { TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals';
import { useFormOptions } from 'common/formOptions';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useCommonTranslations } from 'translations/hooks';

type FormDatePicker = {
  disabled?: boolean;
};

export const DueDateSelect = ({ disabled = false }: FormDatePicker) => {
  const [open, setOpen] = useState(false);

  const { control } = useFormContext();
  const { required } = useFormOptions();

  const { dueDateLabel } = useCommonTranslations();

  return (
    <Controller
      control={control}
      name="dueDate"
      defaultValue={null}
      rules={{ required }}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <>
          <DatePicker
            disabled={disabled}
            open={open}
            inputFormat="MM/dd/yyyy"
            disablePast
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            label={<Typography variant="body2">{dueDateLabel}</Typography>}
            DialogProps={datePickerPaperProps}
            PaperProps={datePickerPaperProps}
            InputProps={datePickerInputProps}
            value={value}
            onChange={onChange}
            renderInput={(params: MuiTextFieldProps) => {
              return (
                <TextField
                  color="secondary"
                  size="small"
                  {...params}
                  sx={textFieldStyle(disabled)}
                  focused={open}
                  error={typeof errors?.dueDate !== 'undefined'}
                  helperText={errors?.dueDate?.message as string}
                  onClick={() => !disabled && setOpen(true)}
                />
              );
            }}
          />
        </>
      )}
    />
  );
};

const textFieldStyle = (disabled: boolean) => ({
  fontSize: '0.875rem',

  caretColor: 'transparent',
  svg: { display: 'none' },
  button: { display: 'none' },
  width: '100%',
  fieldset: {
    borderStyle: !disabled ? 'solid' : 'dotted',
  },

  '& .MuiInputLabel-root': {
    mt: '4px',
  },
  '& .MuiInputLabel-root[data-shrink="true"]': {
    mt: 0,
  },
});

const datePickerPaperProps = {
  sx: {
    '& .MuiPickersDay-root, & .PrivatePickersYear-yearButton': {
      '&.Mui-selected': {
        backgroundColor: 'secondary.main',
      },
    },
    '& .MuiDialogActions-root .MuiButtonBase-root': {
      color: 'secondary.main',
    },
  },
};

const datePickerInputProps = {
  sx: {
    fontSize: '0.875rem',
    height: '46px',
    color: 'text.primary',
  },
};
