import { Box, SxProps } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowParams,
  GridSortModel,
  GridValidRowModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { usePermissions } from 'hooks';
import { JSXElementConstructor, useMemo } from 'react';

export type TableColumn = GridColDef;
export type TableCellParams = GridValueGetterParams | GridRenderCellParams;
export type TableSortModel = GridSortModel;
export type SortModelChangeHandler = (model: TableSortModel) => void;
export type TableRow<T extends GridValidRowModel> = GridRowParams<T>;
export type TableRowHeightParams = GridRowHeightParams;

type TableProps = {
  disabled?: boolean;
  isStandAlone?: boolean;
  rows: Array<any>;
  columns: TableColumn[];
  loading?: boolean;
  NoRowsOverlay?: JSXElementConstructor<any>;
  sortModelChangeHandler: SortModelChangeHandler;
  sortModel: TableSortModel;
  page?: number;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  onPageSizeChange?: (pageSize: number) => void;
  rowCount?: number;
  onRowClick?: (row: GridRowParams) => void;
  getRowHeight?: (params: TableRowHeightParams) => GridRowHeightReturnValue;
  getRowClassName?: (params: GridRowClassNameParams<any>) => string;
  style?: SxProps;
  autoHeight?: boolean;
};

const Table = ({
  disabled,
  isStandAlone = true,
  rows,
  columns,
  loading,
  NoRowsOverlay,
  sortModelChangeHandler,
  sortModel,
  page,
  onPageChange,
  pageSize = PAGE_SIZE,
  onPageSizeChange,
  rowCount,
  onRowClick,
  getRowHeight,
  getRowClassName,
  style,
  autoHeight = true,
}: TableProps) => {
  const { roles } = usePermissions();

  const rowsPerPage = useMemo(() => {
    if (onPageSizeChange) {
      return roles.isUserPlatformSuperAdmin
        ? ROWS_PER_PAGE_OPTIONS_SUPER_ADMIN
        : ROWS_PER_PAGE_OPTIONS_USER;
    }

    return [PAGE_SIZE];
  }, [onPageSizeChange, roles.isUserPlatformSuperAdmin]);

  const sx = {
    ...(isStandAlone ? paperStyle : paperStyleInline),
    ...(disabled ? disabledStyle : undefined),
    ...style,
  } as SxProps;

  return (
    <Box height={autoHeight ? undefined : TABLE_HEIGHT} sx={sx}>
      <DataGrid
        sx={tableStyle}
        rowHeight={72}
        rows={rows}
        columns={columns}
        sortingMode="server"
        paginationMode={onPageChange ? 'server' : undefined}
        onSortModelChange={loading ? undefined : sortModelChangeHandler}
        sortModel={sortModel}
        components={{ NoRowsOverlay }}
        pageSize={pageSize}
        rowsPerPageOptions={rowsPerPage}
        onPageSizeChange={onPageSizeChange}
        page={page}
        rowCount={rowCount}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        disableSelectionOnClick
        disableColumnMenu
        autoHeight={autoHeight}
        loading={loading}
        getRowHeight={getRowHeight}
        getRowClassName={getRowClassName}
      />
    </Box>
  );
};

export const TABLE_HEIGHT = '509px';

export const PAGE_SIZE = 25;

export const ROWS_PER_PAGE_OPTIONS_USER = [5, 10, 25];

export const ROWS_PER_PAGE_OPTIONS_SUPER_ADMIN = [
  ...ROWS_PER_PAGE_OPTIONS_USER,
  50,
  100,
];

const disabledStyle = {
  opacity: 0.4,
  pointerEvents: 'none',
};

const paperStyle = {
  padding: '20px',
};

const paperStyleInline = {
  padding: '20px 0',
};

const tableStyle = {
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
    {
      width: 'auto',
      visibility: 'visible',
    },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiTablePagination-actions': {
    color: 'text.secondary',
  },
  border: 0,
};

export default Table;
