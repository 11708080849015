import { useMutation } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import {
  getClientPresignedUrlRequest,
  PresignedUrlRequestProps,
} from 'services/api/client';
import { uploadFileRequest } from 'services/api/client/uploadImage';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { FileType } from 'types/FileType';
import { SocketHandlerProps } from 'types/socket';

export type UrlResponse = {
  url: string;
};
type Request = PresignedUrlRequestProps['params'];

export type SuccessType = {
  name: string;
  url: string;
};

export const useUploadStakeholderMap = ({
  defaultParams,
  onSuccess,
}: SocketHandlerProps<Omit<Request, 'fileType'>, SuccessType>) => {
  const { notify } = useNotifications();

  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
    },
    [notify]
  );
  const onError = useCallback(() => {
    notify(NotificationType.MAP_UPLOAD_FAILED);
  }, [notify]);

  const { fetchFnMutation } = useFetch<Request, UrlResponse>({
    topic: socketTopics.CLIENT_PRESIGNED_URL,
    request: getClientPresignedUrlRequest,
    shouldNotifyOnRestError: false,
  });

  const { mutate: getPresignedUrl, isLoading: isLoadingGet } = useMutation(
    fetchFnMutation,
    { onError: failureHandler }
  );

  const { mutate: uploadImage, isLoading: isLoadingSet } = useMutation(
    uploadFileRequest,
    { onError }
  );

  const uploadImageHandler = useCallback(
    (file: File) => (response?: UrlResponse) => {
      const url = response?.url || '';
      if (!url) {
        onError();

        return;
      }

      uploadImage(
        { url, file },
        {
          onSuccess: () => {
            onSuccess?.({ name: file.name, url });
          },
        }
      );
    },
    [onError, onSuccess, uploadImage]
  );

  const uploadMap = useCallback(
    (file: File) => {
      getPresignedUrl(
        {
          clientId: defaultParams?.clientId as string,
          key: `${defaultParams?.key}-${file.name}`,
          fileType: file.type as FileType,
        },
        {
          onSuccess: uploadImageHandler(file),
          onError,
        }
      );
    },
    [defaultParams, getPresignedUrl, onError, uploadImageHandler]
  );

  return {
    isLoading: isLoadingGet || isLoadingSet,
    uploadMap,
  };
};
