import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { ChangeEvent } from 'react';
import { validateImgSize, validateImgType } from 'utils';

type ValidateImageProps = {
  onSuccess?: (props: { image: File; url: string }) => void;
  onFailure?: (props: { isValidType: boolean; isValidSize: boolean }) => void;
  validTypes?: string[];
  maxSize?: number;
};

export const useValidateImage = ({
  onSuccess,
  onFailure,
  validTypes,
  maxSize,
}: ValidateImageProps) => {
  const { notify } = useNotifications();

  const validateImg = (image: File) => {
    const isValidType = validateImgType(image.type, validTypes);
    const isValidSize = validateImgSize(image.size, maxSize);

    if (isValidSize && isValidType) {
      const url = URL.createObjectURL(image);
      onSuccess?.({ image, url });

      return;
    }

    !isValidType && notify(NotificationType.IMAGE_UPLOAD_WRONG_TYPE);
    !isValidSize && notify(NotificationType.IMAGE_UPLOAD_WRONG_SIZE);
    onFailure?.({ isValidType, isValidSize });
  };

  const validateImgInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    image && validateImg(image);
  };

  return { validateImg, validateImgInputHandler };
};
