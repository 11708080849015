import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import Img from 'next/image';
import { StakeholderMap } from 'types/ventureBuilder';
import { ModalStyled } from '../ModalStyled';

type EditClientUserModalProps = {
  stakeholderMap: StakeholderMap;
  isOpen: boolean;
  onClose: () => void;
};

export const ExpandedMapModal = ({
  stakeholderMap,
  isOpen,
  onClose,
}: EditClientUserModalProps) => {
  return (
    <ModalStyled style={modalStyle} open={isOpen} onClose={onClose}>
      <Grid container>
        <Grid item container xs={12} justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Img
            src={stakeholderMap.imageUrl}
            alt={stakeholderMap.name}
            width={200}
            height={200}
            sizes="100vw"
            style={imageStyle}
            priority
          />
        </Grid>
      </Grid>
    </ModalStyled>
  );
};
const modalStyle = {
  p: '12px',
  pt: 0,
  maxWidth: '100%',
  maxHeight: '100%',
};
const imageStyle = {
  width: '100%',
  height: '100%',
  maxWidth: 'calc(100vw - 80px)',
  maxHeight: 'calc(100vh - 80px)',
};
