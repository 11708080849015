import { Box, Typography } from '@mui/material';
import { useBrand, useCustomMediaQuery } from 'hooks';
import Img from 'next/image';
import { CSSProperties } from 'react';
import { useClient } from 'store/rq/clientData';

export const PlaybookSidebarLogo = ({ isOpen }: { isOpen: boolean }) => {
  const { client } = useClient();
  const { brand } = useBrand();
  const { lessThenSm } = useCustomMediaQuery();

  const title = client!.name;
  const { logos } = brand;

  if (isOpen) {
    return (
      <Box
        px="16px"
        display="flex"
        alignItems="center"
        width={lessThenSm ? 190 : '85%'}
        height={lessThenSm ? 45 : 50}
        m="auto"
        position="relative"
      >
        {logos?.largeLogo ? (
          <Img
            src={logos?.largeLogo}
            alt="logo"
            style={imgStyle(lessThenSm)}
            fill
            priority
          />
        ) : (
          <Typography noWrap fontSize={28}>
            {title}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {logos?.smallLogo ? (
        <Img
          src={logos?.smallLogo}
          alt="logo"
          width={40}
          height={40}
          priority
        />
      ) : (
        <Typography textTransform="capitalize" fontSize={28}>
          {title[0]}
        </Typography>
      )}
    </Box>
  );
};

const imgStyle = (lessThenSm: boolean) =>
  ({
    objectFit: 'contain',
    objectPosition: lessThenSm ? 'left' : 'center',
    left: lessThenSm ? 16 : 0,
  } as CSSProperties);
