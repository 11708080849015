import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { thumbnailWidth } from 'common/consts';
import { StandardSanityImageOptions } from 'types/incubatePlaybook';
import { getProcessEnv } from './getProcessEnv';

const processEnv = getProcessEnv();

const isValid =
  processEnv.NEXT_PUBLIC_SANITY_PROJECTID &&
  processEnv.NEXT_PUBLIC_SANITY_DATASET;

const client =
  isValid &&
  sanityClient({
    projectId: processEnv.NEXT_PUBLIC_SANITY_PROJECTID,
    dataset: processEnv.NEXT_PUBLIC_SANITY_DATASET,
    apiVersion: '2022-11-01',
    useCdn: false, // `false` if you want to ensure fresh data
  });

const builder = imageUrlBuilder(client);

export function urlFor(source: StandardSanityImageOptions['image'] | null) {
  return source && 'asset' in source ? builder.image(source) : undefined;
}

export function urlForThumbnail(
  source: StandardSanityImageOptions['image'] | null
) {
  return urlFor(source)?.width(thumbnailWidth).height(thumbnailWidth).url();
}
