import { ProductType } from 'common/enums';
import { setLocalStoragePlaybookInfoCache } from 'common/localStorage';
import { NotificationType } from 'common/notificationConsts';
import { topRoutes } from 'common/routes';
import useNotifications from 'common/useNotifications';
import { MainHeader } from 'components/Header';
import { LoadingScreen } from 'components/Loading';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { useGetClientInfo } from 'services/api/client/useGetClientInfo';
import { useGetIncubatePlaybookData } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookData';
import { useGetLastPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetLastPlaybookInfo';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useSetPlaybookInfo } from 'services/api/product/incubatePlaybook/useSetPlaybookInfo';
import { useFeatureFlags } from 'services/api/useFeatureFlags';
import { useGetProducts } from 'services/api/useGetProducts';
import { useGetRoles } from 'services/api/useGetRoles';
import { useSessionData } from 'services/api/useSessionData';
import { useClient } from 'store/rq/clientData';
import { useLastRoute } from 'store/useLastRoute';
import { ClientLayout } from './ClientLayout';
import { PlaybookLayout } from './PlaybookLayout';

export const Layout = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { notify } = useNotifications();
  const { setLastRoute } = useLastRoute();

  const redirectPath =
    typeof router.query.redirectPath === 'string'
      ? router.query.redirectPath
      : '';

  const isRedirectRoute = router.route.split('?')[0] === '/';
  const isRedirectInitialRoute = isRedirectRoute && !!router.query.initialLoad;
  const isRedirectChatRoute =
    isRedirectRoute && redirectPath.includes('https://chat');
  const isRedirectPersonasRoute =
    isRedirectRoute && redirectPath.includes('https://personas');
  const isRedirectPlaybookRoute =
    isRedirectRoute &&
    !!router.query.playbookSlug &&
    !!router.query.clientId &&
    !!router.query.projectId &&
    !!router.query.productId &&
    !!router.query.productType;
  const isRedirectSpecificRoute =
    isRedirectChatRoute || isRedirectPlaybookRoute;
  // ----------------------- direct routes -----------------------
  const isMach49Route = router.route.startsWith(topRoutes.mach49);
  const isClientRoute = router.route.startsWith(topRoutes.client);
  const isPlaybookRoute = router.route.startsWith(topRoutes.playbook);
  const isChangePasswordRoute = router.route.startsWith(
    topRoutes.changePassword
  );

  const { setPlaybookInfo } = useSetPlaybookInfo();

  const { isSessionValid, permissions, roles } = useSessionData({
    onSuccess: (result) => {
      if (isRedirectChatRoute || isRedirectPersonasRoute) {
        if (result?.permissions.canViewChat) {
          router.replace(redirectPath);
        } else if (result?.permissions.canViewPersonas) {
          router.replace(redirectPath);
        } else {
          notify(NotificationType.UNAUTHORIZED);
          router.replace('/');
        }
      }
      if (isRedirectPlaybookRoute) {
        setPlaybookInfo(
          {
            clientId: router.query.clientId! as string,
            projectId: router.query.projectId! as string,
            productId: router.query.productId! as string,
            productType: router.query.productType! as ProductType,
          },
          router.query.playbookSlug! as string
        );

        router.replace(
          `/playbook/${router.query.playbookSlug! as string}/home`
        );
      }
    },
  });

  const { client, isClientLoading } = useClient();
  useFeatureFlags({});
  useGetRoles({ enabled: isSessionValid });
  useGetProducts({ enabled: permissions.canSeePlatformProducts });

  const { isPlaybookInfoValid, saveCurrentPlaybookInfo } = useGetPlaybookInfo({
    onFailure: () => {
      router.replace('/clients');
    },
  });

  useGetLastPlaybookInfo({
    onSuccess: (response) => {
      if (!response) return;

      const { clientId, projectId, productId, slugId, productType } = response;
      setLocalStoragePlaybookInfoCache({
        playbookSlug: slugId,
        playbookInfo: { clientId, projectId, productId, productType },
        timestamp: new Date(),
      });

      router.replace(`/playbook/${slugId}/home`);
    },
    onFailure: () => {
      router.replace('/projects');
    },
    enabled:
      isSessionValid &&
      isRedirectInitialRoute &&
      !isRedirectSpecificRoute &&
      roles.isUserPlatformAdmin,
  });

  const { sanityId } = useGetIncubatePlaybookData({
    enabled: isSessionValid && isPlaybookInfoValid && isPlaybookRoute,
    onFailure: () => {
      router.replace(`/client/${client?._id}/projects`);
    },
    onSuccess: () => {
      saveCurrentPlaybookInfo();
    },
  });

  const shouldRenderAny = isSessionValid && isChangePasswordRoute;
  const shouldRenderMainLayout = isMach49Route && roles.isPlatformUser;
  const shouldRenderClientLayout = isClientRoute && client && !isClientLoading;
  const shouldRenderPlaybookLayout =
    isPlaybookRoute &&
    client &&
    !isClientLoading &&
    sanityId &&
    isPlaybookInfoValid;

  const { clientInfo } = useGetClientInfo({});

  useEffect(() => {
    if (
      clientInfo &&
      (isMach49Route || isRedirectRoute) &&
      !isRedirectSpecificRoute
    ) {
      router.replace(`/client/${clientInfo._id}/projects`);
    }
  }, [
    clientInfo,
    isMach49Route,
    isRedirectSpecificRoute,
    isRedirectRoute,
    router,
  ]);

  useEffect(() => {
    if (isRedirectRoute && !isRedirectSpecificRoute) {
      if (roles.isUserPlatformSuperAdmin || roles.isUserPlatformAnalyst) {
        router.replace('/clients');
      } else if (roles.isUserPlatformAdmin && !isRedirectInitialRoute) {
        router.replace('/projects');
      }
    }
  }, [
    isRedirectSpecificRoute,
    isRedirectRoute,
    roles,
    router,
    isRedirectInitialRoute,
  ]);

  useEffect(() => {
    router.events.on('routeChangeComplete', (route) => {
      setLastRoute(route);
    });
  }, [router.events, setLastRoute]);

  if (shouldRenderMainLayout) {
    return <MainHeader>{children}</MainHeader>;
  }

  if (shouldRenderClientLayout) {
    return <ClientLayout>{children}</ClientLayout>;
  }

  if (shouldRenderPlaybookLayout) {
    return <PlaybookLayout>{children}</PlaybookLayout>;
  }

  if (shouldRenderAny) {
    return <>{children}</>;
  }

  return <LoadingScreen />;
};
