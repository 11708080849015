import {
  PopperProps,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { ReactNode } from 'react';

type TooltipProps = MuiTooltipProps & {
  enabled?: boolean;
  children: ReactNode;
  popperStyle?: PopperProps['sx'];
  maxWidth?: string | number;
};

export const Tooltip = ({
  enabled = true,
  popperStyle,
  maxWidth,
  children,
  ...props
}: TooltipProps) => {
  if (!enabled) return <>{children}</>;

  const popperSx = {
    sx: {
      maxWidth,
      ...popperStyle,
    },
  };

  return (
    <MuiTooltip arrow PopperProps={popperSx} {...props}>
      {children}
    </MuiTooltip>
  );
};
