import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SegmentIcon from '@mui/icons-material/Segment';
import {
  Box,
  Container,
  Dialog,
  Fade,
  Grid,
  ListItemButton,
  Paper,
  Typography,
} from '@mui/material';
import { incubatePlaybookTaskEnum } from 'common/enums';
import { ButtonText } from 'components/Inputs/Button';
import { MuiIcon } from 'components/MuiIcon';
import { useProductFeatures } from 'hooks';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useGetAllWatchedVideos } from 'services/api/product/assignContent/useGetAllWatchedVideos';
import { useGetIncubatePlaybookVisited } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookVisited';
import { useFeatureFlags } from 'services/api/useFeatureFlags';
import { useSessionData } from 'services/api/useSessionData';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookTask } from 'types/incubatePlaybook';
import { getBaseLink } from 'utils/incubatePlaybook';
import { TaskTimeEstimate } from '../Tasks/TaskTimeEstimate';

type PlaybookUnitSidebarProps = {
  tasks: IncubatePlaybookTask[];
  currentTaskId: string;
  unitId: string;
  unitSlug: string;
};

export const PlaybookUnitSidebar = ({
  tasks,
  currentTaskId,
  unitId,
  unitSlug,
}: PlaybookUnitSidebarProps) => {
  const router = useRouter();
  const { permissions } = useSessionData({});

  const { visited } = useGetIncubatePlaybookVisited({});
  const { allWatchedVideos } = useGetAllWatchedVideos({
    enabled: permissions.canViewAssignments && permissions.canViewTeamProgress,
  });

  const clickHandler = useCallback(
    (taskSlug: string) => () => {
      if (taskSlug === router.query.taskSlug) {
        return;
      }
      const baseLink = getBaseLink(router.asPath);
      router.push(`${baseLink}/${unitSlug}/task/${taskSlug}`);
    },
    [router, unitSlug]
  );
  const firstSidebarId = tasks[0]._id;
  const lastSidebarId = tasks[tasks.length - 1]._id;

  const sidebarItems = tasks.map((task) => (
    <SidebarItem
      key={task._id}
      task={task}
      currentTaskId={currentTaskId}
      onClick={clickHandler(task.slug.current)}
      firstSidebarId={firstSidebarId}
      lastSidebarId={lastSidebarId}
      visited={!!visited?.[`${unitId}-${task._id}`]}
      watched={
        (task._type === incubatePlaybookTaskEnum.taskActivity ||
          task._type === incubatePlaybookTaskEnum.taskConcept) &&
        allWatchedVideos.includes(task?.video?._id || '')
      }
    />
  ));

  return (
    <>
      <Box width="25%" mb="20px" display={displayBlockGreaterThenMd}>
        <Paper elevation={1}>{sidebarItems}</Paper>
      </Box>
      <SmallScreenFullList>{sidebarItems}</SmallScreenFullList>
    </>
  );
};

const displayBlockGreaterThenMd = { xs: 'none', md: 'block' };

const SmallScreenFullList = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [router]);

  const { closeLabel, fullListLabel } = useCommonTranslations();

  return (
    <Box display={{ xs: 'block', md: 'none' }}>
      <ButtonText onClick={handleClickOpen} sx={{ textTransform: 'none' }}>
        <SegmentIcon />
        <Typography variant="body1" ml="10px">
          {fullListLabel}
        </Typography>
      </ButtonText>
      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        PaperProps={fullListDialogPaperProps}
      >
        <Container maxWidth="sm">
          <Grid container my={2}>
            <Grid container item xs={12} justifyContent="flex-end">
              <ButtonText
                aria-label="close"
                onClick={handleClose}
                sx={closeButtonSx}
              >
                <Typography variant="body1" mr="10px">
                  {closeLabel}
                </Typography>
                <CloseIcon />
              </ButtonText>
            </Grid>

            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Box>
  );
};

const fullListDialogPaperProps = {
  sx: { bgcolor: 'background.default' },
};

const closeButtonSx = {
  color: 'grey.500',
  textTransform: 'none',
};

const SidebarItem = ({
  task,
  currentTaskId,
  onClick,
  firstSidebarId,
  lastSidebarId,
  visited,
  watched,
}: {
  task: IncubatePlaybookTask;
  currentTaskId: string;
  onClick: () => void;
  firstSidebarId: string;
  lastSidebarId: string;
  visited: boolean;
  watched: boolean;
}) => {
  const first = task._id === firstSidebarId;
  const last = task._id === lastSidebarId;

  const { permissions } = useSessionData({});
  const { ff } = useFeatureFlags({});
  const productFeatures = useProductFeatures();

  const isAssignmentsEnabled =
    ff.playbookAssignments &&
    productFeatures.assignContent &&
    permissions.canViewTeamProgress &&
    permissions.canViewAssignments;

  return (
    <ListItemButton
      onClick={onClick}
      disabled={currentTaskId === task._id}
      sx={getItemStyle(first, last)}
      className={visited ? 'sidebar-item-visited' : undefined}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        minHeight="64px"
        width="100%"
        py="12px"
        pl="21px"
        pr="10px"
      >
        <Box my="auto">
          <Typography>{task.title}</Typography>
          <TaskTimeEstimate hideClock task={task} />
        </Box>
        <Box my="auto" sx={pointerEventsSx}>
          {isAssignmentsEnabled ? (
            <>
              {task._type === incubatePlaybookTaskEnum.taskActivity &&
                !watched && (
                  <MuiIcon iconName="AssignmentOutlined" fontSize="small" />
                )}
              {(task._type === incubatePlaybookTaskEnum.taskActivity ||
                task._type === incubatePlaybookTaskEnum.taskConcept) && (
                <Fade in={watched}>
                  <CheckOutlinedIcon color="success" />
                </Fade>
              )}
            </>
          ) : (
            <>
              {task._type === incubatePlaybookTaskEnum.taskActivity && (
                <MuiIcon iconName="AssignmentOutlined" fontSize="small" />
              )}
            </>
          )}
        </Box>
      </Box>
    </ListItemButton>
  );
};

// SxProps from MUI is not working properly, so leaving type as any for now
const getItemStyle =
  (first: boolean, last: boolean) =>
  ({ palette }: any) => ({
    borderRadius: getBorderRadius(first, last),
    padding: 0,
    '&.MuiListItemButton-root.sidebar-item-visited': {
      opacity: palette.action.disabledOpacity,
    },
    '&.MuiListItemButton-root.Mui-disabled': {
      backgroundColor: palette.action.selected,
      opacity: 1,
    },
  });

const getBorderRadius = (first: boolean, last: boolean) => {
  const top = first ? '4px 4px ' : '0px 0px ';
  const bottom = last ? '4px 4px' : '0px 0px';

  return top + bottom;
};

const pointerEventsSx = { pointerEvents: 'auto' };
