import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { Theme } from 'common/enums';
import { Analytics } from 'components/Analytics';
import { useDatadogInit } from 'hooks/useDatadogInit';
import { Provider as JotaiProvider } from 'jotai';
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import NotificationsManager from 'store/context/NotificationsManager';
import { PreviousRouteProvider } from 'store/context/PreviousRouteContext';
import { ReactQueryProvider } from 'store/context/ReactQueryProvider';
import SocketProvider from 'store/context/SocketProvider';
import { ThemeProvider } from 'theme/ThemeProvider';
import { TranslationsProvider } from 'translations/TranslationsProvider';
import { AuthCheck } from '../components/AuthCheck';
import oktaAuth from '../services/auth/okta';
import '../styles/globals.scss';

export default function App({ Component, pageProps }: AppProps) {
  useDatadogInit();

  const router = useRouter();
  const restoreOriginalUri = useCallback(
    async (_oktaAuth: any, originalUri: any) => {
      router.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Analytics />
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <SocketProvider>
          <ReactQueryProvider>
            <JotaiProvider>
              <NextThemeProvider
                defaultTheme={Theme.dark}
                themes={[Theme.dark, Theme.light]}
                enableSystem={false}
              >
                <ThemeProvider>
                  <TranslationsProvider>
                    <NotificationsManager>
                      <AuthCheck>
                        <PreviousRouteProvider>
                          <Component {...pageProps} />
                        </PreviousRouteProvider>
                      </AuthCheck>
                    </NotificationsManager>
                  </TranslationsProvider>
                </ThemeProvider>
              </NextThemeProvider>
            </JotaiProvider>
          </ReactQueryProvider>
        </SocketProvider>
      </Security>
    </>
  );
}
