import { useState } from 'react';

type UseFilterItemProps = {
  defaultValue?: string;
  mobileDefaultValue?: string;
};

export const useFilterItem = ({
  defaultValue = '',
  mobileDefaultValue = '',
}: UseFilterItemProps) => {
  const [value, setValue] = useState<string>(defaultValue);
  const [mobileValue, setMobileValue] = useState<string>(mobileDefaultValue);

  const onChange = (v: string) => {
    setValue(v);
    setMobileValue(v);
  };
  const onMobileChange = (v: string) => {
    setMobileValue(v);
  };

  return {
    value,
    mobileValue,
    onChange,
    onMobileChange,
  };
};
