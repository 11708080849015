import { CircularProgress, Divider, Menu, MenuItem } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { NestedMenuItem } from 'mui-nested-menu';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';

export type NestedDropdownContent = {
  title: string;
  onClick?: () => void;
  items: Array<{
    title?: string;
    onClick?: () => void;
    selected?: boolean;
    bottomDivider?: boolean;
    customMenuItem?: ReactNode;
    customMenuItemSx?: SxProps;
  }>;
};
export type DropdownContent = {
  title?: string;
  selected?: boolean;
  sx?: SxProps;
  onClick?: () => void;
  disabled?: boolean;
  disableRipple?: boolean;
  customMenuItem?: ReactNode;
  customMenuItemSx?: SxProps;
  topDivider?: boolean;
  bottomDivider?: boolean;
  nested?: NestedDropdownContent;
};

export type DropdownProps = {
  sx?: SxProps;
  dropdownEl: ReactNode;
  list: DropdownContent[];
  isLoading?: boolean;
};

export const Dropdown = ({
  sx,
  dropdownEl,
  list,
  isLoading,
}: DropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const menuItemOnClickHandlerWrapper = useCallback(
    (onClick: DropdownContent['onClick']) => () => {
      onClick?.();
      setAnchorEl(null);
    },
    []
  );

  const loadingWrapperStyle = { cursor: 'pointer', ...sx };

  return (
    <>
      <Box
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={loadingWrapperStyle}
      >
        {isLoading ? <CircularProgress size={22} /> : dropdownEl}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!isLoading && open}
        onClose={handleClose}
        disableAutoFocusItem
        MenuListProps={menuListProps}
      >
        {list.map(
          (
            {
              onClick,
              selected,
              disabled,
              title,
              sx: itemStyle,
              disableRipple,
              customMenuItem,
              customMenuItemSx,
              topDivider,
              bottomDivider,
              nested,
            },
            i
          ) => (
            <Box key={title + `-${i}`}>
              {topDivider && <Divider />}
              {nested && (
                <NestedMenuItem
                  label={nested.title}
                  parentMenuOpen={open}
                  onClick={menuItemOnClickHandlerWrapper(nested.onClick)}
                  nonce={new Date().getTime().toString()}
                  MenuProps={nestedMenuProps}
                  sx={nestedMenuItemStyle}
                >
                  {nested.items.map((n, idx) => (
                    <MenuItem
                      key={n.title + `-${i}-${idx}`}
                      onClick={menuItemOnClickHandlerWrapper(n.onClick)}
                      selected={n.selected}
                      divider={n.bottomDivider}
                      sx={n.customMenuItemSx}
                    >
                      {n.customMenuItem ?? n.title}
                    </MenuItem>
                  ))}
                </NestedMenuItem>
              )}
              {!nested && (
                <MenuItem
                  onClick={menuItemOnClickHandlerWrapper(onClick)}
                  selected={selected}
                  disabled={disabled}
                  disableRipple={!!disableRipple}
                  divider={bottomDivider}
                  sx={
                    customMenuItem
                      ? { ...menuItemStyle, ...customMenuItemSx }
                      : itemStyle
                  }
                >
                  {customMenuItem ?? title}
                </MenuItem>
              )}
            </Box>
          )
        )}
      </Menu>
    </>
  );
};

const menuItemStyle = { p: 0 };
const menuListProps = { 'aria-labelledby': 'basic-button' };
const nestedMenuItemStyle = { padding: 1 };
const nestedMenuProps = {
  anchorOrigin: { vertical: 'top', horizontal: 'left' },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  disableAutoFocus: true,
  disableAutoFocusItem: true,
  disableRestoreFocus: true,
  disableEnforceFocus: true,
  PaperProps: {
    style: {
      pointerEvents: 'auto',
    },
  },
} as const;
