import { Dialog, DialogProps, SxProps, Typography } from '@mui/material';

type ModalStyledProps = DialogProps & {
  title?: string;
  style?: SxProps;
  elevation?: number;
};

export const ModalStyled = ({
  title,
  children,
  style = {},
  elevation = 8,
  ...props
}: ModalStyledProps) => {
  const dialogPaperProps = {
    sx: {
      margin: '16px',
      maxHeight: 'calc(100% - 32px)',
      maxWidth: '777px',
      p: '3rem 4rem 3rem 3rem',
      ...style,
    } as SxProps,
    elevation,
  };

  return (
    <Dialog {...props} PaperProps={dialogPaperProps}>
      {title && (
        <Typography fontSize={24} marginBottom="40px">
          {title}
        </Typography>
      )}
      {children}
    </Dialog>
  );
};
