import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Link } from 'components/Inputs/Link';
import { PlaybookFavoriteButton } from 'components/Playbook/PlaybookFavoriteButton';
import { TableSortModel } from 'components/Table';
import PlaybookTable from 'components/Table/PlaybookTable';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookRecents } from 'types/incubatePlaybook';

type IncubatePlaybookRecentsTableData = IncubatePlaybookRecents & {
  id: string;
};

type onNameClick = (row: IncubatePlaybookRecentsTableData) => void;

type RecentsTableProps = {
  recents: IncubatePlaybookRecents[];
  loading?: boolean;
  sortParams: TableSortModel;
  setSortParams: (model: TableSortModel) => void;
  totalCount?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  onNameClick: onNameClick;
};

export const PlaybookRecentsTable = ({
  recents,
  loading,
  sortParams,
  setSortParams,
  totalCount,
  page,
  onPageChange,
  onNameClick,
}: RecentsTableProps) => {
  const recentsData = useMemo(() => adaptData(recents), [recents]);

  return (
    <PlaybookTable
      rows={recentsData}
      columns={Columns(onNameClick)}
      sortModel={sortParams}
      sortModelChangeHandler={setSortParams}
      loading={loading}
      rowCount={totalCount}
      page={page}
      onPageChange={onPageChange}
    />
  );
};

const favouriteButtonWrapperStyle = { textAlign: 'right' as const };

const adaptData = (
  recents: IncubatePlaybookRecents[]
): IncubatePlaybookRecentsTableData[] =>
  recents.map((recent: IncubatePlaybookRecents) => ({
    ...recent,
    id: recent.unitId,
  }));

const renderUnitName = (params: GridCellParams, onNameClick: onNameClick) => {
  return (
    <Link color="secondary" onClick={() => onNameClick(params.row)}>
      {params.row.unitName}
    </Link>
  );
};

const renderFavouriteButton = (params: GridCellParams) => {
  return (
    <div style={favouriteButtonWrapperStyle}>
      <PlaybookFavoriteButton
        iconOnly
        itemId={params.row.unitId}
        itemName={params.row.unitName}
        itemPhaseName={params.row.phaseName}
        itemModuleName={params.row.moduleName}
        itemUnitName={params.row.unitName}
        itemImageURL={params.row.imageURL}
        itemType="unit"
        itemLink=""
      />
    </div>
  );
};

const renderDate = (
  params: GridValueFormatterParams,
  locale: string = 'en-US'
) => {
  const date = new Date(params?.value);

  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const Columns: (onNameClick: onNameClick) => GridColDef[] = (onNameClick) => {
  const { nameLabel, phaseLabel, dateAddedLabel } = useCommonTranslations();
  const { locale = 'en-US' } = useRouter();

  return [
    {
      field: 'unitName',
      headerName: nameLabel,
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 250,
      editable: false,
      renderCell: (params: GridCellParams) =>
        renderUnitName(params, onNameClick),
      align: 'left',
      disableColumnMenu: true,
    },
    {
      field: 'phaseName',
      headerName: phaseLabel,
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dateViewed',
      headerName: dateAddedLabel,
      sortable: true,
      filterable: false,
      flex: 1,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        renderDate(params, locale),
    },
    {
      field: 'fav',
      headerName: '',
      sortable: false,
      filterable: false,
      flex: 1,
      editable: false,
      renderCell: renderFavouriteButton,
      align: 'right',
      disableColumnMenu: true,
    },
  ];
};
