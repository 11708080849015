import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useFormOptions } from 'common/formOptions';
import { useCustomMediaQuery } from 'hooks';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetAllUserOnProject } from 'services/api/client/project/useGetAllProjectUsersList';
import { useCommonTranslations } from 'translations/hooks';
import { getInitials } from 'utils';

export const TeamMembersSelect = ({
  disabled = false,
  setMobileModalStep,
}: {
  disabled?: boolean;
  setMobileModalStep?: Dispatch<SetStateAction<string>>;
}) => {
  const { data: usersOnProject } = useGetAllUserOnProject();
  const [open, setOpen] = useState(false);
  const { lessThenSm } = useCustomMediaQuery();

  const { assigneesLabel, allTeamMembersLabel, noTeamMembersLabel } =
    useCommonTranslations();

  const {
    setValue,
    getValues,
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const { required } = useFormOptions();

  const selectedMembers: string[] = watch('assignees');

  const selectAll = useMemo(
    () =>
      !!usersOnProject?.count &&
      selectedMembers.length === usersOnProject?.count,
    [selectedMembers, usersOnProject]
  );

  const handleMemberSelect = (values: string[]) => {
    const indexOfAll = values.indexOf('all');

    if (indexOfAll > -1) {
      values.splice(indexOfAll, 1);
      handleSelectAll();
    } else {
      setValue('assignees', values);
    }
  };

  const selectMemberHandler = (event: SelectChangeEvent<string[]>) => {
    const selectedOptions = event.target.value as string[];
    handleMemberSelect(selectedOptions);
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setValue(
        'assignees',
        usersOnProject?.items.map((member) => member.userId)
      );
    } else {
      setValue('assignees', []);
    }
  };

  const handleChipDelete = (userId?: string) => (event: Event) => {
    event.stopPropagation();
    handleMemberSelect(selectedMembers.filter((id) => id !== userId));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (lessThenSm)
      setMobileModalStep && setMobileModalStep('assignmentAssignees');
    else setOpen(true);
  };

  return (
    <FormControl
      fullWidth
      size="small"
      error={typeof errors?.assignees !== 'undefined'}
    >
      <InputLabel id="team-members-label" sx={inputLabelSx(disabled)}>
        {assigneesLabel}
      </InputLabel>
      <Select
        open={open}
        color="secondary"
        disabled={disabled}
        label={assigneesLabel}
        labelId="team-members-label"
        id="team-members-select"
        IconComponent={lessThenSm ? ArrowRight : ArrowDropDown}
        sx={selectSx(disabled)}
        multiple
        onOpen={handleOpen}
        onClose={handleClose}
        value={getValues('assignees')}
        {...register('assignees', {
          onChange: selectMemberHandler,
          required,
        })}
        renderValue={(selected: string[]) => {
          if (selected.length === usersOnProject?.count) {
            return allTeamMembersLabel;
          }

          const selectedMembers = selected
            .map((memberId) =>
              usersOnProject?.items.find((m) => m.userId === memberId)
            )
            .filter(Boolean);

          return (
            <Box
              overflow="scroll"
              height="100%"
              display="flex"
              alignItems="center"
            >
              {selectedMembers.map((member) => (
                <Chip
                  avatar={
                    <Avatar src={member?.profileImg}>
                      {getInitials(member?.name)}
                    </Avatar>
                  }
                  size="small"
                  sx={chipSx}
                  key={member?.userId}
                  label={member?.name.split(' ')[0]}
                  onMouseDown={(event) => event.stopPropagation()}
                  onDelete={handleChipDelete(member?.userId)}
                />
              ))}
            </Box>
          );
        }}
      >
        {!!usersOnProject?.count ? (
          <MenuItem
            key="select-all"
            value="all"
            className={selectAll ? 'Mui-selected' : ''}
          >
            <Checkbox checked={selectAll} color="secondary" />
            {allTeamMembersLabel}
          </MenuItem>
        ) : (
          <MenuItem>{noTeamMembersLabel}</MenuItem>
        )}
        {usersOnProject?.items.map((member) => (
          <MenuItem
            key={member.userId}
            value={member.userId}
            sx={userMenuItemSx}
          >
            <Checkbox
              checked={selectedMembers.includes(member.userId)}
              color="secondary"
            />
            {member.name}
          </MenuItem>
        ))}
      </Select>
      {errors?.assignees?.message && (
        <FormHelperText>{errors?.assignees?.message as string}</FormHelperText>
      )}
    </FormControl>
  );
};

const selectSx = (disabled: boolean) => ({
  fontSize: '0.875rem',
  height: '46px',
  fieldset: {
    borderStyle: !disabled ? 'solid' : 'dotted',
  },
  '& #team-members-select': {
    py: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

const inputLabelSx = (disabled: boolean) => ({
  color: disabled ? 'text.disabled' : 'text.secondary',
  fontSize: '0.875rem',
  mt: '4px',
  '&[data-shrink=true]': {
    mt: 0,
  },
});

const userMenuItemSx = { pl: 6 };

const chipSx = { mr: '2px' };
