import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { IncubatePlaybookVisited } from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import {
  IncubatePlaybookVisitedUpdateParams,
  IncubatePlaybookVisitedUpdateProps,
  setIncubatePlaybookVisitedRequest,
} from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

type Request = IncubatePlaybookVisitedUpdateProps['params'];

export const useSetIncubatePlaybookVisited = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps) => {
  const queryClient = useQueryClient();
  const { playbookInfo } = useGetPlaybookInfo({});

  const successHandler = () => {
    onSuccess?.();
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFnMutation } = useFetch<Request, void>({
    topic: socketTopics.VISITED_UPDATE,
    request: setIncubatePlaybookVisitedRequest,
    shouldNotifyOnRestError: false,
  });

  const { mutate } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const setVisited = useCallback(
    (params: IncubatePlaybookVisitedUpdateParams) => {
      queryClient.setQueriesData<IncubatePlaybookVisited>(
        [incubatePlaybookQueryKeys.visited(playbookInfo)],
        (old) => ({ ...old, [params.itemId]: true })
      );
      mutate({ defaultParams: playbookInfo, params });
    },
    [playbookInfo, mutate, queryClient]
  );

  return { setVisited };
};
