import { pluralize } from 'common/pluralize';
import {
  addMinutes,
  formatDistance,
  formatDistanceStrict,
  subMinutes,
} from 'date-fns';
import { useCommonTranslations } from 'translations/hooks';
import { Languages, languagesMap } from 'translations/TranslationsProvider';

export const useTimeUtils = () => {
  const { weekLabel, dayLabel, hourLabel, minuteLabel } =
    useCommonTranslations();

  const formatMinutesToReadableTime = (mins?: number) => {
    if (!mins || mins < 0) return '';

    const m = mins % 60;
    const h = Math.floor(mins / 60) % 24;
    const d = Math.floor(mins / (60 * 24)) % 7;
    const w = Math.floor(mins / (60 * 24 * 7));

    const result = [
      {
        value: w,
        title: weekLabel(w),
      },
      {
        value: d,
        title: dayLabel(d),
      },
      {
        value: h,
        title: hourLabel(h),
      },
      {
        value: m,
        title: minuteLabel(m),
      },
    ];

    return result
      .filter(({ value }) => value)
      .map(({ title }) => title)
      .join(', ');
  };

  const formatSecondsToMinutes = (seconds?: number) => {
    if (!seconds || seconds < 0) return '';

    let mins = Math.floor(seconds / 60);
    const restSeconds = seconds % 60;

    if (restSeconds >= 30) {
      mins++;
    }

    return minuteLabel(mins || 1);
  };

  return {
    formatSecondsToMinutes,
    formatMinutesToReadableTime,
  };
};

export const formatMinutesToReadableTime = (mins?: number) => {
  if (!mins || mins < 0) return '';

  const m = mins % 60;
  const h = Math.floor(mins / 60) % 24;
  const d = Math.floor(mins / (60 * 24)) % 7;
  const w = Math.floor(mins / (60 * 24 * 7));

  const result = [
    {
      value: w,
      title: 'week',
    },
    {
      value: d,
      title: 'day',
    },
    {
      value: h,
      title: 'hour',
    },
    {
      value: m,
      title: 'minute',
    },
  ];

  return result
    .filter(({ value }) => value)
    .map(({ value, title }) =>
      pluralize({ word: title, count: value, showCount: true })
    )
    .join(', ');
};

export const formatSecondsToMinutes = (seconds?: number) => {
  if (!seconds || seconds < 0) return '';

  let mins = Math.floor(seconds / 60);
  const restSeconds = seconds % 60;

  if (restSeconds >= 30) {
    mins++;
  }

  return pluralize({ word: 'minute', count: mins || 1, showCount: true });
};

export enum millisecondsIn {
  seconds = 1000,
  minutes = seconds * 60,
  hours = minutes * 60,
  days = hours * 24,
  weeks = days * 7,
}
export const convertMilliseconds = (
  ms: number,
  measure: millisecondsIn,
  round: 'up' | 'down'
) => (round === 'up' ? Math.ceil(ms / measure) : Math.floor(ms / measure));

export const fixUTCOffset = (date: Date, offset: number) => {
  return Math.sign(offset) !== -1
    ? addMinutes(date, offset)
    : subMinutes(date, Math.abs(offset));
};

type UTCTimeDiffTextFromNowProps = {
  locale?: string;
  date?: Date;
  addSuffix?: boolean;
  strict?: boolean;
};
export const getUTCTimeDiffTextFromNow = ({
  locale = 'en-US',
  date,
  addSuffix = true,
  strict = false,
}: UTCTimeDiffTextFromNowProps) => {
  if (!date) return '';

  const localeData = languagesMap[locale as keyof typeof Languages];

  const now = new Date();
  const offset = now.getTimezoneOffset();
  const nowUTC = fixUTCOffset(now, offset);
  const dateUTC = fixUTCOffset(date, offset);

  if (strict) {
    return formatDistanceStrict(dateUTC, nowUTC, {
      addSuffix,
      locale: localeData.dateAdapterLocales as Locale,
    });
  }

  return formatDistance(dateUTC, nowUTC, {
    addSuffix,
    locale: localeData.dateAdapterLocales as Locale,
  });
};

/**
 * @param _date date to compare against
 * @param limit number of seconds to compare against @default 20
 * @returns true / false
 */
export const isEnoughSecondsPassed = (
  _date: number | string | Date,
  limit = 20
) => {
  if (!_date) return false;

  const date = new Date(_date).getTime();
  const now = new Date().getTime();
  const diff = (now - date) / 1000;

  return diff > limit;
};
