import { setLocalStoragePlaybookInfoCache } from 'common/localStorage';
import { useEffect } from 'react';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';

export const useSavePlaybookInfo = () => {
  const { playbookInfo, productType, playbookSlug, isPlaybookInfoValid } =
    useGetPlaybookInfo({});

  const savePlaybookInfo = () => {
    if (isPlaybookInfoValid) {
      setLocalStoragePlaybookInfoCache({
        playbookSlug,
        playbookInfo: { ...playbookInfo, productType },
        timestamp: new Date(),
      });
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', savePlaybookInfo);

    return () => {
      window.removeEventListener('beforeunload', savePlaybookInfo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
