import { AllRoles } from 'common/userConsts';
import { useIntl } from 'react-intl';

export const useUserTranslations = () => {
  const { formatMessage } = useIntl();

  const usersLabel = formatMessage({
    id: 'users',
    defaultMessage: 'Users',
  });

  const haventInvitedM49Label = formatMessage({
    id: 'users.haventInvitedM49',
    defaultMessage: 'You haven’t invited anyone from Mach49 yet.',
  });

  const haventInvitedAnyUserLabel = formatMessage({
    id: 'users.haventInvitedAnyUser',
    defaultMessage: 'You haven’t invited any users yet.',
  });

  const userLabel = formatMessage({ id: 'user', defaultMessage: 'User' });

  const inviteYourFirstUserLabel = formatMessage({
    id: 'users.inviteYourFirstUser',
    defaultMessage: 'Invite your first user.',
  });

  const platformUsersLabel = formatMessage({
    id: 'users.platformUsers',
    defaultMessage: 'Platform users',
  });

  const shiftUsersLabel = formatMessage({
    id: 'users.shiftUsers',
    defaultMessage: 'Shift AI Users',
  });

  const inviteNewUserPlusLabel = formatMessage({
    id: 'users.inviteNewUserPlus',
    defaultMessage: '+ invite new user',
  });

  const inviteExistingUserPlusLabel = formatMessage({
    id: 'users.inviteExistingUserPlus',
    defaultMessage: '+ invite existing user',
  });

  const createNewUserPlusLabel = formatMessage({
    id: 'users.createNewUserPlus',
    defaultMessage: '+ create new user',
  });

  const inviteNewUserLabel = formatMessage({
    id: 'users.inviteNewUser',
    defaultMessage: 'Invite New User',
  });

  const searchUsersLabel = formatMessage({
    id: 'users.search',
    defaultMessage: 'Search users',
  });

  const editUserLabel = formatMessage({
    id: 'users.editUser',
    defaultMessage: 'Edit User',
  });

  const deleteUserLabel = formatMessage({
    id: 'users.deleteUser',
    defaultMessage: 'Delete User',
  });

  const deactivateUserLabel = formatMessage({
    id: 'users.deactivateUser',
    defaultMessage: 'Deactivate User',
  });

  const reactivateUserLabel = formatMessage({
    id: 'users.reactivateUser',
    defaultMessage: 'Reactivate User',
  });

  const activeUsersLabel = formatMessage({
    id: 'users.activeUsers',
    defaultMessage: 'Active Users',
  });

  const aboutToDeactivateUserWithNameLabel = (username: string) =>
    formatMessage(
      {
        id: 'users.aboutToDeactivateUserWithName',
        defaultMessage: 'You are about to deactivate {username} as a user',
      },
      { username }
    );

  const deactivationPlatformUserAccountConsequenceLabel = formatMessage({
    id: 'users.deactivationPlatformUserAccountConsequence',
    defaultMessage:
      'This user will be deactivate and they will no longer have access to clients or project data.',
  });

  const userStatusLabel = formatMessage({
    id: 'users.userStatus',
    defaultMessage: 'User Status',
  });

  const lastLoggedInLabel = formatMessage({
    id: 'users.lastLoggedIn',
    defaultMessage: 'Last active',
  });

  const resolvedUserRoleLabel = (key?: AllRoles) =>
    formatMessage(
      {
        id: 'users.resolvedUserRole',
        defaultMessage: `{key, select, 
          client_admin {Client Admin} 
          client_user {Client User} 
          platform_super_admin {Platform Super Admin} 
          platform_admin {Multi-Client Admin} 
          platform_analyst {Platform Analyst} 
          certification_candidate {Certification Candidate} 
          other {N/A}
        }`,
      },
      {
        key: key || '',
      }
    );

  const addFromExistingClientUserListLabel = formatMessage({
    id: 'users.addFromExistingClientUserList',
    defaultMessage: 'Add From Existing Client User List',
  });

  const addFromClientUserListLabel = formatMessage({
    id: 'users.addFromClientUserList',
    defaultMessage: 'Add From Client User List',
  });

  const deleteUserWithUserNameQuestionLabel = (userName: string) =>
    formatMessage(
      {
        id: 'users.deleteUserWithUserNameQuestion',
        defaultMessage: 'Are you sure you want to delete {userName}?',
      },
      {
        userName,
      }
    );

  const deleteUserConsequenceLabel = formatMessage({
    id: 'users.deleteUserConsequence',
    defaultMessage:
      'This user will be deleted permanently and they will no longer have access to clients or project data.',
  });

  const userDeletedLabel = formatMessage({
    id: 'users.userDeleted',
    defaultMessage: 'User deleted',
  });

  const userDeactivatedLabel = formatMessage({
    id: 'users.userDeactivated',
    defaultMessage: 'User deactivated',
  });

  const userReactivatedLabel = formatMessage({
    id: 'users.userReactivated',
    defaultMessage: 'User reactivated',
  });

  const userUpdatedLabel = formatMessage({
    id: 'users.userUpdated',
    defaultMessage: 'User updated',
  });

  const usersAddedLabel = formatMessage({
    id: 'users.usersAdded',
    defaultMessage: 'User(s) added',
  });

  const userRemovedLabel = formatMessage({
    id: 'users.userRemoved',
    defaultMessage: 'User removed',
  });

  const userAlreadyExistsLabel = formatMessage({
    id: 'users.userAlreadyExists',
    defaultMessage: 'User already exists',
  });

  const userAlreadyActiveLabel = formatMessage({
    id: 'users.userAlreadyActive',
    defaultMessage: 'User already active',
  });

  const userAlreadyAcceptedInvitationLabel = formatMessage({
    id: 'users.userAlreadyAcceptedInvitation',
    defaultMessage: 'User already accepted invitation',
  });

  const userNotFoundLabel = formatMessage({
    id: 'users.userNotFound',
    defaultMessage: 'User not found',
  });

  const userReactivateFailedLabel = formatMessage({
    id: 'users.userReactivateFailed',
    defaultMessage: 'User reactivate failed.',
  });

  const userIsNotAssignedToAnyProjectLabel = formatMessage({
    id: 'users.userIsNotAssignedToAnyProject',
    defaultMessage: 'User is not assigned to any project',
  });

  const featureCannotBeAddedLabel = formatMessage({
    id: 'users.featureCannotBeAdded',
    defaultMessage: "This feature can't be added to this product",
  });

  const featureDisabledLabel = formatMessage({
    id: 'users.featureDisabled',
    defaultMessage: 'Feature disabled!',
  });

  const themeSuccessfullyUpdatedLabel = formatMessage({
    id: 'users.themeSuccessfullyUpdated',
    defaultMessage: 'Theme successfully updated',
  });

  return {
    userIsNotAssignedToAnyProjectLabel,
    featureCannotBeAddedLabel,
    featureDisabledLabel,
    userReactivateFailedLabel,
    userNotFoundLabel,
    userAlreadyAcceptedInvitationLabel,
    userAlreadyActiveLabel,
    userAlreadyExistsLabel,
    userReactivatedLabel,
    usersAddedLabel,
    userRemovedLabel,
    userUpdatedLabel,
    userDeletedLabel,
    userDeactivatedLabel,
    deleteUserConsequenceLabel,
    deleteUserWithUserNameQuestionLabel,
    deleteUserLabel,
    addFromClientUserListLabel,
    addFromExistingClientUserListLabel,
    haventInvitedAnyUserLabel,
    activeUsersLabel,
    resolvedUserRoleLabel,
    userStatusLabel,
    lastLoggedInLabel,
    inviteNewUserLabel,
    deactivationPlatformUserAccountConsequenceLabel,
    aboutToDeactivateUserWithNameLabel,
    reactivateUserLabel,
    deactivateUserLabel,
    editUserLabel,
    searchUsersLabel,
    inviteNewUserPlusLabel,
    shiftUsersLabel: shiftUsersLabel,
    userLabel,
    usersLabel,
    haventInvitedM49Label,
    inviteYourFirstUserLabel,
    platformUsersLabel,
    createNewUserPlusLabel,
    inviteExistingUserPlusLabel,
    themeSuccessfullyUpdatedLabel,
  };
};
