import { ChevronRight } from '@mui/icons-material';
import {
  TreeItem,
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem,
} from '@mui/lab';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { getBaseLink } from 'utils/incubatePlaybook';

const CustomContent = (
  expandable = true,
  isActive?: boolean,
  isModule?: boolean,
  isUnit?: boolean,
  link?: string,
  dataTestId?: string
) =>
  React.forwardRef(function CustomContent(
    { label, nodeId }: TreeItemContentProps,
    ref
  ) {
    const router = useRouter();
    const { expanded, handleExpansion } = useTreeItem(nodeId!);
    const baseLink = useMemo(() => getBaseLink(router.asPath), [router.asPath]);
    const isLink = !!link;
    const handleExpansionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleExpansion(event);
    };

    const onNodeClick = () => {
      link && router.push(`${baseLink}/${link}`);
    };

    return (
      <Box
        ref={ref as React.Ref<HTMLDivElement>}
        display="flex"
        alignItems="center"
        sx={wrapperStyle(isActive)}
      >
        {expandable && (
          <Box
            onClick={handleExpansionClick}
            display="flex"
            alignItems="center"
          >
            <ChevronRight sx={iconSx(expanded)} />
          </Box>
        )}
        <Typography
          data-testid={dataTestId}
          onClick={onNodeClick}
          component="div"
          sx={entityLabelStyle(isLink, isUnit, isModule)}
        >
          {label}
        </Typography>
      </Box>
    );
  });

const entityLabelStyle = (
  isLink: boolean,
  isUnit?: boolean,
  isModule?: boolean
) => ({
  ...(isUnit ? { paddingLeft: '10px' } : {}),
  ...(isUnit || isModule ? { fontSize: '14px' } : {}),
  cursor: isLink ? 'pointer' : 'regular',
  width: '100%',
  ...(isLink
    ? {
        '&:hover': {
          textDecoration: 'underline',
        },
      }
    : {}),
});

export const activeStyle = {
  bgcolor: 'action.selected',
};

const wrapperStyle = (isActive?: boolean) => ({
  minHeight: '24px',
  padding: '15px 10px',
  transition: 'background-color 0.2s',
  ...(isActive ? activeStyle : {}),
  ...(!isActive
    ? {
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }
    : {}),
});

const iconSx = (expanded: boolean) => ({
  color: 'text.secondary',
  mr: 1,
  transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
  transition: 'transform 0.05s',
});

type CustomTreeProps = TreeItemProps & {
  expandable?: boolean;
  isActive?: boolean;
  isModule?: boolean;
  isUnit?: boolean;
  link?: string;
  dataTestId?: string;
};
const CustomTreeItem = ({
  expandable,
  isActive,
  isModule,
  isUnit,
  link,
  dataTestId,
  ...props
}: CustomTreeProps) => (
  <TreeItem
    ContentComponent={CustomContent(
      expandable,
      isActive,
      isModule,
      isUnit,
      link,
      dataTestId
    )}
    {...props}
  />
);

export default CustomTreeItem;
