import { Box, Paper, Typography } from '@mui/material';
import { useCommonTranslations } from 'translations/hooks';
import { PlaybookQuizResultsBar } from './ResultsBar';

type PlaybookQuizStartScreenTakenProps = {
  correct: number;
  total: number;
  unitTitle: string;
  onClick: () => void;
};

export const PlaybookQuizStartScreenTaken = ({
  correct,
  total,
  unitTitle,
  onClick,
}: PlaybookQuizStartScreenTakenProps) => {
  const { quizLabel, testYourKnowledgeOfTitleLabel } = useCommonTranslations();

  return (
    <Paper elevation={1}>
      <Box p="30px" borderRadius="4px" maxWidth="923px">
        <Typography
          mb="30px"
          fontSize={20}
          fontWeight={600}
          data-testid="task-title"
        >
          {quizLabel}
        </Typography>
        <Typography mb="30px">
          {testYourKnowledgeOfTitleLabel(unitTitle)}
        </Typography>
        <PlaybookQuizResultsBar
          correct={correct}
          total={total}
          onClick={onClick}
        />
      </Box>
    </Paper>
  );
};
