import { useRouter } from 'next/router';
import { Languages } from 'translations/TranslationsProvider';

const useLanguage = () => {
  const { asPath, locale = 'en-US', push } = useRouter();

  const changeAppLanguage = (newLanguage?: Languages) => {
    if (!!newLanguage && locale !== newLanguage) {
      push(asPath, asPath, { locale: newLanguage });
    }
  };

  return { changeAppLanguage };
};

export default useLanguage;
