import { Draggable } from '@hello-pangea/dnd';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { useFormOptions } from 'common/formOptions';
import { ButtonContained, ButtonOutlined } from 'components/Inputs/Button';
import { Dropdown } from 'components/Inputs/Dropdown';
import { DragIcon, VerticalMoreIcon } from 'components/MuiIcon';
import { useLeaveConfirm } from 'hooks/useLeaveConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  useCommonTranslations,
  useProductTranslations,
} from 'translations/hooks';
import { Stakeholder, TopValueProposition } from 'types/ventureBuilder';
import { TopValuePropositionsListState } from '.';

type TopValuePropositionsListItemProps = {
  index: number;
  data: TopValueProposition;
  stakeholders: Stakeholder[];
  state: TopValuePropositionsListState;
  onCancel: () => void;
  onEdit: (topValueProposition: TopValueProposition) => void;
  onDelete: (topValueProposition: TopValueProposition) => void;
  onSave: (topValueProposition: TopValueProposition) => boolean;
};

export const TopValuePropositionsListItem = ({
  index,
  data,
  stakeholders,
  state,
  onEdit,
  onCancel,
  onDelete,
  onSave,
}: TopValuePropositionsListItemProps) => {
  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<TopValueProposition>();

  const { numberInRangeValidation, textInputValidation } = useFormOptions();

  const isEditing = state.type !== VentureBuilderItemAction.VIEW;

  const handleSubmitForm = (form: TopValueProposition) => {
    const success = onSave({ ...data, ...form });
    if (success)
      reset({
        ...data,
        ...form,
      });
  };

  const resetForm = () =>
    reset({
      valuePropositionName: data.valuePropositionName || '',
      stakeholderId: data.stakeholderId || '',
      averageNps: data.averageNps,
      averagePainScore: data.averagePainScore,
    });

  const handleEdit = () => {
    resetForm();
    onEdit(data);
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleDelete = () => onDelete(data);

  const { editLabel, deleteLabel, saveLabel, cancelLabel } =
    useCommonTranslations();
  const {
    valuePropositionNameLabel,
    stakeholderLabel,
    avgNPSLabel,
    avgPainScoreLabel,
  } = useProductTranslations();

  const menuList = [
    {
      title: editLabel,
      onClick: handleEdit,
      disabled: isEditing,
    },
    {
      title: deleteLabel,
      onClick: handleDelete,
      disabled: isEditing,
    },
  ];

  const getStakeholderLabel = () => {
    return (
      stakeholders.find(
        (stakeholder) => stakeholder._id === data?.stakeholderId
      )?.name || ''
    );
  };

  useEffect(() => {
    const currentFormData = getValues();

    if (
      isEditing &&
      !!currentFormData.stakeholderId &&
      !stakeholders.find((sh) => sh._id === currentFormData.stakeholderId)
    ) {
      setValue('stakeholderId', '');
    }
  }, [getValues, setValue, stakeholders, isEditing, reset]);

  return (
    <>
      {useLeaveConfirm({ isChanged: isDirty })}
      <Draggable key={data._id} draggableId={data._id} index={index}>
        {(provided, snapshot) => {
          {
            return state.type !== VentureBuilderItemAction.VIEW &&
              data._id === state?.data?._id ? (
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Grid
                  container
                  sx={listItemStyle(snapshot.isDragging)}
                  flexDirection="row"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  minWidth="600px"
                >
                  <Grid
                    container
                    item
                    xs={9}
                    columnGap={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      container
                      item
                      xs={0.5}
                      {...provided.dragHandleProps}
                    />

                    <Grid container item xs={3}>
                      <TextField
                        label={valuePropositionNameLabel}
                        color="secondary"
                        {...register(
                          'valuePropositionName',
                          textInputValidation
                        )}
                        error={!!errors.valuePropositionName}
                        helperText={errors.valuePropositionName?.message}
                        fullWidth
                        autoFocus
                      />
                    </Grid>

                    <Grid container item xs={3}>
                      <TextField
                        label={stakeholderLabel}
                        color="secondary"
                        select
                        defaultValue={data.stakeholderId || ''}
                        {...register('stakeholderId')}
                        error={!!errors.stakeholderId}
                        helperText={errors.stakeholderId?.message}
                        fullWidth
                      >
                        {stakeholders?.map(({ _id, name }) => (
                          <MenuItem key={_id} value={_id}>
                            {name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid container item xs={2}>
                      <TextField
                        type="number"
                        color="secondary"
                        inputProps={numberInputProps}
                        label={avgNPSLabel}
                        {...register(
                          'averageNps',
                          numberInRangeValidation(0, 10)
                        )}
                        error={!!errors.averageNps}
                        helperText={errors.averageNps?.message}
                        fullWidth
                      />
                    </Grid>

                    <Grid container item xs={2.5}>
                      <TextField
                        type="number"
                        color="secondary"
                        inputProps={numberInputProps}
                        label={avgPainScoreLabel}
                        {...register(
                          'averagePainScore',
                          numberInRangeValidation(0, 10)
                        )}
                        error={!!errors.averagePainScore}
                        helperText={errors.averagePainScore?.message}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    marginTop="10px"
                    container
                    item
                    xs={3}
                    justifyContent="right"
                  >
                    <Grid item marginLeft="10px">
                      <ButtonOutlined color="secondary" onClick={handleCancel}>
                        {cancelLabel}
                      </ButtonOutlined>
                    </Grid>
                    <Grid item marginLeft="4px">
                      <ButtonContained type="submit" color="secondary">
                        {saveLabel}
                      </ButtonContained>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Grid
                container
                sx={listItemStyle(snapshot.isDragging)}
                flexDirection="row"
                ref={provided.innerRef}
                {...provided.draggableProps}
                minWidth="600px"
              >
                <Grid
                  container
                  item
                  xs={9}
                  columnGap={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid container item xs={0.5} {...provided.dragHandleProps}>
                    {state.type === VentureBuilderItemAction.VIEW && (
                      <DragIcon />
                    )}
                  </Grid>

                  <Grid container item xs={3}>
                    <Typography>{data.valuePropositionName}</Typography>
                  </Grid>

                  <Grid container item xs={3}>
                    <Typography>{getStakeholderLabel()}</Typography>
                  </Grid>

                  <Grid container item xs={2}>
                    <Typography>{data.averageNps}</Typography>
                  </Grid>
                  <Grid container item xs={2.5}>
                    <Typography>{data.averagePainScore}</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={2} />

                <Grid item xs={1} textAlign="right">
                  <Dropdown
                    dropdownEl={<VerticalMoreIcon />}
                    list={menuList}
                    isLoading={data._id === TEMP_ID}
                  />
                </Grid>
              </Grid>
            );
          }
        }}
      </Draggable>
    </>
  );
};

const listItemStyle = (isDragging: boolean) => ({
  minHeight: '53px',
  borderBottom: '1px solid',
  borderColor: 'action.disabledBackground',
  padding: '12px 0 8px 10px',
  backgroundColor: isDragging ? 'action.disabledBackground' : '',
  opacity: isDragging ? 0.4 : 1,
});

const numberInputProps = {
  step: 0.1,
};

export const TEMP_ID = 'tempId';
