import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, ListItemButton, Typography } from '@mui/material';
import { useCommonTranslations } from 'translations/hooks';

type PlaybookQuizExitButtonProps = {
  onClick: () => void;
};

export const ExitButton = ({ onClick }: PlaybookQuizExitButtonProps) => {
  const { exitQuizLabel } = useCommonTranslations();

  return (
    <ListItemButton sx={btnStyle} onClick={onClick}>
      <Box
        height="36px"
        width="135px"
        px="8px"
        display="flex"
        alignContent="center"
        gap="11.3px"
      >
        <Box my="auto">
          <ArrowBackIcon />
        </Box>
        <Typography textTransform="uppercase" width="inherit" my="auto">
          {exitQuizLabel}
        </Typography>
      </Box>
    </ListItemButton>
  );
};

const btnStyle = {
  padding: 0,
  height: '36px',
  width: '135px',
  borderRadius: '4px',
};
