import { useIntl } from 'react-intl';

export const useNonAuthTranslations = () => {
  const { formatMessage } = useIntl();

  const loginPageTitle = formatMessage({
    id: 'nonAuth.loginPage',
    defaultMessage: 'Login page',
  });

  const loginLabel = formatMessage({
    id: 'nonAuth.login',
    defaultMessage: 'Login',
  });

  const logInLabel = formatMessage({
    id: 'nonAuth.logIn',
    defaultMessage: 'Log In',
  });

  const logInWithOktaLabel = formatMessage({
    id: 'nonAuth.logWithOkta',
    defaultMessage: 'Log In With Okta',
  });

  const logInWithGoogleLabel = formatMessage({
    id: 'nonAuth.logInWithGoogle',
    defaultMessage: 'Log In With Google',
  });

  const emailLabel = formatMessage({
    id: 'common.email',
    defaultMessage: 'Email',
  });

  const passLabel = formatMessage({
    id: 'common.password',
    defaultMessage: 'Password',
  });

  const forgotPassLabel = formatMessage({
    id: 'nonAuth.forgotPass',
    defaultMessage: 'Forgot Password',
  });

  const createPassLabel = formatMessage({
    id: 'nonAuth.createPass',
    defaultMessage: 'Create Password',
  });

  const confirmPassLabel = formatMessage({
    id: 'nonAuth.confirmPass',
    defaultMessage: 'Confirm password',
  });

  const passMismatch = formatMessage({
    id: 'form.passwordsMismatch',
    defaultMessage: 'Passwords do not match',
  });

  const createAccountPageTitle = formatMessage({
    id: 'nonAuth.createAccountPage',
    defaultMessage: 'Create Account page',
  });

  const createAccountLabel = formatMessage({
    id: 'nonAuth.createAccount',
    defaultMessage: 'Create Account',
  });

  const orLabel = formatMessage({
    id: 'common.or',
    defaultMessage: 'Or',
  });

  const alreadyHaveAccountQuestion = formatMessage({
    id: 'nonAuth.alreadyHaveAccount',
    defaultMessage: 'Already have an account?',
  });

  const changePassPageTitle = formatMessage({
    id: 'nonAuth.changePassPage',
    defaultMessage: 'Change password page',
  });

  const changePassLabel = formatMessage({
    id: 'nonAuth.changePass',
    defaultMessage: 'Change Password',
  });

  const resetPassLabel = formatMessage({
    id: 'nonAuth.resetPass',
    defaultMessage: 'Reset Password',
  });

  const newPassLabel = formatMessage({
    id: 'nonAuth.newPass',
    defaultMessage: 'New password',
  });

  const confirmNewPassLabel = formatMessage({
    id: 'nonAuth.confirmNewPass',
    defaultMessage: 'Confirm new password',
  });

  const forgotPassPageTitle = formatMessage({
    id: 'nonAuth.forgotPassPage',
    defaultMessage: 'Forgot password page',
  });

  const cancelLabel = formatMessage({
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  });

  const resetPassPageTitle = formatMessage({
    id: 'nonAuth.resetPassPage',
    defaultMessage: 'Reset password page',
  });

  const acceptedInvitePageTitle = formatMessage({
    id: 'nonAuth.acceptedInvite',
    defaultMessage: 'Accepted Invite',
  });

  const inviteExpiredSubTitle = formatMessage({
    id: 'nonAuth.inviteExpired.title',
    defaultMessage: 'Sorry, your invite has expired.',
  });

  const inviteExpiredDescription = formatMessage({
    id: 'nonAuth.inviteExpired.description',
    defaultMessage:
      'Your invite link is no longer valid. To request a new invite, please contact Shift AI tech support.',
  });

  const accountCreationSupportLabel = formatMessage({
    id: 'nonAuth.accountCreationSupport',
    defaultMessage: 'Having trouble creating an account?',
  });

  const havingTroubleSupportLabel = formatMessage({
    id: 'nonAuth.havingTroubleSupport',
    defaultMessage: 'Having trouble?',
  });

  const accountLoggingInSupportLabel = formatMessage({
    id: 'nonAuth.accountLoggingInSupport',
    defaultMessage: 'Having trouble logging in?',
  });

  const currentPasswordLabel = formatMessage({
    id: 'nonAuth.currentPassword',
    defaultMessage: 'Current password',
  });

  return {
    currentPasswordLabel,
    loginLabel,
    resetPassPageTitle,
    cancelLabel,
    resetPassLabel,
    forgotPassLabel,
    forgotPassPageTitle,
    loginPageTitle,
    createAccountPageTitle,
    changePassPageTitle,
    logInLabel,
    logInWithOktaLabel,
    emailLabel,
    passLabel,
    createPassLabel,
    confirmPassLabel,
    createAccountLabel,
    orLabel,
    passMismatch,
    alreadyHaveAccountQuestion,
    changePassLabel,
    newPassLabel,
    confirmNewPassLabel,
    acceptedInvitePageTitle,
    inviteExpiredSubTitle,
    inviteExpiredDescription,
    accountCreationSupportLabel,
    havingTroubleSupportLabel,
    accountLoggingInSupportLabel,
  };
};
