import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import en from './langs/compiled-langs/en.json';
import fr from './langs/compiled-langs/fr.json';

export enum Languages {
  'en' = 'en',
  'en-GB' = 'en-GB',
  'en-US' = 'en-US',
  'en-AU' = 'en-AU',
  'de' = 'de',
  'es' = 'es',
}

export type MessagesIntl =
  | Record<string, string>
  | Record<string, MessageFormatElement[]>
  | undefined;

export type DateAdapterLocales = string | object | undefined;

export type LanguageData = {
  name: string;
  messages: MessagesIntl;
  dateAdapterLocales: DateAdapterLocales;
};

type LanguagesMap = {
  [key in Languages]: LanguageData;
};

export const languagesMap: LanguagesMap = {
  [Languages['en']]: {
    name: 'English',
    messages: en,
    dateAdapterLocales: enLocale,
  },
  [Languages['en-GB']]: {
    name: 'English (GB)',
    messages: en,
    dateAdapterLocales: enLocale,
  },
  [Languages['en-US']]: {
    name: 'English (US)',
    messages: en,
    dateAdapterLocales: enLocale,
  },
  [Languages['en-AU']]: {
    name: 'English (AU)',
    messages: en,
    dateAdapterLocales: enLocale,
  },
  [Languages.de]: {
    name: 'Deutsch',
    messages: fr,
    dateAdapterLocales: frLocale,
  },
  [Languages.es]: {
    name: 'Spanish',
    messages: fr,
    dateAdapterLocales: enLocale,
  },
};

export const TranslationsProvider = ({ children }: { children: ReactNode }) => {
  const { locale = 'en-US' } = useRouter();

  const languageData = useMemo(
    () => languagesMap[locale as keyof typeof Languages],
    [locale]
  );

  return (
    <IntlProvider locale={locale!} messages={languageData.messages}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={languageData.dateAdapterLocales}
      >
        {children}
      </LocalizationProvider>
    </IntlProvider>
  );
};
