import { useIntl } from 'react-intl';

export const useClientTranslations = () => {
  const { formatMessage } = useIntl();

  const clientsLabel = formatMessage({
    id: 'clients',
    defaultMessage: 'Clients',
  });

  const clientLabel = formatMessage({
    id: 'client',
    defaultMessage: 'Client',
  });

  const searchClientsLabel = formatMessage({
    id: 'clients.search',
    defaultMessage: 'Search Clients',
  });

  const createNewClientPlusLabel = formatMessage({
    id: 'clients.createNew',
    defaultMessage: '+ create new client',
  });

  const noCreatedClientsLabel = formatMessage({
    id: 'clients.noCreatedClients',
    defaultMessage: 'You haven’t created any clients yet.',
  });

  const addFirstClientLabel = formatMessage({
    id: 'clients.addFirstClient',
    defaultMessage: 'Add your first client.',
  });

  const clientNameLabel = formatMessage({
    id: 'clients.clientName',
    defaultMessage: 'Client Name',
  });

  const clientOwnerLabel = formatMessage({
    id: 'clients.clientOwner',
    defaultMessage: 'Client Owner',
  });

  const addNewClientLabel = formatMessage({
    id: 'clients.addNewClient',
    defaultMessage: 'Add New Client',
  });

  const addClientLabel = formatMessage({
    id: 'clients.addClient',
    defaultMessage: 'add client',
  });

  const inviteClientOwnerLabel = formatMessage({
    id: 'clients.inviteClientOwner',
    defaultMessage: 'Invite Client Owner',
  });

  const showMoreClientOwnersLabel = (length: number) =>
    formatMessage(
      {
        id: 'clients.showMoreClientOwners',
        defaultMessage: '{length, select, 2 {1 Other} other {Show All}}',
      },
      {
        length,
      }
    );

  const deactivatedClientLabel = formatMessage({
    id: 'clients.deactivatedClient',
    defaultMessage: 'Deactivated Client',
  });

  const aboutToDeactivateClientWithNameLabel = (clientName?: string) =>
    formatMessage(
      {
        id: 'clients.aboutToDeactivateClientWithName',
        defaultMessage:
          'You are about to deactivate {clientName, select, undefined {this client} other {{clientName} as a client}}',
      },
      { clientName }
    );

  const deactivationClientConsequenceLabel = formatMessage({
    id: 'clients.deactivationClientConsequence',
    defaultMessage:
      'The client will no longer have access to any projects or project data.',
  });

  const makeClientOwnerLabel = formatMessage({
    id: 'clients.makeClientOwner',
    defaultMessage: 'Make Client Owner',
  });

  const clientDeactivatedLabel = formatMessage({
    id: 'clients.clientDeactivated',
    defaultMessage: 'Client deactivated',
  });

  const clientSuccessfullyCreatedLabel = formatMessage({
    id: 'clients.clientSuccessfullyCreated',
    defaultMessage: 'Client successfully created',
  });

  const clientSuccessfullyUpdatedLabel = formatMessage({
    id: 'clients.clientSuccessfullyUpdated',
    defaultMessage: 'Client successfully updated',
  });

  return {
    clientSuccessfullyCreatedLabel,
    clientSuccessfullyUpdatedLabel,
    clientDeactivatedLabel,
    makeClientOwnerLabel,
    aboutToDeactivateClientWithNameLabel,
    deactivationClientConsequenceLabel,
    clientLabel,
    deactivatedClientLabel,
    showMoreClientOwnersLabel,
    inviteClientOwnerLabel,
    addClientLabel,
    addNewClientLabel,
    clientsLabel,
    clientNameLabel,
    clientOwnerLabel,
    searchClientsLabel,
    createNewClientPlusLabel,
    noCreatedClientsLabel,
    addFirstClientLabel,
  };
};
