import { Box, Typography } from '@mui/material';
import { TextEditor } from 'components/Inputs/TextEditor/TextEditor';
import { Controller, useFormContext } from 'react-hook-form';
import { useCommonTranslations } from 'translations/hooks';

export const Notes = ({ disabled }: { disabled: boolean }) => {
  const { control } = useFormContext();
  const { notesLabel } = useCommonTranslations();

  return (
    <>
      <Typography
        variant="caption"
        color="text.secondary"
        component="div"
        lineHeight={1}
        gutterBottom
        sx={typographySx(disabled)}
      >
        {notesLabel}
      </Typography>
      <Box height="calc(100% - 1.1rem)" fontSize="0.875rem">
        <Controller
          control={control}
          render={({ field }) => (
            <TextEditor
              content={field.value.json}
              onChangeAll={field.onChange}
              disabled={disabled}
            />
          )}
          name="notes"
          defaultValue=""
        />
      </Box>
    </>
  );
};

const typographySx = (disabled: boolean) => ({ opacity: disabled ? 0.5 : 1 });
