import { Box, Typography } from '@mui/material';
import { ProductType } from 'common/enums';
import { setLocalStoragePlaybookInfoCache } from 'common/localStorage';
import { MenuThemeSwitcher } from 'components/Header/MenuThemeSwitcher';
import { ProfileMenuAvatar } from 'components/Header/ProfileMenuAvatar';
import { Dropdown, DropdownContent } from 'components/Inputs/Dropdown';
import { useLogout, useUsersProjectsList } from 'hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useGetClientProject } from 'services/api/client/project/useGetClientProject';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useSetPlaybookInfo } from 'services/api/product/incubatePlaybook/useSetPlaybookInfo';
import { useFeatureFlags } from 'services/api/useFeatureFlags';
import { useSessionData } from 'services/api/useSessionData';
import {
  useAccountTranslations,
  useProjectTranslations,
} from 'translations/hooks';
import { getProcessEnv } from 'utils';

const {
  NEXT_PUBLIC_CHAT_URL: chatLink,
  NEXT_PUBLIC_PERSONAS_URL: personasLink,
} = getProcessEnv();

export const PlaybookProfile = () => {
  const router = useRouter();
  const { logout } = useLogout();
  const { roles } = useSessionData({});
  const { ff } = useFeatureFlags({});

  const { playbookInfo } = useGetPlaybookInfo({});
  const { setPlaybookInfo } = useSetPlaybookInfo();

  const { clientId, projectId, productId } = playbookInfo;
  const { availableProjects, isMoreProjects } = useUsersProjectsList();

  const { data: project } = useGetClientProject({
    defaultParams: {
      clientId,
      projectId,
    },
  });

  const { logOutLabel, myProfileLabel } = useAccountTranslations();
  const { myProjectsLabel } = useProjectTranslations();

  const productClickHandler = useCallback(
    (playbookSlug: string, _productId: string, productType: ProductType) =>
      () => {
        if (productType !== ProductType.chat) {
          setLocalStoragePlaybookInfoCache({
            playbookSlug,
            playbookInfo: {
              clientId,
              projectId,
              productId: _productId,
              productType,
            },
            timestamp: new Date(),
          });
        }
      },
    [clientId, projectId]
  );

  const profileMenuItem = useMemo(
    () => ({
      customMenuItem: (
        <ListItemLink
          title={myProfileLabel}
          link={roles.isClientUser ? `/client/${clientId}/profile` : '/profile'}
        />
      ),
    }),
    [clientId, roles.isClientUser, myProfileLabel]
  );
  const productsMenuItem = useMemo(
    () =>
      project?.products
        .filter(({ type }) => !(type === ProductType.chat && !ff.chatbot))
        .map(({ title, productId: _productId, slugId, type }, i) => ({
          topDivider: i === 0,
          selected: _productId === productId,
          customMenuItem: (
            <Box
              key={_productId}
              display="flex"
              alignItems="center"
              width="100%"
            >
              {_productId === productId ? (
                <Box p="6px 16px">
                  <Typography>{title}</Typography>
                </Box>
              ) : (
                <ListItemLink
                  title={title}
                  link={
                    type === ProductType.chat
                      ? chatLink
                      : type === ProductType.personas
                      ? personasLink
                      : `/playbook/${slugId}/home`
                  }
                  onClick={productClickHandler(slugId, _productId, type)}
                />
              )}
            </Box>
          ),
        })) ?? [],
    [project?.products, productId, productClickHandler, ff.chatbot]
  );
  const projectsMenuItem = useMemo(() => {
    const myProjectsLink =
      roles.isClientUser || roles.isUserPlatformAnalyst
        ? `/client/${clientId}/projects`
        : '/projects';

    const myProjects = {
      title: myProjectsLabel,
      onClick: () => {
        router.push(myProjectsLink);
      },
    };

    if (isMoreProjects) {
      const items = availableProjects.flatMap((c, i) => {
        const projectInfo = c[0];
        const clientMenuItem = {
          customMenuItemSx: { p: 0 },
          customMenuItem: (
            <Link
              href={`/client/${projectInfo.clientId}/projects`}
              style={aStyle}
            >
              <Typography fontWeight={700}>{projectInfo.clientName}</Typography>
            </Link>
          ),
        };
        const projectsMenuItems = c.map((p, idx) => {
          const bottomDivider =
            idx === c.length - 1 && i !== availableProjects.length - 1;
          const product = p.products[0];

          return {
            title: p.name,
            onClick: () => {
              setPlaybookInfo(
                {
                  clientId: p.clientId,
                  projectId: p._id,
                  productId: product.productId,
                  productType: product.type,
                },
                product.slugId
              );
            },
            selected: p._id === projectId,
            bottomDivider,
          };
        });

        return [clientMenuItem, ...projectsMenuItems];
      });

      return {
        nested: {
          ...myProjects,
          items,
        },
      };
    }

    return myProjects;
  }, [
    isMoreProjects,
    availableProjects,
    myProjectsLabel,
    projectId,
    setPlaybookInfo,
    roles.isClientUser,
    roles.isUserPlatformAnalyst,
    clientId,
    router,
  ]);

  const contentList: DropdownContent[] = [
    profileMenuItem,
    projectsMenuItem,
    { ...MenuThemeSwitcher() },
    ...productsMenuItem,
    {
      title: logOutLabel,
      onClick: logout,
      topDivider: true,
    },
  ];

  return <Dropdown dropdownEl={<ProfileIcon />} list={contentList} />;
};

const aStyle = {
  width: '100%',
  padding: '6px 16px',
};

const ProfileIcon = () => (
  <Box sx={profileIconWrapperStyle}>
    <ProfileMenuAvatar />
  </Box>
);

const profileIconWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  backgroundColor: 'background.paper',
  borderRadius: '50%',
};

export const ListItemLink = ({
  title,
  link,
  onClick,
}: {
  title: string;
  link: string;
  onClick?: () => void;
}) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    style={aStyle}
    onClick={onClick}
  >
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="6.5px"
    >
      <Typography width="100%">{title}</Typography>
    </Box>
  </a>
);
