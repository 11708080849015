import { headerSocketTopic } from 'common/consts';
import {
  DefaultPlaybookParams,
  IncubatePlaybookParams,
  IncubatePlaybookProps,
} from 'services/api/product/incubatePlaybook';
import { API } from 'services/api/utils';
import { Assignment } from 'types/AssignContent';
import { DefaultParams, DefaultRequest } from 'types/api';

// Create new assignment
//POST /v2/assignments
export type CreateAssignmentProps = DefaultRequest<
  DefaultPlaybookParams & Assignment
>;
export const createAssignmentRequest = ({
  params,
  topic,
}: CreateAssignmentProps) =>
  API.post('/v2/assignments', params, {
    headers: { [headerSocketTopic]: topic },
  });

// Get all non hidden frequently assigned next
// GET /v2/assignments/frequently-assigned-next/:clientId/:projectId/:productId
export const getAllFrequentlyAssignedNextRequest = ({
  params,
  topic,
}: IncubatePlaybookProps) => {
  const { clientId, projectId, productId } = params;

  return API.get(
    `/v2/assignments/frequently-assigned-next/${clientId}/${projectId}/${productId}?filterBy=isHidden&filterValue=false`,
    {
      headers: { [headerSocketTopic]: topic },
    }
  );
};

// Get all hidden frequently assigned next
// GET /v2/assignments/frequently-assigned-next/:clientId/:projectId/:productId
export const getAllHiddenFrequentlyAssignedNextRequest = ({
  params,
  topic,
}: IncubatePlaybookProps) => {
  const { clientId, projectId, productId } = params;

  return API.get(
    `/v2/assignments/frequently-assigned-next/${clientId}/${projectId}/${productId}?filterBy=isHidden&filterValue=true`,
    {
      headers: { [headerSocketTopic]: topic },
    }
  );
};

// Hide frequently assigned next
// PATCH /v2/assignments/frequently-assigned-next/:clientId/:projectId/:productId
export type HideFrequentlyAssignedNextParams = {
  unitId: string;
};

export type HideFrequentlyAssignedNextProps = DefaultRequest<
  IncubatePlaybookParams<HideFrequentlyAssignedNextParams>
>;

export const hideFrequentlyAssignedNextRequest = ({
  params: { defaultParams, params },
  topic,
}: HideFrequentlyAssignedNextProps) => {
  const { clientId, projectId, productId } = defaultParams;

  return API.patch(
    `/v2/assignments/frequently-assigned-next/${clientId}/${projectId}/${productId}`,
    params,
    {
      headers: { [headerSocketTopic]: topic },
    }
  );
};

// Get assignments list
// GET /v2/assignments/:clientId/:projectId/:productId
export type GetAssignmentsListProps = DefaultRequest<{
  defaultParams: DefaultPlaybookParams;
  params: { offset: number; limit: number };
}>;

export const getAssignmentsListRequest = ({
  params: {
    defaultParams: { clientId, projectId, productId },
    params,
  },
  topic,
}: GetAssignmentsListProps) =>
  API.get(`/v2/assignments/${clientId}/${projectId}/${productId}`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export type GetAssignmentInfoProps = DefaultRequest<{
  assignmentId: string;
}>;
export const getAssignmentInfoRequest = ({
  params: { assignmentId },
  topic,
}: GetAssignmentInfoProps) =>
  API.get(`/v2/assignments/${assignmentId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export type DeleteAssignmentProps = DefaultRequest<{
  assignmentId: string;
  unitId: string;
}>;
export const deleteAssignmentUnitRequest = ({
  params: { assignmentId, unitId },
  topic,
}: DeleteAssignmentProps) =>
  API.patch(`/v2/assignments/${assignmentId}/units/${unitId}`, undefined, {
    headers: { [headerSocketTopic]: topic },
  });

export type UpdateAssignmentProps = DefaultRequest<{
  assignmentId: string;
  defaultParams: DefaultPlaybookParams;
  params: Assignment;
}>;
export const updateAssignmentRequest = ({
  params: { assignmentId, params, defaultParams },
  topic,
}: UpdateAssignmentProps) =>
  API.patch(
    `/v2/assignments/${assignmentId}`,
    { ...params, ...defaultParams },
    {
      headers: { [headerSocketTopic]: topic },
    }
  );
// Get all assignments list
// GET /v2/assignments/all/:clientId/:projectId/:productId
export type GetAllAssignmentsListProps = DefaultRequest<
  IncubatePlaybookParams<Omit<DefaultParams, 'status'>>
>;

export const getAllAssignmentsListRequest = ({
  params: {
    defaultParams: { clientId, projectId, productId },
    params,
  },
  topic,
}: GetAllAssignmentsListProps) =>
  API.get(`/v2/assignments/all/${clientId}/${projectId}/${productId}`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

// Get assignment with unit and task id
// GET /v2/assignments/:clientId/:projectId/:productId/unitId/:unitId/taskId/:taskId

export type GetAssignmentWithUnitAndTaskIdParams = {
  unitId: string;
  taskId: string;
};

export type GetAssignmentWithUnitAndTaskIdProps = DefaultRequest<
  IncubatePlaybookParams<GetAssignmentWithUnitAndTaskIdParams>
>;

export const getAssignmentWithUnitAndTaskIdRequest = ({
  params: {
    defaultParams: { clientId, projectId, productId },
    params: { unitId, taskId },
  },
  topic,
}: GetAssignmentWithUnitAndTaskIdProps) =>
  API.get(
    `/v2/assignments/${clientId}/${projectId}/${productId}/unitId/${unitId}/taskId/${taskId}`,
    {
      headers: { [headerSocketTopic]: topic },
    }
  );

// Get all watched videos
// GET /v2/assignments/all-watched-videos/:clientId/:projectId/:productId
export const getAllWatchedVideosRequest = ({
  params,
  topic,
}: IncubatePlaybookProps) => {
  const { clientId, projectId, productId } = params;

  return API.get(
    `/v2/assignments/all-watched-videos/${clientId}/${projectId}/${productId}`,
    {
      headers: { [headerSocketTopic]: topic },
    }
  );
};

// Get teammates who watched video with video id
// GET /v2/assignments/teammates/:clientId/:projectId/:productId/:videoId

export type GetTeammatesWhoWatchedVideoPropsParams = {
  videoId: string;
};

export type GetTeammatesWhoWatchedVideoProps = DefaultRequest<
  IncubatePlaybookParams<GetTeammatesWhoWatchedVideoPropsParams>
>;

export const getTeammatesWhoWatchedVideoRequest = ({
  params: {
    defaultParams: { clientId, projectId, productId },
    params: { videoId },
  },
  topic,
}: GetTeammatesWhoWatchedVideoProps) =>
  API.get(
    `/v2/assignments/teammates/${clientId}/${projectId}/${productId}/${videoId}`,
    {
      headers: { [headerSocketTopic]: topic },
    }
  );
