import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Grid, IconButton, Paper, TextField } from '@mui/material';
import { useFormOptions } from 'common/formOptions';
import {
  ButtonContained,
  ButtonOutlined,
  ExpandImageButton,
} from 'components/Inputs/Button';
import { ImageUploadInput } from 'components/Inputs/fileUpload';
import { useValidateImage } from 'hooks';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  SuccessType,
  useUploadStakeholderMap,
} from 'services/api/product/ventureBuilder/stakeholderMap/useUploadStakeholderMap';
import { useCommonTranslations } from 'translations/hooks';
import { StakeholderMap } from 'types/ventureBuilder';
import { saveFileAs } from 'utils';
import { ModalStyled } from '../ModalStyled';

type EditClientUserForm = {
  name: string;
};

type EditClientUserModalProps = {
  stakeholderMap: StakeholderMap;
  isOpen: boolean;
  onClose: () => void;
  onSave: (changedSh: StakeholderMap) => void;
  onDelete: (map: StakeholderMap) => void;
  onExpand: (map: StakeholderMap) => void;
};

export const EditMapModal = ({
  stakeholderMap,
  isOpen,
  onSave,
  onDelete,
  onExpand,
  onClose,
}: EditClientUserModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditClientUserForm>({
    defaultValues: {
      name: stakeholderMap.name,
    },
  });

  const { textInputValidation } = useFormOptions();

  const [formUrl, setFormUrl] = useState(stakeholderMap.imageUrl);

  const submitHandler = ({ name }: EditClientUserForm) => {
    if (stakeholderMap.name === name && stakeholderMap.imageUrl === formUrl) {
      onClose();
    }
    const form: StakeholderMap = {
      _id: stakeholderMap._id,
      name,
      imageUrl: formUrl,
    };
    onSave(form);
  };

  const closeHandler = () => {
    onClose();
  };
  const expandImageHandler = () => onExpand(stakeholderMap);
  const deleteHandler = () => onDelete(stakeholderMap);

  const onNewImageUpload = (newMap: SuccessType) => {
    const formUrl = newMap.url.split('?')[0];
    setFormUrl(formUrl);
  };

  const handleDownload = () => {
    saveFileAs(stakeholderMap.imageUrl, stakeholderMap.name);
  };

  const { nameLabel, cancelLabel, saveLabel } = useCommonTranslations();

  return (
    <ModalStyled open={isOpen} onClose={closeHandler} style={modalStyle}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container display="flex" gap="40px">
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} sm={6}>
              <TextField
                sx={textFieldStyle}
                label={nameLabel}
                disabled={false}
                defaultValue={stakeholderMap.name}
                {...register('name', textInputValidation)}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid
              container
              xs={12}
              sm={6}
              item
              flex="100px 0 0"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton onClick={handleDownload}>
                <FileDownloadOutlinedIcon />
              </IconButton>
              <IconButton onClick={deleteHandler}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container sx={imageWrapperStyle} justifyContent="center">
            <ExpandImageButton
              src={formUrl}
              alt={stakeholderMap.name}
              onClick={expandImageHandler}
            />
            <EditUrlButton onUpload={onNewImageUpload} />
          </Grid>
          <Grid container item justifyContent="flex-end" spacing={2}>
            <Grid item>
              <ButtonOutlined color="secondary" onClick={closeHandler}>
                {cancelLabel}
              </ButtonOutlined>
            </Grid>
            <Grid item>
              <ButtonContained color="secondary" type="submit" loading={false}>
                {saveLabel}
              </ButtonContained>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ModalStyled>
  );
};
const textFieldStyle = { width: '100%' };
const imageWrapperStyle = {
  position: 'relative',
  '&:hover': {
    '& .edit-button': {
      display: 'flex',
    },
  },
};
const modalStyle = { p: { xs: '1rem', sm: '2rem' } };

const EditUrlButton = ({
  onUpload,
}: {
  onUpload: (newMap: SuccessType) => void;
}) => {
  const {
    query: { clientId },
  } = useRouter();
  const { uploadMap, isLoading } = useUploadStakeholderMap({
    defaultParams: {
      clientId: clientId as string,
      key: 'stakeholderMaps&frasl;stakeholderMap',
    },
    onSuccess: (result?: SuccessType) => {
      if (result) {
        onUpload(result);
      }
    },
  });
  const { validateImgInputHandler } = useValidateImage({
    onSuccess: ({ image }) => {
      uploadMap(image);
    },
  });

  return (
    <Paper className="edit-button" sx={menuPaperStyle}>
      <IconButton disabled={isLoading} component="label">
        <EditOutlinedIcon />
        <ImageUploadInput onChange={validateImgInputHandler} />
      </IconButton>
    </Paper>
  );
};

const menuPaperStyle = {
  zIndex: '3',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50px',
  height: '50px',
  position: 'absolute',
  right: '4px',
  top: '4px',
  padding: 0,
};
