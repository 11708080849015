import { MoreVert } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { ExpandImageButton } from 'components/Inputs/Button';
import { Dropdown } from 'components/Inputs/Dropdown';
import { useCommonTranslations } from 'translations/hooks';
import { StakeholderMap } from 'types/ventureBuilder';
import { saveFileAs } from 'utils';

type StakeholderMapItemProps = {
  stakeholderMap: StakeholderMap;
  editHandler: (shMap: StakeholderMap) => void;
  deleteHandler: (shMap: StakeholderMap) => void;
  expandHandler: (shMap: StakeholderMap) => void;
};

export const StakeholderMapItem = ({
  stakeholderMap,
  editHandler,
  deleteHandler,
  expandHandler,
}: StakeholderMapItemProps) => {
  const editMenuHandler = () => {
    editHandler(stakeholderMap);
  };
  const deleteMenuHandler = () => {
    deleteHandler(stakeholderMap);
  };
  const expandImageHandler = () => {
    expandHandler(stakeholderMap);
  };

  const handleDownload = () => {
    saveFileAs(stakeholderMap.imageUrl, stakeholderMap.name);
  };

  const { editLabel, deleteLabel, downloadLabel } = useCommonTranslations();

  const menuList = [
    {
      title: editLabel,
      onClick: editMenuHandler,
    },
    {
      customMenuItem: (
        <Typography sx={downloadLinkStyle} onClick={handleDownload}>
          {downloadLabel}
        </Typography>
      ),
    },
    {
      title: deleteLabel,
      onClick: deleteMenuHandler,
    },
  ];

  const dropdownEl = (
    <Paper elevation={1} sx={menuPaperStyle}>
      <MoreVert />
    </Paper>
  );

  return (
    <Box position="relative">
      <Typography fontSize="14px" noWrap width="200px" marginBottom="4px">
        {stakeholderMap.name}
      </Typography>
      <ExpandImageButton
        src={stakeholderMap.imageUrl}
        alt={stakeholderMap.name}
        width={200}
        height={200}
        onClick={expandImageHandler}
        cover
      />
      <Dropdown sx={menuStyle} dropdownEl={dropdownEl} list={menuList} />
    </Box>
  );
};

const downloadLinkStyle = {
  padding: '8px 16px',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'none',
  },
};
const menuPaperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  color: 'text.secondary',
};
const menuStyle = {
  zIndex: '3',
  position: 'absolute',
  right: '4px',
  top: '28px',
};
