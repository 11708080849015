import { Grid } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Link } from 'components/Inputs/Link';
import { PlaybookFavoriteButton } from 'components/Playbook/PlaybookFavoriteButton';
import { TableSortModel } from 'components/Table';
import PlaybookTable from 'components/Table/PlaybookTable';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookSearch } from 'types/incubatePlaybook';

type IncubatePlaybookSearchTableData = IncubatePlaybookSearch & {
  id: string;
};

type onClick = (row: IncubatePlaybookSearchTableData) => void;

type SearchTableProps = {
  items: IncubatePlaybookSearch[];
  loading?: boolean;
  sortModel?: TableSortModel;
  sortModelChangeHandler?: (model: TableSortModel) => void;
  totalCount?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  onUnitClick: onClick;
  onTaskClick: onClick;
  addBelowTable?: ReactNode;
};

export const PlaybookSearchTable = ({
  items,
  loading,
  sortModel,
  sortModelChangeHandler,
  totalCount,
  page,
  onPageChange,
  onUnitClick,
  onTaskClick,
  addBelowTable,
}: SearchTableProps) => {
  return (
    <PlaybookTable
      rows={items}
      columns={Columns(onUnitClick, onTaskClick)}
      sortModel={sortModel}
      sortModelChangeHandler={sortModelChangeHandler}
      loading={loading}
      rowCount={totalCount}
      page={page}
      onPageChange={onPageChange}
      addBelowTable={addBelowTable}
    />
  );
};

const favouriteButtonWrapperStyle = { textAlign: 'right' as const };

const renderUnitName = (
  params: GridCellParams,
  onUnitClick: onClick,
  onTaskClick: onClick
) => {
  return params.row.documentType == 'TASK' ? (
    <Grid container>
      <Grid item xs={12}>
        <Link
          onClick={() => onTaskClick(params.row)}
          variant="body1"
          color="secondary"
        >
          {params.row.documentName}
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Link
          onClick={() => onUnitClick(params.row)}
          variant="body2"
          color="secondary"
        >
          {params.row.parentName}
        </Link>
      </Grid>
    </Grid>
  ) : (
    <Link
      onClick={() => onUnitClick(params.row)}
      variant="body1"
      color="secondary"
    >
      {params.row.documentName}
    </Link>
  );
};

const renderFavouriteButton = (params: GridCellParams) => {
  return (
    <div style={favouriteButtonWrapperStyle}>
      {params.row.documentId.match(/taskconcept|taskactivity/i) && (
        <PlaybookFavoriteButton
          iconOnly
          itemId={params.row.documentId}
          itemName={params.row.documentName}
          itemPhaseName={params.row.phaseName}
          itemModuleName={params.row.moduleName}
          itemUnitName={params.row.unitName}
          itemImageURL={params.row.imageURL}
          itemType={params.row.documentType.toLowerCase()}
          itemLink={params.row.itemLink ?? ''}
        />
      )}
    </div>
  );
};

const renderDate = (
  params: GridValueFormatterParams,
  locale: string = 'en-US'
) => {
  if (!params?.value) return '';

  const date = new Date(params?.value);

  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const Columns: (onUnitClick: onClick, onTaskClick: onClick) => GridColDef[] = (
  onUnitClick,
  onTaskClick
) => {
  const { nameLabel, phaseLabel, lastViewedLabel } = useCommonTranslations();
  const { locale = 'en-US' } = useRouter();

  return [
    {
      field: 'documentName',
      headerName: nameLabel,
      sortable: false,
      filterable: false,
      flex: 3,
      minWidth: 250,
      editable: false,
      renderCell: (params: GridCellParams) =>
        renderUnitName(params, onUnitClick, onTaskClick),
      disableColumnMenu: true,
    },
    {
      field: 'phaseName',
      headerName: phaseLabel,
      sortable: true,
      filterable: false,
      flex: 1,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dateViewed',
      headerName: lastViewedLabel,
      sortable: false,
      filterable: false,
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        renderDate(params, locale),
      disableColumnMenu: true,
    },
    {
      field: 'fav',
      headerName: '',
      sortable: false,
      filterable: false,
      flex: 1,
      editable: false,
      renderCell: renderFavouriteButton,
      align: 'right',
      disableColumnMenu: true,
    },
  ];
};
