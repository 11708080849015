import { useQuery } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import { ClientProjectRequestProps } from 'services/api/client/project';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { Project, ProjectSchema } from 'types/Client';
import { SocketHandlerProps } from 'types/socket';
import { getClientProjectRequest } from '.';
import { projectQueryKeys } from './projectQueryKeys';

const schema = ProjectSchema;

type ResponseData = Project;
type Request = ClientProjectRequestProps['params'];

export const useGetClientProject = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
  enabled,
}: SocketHandlerProps<Request, ResponseData>) => {
  const { notify } = useNotifications();
  const successHandler = useCallback(
    (result?: ResponseData) => {
      onSuccess?.(result);
    },
    [onSuccess]
  );
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.PROJECT_LOAD,
    request: getClientProjectRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [projectQueryKeys.single(defaultParams!)],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled,
    }
  );

  return {
    isLoading,
    data,
  };
};
