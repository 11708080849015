import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import { ButtonContained, ButtonOutlined } from 'components/Inputs/Button';
import { useCommonTranslations } from 'translations/hooks';

type ConfirmModalType = {
  cancel: () => void;
  proceed: () => void;
};

const ConfirmModal = ({ cancel, proceed }: ConfirmModalType) => {
  const handleBackdropClick = (event: MouseEvent) => {
    event.stopPropagation();

    return false;
  };

  const {
    saveChangesQuestionLabel,
    unsavedChangesWillBeLostLabel,
    cancelLabel,
    discardChangesLabel,
  } = useCommonTranslations();

  return (
    <Dialog open={true} onClose={handleBackdropClick}>
      <Box p="50px 100px">
        <DialogContent sx={dialogBoxStyle}>
          <Typography variant="h5" mb="10px">
            {saveChangesQuestionLabel}
          </Typography>
          <Typography variant="body1">
            {unsavedChangesWillBeLostLabel}
          </Typography>
        </DialogContent>
        <Box display="flex" justifyContent="space-around" mt="20px">
          <ButtonOutlined onClick={cancel}>{cancelLabel}</ButtonOutlined>
          <ButtonContained variant="contained" onClick={proceed}>
            {discardChangesLabel}
          </ButtonContained>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmModal;

const dialogBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
};
