import { Box, CircularProgress } from '@mui/material';

export const LoadingScreen = () => (
  <Box
    justifyContent="center"
    alignItems="center"
    height="100vh"
    width="100%"
    display="flex"
    position="fixed"
  >
    <CircularProgress size={55} thickness={3} />
  </Box>
);
