import { DropResult } from '@hello-pangea/dnd';
import VentureBuilderIcon from '@mui/icons-material/BuildOutlined';
import { Box, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { SectionModes } from 'common/ventureBuilderConsts';
import { ReactNode, useMemo, useState } from 'react';
import { useTopValuePropositionsData } from 'services/api/product/ventureBuilder/topValuePropositions/useTopValuePropositionsData';
import { useWhoMattersMostData } from 'services/api/product/ventureBuilder/whoMattersMost/useWhoMattersMostData';
import { useProductTranslations } from 'translations/hooks';
import { TopValueProposition } from 'types/ventureBuilder';
import { removeEmptyKeys } from 'utils';
import TopValuePropositionsList from './TopValuePropositionsList';
import { TEMP_ID } from './TopValuePropositionsListItem';

export type TopValuePropositionsListState =
  | {
      type: VentureBuilderItemAction.VIEW;
    }
  | {
      type: VentureBuilderItemAction.EDIT;
      data: TopValueProposition;
      index: number;
    }
  | {
      type: VentureBuilderItemAction.ADD;
      data: TopValueProposition;
      index: number;
    };

export const TopValuePropositions = ({ mode }: { mode: SectionModes }) => {
  const { notify } = useNotifications();

  const { topValuePropositions, isGetting, updateTopValuePropositions } =
    useTopValuePropositionsData();

  const { stakeholders } = useWhoMattersMostData();

  const [state, setState] = useState<TopValuePropositionsListState>({
    type: VentureBuilderItemAction.VIEW,
  });

  const topValuePropositionsLocal = useMemo(() => {
    const tmpTopValuePropositions = [...topValuePropositions];

    if (state.type === VentureBuilderItemAction.VIEW)
      return tmpTopValuePropositions;

    const currentIndex =
      state.index > tmpTopValuePropositions.length
        ? tmpTopValuePropositions.length
        : state.index;

    if (state.type === VentureBuilderItemAction.ADD) {
      tmpTopValuePropositions.splice(currentIndex, 0, state.data);

      return tmpTopValuePropositions;
    }

    if (state.type === VentureBuilderItemAction.EDIT) {
      const isDeleted = !tmpTopValuePropositions.some(
        (hb) => hb._id === state?.data?._id
      );
      if (isDeleted)
        setState(
          (prevState) =>
            ({
              ...prevState,
              type: VentureBuilderItemAction.ADD,
            } as TopValuePropositionsListState)
        );
      tmpTopValuePropositions.splice(currentIndex, 1, state.data);

      return tmpTopValuePropositions;
    }

    return tmpTopValuePropositions;
  }, [topValuePropositions, state]);

  // handlers
  const handleAdd = (addNewShouldBeOnTopOfTheList: boolean) => () => {
    setState({
      type: VentureBuilderItemAction.ADD,
      data: {
        _id: TEMP_ID,
        valuePropositionName: '',
        stakeholderId: '',
        averageNps: undefined,
        averagePainScore: undefined,
      },
      index: addNewShouldBeOnTopOfTheList
        ? 0
        : topValuePropositionsLocal.length,
    });
  };

  const handleEdit = (topValueProposition: TopValueProposition) => {
    setState({
      type: VentureBuilderItemAction.EDIT,
      data: topValueProposition,
      index: topValuePropositions.findIndex(
        (tvp) => tvp._id === topValueProposition._id
      ),
    });
  };

  const handleCancel = () => {
    setState({ type: VentureBuilderItemAction.VIEW });
  };

  const handleDelete = (topValueProposition: TopValueProposition) => {
    const newData = topValuePropositions.filter(
      (tvp) => tvp._id !== topValueProposition._id
    );

    updateTopValuePropositions({
      payload: {
        action: VentureBuilderItemAction.DELETE,
        data: topValueProposition,
      },
      newData,
    });
  };

  const stakeholderHasError = (
    topValueProposition: TopValueProposition
  ): boolean => {
    if (state.type === VentureBuilderItemAction.VIEW) return true;
    const cleanupString = (string: string) => string?.toLowerCase().trim();

    const topValuePropositionExists = topValuePropositions.some(
      (tvp) =>
        tvp._id !== state.data._id &&
        !topValueProposition.stakeholderId &&
        cleanupString(tvp.valuePropositionName) ===
          cleanupString(topValueProposition?.valuePropositionName)
    );

    const topValuePropositionForStakeholderExists = topValuePropositions.some(
      (tvp) =>
        tvp._id !== state.data._id &&
        tvp.stakeholderId === topValueProposition.stakeholderId &&
        cleanupString(tvp.valuePropositionName) ===
          cleanupString(topValueProposition?.valuePropositionName)
    );

    if (topValuePropositionExists) {
      notify(NotificationType.TOP_VALUE_PROPOSITION_EXISTS);
    }

    if (topValuePropositionForStakeholderExists) {
      notify(NotificationType.TOP_VALUE_PROPOSITION_FOR_STAKEHOLDER_EXISTS);
    }

    return topValuePropositionExists || topValuePropositionForStakeholderExists;
  };

  const handleSave = (topValueProposition: TopValueProposition) => {
    if (stakeholderHasError(topValueProposition)) return false;

    // remove empty keys
    const tmpPayload = removeEmptyKeys(topValueProposition);

    const newData = [...topValuePropositions];
    if (state.type === VentureBuilderItemAction.ADD) {
      newData.push({ ...topValueProposition });
    } else if (state.type === VentureBuilderItemAction.EDIT) {
      newData.splice(state.index, 1, topValueProposition);
    }

    updateTopValuePropositions({
      payload: {
        action: state.type,
        data: tmpPayload,
      },
      newData,
    });

    setState({ type: VentureBuilderItemAction.VIEW });

    return true;
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    const noChanges =
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index);

    if (noChanges) return;

    const newTopValuePropositions = [...topValuePropositionsLocal];
    const draggedTopValuePropositions = topValuePropositionsLocal.find(
      (tvp) => tvp._id === draggableId
    );
    newTopValuePropositions.splice(source.index, 1);
    newTopValuePropositions.splice(
      destination.index,
      0,
      draggedTopValuePropositions as TopValueProposition
    );

    if (state.type === VentureBuilderItemAction.VIEW) {
      updateTopValuePropositions({
        payload: {
          action: VentureBuilderItemAction.EDIT,
          data: {
            ...draggedTopValuePropositions,
            newOrderIndex: destination.index,
          } as TopValueProposition,
        },
        newData: newTopValuePropositions,
      });
    }
  };

  const renderList = (
    <TopValuePropositionsList
      isLoading={isGetting}
      collapseList={mode === SectionModes.TASK}
      data={topValuePropositionsLocal}
      stakeholders={stakeholders}
      state={state}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onCancel={handleCancel}
      onDelete={handleDelete}
      onSave={handleSave}
      onDragEnd={handleDragEnd}
    />
  );

  return mode === SectionModes.TASK ? (
    <SectionTaskWrapper>{renderList}</SectionTaskWrapper>
  ) : (
    <Box margin="32px 0">{renderList}</Box>
  );
};

//  TODO: Check and change if needed title and subtitle text after the design is provided
const SectionTaskWrapper = ({ children }: { children: ReactNode }) => {
  const {
    objectiveDocumentSectionInVBLabel,
    topValuePropositionsLabel,
    documentAllTopValuePropositionsInVBLabel,
  } = useProductTranslations();

  return (
    <>
      <Box display="flex" alignItems="center" gap="10px">
        <VentureBuilderIcon />
        <Typography fontSize="20px">
          {objectiveDocumentSectionInVBLabel(topValuePropositionsLabel)}
        </Typography>
      </Box>
      <Typography margin="10px 0 30px 0">
        {documentAllTopValuePropositionsInVBLabel}
      </Typography>
      {children}
    </>
  );
};
