import { incubatePlaybookTaskEnum } from 'common/enums';
import { IncubatePlaybookUnitData } from 'types/incubatePlaybook';

export const getAllTaskResources = (
  tasks: IncubatePlaybookUnitData['tasks']
) => {
  if (!tasks) return [];

  const temp: Record<string, boolean> = {};

  const resources = tasks
    .flatMap((task) =>
      task._type === incubatePlaybookTaskEnum.taskActivity ||
      task._type === incubatePlaybookTaskEnum.taskConcept
        ? task.resources ?? []
        : []
    )
    .filter((x) => {
      if (!temp[x.slug.current]) {
        temp[x.slug.current] = true;

        return true;
      }
    });

  return resources;
};
