import { Box, CircularProgress, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { ButtonText } from 'components/Inputs/Button';
import { Link } from 'components/Inputs/Link';
import { useState } from 'react';
import {
  useCommonTranslations,
  useProductTranslations,
} from 'translations/hooks';
import { Stakeholder } from 'types/ventureBuilder';
import { WhoMattersMostState } from '.';
import { StakeholdersListItem } from './StakeholdersListItem';

type StakeholdersListProps = {
  isLoading: boolean;
  stakeholders: Stakeholder[];
  collapseList: boolean;
  state: WhoMattersMostState;
  onAdd: (addNewShouldBeOnTopOfTheList: boolean) => () => void;
  onEdit: (stakeholder: Stakeholder) => void;
  onCancel: () => void;
  onDelete: (stakeholder: Stakeholder) => void;
  onSave: (stakeholder: Stakeholder) => boolean;
};

const StakeholdersList = ({
  isLoading,
  stakeholders,
  collapseList,
  state,
  onAdd,
  onEdit,
  onCancel,
  onDelete,
  onSave,
}: StakeholdersListProps) => {
  const [showAll, setShowAll] = useState(!collapseList);

  const stakeholdersToShow = showAll
    ? stakeholders
    : stakeholders.slice(0, COLLAPSED_LIST_LIMIT);

  const setShowAllTrue = () => setShowAll(true);

  const { whoMattersMostLabel, stakeholdersLabel, plusNewStakeholderLabel } =
    useProductTranslations();
  const { showAllLabel } = useCommonTranslations();

  return (
    <Box>
      <Typography variant="h6" fontWeight="600">
        {whoMattersMostLabel}
      </Typography>
      <Box margin="10px 0">
        <Typography fontWeight={500} sx={listHeaderStyle}>
          {stakeholdersLabel}
        </Typography>
        {isLoading && stakeholders.length === 0 && (
          <Box sx={loaderWrapperStyle}>
            <CircularProgress size={30} />
          </Box>
        )}
        <>
          {stakeholders.length ? (
            stakeholdersToShow.map((stakeholder) => (
              <StakeholdersListItem
                key={stakeholder._id}
                stakeholder={stakeholder}
                state={state}
                onEdit={onEdit}
                onCancel={onCancel}
                onDelete={onDelete}
                onSave={onSave}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </>
        <Box display="flex" justifyContent="space-between">
          <ButtonText
            color="secondary"
            disabled={state.type !== VentureBuilderItemAction.VIEW}
            onClick={onAdd(
              !showAll && stakeholders.length >= COLLAPSED_LIST_LIMIT
            )}
          >
            {plusNewStakeholderLabel}
          </ButtonText>
          {!showAll && stakeholders.length > COLLAPSED_LIST_LIMIT && (
            <Link sx={linkStyle} onClick={setShowAllTrue}>
              {showAllLabel}
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const listHeaderStyle = {
  width: '100%',
  borderBottom: '1px solid',
  borderColor: 'action.disabledBackground',
  marginTop: '12px',
  padding: '0 0 8px 16px',
};
const loaderWrapperStyle = { margin: '10px', textAlign: 'center' };
const linkStyle = { marginTop: '8px' };

const COLLAPSED_LIST_LIMIT = 3;

const EmptyState = () => {
  const { noAddedStakeholdersLabel } = useProductTranslations();

  return (
    <Box marginY="8px">
      <Typography fontWeight={200}>{noAddedStakeholdersLabel}</Typography>
    </Box>
  );
};

export default StakeholdersList;
