import { ButtonText } from 'components/Inputs/Button';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import { getBaseLink } from 'utils/incubatePlaybook';
import { NotificationActionType } from './notificationConsts';

export const NotificationActionMapper = ({
  type,
}: {
  type: NotificationActionType;
}): ReactNode => {
  const router = useRouter();

  const { viewAllAssignmentsLabel } = useCommonTranslations();

  const goToAssignmentsPage = () => {
    const baseLink = getBaseLink(router.asPath);
    router.push(`${baseLink}/assignments`);
  };

  switch (type) {
    case NotificationActionType.VIEW_ALL_ASSIGNMENTS:
      return (
        <ButtonText sx={buttonSx} onClick={goToAssignmentsPage}>
          {viewAllAssignmentsLabel}
        </ButtonText>
      );

    default:
      return <></>;
  }
};

const buttonSx = { color: 'common.white' };
