import {
  IncubatePlaybookFavoriteListParams,
  IncubatePlaybookProps,
  IncubatePlaybookRecentsEnterpriseResources,
  IncubatePlaybookRecentsListParams,
  IncubatePlaybookRecentsResources,
  IncubatePlaybookSearchListParams,
  PlaybookInfoProps,
} from '.';

export const incubatePlaybookQueryKeys = {
  base: { scope: 'incubate-playbook' } as const,
  info: (params: PlaybookInfoProps['params']) =>
    ({ ...incubatePlaybookQueryKeys.base, entity: 'info', params } as const),
  lastInfo: () =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'last-info',
      params: undefined,
    } as const),
  data: (params: IncubatePlaybookProps['params']) =>
    ({ ...incubatePlaybookQueryKeys.base, entity: 'data', params } as const),
  favoriteIds: (params: IncubatePlaybookProps['params']) =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'favoriteIds',
      params,
    } as const),
  inProgress: (params: IncubatePlaybookProps['params']) =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'inProgress',
      params,
    } as const),
  visited: (params: IncubatePlaybookProps['params']) =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'visited',
      params,
    } as const),
  videosWatchedTime: () =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'videos-watched-time',
      params: undefined,
    } as const),
  quiz: (params: IncubatePlaybookProps['params']) =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'quiz',
      params,
    } as const),
  resources: (params: IncubatePlaybookRecentsResources['params']) =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'resources',
      params,
    } as const),
  enterpriseResources: (
    params: IncubatePlaybookRecentsEnterpriseResources['params']
  ) =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'enterprise-resources',
      params,
    } as const),
  favoriteListBase: () =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'favoriteList',
    } as const),
  favoriteList: (params: IncubatePlaybookFavoriteListParams['params']) =>
    ({
      ...incubatePlaybookQueryKeys.favoriteListBase(),
      params,
    } as const),
  searchListBase: () =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'searchList',
    } as const),
  searchList: (params: IncubatePlaybookSearchListParams['params']) =>
    ({
      ...incubatePlaybookQueryKeys.searchListBase(),
      params,
    } as const),
  recentsListBase: () =>
    ({
      ...incubatePlaybookQueryKeys.base,
      entity: 'recentsList',
    } as const),
  recentsList: (params: IncubatePlaybookRecentsListParams['params']) =>
    ({
      ...incubatePlaybookQueryKeys.recentsListBase(),
      params,
    } as const),
};
