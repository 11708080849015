import { Box, Typography } from '@mui/material';
import { usePlaybookResources } from 'hooks/usePlaybookResources';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookUnitData, Resource } from 'types/incubatePlaybook';
import { PlaybookResourceItem } from '../PlaybookResourceItem';
import { TaskWrapper } from './TaskWrapper';

type TaskResourcesProps = {
  resources?: Resource[] | null;
  title?: string;
  moduleTitle: IncubatePlaybookUnitData['moduleTitle'];
  phaseTitle: IncubatePlaybookUnitData['phaseTitle'];
};

export const TaskResources = ({
  resources,
  title,
  moduleTitle,
  phaseTitle,
}: TaskResourcesProps) => {
  const { resourcesLabel } = useCommonTranslations();
  const { resourcesData } = usePlaybookResources();

  if (!resources?.length) return null;

  const resourcesToShow = resources
    .map(({ title, resourceType, _id, thumbnail, slug }) => {
      if (resourcesData[slug.current ?? ''])
        return (
          <PlaybookResourceItem
            key={_id}
            title={title}
            module={moduleTitle}
            favoritesId={_id}
            type={resourceType.title}
            phase={phaseTitle}
            imgSrc={thumbnail?.asset?.url}
            resourceSlug={slug.current}
          />
        );
      else return null;
    })
    .filter((el) => el);

  return resourcesToShow.length ? (
    <TaskWrapper>
      <Box>
        <Typography fontSize="20px" fontWeight="600" mb="30px">
          {title || resourcesLabel}
        </Typography>
        <Box
          display="flex"
          gap="22px"
          width="100%"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {resourcesToShow}
        </Box>
      </Box>
    </TaskWrapper>
  ) : (
    <></>
  );
};
