import { useQuery } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import { topRoutes } from 'common/routes';
import useNotifications from 'common/useNotifications';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { socketTopics } from 'services/sockets/topics';
import { Client, ClientSchema } from 'types/Client';
import { SocketHandlerProps } from 'types/socket';
import { ClientParams, getClientRequest } from '../client';
import { useGetPlaybookInfo } from '../product/incubatePlaybook/useGetPlaybookInfo';
import { useSessionData } from '../useSessionData';
import { useFetch } from '../utils';
import { clientQueryKeys } from './clientQueryKeys';

const schema = ClientSchema;

type Request = ClientParams['params'];
type ResponseData = Client;

export const useGetClient = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<void, ResponseData>) => {
  const { query, route, replace } = useRouter();
  const { playbookInfo } = useGetPlaybookInfo({});
  const clientId =
    (query.clientId as string) || (playbookInfo.clientId as string);
  const isValidRoute =
    route.startsWith(topRoutes.client) || route.startsWith(topRoutes.playbook);

  const { notify } = useNotifications();
  const { roles } = useSessionData({});

  const enabled = !!clientId && roles.isPlatformUser && isValidRoute;

  const successHandler = useCallback(
    (result?: ResponseData) => {
      onSuccess?.(result);
    },
    [onSuccess]
  );
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      replace('/clients');
      onFailure?.();
    },
    [notify, onFailure, replace]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.CLIENT_LOAD,
    request: getClientRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [clientQueryKeys.single({ clientId })],
    fetchFn,
    {
      enabled,
      staleTime: 5 * 60 * 1000,
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
    }
  );

  return { client: data, isLoadingClient: isLoading && enabled };
};
