import { Box, Typography } from '@mui/material';
import { MuiIcon } from 'components/MuiIcon';
import { TimeEstimateComponent } from './TimeEstimateComponent';

type PlaybookPhaseHeaderProps = {
  title: string;
  subtitle: string;
  iconName?: string;
  estimatedTime?: number | string | null;
};

export const PlaybookPhaseHeader = ({
  title,
  subtitle,
  iconName,
  estimatedTime,
}: PlaybookPhaseHeaderProps) => (
  <Box mb="36px" width="100%">
    <Box display="flex" alignItems="center" gap="13px" mt="52px">
      <MuiIcon iconName={iconName} fontSize="large" />
      <Typography fontSize={34}>{title}</Typography>
    </Box>
    <Typography fontSize={20} fontWeight={600} mt="12px" mb="16px">
      {subtitle}
    </Typography>
    <TimeEstimateComponent value={estimatedTime} />
  </Box>
);
