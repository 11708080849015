import {
  Box,
  Grid,
  IconButton,
  Paper,
  Rating,
  TextField,
  Typography,
} from '@mui/material';
import { ButtonOutlined } from 'components/Inputs/Button';
import { MuiIcon } from 'components/MuiIcon';
import { useCustomMediaQuery } from 'hooks';
import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useSendIncubatePlaybookFeedback } from 'services/api/product/incubatePlaybook/useSendIncubatePlaybookFeedback';
import { useCommonTranslations } from 'translations/hooks';
import { FeedbackPageType, FeedbackRatingStatus } from 'types/incubatePlaybook';

type State = 'init' | 'feedback' | 'submitted';
type Form = { feedback: string };

type FeedbackModuleProps = {
  pageType: FeedbackPageType;
  page: string;
};

export const FeedbackModule = ({ pageType, page }: FeedbackModuleProps) => {
  const { sendRating, sendFeedback, isLoading } =
    useSendIncubatePlaybookFeedback({});

  const { lessThenSm } = useCustomMediaQuery();

  const { shareFeedbackLabel } = useCommonTranslations();

  const [state, setState] = useState<State>('init');
  const [ratingValue, setRatingValue] = useState<
    FeedbackRatingStatus | undefined
  >();

  const { register, handleSubmit, watch, resetField } = useForm<Form>();

  useEffect(() => {
    setState('init');
    resetField('feedback');
  }, [pageType, page, resetField]);

  const ratingHandler: MouseEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const target = e.target as HTMLInputElement;
      if (target.value === undefined) return;
      const rValue = Number(target.value) as FeedbackRatingStatus;

      sendRating({
        feedback: null,
        ratingValue: rValue,
        pageType,
        page,
      });
      setRatingValue(rValue);
      setState('feedback');
    },
    [page, pageType, sendRating]
  );
  const feedbackHandler = useCallback(
    ({ feedback }: Form) => {
      if (ratingValue !== undefined) {
        sendFeedback({
          feedback,
          ratingValue,
          pageType,
          page,
        });
        setState('submitted');
      }
    },
    [page, pageType, ratingValue, sendFeedback]
  );
  const initFeedbackHandler = useCallback(() => {
    setState('init');
    resetField('feedback');
  }, [resetField]);

  if (state === 'init') {
    return (
      <Init
        onClick={ratingHandler}
        isSearch={pageType === 'Search'}
        xs={lessThenSm}
      />
    );
  }

  if (state === 'submitted') {
    return <Submitted />;
  }

  return (
    <Paper elevation={1}>
      <Grid container spacing={2} p="20px" minHeight="111px">
        <Grid
          item
          container
          gap="16px"
          xs={12}
          md={5}
          alignItems="center"
          flexWrap="nowrap"
        >
          <IconButton
            disabled={isLoading}
            sx={arrowButtonStyle}
            onClick={initFeedbackHandler}
          >
            <MuiIcon iconName="ChevronLeft" fontSize="large" />
          </IconButton>
          <Typography
            fontSize={24}
            fontWeight={700}
            width="max-content"
            color="secondary"
          >
            {ratingValue !== undefined && (
              <FeedbackText isPositive={ratingValue === 5} />
            )}
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={9} md={4} alignItems="center">
          <TextField
            {...register('feedback')}
            color="secondary"
            disabled={isLoading}
            fullWidth
            multiline
            autoFocus
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={3}
          justifyContent="flex-end"
          alignItems="center"
        >
          <ButtonOutlined
            color="secondary"
            disabled={!watch('feedback')?.trim()}
            loading={isLoading}
            sx={buttonStyle}
            onClick={handleSubmit(feedbackHandler)}
          >
            {shareFeedbackLabel}
          </ButtonOutlined>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Init = ({
  onClick,
  isSearch,
  xs,
}: {
  onClick: MouseEventHandler<HTMLInputElement>;
  isSearch: boolean;
  xs: boolean;
}) => {
  return (
    <Paper elevation={1}>
      <Grid
        container
        minHeight="111px"
        p="20px"
        alignItems="center"
        spacing={2}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          gap="30px"
        >
          <Typography fontSize={24} fontWeight={700} color="secondary">
            <FeedbackTitle isSearch={isSearch} />
          </Typography>
          <Box>
            <Box px={xs ? 0 : 2}>
              <Rating
                name="feedback-rating"
                onClick={onClick}
                emptyIcon={<StarIcon empty xs={xs} />}
                icon={<StarIcon xs={xs} />}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <StarText xs={xs}>
                <FeedbackPoorStar isSearch={isSearch} />
              </StarText>
              <StarText xs={xs}>
                <FeedbackGoodStar isSearch={isSearch} />
              </StarText>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Submitted = () => {
  const { thankYouForYourFeedbackLabel } = useCommonTranslations();

  return (
    <Paper elevation={1}>
      <Grid container minHeight="111px" spacing={2} p="20px">
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Typography fontSize={24} fontWeight={700} m="auto" color="secondary">
            {thankYouForYourFeedbackLabel}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const StarIcon = ({ empty, xs }: { empty?: boolean; xs?: boolean }) => (
  <Box mx={xs ? 2 : 3}>
    <MuiIcon
      iconName={empty ? 'StarOutlineOutlined' : 'StarOutlined'}
      fontSize="large"
      color="secondary"
    />
  </Box>
);

const StarText = ({ xs, children }: { xs: boolean; children: ReactNode }) => (
  <Typography align="center" width={xs ? '90px' : '110px'} fontSize={12}>
    {children}
  </Typography>
);

const buttonStyle = {
  width: '155px',
  height: '36px',
  borderRadius: '80px',
  whiteSpace: 'nowrap',
};
const arrowButtonStyle = {
  p: 0,
};

const FeedbackTitle = ({ isSearch }: { isSearch: boolean }) => {
  const { didYouFindWhatYouWereLookingForLabel, wasThisUsefulQuestionLabel } =
    useCommonTranslations();

  return (
    <>
      {isSearch
        ? didYouFindWhatYouWereLookingForLabel
        : wasThisUsefulQuestionLabel}
    </>
  );
};

const FeedbackText = ({ isPositive }: { isPositive: boolean }) => {
  const {
    greatWhatsWorkingQuestionLabel,
    howWeCanMakeThisBetterQuestionLabel,
  } = useCommonTranslations();

  return (
    <>
      {isPositive
        ? greatWhatsWorkingQuestionLabel
        : howWeCanMakeThisBetterQuestionLabel}
    </>
  );
};

const FeedbackPoorStar = ({ isSearch }: { isSearch: boolean }) => {
  const { iDidntFindWhatIWasLookingForLabel, notReallyUsefulLabel } =
    useCommonTranslations();

  return (
    <>{isSearch ? iDidntFindWhatIWasLookingForLabel : notReallyUsefulLabel}</>
  );
};

const FeedbackGoodStar = ({ isSearch }: { isSearch: boolean }) => {
  const { iFoundExactlyWhatINeededLabel, veryUsefulLabel } =
    useCommonTranslations();

  return <>{isSearch ? iFoundExactlyWhatINeededLabel : veryUsefulLabel}</>;
};
