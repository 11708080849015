import { Box, Paper } from '@mui/material';
import { ReactNode } from 'react';
import { StandardSanityImageOptions } from 'types/incubatePlaybook';
import { urlFor } from 'utils';
import { TaskImage } from './TaskImage';

export const TaskWrapper = ({
  children,
  image,
}: {
  children: ReactNode;
  image?: StandardSanityImageOptions['image'] | null;
}) => (
  <Paper elevation={1}>
    <TaskImage imageUrl={urlFor(image)?.width(1460).height(700).url()} />
    <Box p="30px">{children}</Box>
  </Paper>
);
