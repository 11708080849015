import { PortableText } from '@portabletext/react';
import { SanityPopoverTypeEnum } from 'common/enums';
import {
  isLocalStoragePlaybookVisited,
  setLocalStoragePlaybookVisited,
} from 'common/localStorage';
import { useCallback } from 'react';
import { useGetIncubatePlaybookVisited } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookVisited';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useSetIncubatePlaybookVisited } from 'services/api/product/incubatePlaybook/useSetIncubatePlaybookVisited';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import { PlaybookModal } from './PlaybookModal';

export const IntroductionPlaybookModal = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const { sanity } = useIncubatePlaybookData();
  const { visited } = useGetIncubatePlaybookVisited({});
  const { setVisited } = useSetIncubatePlaybookVisited({});

  const localItemId = `${playbookInfo.projectId}-${playbookInfo.productId}`;

  const closeHandler = useCallback(() => {
    setVisited({ itemId: ITEM_ID });
    setLocalStoragePlaybookVisited(localItemId);
  }, [localItemId, setVisited]);

  const data = sanity.popovers?.find(
    ({ type, activated }) =>
      activated && type === SanityPopoverTypeEnum.introduction
  );

  const isOpen =
    !!visited &&
    !visited[ITEM_ID] &&
    !isLocalStoragePlaybookVisited(localItemId);

  const { getStartedLabel, welcomeToYourTitleLabel } = useCommonTranslations();

  if (!data || !isOpen) return null;

  return (
    <PlaybookModal
      title={welcomeToYourTitleLabel(sanity.title)}
      open={isOpen}
      onClose={closeHandler}
      onClick={closeHandler}
      btnText={getStartedLabel}
      image={data.image}
    >
      <PortableText value={data.content} />
    </PlaybookModal>
  );
};

const ITEM_ID = 'introduction-popup';
