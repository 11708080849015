import { Box, Collapse, Divider, Grid, Paper, Typography } from '@mui/material';
import {
  defaultPlaybookCardImgLink,
  playbookCardImageHeight,
  playbookCardImageWidth,
} from 'common/consts';
import { Link } from 'components/Inputs/Link';
import { MuiIcon } from 'components/MuiIcon';
import { useCustomMediaQuery } from 'hooks';
import Img from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookInProgress,
  IncubatePlaybookPhase,
  StandardSanityImageOptions,
} from 'types/incubatePlaybook';
import { urlFor } from 'utils';
import { urlForThumbnail } from 'utils/imageUrlBuilder';
import { getBaseLink } from 'utils/incubatePlaybook';
import { PlaybookFavoriteButton } from './PlaybookFavoriteButton';
import { PlaybookInProgress } from './PlaybookInProgress';
import { TimeEstimateComponent } from './TimeEstimateComponent';

type Item = {
  _id: string;
  title: string;
  slug: IncubatePlaybookPhase['slug'];
};

type SubtitleState = 'initial' | 'expanded' | 'ellipsis';

type PlaybookCardProps = {
  title: string;
  subtitle: string;
  items: Item[];
  image: StandardSanityImageOptions['image'] | null;
  icon?: string;
  estimatedTime?: number | string | null;
  onClick: () => void;
  favoritesId?: string;
  phaseName?: string;
  parentSlug?: string;
  moduleName?: string;
  inProgressData?: IncubatePlaybookInProgress;
};

export const PlaybookCard = ({
  title,
  subtitle,
  items,
  image,
  icon,
  estimatedTime,
  onClick,
  favoritesId,
  phaseName,
  parentSlug,
  moduleName,
  inProgressData,
}: PlaybookCardProps) => {
  const { lessThenMd } = useCustomMediaQuery();
  const isUnitCard = !!phaseName;
  const [showMoreList, setShowMoreList] = useState(false);
  const [subtitleState, setSubtitleState] = useState<SubtitleState>(
    isUnitCard ? 'initial' : 'expanded'
  );
  const showMoreListToggle = () => setShowMoreList((o) => !o);

  const hasFavorite = typeof favoritesId !== 'undefined';
  const router = useRouter();
  const goToTaskOrModule = useCallback(
    (slug: string) => () => {
      const baseLink = getBaseLink(router.asPath);
      const link = isUnitCard
        ? `/${parentSlug}/task/${slug}`
        : `/phase/${parentSlug}#${slug}`;

      router.push(baseLink + link);
    },
    [isUnitCard, parentSlug, router]
  );

  const img =
    urlFor(image)
      ?.width(playbookCardImageWidth)
      .height(playbookCardImageHeight)
      .url() ?? defaultPlaybookCardImgLink;
  const imgThumbnail = urlForThumbnail(image) ?? '';

  const subtitleStateCheck = useCallback((e: HTMLDivElement) => {
    if (e?.offsetHeight < e?.scrollHeight) {
      setSubtitleState('ellipsis');
    }
  }, []);

  const { seeMoreLabel, seeLessLabel, seemoreWithNumInParenthesesLabel } =
    useCommonTranslations();

  return (
    <Grid item xs={12} sm={6}>
      <Paper sx={paperStyle} elevation={1}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box>
            <Box
              borderRadius="10px 10px 0 0"
              overflow="hidden"
              maxHeight="170px"
            >
              <Img
                src={img}
                alt={title}
                width={200}
                height={200}
                style={imgStyle}
                sizes="100vw"
                priority
              />
            </Box>
            <Box
              p="16px"
              minHeight="208px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" gap="15px">
                <Box
                  display="flex"
                  gap="5px"
                  alignItems="center"
                  width="fit-content"
                  onClick={onClick}
                  sx={titleStyle}
                >
                  <MuiIcon iconName={icon} />
                  <Typography
                    fontSize={!phaseName ? 24 : 20}
                    fontWeight={!phaseName ? 700 : 600}
                  >
                    {title}
                  </Typography>
                </Box>
                <Box
                  fontSize={16}
                  sx={getSubtitleStyle(subtitleState)}
                  ref={subtitleStateCheck}
                >
                  {subtitle}
                </Box>
                {isUnitCard && subtitleState === 'ellipsis' && (
                  <Box>
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubtitleState('expanded');
                      }}
                    >
                      {seeMoreLabel}
                    </Link>
                  </Box>
                )}
              </Box>
              {estimatedTime && (
                <Box mt="14px">
                  <TimeEstimateComponent
                    value={estimatedTime}
                    justValue={isUnitCard}
                  />
                </Box>
              )}
            </Box>
            <Divider />
            <Box
              mx="40px"
              my={!phaseName ? '30px' : '10px'}
              display="flex"
              flexDirection="column"
              gap={!phaseName ? '30px' : '20px'}
              sx={itemsStyle}
            >
              <TransitionGroup>
                {items
                  .slice(
                    0,
                    isUnitCard && items.length > 4 && !showMoreList
                      ? 3
                      : items.length
                  )
                  .map((item) => (
                    <Collapse key={item._id}>
                      <Typography key={item._id} fontSize={16} noWrap py="10px">
                        <Link
                          color="inherit"
                          onClick={goToTaskOrModule(item.slug.current)}
                        >
                          {item.title}
                        </Link>
                      </Typography>
                    </Collapse>
                  ))}
              </TransitionGroup>
              {isUnitCard && items.length > 4 && (
                <Box display="flex" mr="5px">
                  <Link onClick={showMoreListToggle} color="secondary">
                    {showMoreList
                      ? seeLessLabel
                      : seemoreWithNumInParenthesesLabel(items.length - 3)}
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
          <Box p={!phaseName ? '25px' : '15px'}>
            <Grid container alignItems="end" spacing={1}>
              <Grid item xs={12} md={6}>
                {hasFavorite && phaseName && (
                  <PlaybookFavoriteButton
                    itemId={favoritesId}
                    itemName={title}
                    itemPhaseName={phaseName}
                    itemModuleName={moduleName}
                    itemImageURL={imgThumbnail}
                    itemType="unit"
                    itemLink=""
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <PlaybookInProgress data={inProgressData} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const getSubtitleStyle = (state: SubtitleState) => ({
  opacity: 0.6,
  lineHeight: '28px',
  overflow: 'hidden',
  height: state === 'initial' ? '84px' : 'auto',
  display: state === 'ellipsis' ? '-webkit-box' : 'block',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
});
const titleStyle = {
  cursor: 'pointer',
  '&:hover': { textDecoration: 'underline' },
};
const paperStyle = { borderRadius: '10px', height: '100%', boxShadow: 'none' };
const itemsStyle = { transition: 'all 0.5s' };
const imgStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain' as const,
};
