import { useQuery } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { AssignmentInfo, AssignmentInfoSchema } from 'types/AssignContent';
import { SocketHandlerProps } from 'types/socket';
import { GetAssignmentInfoProps, getAssignmentInfoRequest } from '.';
import { assignmentsQueryKeys } from './assignmentsQueryKeys';

const schema = AssignmentInfoSchema;

type ResponseData = AssignmentInfo;
type Request = GetAssignmentInfoProps['params'];

export const useGetAssignmentInfo = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<Partial<Request>, ResponseData>) => {
  const { notify } = useNotifications();
  const successHandler = useCallback(
    (result?: ResponseData) => {
      onSuccess?.(result);
    },
    [onSuccess]
  );
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.ASSIGNMENT_LOAD,
    request: getAssignmentInfoRequest,
    schema,
  });

  const assignmentId = defaultParams?.assignmentId as string;

  const { data, isLoading } = useQuery(
    [assignmentsQueryKeys.single({ assignmentId })],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      staleTime: CACHE_TIME,
      cacheTime: CACHE_TIME,
      refetchOnWindowFocus: false,
      enabled: enabled && !!assignmentId,
    }
  );

  return {
    data,
    isLoading,
  };
};

const CACHE_TIME = 1000 * 60;
