import {
  NotificationType,
  NotificationTypeMapper,
} from 'common/notificationConsts';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { NotificationActionMapper } from './NotificationActionsMapper';

type NotifyOptions = {
  autoHideDuration?: number;
};

const useNotifications = (props?: NotifyOptions) => {
  const { enqueueSnackbar } = useSnackbar();
  const notificationTypeMapper = NotificationTypeMapper();

  const notify = useCallback(
    (type: NotificationType, options?: NotifyOptions) => {
      const autoHideDuration =
        options?.autoHideDuration ??
        props?.autoHideDuration ??
        DEFAULT_AUTO_HIDE_DURATION;

      const { message, severity, action } =
        notificationTypeMapper[type] || notificationTypeMapper['ERROR'];

      enqueueSnackbar(message, {
        variant: severity,
        autoHideDuration,
        action: () =>
          action ? NotificationActionMapper({ type: action }) : undefined,
      });
    },
    [enqueueSnackbar, notificationTypeMapper, props?.autoHideDuration]
  );

  return { notify };
};

export default useNotifications;

const DEFAULT_AUTO_HIDE_DURATION = 5000; // 5 seconds
