import { useDeleteAssignment } from 'services/api/product/assignContent/useDeleteAssignment';
import { useCommonTranslations } from 'translations/hooks';
import { DeleteModal } from './DeleteModal';

type DeleteAssignmentModalProps = {
  unitId: string;
  unitName: string;
  assignmentId: string;
  open: boolean;
  onClose: () => void;
  onDeleted: () => void;
};

export const DeleteAssignmentModal = ({
  unitId,
  unitName,
  assignmentId,
  open,
  onClose,
  onDeleted,
}: DeleteAssignmentModalProps) => {
  const { deleteAssignmentUnit, isLoading } = useDeleteAssignment({
    defaultParams: { assignmentId, unitId },
    onSuccess: () => {
      onDeleted();
    },
  });

  const {
    deleteAssignedUnitTitleLabel,
    deleteAssignedUnitAlertTitleLabel,
    deleteAssignedUnitTextLabel,
  } = useCommonTranslations();

  return (
    <DeleteModal
      title={deleteAssignedUnitTitleLabel}
      alertTitle={deleteAssignedUnitAlertTitleLabel(unitName)}
      text={deleteAssignedUnitTextLabel}
      loading={isLoading}
      open={open}
      onClose={onClose}
      onDelete={deleteAssignmentUnit}
      cancelBtnColor="secondary"
    />
  );
};
