import { useOktaAuth } from '@okta/okta-react';
import { NotificationType } from 'common/notificationConsts';
import { topRoutes } from 'common/routes';
import useNotifications from 'common/useNotifications';
import useSocket from 'common/useSocket';
import { Layout } from 'components/Layout';
import { LoadingScreen } from 'components/Loading';
import { useLogout } from 'hooks';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { SocketProps } from 'types/socket';

export const AuthCheck = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { authState, oktaAuth } = useOktaAuth();
  const { socket } = useSocket();
  const { notify } = useNotifications();
  const { logout } = useLogout();

  const authCheck = useRef(false);
  const [isOktaCheckLogin, setIsOktaCheckLogin] = useState(true);

  socket?.onAny((_, { status }: SocketProps) => {
    if (status === 401) {
      notify(NotificationType.UNAUTHORIZED);
      socket.off();
      logout();
      localStorage.clear();

      return;
    }
  });

  const isNonAuthRoute = router.route.startsWith(topRoutes.nonAuth);
  const isRouteValid =
    Object.values(topRoutes).some((r) => router.route.startsWith(r)) ||
    router.route === '/';

  useEffect(() => {
    if (!isRouteValid) {
      router.replace('/');
    }
  }, [isRouteValid, router]);

  useEffect(() => {
    if (
      authState &&
      !authState.isAuthenticated &&
      isRouteValid &&
      !isNonAuthRoute
    ) {
      const path = router.asPath === '/' ? '' : `?path=${router.asPath}`;
      router.replace('/login' + path);
    }
  }, [authState, isNonAuthRoute, isRouteValid, router]);

  useEffect(() => {
    if (authState?.isAuthenticated && !authCheck.current) {
      authCheck.current = true;

      oktaAuth.session
        .get()
        .then((session) => {
          if (session.status === 'INACTIVE') {
            logout();
          }
        })
        .finally(() => {
          setIsOktaCheckLogin(false);
        });
    }
  }, [authState, logout, oktaAuth]);

  if (isNonAuthRoute && socket) {
    return <>{children}</>;
  }

  if (
    socket &&
    authState?.isAuthenticated &&
    isRouteValid &&
    !isNonAuthRoute &&
    !isOktaCheckLogin
  ) {
    return <Layout>{children}</Layout>;
  }

  return <LoadingScreen />;
};
