import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  IconButtonProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCustomMediaQuery } from 'hooks';
import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookTaskWithVideo,
  SelectedUnits,
} from 'types/incubatePlaybook';
import { convertMilliseconds } from 'utils';
import { millisecondsIn } from 'utils/time';
import AssignableUnitCardTask from './AssignableUnitCardTask';

type AssignableUnitCardProps = {
  unitId: string;
  selectedUnits: SelectedUnits;
  setSelectedUnits: Dispatch<SetStateAction<SelectedUnits>>;
  setPreviewTask: Dispatch<
    SetStateAction<IncubatePlaybookTaskWithVideo | null>
  >;
};

export function AssignableUnitCard({
  unitId,
  selectedUnits,
  setSelectedUnits,
  setPreviewTask,
}: AssignableUnitCardProps) {
  const { lessThenSm } = useCustomMediaQuery();
  const { minuteLabel, videosLabel } = useCommonTranslations();
  const { allUnitData } = useIncubatePlaybookData();
  const [expanded, setExpanded] = useState(false);

  const unitData = useMemo(() => allUnitData[unitId], [allUnitData, unitId]);

  const selected = useMemo(() => {
    return !!selectedUnits[unitId];
  }, [selectedUnits, unitId]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleToggle = () => {
    setSelectedUnits((oldSelectedUnits) => {
      const currentIndex = oldSelectedUnits[unitId];
      const newChecked = { ...oldSelectedUnits };

      if (currentIndex) {
        delete newChecked[unitId];
      } else {
        newChecked[unitId] = {
          id: unitId,
          title: unitData.title,
          tasks: unitData?.assignableTasks?.map((task) => task._id),
        };
      }

      return newChecked;
    });
  };

  const handleTaskToggle = (taskId: string) => {
    setSelectedUnits((oldSelectedUnits) => {
      const currentUnit = oldSelectedUnits[unitId];
      const newUnits = cloneDeep(oldSelectedUnits);

      if (currentUnit) {
        const currentTaskIndex = currentUnit.tasks?.indexOf(taskId);
        const newTasks = [...(currentUnit.tasks ?? [])];

        if (currentTaskIndex !== undefined && currentTaskIndex !== -1) {
          newTasks.splice(currentTaskIndex, 1);

          if (newTasks.length === 0) {
            delete newUnits[unitId];

            return newUnits;
          }
        } else {
          newTasks.push(taskId);
        }

        newUnits[unitId].tasks = [...newTasks];
      } else {
        newUnits[unitId] = {
          id: unitId,
          title: unitData.title,
          tasks: [taskId],
        };
      }

      return newUnits;
    });
  };

  const videosEstimatedTime = useMemo(() => {
    return unitData.assignableTasks.reduce((acc, task) => {
      const taskt = task as IncubatePlaybookTaskWithVideo;

      return (
        acc +
        convertMilliseconds(
          taskt.video?.source?.duration
            ? taskt.video?.source?.duration * 1000
            : 0,
          millisecondsIn.minutes,
          'up'
        )
      );
    }, 0);
  }, [unitData.assignableTasks]);

  return (
    <Box display="flex" alignItems="baseline" width="100%" py="10px">
      {selected ? (
        <IconButton onClick={handleToggle}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      ) : (
        <Checkbox color="secondary" onClick={handleToggle} checked={selected} />
      )}
      <Card sx={cardStyle(selected)} elevation={lessThenSm ? 0 : 1}>
        <CardContent>
          <Grid container>
            <Grid item xs={lessThenSm ? 9 : 7}>
              <Typography variant="body1" gutterBottom>
                {unitData.title}
              </Typography>
              {lessThenSm && (
                <Typography variant="caption" color="text.secondary">
                  {videosLabel(unitData.assignableTasks.length)},{' '}
                  {minuteLabel(videosEstimatedTime)}
                </Typography>
              )}
              <Typography variant="body2" color="text.secondary">
                {(unitData.objectives ?? []).join(' ')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={lessThenSm ? 3 : 5}
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
            >
              {!lessThenSm && (
                <Typography variant="caption" color="text.secondary">
                  {videosLabel(unitData.assignableTasks.length)},{' '}
                  {minuteLabel(videosEstimatedTime)}
                </Typography>
              )}
              <ExpandMore expand={expanded} onClick={handleExpandClick}>
                <ExpandMoreIcon />
              </ExpandMore>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider />
          {unitData.assignableTasks.map((task) => (
            <AssignableUnitCardTask
              key={task._id}
              task={task as IncubatePlaybookTaskWithVideo}
              selected={
                selectedUnits[unitId]?.tasks?.includes(task._id) ?? false
              }
              onlySelected={
                selectedUnits[unitId]?.tasks?.length === 1 &&
                (selectedUnits[unitId]?.tasks?.includes(task._id) ?? false)
              }
              setSelectedUnits={setSelectedUnits}
              handleTaskToggle={handleTaskToggle}
              setPreviewTask={setPreviewTask}
            />
          ))}
        </Collapse>
      </Card>
    </Box>
  );
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(-90deg)' : 'rotate(0)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const cardStyle = (selected: boolean) => ({
  width: '100%',
  backgroundColor: selected ? 'background.paper' : 'transparent',
  transition: 'all 0.3s ease-in-out, background-image 1s ease-in-out',
  boxShadow: `0px 30px 40px 4px ${
    selected ? 'rgba(0, 0, 0, 0.1)' : 'transparent'
  }`,
  border: '1px solid',
  borderColor: selected ? 'transparent' : 'text.disabled',
});
