import { PASSWD_REGEX, URL_REGEX } from 'common/consts';
import { useIntl } from 'react-intl';
import isEmail from 'validator/lib/isEmail';

export const useFormOptions = () => {
  const { formatMessage } = useIntl();

  const required = {
    value: true,
    message: formatMessage({
      id: 'form.missingField',
      defaultMessage: 'Missing Field',
    }),
  };

  const requiredWithoutWhiteSpace = (value: string) => {
    return (
      !!value?.trim() ||
      formatMessage({
        id: 'form.missingField',
        defaultMessage: 'Missing Field',
      })
    );
  };

  const maxLength = {
    value: 250,
    message: formatMessage({
      id: 'form.maxChar250',
      defaultMessage: 'Max character limit is 250',
    }),
  };

  const emailValidation = (value: string) => {
    return (
      isEmail(value) ||
      formatMessage({
        id: 'form.notValidEmail',
        defaultMessage: 'Not a valid email',
      })
    );
  };

  const passwordOpts = {
    pattern: {
      value: PASSWD_REGEX,
      message: formatMessage({
        id: 'form.passPattern',
        defaultMessage:
          'Password should contain at least 8 characters, a lowercase letter, an uppercase letter, a number, no parts of your email. Your password cannot be any of your last 4 passwords.',
      }),
    },
    minLength: {
      value: 8,
      message: formatMessage({
        id: 'form.passMinLength',
        defaultMessage: 'Password must have at least 8 characters',
      }),
    },
  };

  const textInputValidation = {
    validate: requiredWithoutWhiteSpace,
    maxLength,
  };

  const markdownValidator = {
    value: /^((?!<script).)*$/s,
    message: formatMessage({
      id: 'form.markdownValidator',
      defaultMessage: 'Code must not contain script tags',
    }),
  };

  const urlPattern = {
    pattern: {
      value: URL_REGEX,
      message: formatMessage({
        id: 'form.urlValidator',
        defaultMessage: 'Not a valid URL',
      }),
    },
  };

  const isNumberInRange = (min: number, max: number) => (value?: number) => {
    if (typeof value === 'undefined') return true;

    const result = value >= min && value <= max;

    return (
      result ||
      formatMessage(
        {
          id: 'form.numInRangeValidator',
          defaultMessage:
            'Value must be between {min, number} and {max, number}',
        },
        { min, max }
      )
    );
  };

  const numberInRangeValidation = (min: number, max: number) => ({
    setValueAs: (v: string) => {
      const numV = Number(v);

      if (!isNaN(numV) && v !== '') {
        return numV;
      }
    },
    validate: isNumberInRange(min, max),
  });

  return {
    maxLength,
    required,
    emailValidation,
    textInputValidation,
    requiredWithoutWhiteSpace,
    passwordOpts,
    markdownValidator,
    urlPattern,
    isNumberInRange,
    numberInRangeValidation,
  };
};

export const required = {
  value: true,
  message: 'Missing Field',
};

export const emailValidation = (value: string) => {
  return isEmail(value) || 'Not a valid email';
};

export const passwordOpts = {
  pattern: {
    value: PASSWD_REGEX,
    message:
      'Password should contain at least 8 characters, a lowercase letter, an uppercase letter, a number, no parts of your email. Your password cannot be any of your last 4 passwords.',
  },
  minLength: {
    value: 8,
    message: 'Password must have at least 8 characters',
  },
};

export const maxLength = {
  value: 250,
  message: 'Max character limit is 250',
};

export const markdownValidator = {
  value: /^((?!<script).)*$/s,
  message: 'Code must not contain script tags',
};

export const urlPattern = {
  pattern: {
    value: URL_REGEX,
    message: 'Not a valid URL',
  },
};

export const isNumberInRange =
  (min: number, max: number) => (value?: number) => {
    if (typeof value === 'undefined') return true;

    const result = value >= min && value <= max;

    return result || `Value must be between ${min} and ${max}`;
  };

export const requiredWithoutWhiteSpace = (value: string) => {
  return !!value?.trim() || 'Missing Field';
};

export const textInputValidation = {
  validate: requiredWithoutWhiteSpace,
  maxLength,
};

export const numberInRangeValidation = (min: number, max: number) => ({
  setValueAs: (v: string) => {
    const numV = Number(v);

    if (!isNaN(numV) && v !== '') {
      return numV;
    }
  },
  validate: isNumberInRange(min, max),
});
