import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Box, IconButton, SxProps } from '@mui/material';
import { useBrand } from 'hooks';
import { useTheme } from 'next-themes';
import { ReactNode, useMemo } from 'react';
import { getTextColor } from 'utils';
import { Dropdown } from './Dropdown';
import { HelpDropdownItem } from './HelpDropdownItem';

type HelpDropdownListItem = {
  title: string;
  subtitle: string;
  link: string;
  newTab?: boolean;
};
type HelpDropdownProps = {
  list: HelpDropdownListItem[];
  basic?: boolean;
  dropdownEl?: ReactNode;
  useWithBrand?: boolean;
};

export const HelpDropdown = ({
  list,
  basic,
  dropdownEl,
  useWithBrand = false,
}: HelpDropdownProps) => {
  const dropdownList = list.map(({ title, subtitle, link, newTab }) => ({
    customMenuItem: (
      <HelpDropdownItem
        key={title}
        title={title}
        subtitle={subtitle}
        link={link}
        newTab={newTab}
      />
    ),
  }));

  const { brand } = useBrand();

  const { colors } = brand;
  const { theme } = useTheme();

  const content = useMemo(
    () => (
      <Dropdown
        dropdownEl={
          dropdownEl ?? (
            <DefaultHelpIcon
              sx={iconSx(useWithBrand, colors?.brandColor, theme)}
            />
          )
        }
        list={dropdownList}
      />
    ),
    [colors?.brandColor, dropdownEl, dropdownList, theme, useWithBrand]
  );

  return basic ? content : <IconButton>{content}</IconButton>;
};

const DefaultHelpIcon = ({ sx }: { sx: SxProps }) => (
  <Box height={24}>
    <HelpIcon sx={sx} />
  </Box>
);

const iconSx = (useWithBrand: boolean, color?: string, theme?: string) => ({
  color: useWithBrand ? getTextColor(color, theme) : 'text.secondary',
  zIndex: 2,
});
