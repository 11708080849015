import { Box, Divider, Typography } from '@mui/material';
import { useCommonTranslations } from 'translations/hooks';
import { PlaybookButton } from '../../PlaybookButton';

type PlaybookQuizNavigationButtonsProps = {
  totalQuestions: number;
  currentQuestion: number;
  isAllAnswered: boolean;
  currentAnswered: boolean;
  onClick: (question: number) => void;
  onFinish: () => void;
};

export const PlaybookQuizNavigationButtons = ({
  totalQuestions,
  currentQuestion,
  isAllAnswered,
  currentAnswered,
  onClick,
  onFinish,
}: PlaybookQuizNavigationButtonsProps) => {
  const shouldFinish = isAllAnswered && currentQuestion === totalQuestions;

  const prevClickHandler = () => {
    onClick(currentQuestion - 1);
  };
  const nextClickHandler = () => {
    if (shouldFinish) {
      onFinish();
    } else {
      onClick(currentQuestion + 1);
    }
  };

  const { previousLabel, nextLabel, submitLabel, ofLabel } =
    useCommonTranslations();

  return (
    <Box mt="61px" mb="10px" width="100%">
      <Divider />
      <Box
        mt="30px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <PlaybookButton
          onClick={prevClickHandler}
          disabled={currentQuestion === 1}
        >
          {previousLabel}
        </PlaybookButton>
        <Typography>{`${currentQuestion} ${ofLabel} ${totalQuestions}`}</Typography>
        <PlaybookButton onClick={nextClickHandler} disabled={!currentAnswered}>
          {shouldFinish ? submitLabel : nextLabel}
        </PlaybookButton>
      </Box>
    </Box>
  );
};
