import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, ButtonBase, ButtonBaseProps } from '@mui/material';
import Img from 'next/image';

type ExpandImageButton = ButtonBaseProps & {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  cover?: boolean;
};

export const ExpandImageButton = ({
  src,
  alt,
  onClick,
  width,
  height,
  cover = false,
}: ExpandImageButton) => {
  return (
    <ButtonBase onClick={onClick} sx={buttonStyle(cover, width, height)}>
      <Img
        src={src}
        alt={alt}
        width={width ?? 200}
        height={height ?? 200}
        sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
        style={imageStyle(cover)}
        priority
      />
      <Box sx={overlayStyle} className="overlay">
        <ZoomInIcon />
      </Box>
    </ButtonBase>
  );
};

const buttonStyle = (cover: boolean, width?: number, height?: number) => ({
  position: 'relative',
  overflow: 'hidden',
  width: width ?? '100%',
  height: height ?? '100%',
  '&:hover': {
    zIndex: 1,
    '& .overlay': {
      display: 'flex',
    },
  },
  borderRadius: cover ? '4px' : 0,
  boxShadow: cover ? '0px 0px 0px 1px #E0E0E0' : 0,
});
const imageStyle = (cover: boolean) =>
  cover
    ? { objectFit: 'cover' as const }
    : {
        width: '100%',
        height: '100%',
        maxHeight: '60vh',
        objectFit: 'contain' as const,
      };
const overlayStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'none',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  bgcolor: 'rgba(0, 0, 0, .15)',
  color: 'text.secondary',
};
