import Brightness2OutlinedIcon from '@mui/icons-material/Brightness2Outlined';
import Brightness6OutlinedIcon from '@mui/icons-material/Brightness6Outlined';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { Theme } from 'common/enums';
import { useTheme } from 'next-themes';
import { MouseEvent } from 'react';
import { useSessionData } from 'services/api/useSessionData';
import { useUpdateProfile } from 'services/api/user/useUpdateProfile';
import { useAccountTranslations } from 'translations/hooks';

export const MenuThemeSwitcher = () => {
  const { sessionData, refetchSessionData } = useSessionData({});
  const { theme, setTheme } = useTheme();

  const { updateTheme, isThemeUpdating } = useUpdateProfile({
    defaultParams: {
      userId: sessionData?.user.userId!,
    },
    onSuccess: () => {
      setTheme(theme === Theme.light ? Theme.dark : Theme.light);
      refetchSessionData();
    },
  });

  const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newTheme = theme === Theme.light ? Theme.dark : Theme.light;
    updateTheme(newTheme);
  };

  const { darkLightModeLabel } = useAccountTranslations();

  const element = (
    <Box
      px="16px"
      py="4px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box>
        <Typography>{darkLightModeLabel(theme)}</Typography>
      </Box>
      <Box>
        <IconButton disabled={isThemeUpdating} onClick={onClickHandler}>
          {isThemeUpdating ? (
            <CircularProgress color="inherit" size="1.5rem" />
          ) : theme === Theme.light ? (
            <Brightness6OutlinedIcon />
          ) : (
            <Brightness2OutlinedIcon />
          )}
        </IconButton>
      </Box>
    </Box>
  );

  return {
    disableRipple: true,
    bottomDivider: false,
    customMenuItem: element,
    customMenuItemSx: menuItemSx,
  };
};

const menuItemSx = {
  '&:hover': { bgcolor: 'transparent', cursor: 'unset' },
};
