import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export const ButtonContained = ({
  children,
  sx,
  ...props
}: LoadingButtonProps) => {
  const btnStyle = {
    fontWeight: 500,
    borderRadius: '10px',
    ...sx,
  };

  return (
    <LoadingButton sx={btnStyle} variant="contained" {...props}>
      {children}
    </LoadingButton>
  );
};
