import { Project } from 'types/Client';

export enum ProjectStatus {
  active = 'active',
  deactivated = 'deactivated',
  closed = 'closed',
}

export const ProjectStatusColors = {
  [ProjectStatus.active]: 'success.main',
  [ProjectStatus.deactivated]: 'error.main',
  [ProjectStatus.closed]: 'gray',
};

export const currentUserInProject = (project: Project, userId?: string) =>
  project.users.find((user) => user.userId === userId);

export const isProjectAdmin = (project: Project, userId?: string) =>
  !userId ? false : !!currentUserInProject(project, userId)?.isProjectAdmin;
