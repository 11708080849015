import { Box } from '@mui/material';
import { incubatePlaybookTaskEnum } from 'common/enums';
import { useMemo } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookSanity,
  IncubatePlaybookTask,
  IncubatePlaybookUnitData,
} from 'types/incubatePlaybook';
import { getAllObjectives, getAllTaskResources } from 'utils/incubatePlaybook';
import { PlaybookActivityTask } from '../Tasks/Activity';
import { PlaybookConceptTask } from '../Tasks/Concept';
import { PlaybookIntroductionTask } from '../Tasks/Introduction';
import { PlaybookQuizTask } from '../Tasks/Quiz';
import { PlaybookSummaryTask } from '../Tasks/Summary';

export const PlaybookUnitContent = ({
  currentTaskData,
  unitData,
  helpLink,
}: {
  currentTaskData: IncubatePlaybookTask;
  unitData: IncubatePlaybookUnitData;
  helpLink: IncubatePlaybookSanity['contactUrl'];
}) => {
  const allResources = useMemo(
    () => getAllTaskResources(unitData?.tasks),
    [unitData?.tasks]
  );

  const allObjectives = useMemo(
    () => getAllObjectives(unitData?.tasks),
    [unitData?.tasks]
  );

  const { noTaskLabel } = useCommonTranslations();

  switch (currentTaskData._type) {
    case incubatePlaybookTaskEnum.taskActivity:
      return (
        <PlaybookActivityTask
          data={currentTaskData}
          phaseId={unitData.phaseId}
          phaseTitle={unitData.phaseTitle}
          moduleId={unitData.moduleId}
          moduleTitle={unitData.moduleTitle}
          unitId={unitData._id}
          unitTitle={unitData.title}
          helpLink={helpLink}
        />
      );
    case incubatePlaybookTaskEnum.taskConcept:
      return (
        <PlaybookConceptTask
          data={currentTaskData}
          phaseId={unitData.phaseId}
          phaseTitle={unitData.phaseTitle}
          moduleId={unitData.moduleId}
          moduleTitle={unitData.moduleTitle}
          unitId={unitData._id}
          unitTitle={unitData.title}
        />
      );
    case incubatePlaybookTaskEnum.taskIntroduction:
      return (
        <PlaybookIntroductionTask
          title={currentTaskData.title}
          summary={unitData.summary}
          objectives={unitData.objectives}
          image={unitData.image}
        />
      );
    case incubatePlaybookTaskEnum.taskQuiz:
      return (
        <PlaybookQuizTask
          data={currentTaskData}
          unitTitle={unitData.title}
          unitId={unitData._id}
          unitSlug={unitData.slug.current}
        />
      );
    case incubatePlaybookTaskEnum.taskSummary:
      return (
        <PlaybookSummaryTask
          allResources={allResources}
          allObjectives={allObjectives}
          unitData={unitData}
          helpLink={helpLink}
          title={currentTaskData.title}
        />
      );
    default:
      return <Box>{noTaskLabel}</Box>;
  }
};
