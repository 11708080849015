import { useMutation } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { SocketHandlerProps } from 'types/socket';
import { MarketSize } from 'types/ventureBuilder';
import {
  setVentureBuilderDataRequest,
  SetVentureBuilderRequestParams,
} from '..';

type Request<T> = SetVentureBuilderRequestParams<T>['params'];

export const useUpdateMarketSizeData = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<null, MarketSize>) => {
  const { notify } = useNotifications();

  const successHandler = useCallback(
    (result?: MarketSize) => {
      onSuccess?.(result);
    },
    [onSuccess]
  );
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFnMutation } = useFetch<Request<MarketSize>, MarketSize>({
    topic: socketTopics.UPDATE_MARKET_SIZE_DATA,
    ignoreTopicSuffix: true,
    request: setVentureBuilderDataRequest,
  });

  const { isLoading, mutate: updateMarketSizeData } = useMutation(
    fetchFnMutation,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
    }
  );

  return {
    isLoading,
    updateMarketSizeData,
  };
};
