import { Link as LinkMUI, LinkProps } from '@mui/material';
import { useMemo } from 'react';

export const Link = ({
  children,
  disabled,
  ...props
}: LinkProps & { disabled?: boolean }) => {
  const linkStyle = useMemo(
    () => ({
      '&:hover': {
        cursor: disabled ? 'not-allowed' : 'pointer',
        textDecoration: disabled ? 'none' : 'underline',
      },
      '&.disabled': {
        color: 'text.disabled',
      },
      ...props.sx,
    }),
    [disabled, props.sx]
  );

  return (
    <LinkMUI
      underline="hover"
      maxWidth="fit-content"
      className={disabled ? 'disabled' : ''}
      {...props}
      sx={linkStyle}
      onClick={disabled ? undefined : props.onClick}
    >
      {children}
    </LinkMUI>
  );
};
