import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { PlaybookInfoData } from 'types/incubatePlaybook';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';

export const useSetPlaybookInfo = () => {
  const queryClient = useQueryClient();
  const { query } = useRouter();

  const setPlaybookInfo = useCallback(
    (playbookInfo: PlaybookInfoData, slug?: string) => {
      const playbookSlug = slug ?? query.playbookSlug;
      if (typeof playbookSlug === 'string') {
        queryClient.setQueryData(
          [incubatePlaybookQueryKeys.info({ playbookSlug })],
          () => playbookInfo
        );
      }
    },
    [query.playbookSlug, queryClient]
  );

  return {
    setPlaybookInfo,
  };
};
