import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import { projectQueryKeys } from 'services/api/client/project/projectQueryKeys';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { Assignment } from 'types/AssignContent';
import { SocketHandlerProps } from 'types/socket';
import { CreateAssignmentProps, createAssignmentRequest } from '.';
import { assignmentsQueryKeys } from './assignmentsQueryKeys';

type Request = CreateAssignmentProps['params'];

export const useCreateAssignment = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps) => {
  const { notify } = useNotifications();
  const { playbookInfo } = useGetPlaybookInfo({});
  const queryClient = useQueryClient();

  const refetchAssignments = useCallback(() => {
    queryClient.invalidateQueries([assignmentsQueryKeys.assignmentsListBase()]);
    queryClient.invalidateQueries([
      assignmentsQueryKeys.allAssignmentsListBase(),
    ]);
    queryClient.invalidateQueries([assignmentsQueryKeys.singleFromTaskBase()]);
    queryClient.invalidateQueries([projectQueryKeys.teamProgressBase()]);
  }, [queryClient]);

  const refetchFrequentlyAssignedNext = useCallback(() => {
    queryClient.invalidateQueries([
      assignmentsQueryKeys.frequentlyAssignedNextBase(),
    ]);
  }, [queryClient]);

  const successHandler = useCallback(() => {
    notify(NotificationType.NEW_VIDEO_ASSIGNMENT_CREATED);
    refetchAssignments();
    refetchFrequentlyAssignedNext();
    onSuccess?.();
  }, [notify, onSuccess, refetchAssignments, refetchFrequentlyAssignedNext]);

  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );

  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFnMutation } = useFetch<Request, void>({
    topic: socketTopics.CREATE_ASSIGNMENT,
    request: createAssignmentRequest,
  });

  const { mutate, isLoading } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const createAssignment = useCallback(
    (data: Assignment) => mutate({ ...playbookInfo, ...data }),
    [mutate, playbookInfo]
  );

  return { createAssignment, isCreating: isLoading };
};
