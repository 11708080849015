import { VentureBuilderItemAction } from 'common/enums';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useStakeholderMapData } from 'services/api/product/ventureBuilder/stakeholderMap/useStakeholderMapData';
import {
  SuccessType,
  useUploadStakeholderMap,
} from 'services/api/product/ventureBuilder/stakeholderMap/useUploadStakeholderMap';
import { StakeholderMap as MapType } from 'types/ventureBuilder';
import { StakeholderMapList } from './StakeholderMapList';

export const StakeholderMap = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const { updateStakeholderMaps, stakeholderMaps } = useStakeholderMapData();
  const { uploadMap, isLoading } = useUploadStakeholderMap({
    defaultParams: {
      clientId: playbookInfo.clientId,
      key: 'stakeholderMaps&frasl;stakeholderMap',
    },
    onSuccess: (result?: SuccessType) => {
      if (result) {
        updateStakeholderMaps({
          payload: {
            action: VentureBuilderItemAction.ADD,
            data: {
              _id: '',
              name: result.name,
              imageUrl: result.url.split('?')[0],
            },
          },
        });
      }
    },
  });
  const onDelete = (shMap: MapType) => {
    updateStakeholderMaps({
      payload: {
        action: VentureBuilderItemAction.DELETE,
        data: shMap,
      },
    });
  };
  const onEdit = (shMap: MapType) => {
    const payload = {
      action: VentureBuilderItemAction.EDIT,
      data: shMap,
    };
    if (stakeholderMaps.findIndex(({ _id }) => _id === shMap._id) === -1) {
      payload.action = VentureBuilderItemAction.ADD;
    }
    updateStakeholderMaps({
      payload,
    });
  };

  return (
    <StakeholderMapList
      stakeholderMaps={stakeholderMaps}
      onAdd={uploadMap}
      onDelete={onDelete}
      onEdit={onEdit}
      isUploading={isLoading}
    />
  );
};
