import { Box, Container, Paper } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { PlaybookQuizForm } from '.';
import { PlaybookQuizAnswer } from './AnswerWrapper';
import { PlaybookQuizHeading } from './Heading';
import { PlaybookQuizNavigationButtons } from './NavigationButtons';
import { PlaybookQuizQuestionTitle } from './QuestionTitle';
import { PlaybookQuizQuestionWrapper } from './QuestionWrapper';

type PlaybookQuizActiveScreenProps = {
  form: PlaybookQuizForm;
  setForm: Dispatch<SetStateAction<PlaybookQuizForm>>;
  onExitQuiz: () => void;
  onFinishQuiz: () => void;
  updateCurrentQuestion: (question: number) => void;
};

export const PlaybookQuizActiveScreen = ({
  form,
  setForm,
  onExitQuiz,
  onFinishQuiz,
  updateCurrentQuestion,
}: PlaybookQuizActiveScreenProps) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);

  const answerHandler = useCallback(
    (mark: string) => () => {
      const correct =
        form[currentQuestion - 1].questionOptions.find((q) => q.isCorrect)! ??
        {};
      let incorrect: PlaybookQuizAnswer;
      if (mark !== correct.mark) {
        const currentAnswer =
          form[currentQuestion - 1].questionOptions.find(
            (q) => q.mark === mark
          )! ?? {};
        incorrect = {
          mark: currentAnswer.mark,
          content: currentAnswer.desc,
        };
      }

      setForm((old) => {
        const updatedAnswers = [...old];
        updatedAnswers[currentQuestion - 1].answer = {
          correct: {
            mark: correct.mark,
            content: correct.desc,
          },
          incorrect,
        };

        return updatedAnswers;
      });
    },
    [currentQuestion, form, setForm]
  );

  const isAllAnswered = useMemo(
    () => form.every(({ answer }) => answer !== null),
    [form]
  );

  const navigationClickHandler = useCallback(
    (question: number) => {
      setCurrentQuestion(question);
      updateCurrentQuestion(question);
    },
    [updateCurrentQuestion]
  );

  return (
    <Paper elevation={1}>
      <Box p="20px" borderRadius="4px" maxWidth="917px" mb="20px">
        <PlaybookQuizHeading
          onClick={onExitQuiz}
          totalQuestions={form.length}
        />
        <PlaybookQuizQuestionTitle
          order={currentQuestion}
          content={form[currentQuestion - 1].content}
        />
        <Container>
          <Box my={5}>
            <PlaybookQuizQuestionWrapper
              items={form[currentQuestion - 1].questionOptions}
              answer={form[currentQuestion - 1].answer}
              onClick={answerHandler}
            />
          </Box>
        </Container>
        <PlaybookQuizNavigationButtons
          onClick={navigationClickHandler}
          currentQuestion={currentQuestion}
          totalQuestions={form.length}
          isAllAnswered={isAllAnswered}
          currentAnswered={form[currentQuestion - 1].answer !== null}
          onFinish={onFinishQuiz}
        />
      </Box>
    </Paper>
  );
};
