import { useQuery } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { RoleSchema } from 'types/User';
import { defaultListResponse } from 'types/api';
import { SocketHandlerProps } from 'types/socket';
import { z } from 'zod';
import { queryKeys } from './queryKeys';
import { getRolesRequest } from './roles';
import { useDirectFetch } from './utils/useFetch';

const schema = defaultListResponse(RoleSchema);

export type ResponseData = z.infer<typeof schema>;

export const useGetRoles = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled,
}: SocketHandlerProps<void, ResponseData>) => {
  const { notify } = useNotifications();

  const successHandler = (result?: ResponseData) => {
    onSuccess?.(result);
  };
  const failureHandler = (message_key: NotificationType) => {
    notify(message_key);
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useDirectFetch<void, ResponseData>({
    request: getRolesRequest,
    schema,
  });

  useQuery([queryKeys.roles()], fetchFn, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
    enabled,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
