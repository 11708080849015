import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ProductType } from 'common/enums';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback, useState } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { ProductSchema } from 'types/Product';
import { defaultListResponse } from 'types/api';
import { SocketHandlerProps } from 'types/socket';
import { z } from 'zod';
import { GetProductsParams, getProductsRequest } from './products';
import { queryKeys } from './queryKeys';
import { useFeatureFlags } from './useFeatureFlags';

const schema = defaultListResponse(ProductSchema);

type Request = GetProductsParams['params'];
export type ResponseData = z.infer<typeof schema>;

export const useGetProducts = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled,
}: SocketHandlerProps<void, ResponseData>) => {
  const { ff } = useFeatureFlags({});
  const queryClient = useQueryClient();
  const { notify } = useNotifications();
  const [searchInput, setSearchInput] = useState('');

  const successHandler = (result?: ResponseData) => {
    onSuccess?.(result);
  };
  const failureHandler = (message_key: NotificationType) => {
    notify(message_key);
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.PRODUCTS_LIST,
    request: getProductsRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [queryKeys.products(searchInput)],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled,
    }
  );

  const refreshProducts = useCallback(() => {
    queryClient.invalidateQueries([queryKeys.productsBase()]);
  }, [queryClient]);

  return {
    products:
      data?.items.filter(
        ({ type }) => !(type === ProductType.chat && !ff.chatbot)
      ) || [],
    isLoading,
    search: { value: searchInput, setValue: setSearchInput },
    refreshProducts,
  };
};
