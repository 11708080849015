import { Slugs } from 'common/ventureBuilderConsts';

export const addCustomSlugs = (slugs: Slugs[]): Slugs[] => {
  const slugsWithCurrency = [Slugs.MARKET_SIZE, Slugs.FUNDING_REQUEST];
  const tmpSlugs = [...slugs];

  const slugWithCurrencyIndex = tmpSlugs.findIndex((slg) =>
    slugsWithCurrency.includes(slg)
  );
  if (slugWithCurrencyIndex > -1) {
    tmpSlugs.splice(slugWithCurrencyIndex, 0, Slugs.CURRENCY);

    return tmpSlugs;
  }

  return tmpSlugs;
};
