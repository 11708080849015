import { Grid, TextField, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { useFormOptions } from 'common/formOptions';
import { ButtonContained, ButtonOutlined } from 'components/Inputs/Button';
import { Dropdown } from 'components/Inputs/Dropdown';
import { VerticalMoreIcon } from 'components/MuiIcon';
import { useLeaveConfirm } from 'hooks/useLeaveConfirm';
import { useForm } from 'react-hook-form';
import {
  useCommonTranslations,
  useProductTranslations,
} from 'translations/hooks';
import { Stakeholder } from 'types/ventureBuilder';
import { WhoMattersMostState } from '.';

type StakeholdersListItemProps = {
  stakeholder: Stakeholder;
  state: WhoMattersMostState;
  onCancel: () => void;
  onEdit: (stakeholder: Stakeholder) => void;
  onDelete: (stakeholder: Stakeholder) => void;
  onSave: (stakeholder: Stakeholder) => boolean;
};

type StakeholdersForm = {
  name: string;
};

export const StakeholdersListItem = ({
  stakeholder,
  state,
  onEdit,
  onCancel,
  onDelete,
  onSave,
}: StakeholdersListItemProps) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<StakeholdersForm>();

  const { textInputValidation } = useFormOptions();

  const isEditing = state.type !== VentureBuilderItemAction.VIEW;

  const handleSubmitForm = (form: StakeholdersForm) => {
    const success = onSave({ ...stakeholder, name: form.name });
    if (success) reset({ ...stakeholder, name: form.name });
  };

  const handleEdit = () => {
    onEdit(stakeholder);
    reset({ name: stakeholder.name });
  };

  const handleCancel = () => {
    reset({ name: stakeholder.name });
    onCancel();
  };

  const handleDelete = () => onDelete(stakeholder);

  const { editLabel, deleteLabel, saveLabel, cancelLabel } =
    useCommonTranslations();
  const { stakeholderNameLabel } = useProductTranslations();

  const menuList = [
    {
      title: editLabel,
      onClick: handleEdit,
      disabled: isEditing,
    },
    {
      title: deleteLabel,
      onClick: handleDelete,
      disabled: isEditing,
    },
  ];

  return (
    <>
      {useLeaveConfirm({ isChanged: isDirty })}
      {state.type !== VentureBuilderItemAction.VIEW &&
      stakeholder._id === state?.data?._id ? (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container sx={listItemStyle} flexDirection="row">
            <Grid container item xs={12} sm={9}>
              <TextField
                color="secondary"
                label={stakeholderNameLabel}
                {...register('name', textInputValidation)}
                error={!!errors.name}
                helperText={errors.name?.message}
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid
              marginTop="10px"
              container
              item
              xs={12}
              sm={3}
              justifyContent="right"
            >
              <Grid item marginLeft="10px">
                <ButtonOutlined color="secondary" onClick={handleCancel}>
                  {cancelLabel}
                </ButtonOutlined>
              </Grid>
              <Grid item marginLeft="4px">
                <ButtonContained color="secondary" type="submit">
                  {saveLabel}
                </ButtonContained>
              </Grid>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Grid container sx={listItemStyle} justifyContent="space-between">
          <Grid item xs={11}>
            <Typography>{stakeholder.name}</Typography>
          </Grid>
          <Grid item xs={1} textAlign="right">
            <Dropdown
              dropdownEl={<VerticalMoreIcon />}
              list={menuList}
              isLoading={stakeholder._id === TEMP_ID}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const listItemStyle = {
  minHeight: '50px',
  borderBottom: '1px solid',
  borderColor: 'action.disabledBackground',
  padding: '12px 0 8px 32px',
};

export const TEMP_ID = 'tempId';
