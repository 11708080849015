import { useOktaAuth } from '@okta/okta-react';
import { removeLocalStorageOktaAccessToken } from 'common/localStorage';
import { useCallback } from 'react';

export const useLogout = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = useCallback(() => {
    oktaAuth.signOut().then(() => {
      removeLocalStorageOktaAccessToken();
    });
  }, [oktaAuth]);

  return { logout };
};
