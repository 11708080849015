import { useMemo } from 'react';
import { useGetClientProjectsList } from 'services/api/client/project/useGetClientProjects';
import { useGetProjectsList } from 'services/api/client/useGetProjectsList';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useSessionData } from 'services/api/useSessionData';
import { Project } from 'types/Client';

export const useUsersProjectsList = () => {
  const { roles } = useSessionData({});
  const { playbookInfo, playbookSlug } = useGetPlaybookInfo({});

  const allProjectsRole =
    roles.isUserPlatformAdmin || roles.isUserPlatformSuperAdmin;
  const clientProjectsRole = roles.isClientUser || roles.isUserPlatformAnalyst;

  const { data: projects } = useGetClientProjectsList({
    defaultParams: {
      clientId: playbookInfo.clientId,
      limit: 100,
    },
    enabled: clientProjectsRole,
  });
  const { data: projectsForAllClients } = useGetProjectsList({
    defaultParams: { limit: 100, forContextSwitching: true },
    enabled: allProjectsRole,
  });
  const projectBase = useMemo(() => {
    const base = allProjectsRole
      ? projectsForAllClients?.items
      : projects?.items;
    if (!base) return [];

    return base
      .map((p) => ({
        ...p,
        products: p.products.filter((x) => x.slugId === playbookSlug),
      }))
      .filter((p) => p.products.length);
  }, [
    allProjectsRole,
    playbookSlug,
    projects?.items,
    projectsForAllClients?.items,
  ]);

  const availableProjects = useMemo(() => {
    if (!projectBase.length) return [];
    const projectsByClients = projectBase.reduce<Record<string, Project[]>>(
      (a, b) => {
        const clientProjects = a[b.clientId];
        a[b.clientId] = clientProjects ? [...clientProjects, b] : [b];

        return a;
      },
      {}
    );

    let projectsList = [];
    const currentClient = projectsByClients[playbookInfo.clientId];
    delete projectsByClients[playbookInfo.clientId];
    if (currentClient) {
      projectsList.push(currentClient);
    }

    return [...projectsList, ...Object.values(projectsByClients)];
  }, [playbookInfo.clientId, projectBase]);

  return {
    isMoreProjects: projectBase.length > 1,
    availableProjects,
  };
};
