import { ComponentProps } from 'react';
import * as MuiIcons from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

type SvgIconProps = ComponentProps<typeof SvgIcon>;

export const MuiIcon = ({
  iconName,
  ...props
}: SvgIconProps & {
  iconName?: string;
}) => {
  const Icon = MuiIcons[iconName as keyof typeof MuiIcons];

  if (!Icon) return null;

  return <Icon {...props} />;
};
