import { Box } from '@mui/material';
import { Container } from '@mui/system';
import { useCustomMediaQuery } from 'hooks';
import { ReactNode } from 'react';

export const PlaybookUnitWrapper = ({ children }: { children: ReactNode }) => {
  const { onlyXs, lessThenMd } = useCustomMediaQuery();

  return (
    <Container disableGutters={onlyXs}>
      <Box
        width="100%"
        display="flex"
        gap="14px"
        flexDirection={lessThenMd ? 'column' : 'row'}
      >
        {children}
      </Box>
    </Container>
  );
};
