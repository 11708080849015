import axios from 'axios';
import {
  getLocalStorageOktaAccessToken,
  getLocalStorageSocketId,
} from 'common/localStorage';
import { getSessionStorageSocketId } from 'common/sessionStorage';
import { getProcessEnv } from 'utils';

const processEnv = getProcessEnv();

const client = axios.create({
  baseURL: processEnv.NEXT_PUBLIC_BACKEND_API_URL,
  responseType: 'json',
});

client.interceptors.request.use((config) => {
  const oktaAccessToken = getLocalStorageOktaAccessToken();
  const socketId = getLocalStorageSocketId();
  const socketLiveId = getSessionStorageSocketId();

  if (oktaAccessToken) {
    config.headers['Authorization'] = `Bearer ${oktaAccessToken}`;
  }
  if (socketId) {
    config.headers['x-socket-id'] = socketId;
  }
  if (socketLiveId) {
    config.headers['x-live-socket-id'] = socketLiveId;
  }

  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const isUserInvalid = error.response?.status === 401;

    if (isUserInvalid) {
      console.log('Status 401', error.response);
    }

    return Promise.reject(error);
  }
);

export const API = client;
