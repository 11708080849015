import { DropResult } from '@hello-pangea/dnd';
import VentureBuilderIcon from '@mui/icons-material/BuildOutlined';
import { Box, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { SectionModes } from 'common/ventureBuilderConsts';
import { ReactNode, useMemo, useState } from 'react';
import { useHypothesisBacklogData } from 'services/api/product/ventureBuilder/hypothesisBacklog/useHypothesisBacklogData';
import { useWhoMattersMostData } from 'services/api/product/ventureBuilder/whoMattersMost/useWhoMattersMostData';
import { useProductTranslations } from 'translations/hooks';
import { Hypothesis } from 'types/ventureBuilder';
import HypothesisBacklogList from './HypothesisBacklogList';
import { TEMP_ID } from './HypothesisBacklogListItem';

export type HypothesisBacklogListState =
  | {
      type: VentureBuilderItemAction.VIEW;
    }
  | {
      type: VentureBuilderItemAction.EDIT;
      data: Hypothesis;
      index: number;
    }
  | {
      type: VentureBuilderItemAction.ADD;
      data: Hypothesis;
      index: number;
    };

export const HypothesisBacklog = ({ mode }: { mode: SectionModes }) => {
  const { notify } = useNotifications();

  const { hypothesisBacklog, isGetting, updateHypothesisBacklog } =
    useHypothesisBacklogData();

  const { stakeholders } = useWhoMattersMostData();

  const [state, setState] = useState<HypothesisBacklogListState>({
    type: VentureBuilderItemAction.VIEW,
  });

  const hypothesisBacklogLocal = useMemo(() => {
    const tmpHypothesisBacklog = [...hypothesisBacklog];

    if (state.type === VentureBuilderItemAction.VIEW)
      return tmpHypothesisBacklog;

    const currentIndex =
      state.index > tmpHypothesisBacklog.length
        ? tmpHypothesisBacklog.length
        : state.index;

    if (state.type === VentureBuilderItemAction.ADD) {
      tmpHypothesisBacklog.splice(currentIndex, 0, state.data);

      return tmpHypothesisBacklog;
    }

    if (state.type === VentureBuilderItemAction.EDIT) {
      const isDeleted = !tmpHypothesisBacklog.some(
        (hb) => hb._id === state?.data?._id
      );
      if (isDeleted)
        setState(
          (prevState) =>
            ({
              ...prevState,
              type: VentureBuilderItemAction.ADD,
            } as HypothesisBacklogListState)
        );
      tmpHypothesisBacklog.splice(currentIndex, 1, state.data);

      return tmpHypothesisBacklog;
    }

    return tmpHypothesisBacklog;
  }, [hypothesisBacklog, state]);

  // handlers
  const handleAdd = (addNewShouldBeOnTopOfTheList: boolean) => () => {
    setState({
      type: VentureBuilderItemAction.ADD,
      data: {
        _id: TEMP_ID,
        hypothesis: '',
        stakeholderId: '',
        status: '',
      },
      index: addNewShouldBeOnTopOfTheList ? 0 : hypothesisBacklogLocal.length,
    });
  };

  const handleEdit = (hypothesis: Hypothesis) => {
    setState({
      type: VentureBuilderItemAction.EDIT,
      data: hypothesis,
      index: hypothesisBacklog.findIndex((sh) => sh._id === hypothesis._id),
    });
  };

  const handleCancel = () => {
    setState({ type: VentureBuilderItemAction.VIEW });
  };

  const handleDelete = (hypothesis: Hypothesis) => {
    const newData = hypothesisBacklog.filter((hb) => hb._id !== hypothesis._id);

    updateHypothesisBacklog({
      payload: { action: VentureBuilderItemAction.DELETE, data: hypothesis },
      newData,
    });
  };

  const stakeholderHasError = (hypothesis: Hypothesis): boolean => {
    if (state.type === VentureBuilderItemAction.VIEW) return true;
    const cleanupString = (string: string) => string?.toLowerCase().trim();

    const hypothesisExists = hypothesisBacklog.some(
      (hb) =>
        hb._id !== state.data._id &&
        !hypothesis.stakeholderId &&
        cleanupString(hb.hypothesis) === cleanupString(hypothesis?.hypothesis)
    );

    const hypothesisForStakeholderExists = hypothesisBacklog.some(
      (hb) =>
        hb._id !== state.data._id &&
        hb.stakeholderId === hypothesis.stakeholderId &&
        cleanupString(hb.hypothesis) === cleanupString(hypothesis?.hypothesis)
    );

    if (hypothesisExists) {
      notify(NotificationType.HYPOTHESIS_EXISTS);
    }

    if (hypothesisForStakeholderExists) {
      notify(NotificationType.HYPOTHESIS_FOR_STAKEHOLDER_EXISTS);
    }

    return hypothesisExists || hypothesisForStakeholderExists;
  };

  const handleSave = (hypothesis: Hypothesis) => {
    if (stakeholderHasError(hypothesis)) return false;

    // remove empty keys
    const tmpPayload = Object.entries(hypothesis).reduce(
      (acc, [key, value]) => {
        if (value) return { ...acc, [key]: value };

        return acc;
      },
      {} as Hypothesis
    );

    const newData = [...hypothesisBacklog];
    if (state.type === VentureBuilderItemAction.ADD) {
      newData.push({ ...hypothesis });
    } else if (state.type === VentureBuilderItemAction.EDIT) {
      newData.splice(state.index, 1, hypothesis);
    }

    updateHypothesisBacklog({
      payload: {
        action: state.type,
        data: tmpPayload,
      },
      newData,
    });

    setState({ type: VentureBuilderItemAction.VIEW });

    return true;
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    const noChanges =
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index);

    if (noChanges) return;

    const newHypothesis = [...hypothesisBacklogLocal];
    const draggedHypothesis = hypothesisBacklogLocal.find(
      (hb) => hb._id === draggableId
    );
    newHypothesis.splice(source.index, 1);
    newHypothesis.splice(destination.index, 0, draggedHypothesis as Hypothesis);

    if (state.type === VentureBuilderItemAction.VIEW) {
      updateHypothesisBacklog({
        payload: {
          action: VentureBuilderItemAction.EDIT,
          data: {
            ...draggedHypothesis,
            newOrderIndex: destination.index,
          } as Hypothesis,
        },
        newData: newHypothesis,
      });
    }
  };

  const renderList = (
    <HypothesisBacklogList
      isLoading={isGetting}
      isTaskMode={mode === SectionModes.TASK}
      data={hypothesisBacklogLocal}
      stakeholders={stakeholders}
      state={state}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onCancel={handleCancel}
      onDelete={handleDelete}
      onSave={handleSave}
      onDragEnd={handleDragEnd}
    />
  );

  return mode === SectionModes.TASK ? (
    <SectionTaskWrapper>{renderList}</SectionTaskWrapper>
  ) : (
    <Box sx={boxStyle}>{renderList}</Box>
  );
};

const boxStyle = {
  margin: '32px 0',
};

const SectionTaskWrapper = ({ children }: { children: ReactNode }) => {
  const {
    objectiveDocumentHypothesesInVBLabel,
    documentAllNewHypothesesInHupothesisBacklogInVBLabel,
  } = useProductTranslations();

  return (
    <>
      <Box display="flex" alignItems="center" gap="10px">
        <VentureBuilderIcon />
        <Typography fontSize="20px">
          {objectiveDocumentHypothesesInVBLabel}
        </Typography>
      </Box>
      <Typography margin="10px 0 30px 0">
        {documentAllNewHypothesesInHupothesisBacklogInVBLabel}
      </Typography>
      {children}
    </>
  );
};
