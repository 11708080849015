import { Box, Button, Typography } from '@mui/material';
import { ButtonContained } from 'components/Inputs/Button';
import { ImageUploadInput } from 'components/Inputs/fileUpload';
import { useValidateImage } from 'hooks';
import { useCommonTranslations } from 'translations/hooks';

type StakeholderMapUploadProps = {
  isLoading?: boolean;
  disabled?: boolean;
  onUpload: (image: File) => void;
};

const StakeholderMapUpload = ({
  isLoading,
  disabled,
  onUpload,
}: StakeholderMapUploadProps) => {
  const { validateImgInputHandler } = useValidateImage({
    onSuccess: ({ image }) => {
      onUpload(image);
    },
  });

  const { uploadLabel, pngOrJpgAcceptedLabel } = useCommonTranslations();

  return (
    <Box sx={wrapperStyle} flexDirection="column" justifyContent="center">
      {/* <Grid item>
        <Typography marginBottom="12px">Drag or Drop Image</Typography>
      </Grid> */}
      <Box>
        <Typography marginBottom="12px">{pngOrJpgAcceptedLabel}</Typography>
      </Box>
      <Box>
        {isLoading ? (
          <ButtonContained loading={isLoading}>{uploadLabel}</ButtonContained>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            disabled={disabled}
            component="label"
          >
            {uploadLabel}
            <ImageUploadInput onChange={validateImgInputHandler} />
          </Button>
        )}
      </Box>
    </Box>
  );
};

const wrapperStyle = {
  marginTop: '25px',
  width: '200px',
  height: '200px',
  border: '1px solid',
  borderColor: 'border.main',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default StakeholderMapUpload;
