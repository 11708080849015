import { ProductFeature, ProductType } from 'common/enums';
import { z } from 'zod';
import { StandardSanityImageOptionsSchema } from './incubatePlaybook';

export const FeatureSchema = z.object({
  featureId: z.string(),
  key: z.nativeEnum(ProductFeature),
  name: z.string(),
  isEnabled: z.boolean(),
});
export type Feature = z.infer<typeof FeatureSchema>;

export const FeatureContentSchema = FeatureSchema.omit({
  featureId: true,
}).merge(z.object({ _id: z.string() }));
export type FeatureContent = z.infer<typeof FeatureContentSchema>;

export const ProductSchema = z.object({
  slugId: z.string(),
  productId: z.string(),
  title: z.string(),
  lastModified: z.string().nullable().optional(),
  type: z.nativeEnum(ProductType),
  startDate: z.union([z.string(), z.date()]).nullable().optional(),
  endDate: z.union([z.string(), z.date()]).nullable().optional(),
  enabled: z.boolean().optional(),
  features: z.array(FeatureSchema),
});
export type Product = z.infer<typeof ProductSchema>;

export const ProductContentSchema = z.object({
  _id: z.string(),
  title: z.string(),
  identifier: z.string(),
  slugId: z.string(),
  image: StandardSanityImageOptionsSchema.shape.image,
});
export type ProductContent = z.infer<typeof ProductContentSchema>;
