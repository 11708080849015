import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { IncubatePlaybookVideosWatchedTime } from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import {
  IncubatePlaybookVideosWatchedTimeUpdateParams,
  IncubatePlaybookVideosWatchedTimeUpdateProps,
  setIncubatePlaybookVideosWatchedTimeRequest,
} from '.';
import { assignmentsQueryKeys } from '../assignContent/assignmentsQueryKeys';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

type Request = IncubatePlaybookVideosWatchedTimeUpdateProps['params'];
type VideosWatchedTimeRequest = Omit<
  IncubatePlaybookVideosWatchedTimeUpdateParams,
  'clientId' | 'projectId' | 'productId'
>;

export const useSetIncubatePlaybookVideosWatchedTime = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps) => {
  const queryClient = useQueryClient();
  const { playbookInfo } = useGetPlaybookInfo({});
  const { clientId, projectId, productId } = playbookInfo;

  const successHandler = () => {
    queryClient.invalidateQueries([
      assignmentsQueryKeys.allWatchedVideosBase(),
    ]);
    queryClient.invalidateQueries([assignmentsQueryKeys.singleFromTaskBase()]);
    queryClient.invalidateQueries([assignmentsQueryKeys.assignmentsListBase()]);
    queryClient.invalidateQueries([
      assignmentsQueryKeys.allAssignmentsListBase(),
    ]);
    onSuccess?.();
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFnMutation } = useFetch<Request, void>({
    topic: socketTopics.VIDEOS_WATCHED_TIME_UPDATE,
    request: setIncubatePlaybookVideosWatchedTimeRequest,
    shouldNotifyOnRestError: false,
  });

  const { mutate } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const setVideosWatchedTime = useCallback(
    (params: VideosWatchedTimeRequest, completedAt?: string) => {
      queryClient.setQueriesData<IncubatePlaybookVideosWatchedTime>(
        [incubatePlaybookQueryKeys.videosWatchedTime()],
        (old) => ({
          ...old,
          [params.videoId]: {
            watchedSeconds: params.watchedSeconds,
            progress: params.progress,
            completedAt: completedAt ?? old?.[params.videoId]?.completedAt,
          },
        })
      );
      mutate({ ...params, clientId, projectId, productId });
    },
    [queryClient, mutate, clientId, projectId, productId]
  );

  return { setVideosWatchedTime };
};
