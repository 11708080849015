export type FilterItem = { name: string; value: string | number | boolean };

export const formatFilters = (filters: FilterItem[]) => {
  const filterValues = filters.map(({ value }) => value);

  const filterBy = filters.map(({ name }) => name).join(',');
  const filterValue = filterValues.join(',');
  const searchValue = filterValues.join('');

  return { filterBy, filterValue, searchValue };
};
