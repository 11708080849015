import { Box, Divider, Typography } from '@mui/material';
import { Theme } from 'common/enums';
import { useTheme } from 'next-themes';
import { Dispatch, SetStateAction } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookTaskWithVideo,
  SelectedUnits,
} from 'types/incubatePlaybook';
import { AssignableContentDetails } from './AssignableContentDetails';
import { AssignableUnitCard } from './AssignableUnitCard';
import { FrequentlyAssignedNextModalSection } from './FrequentlyAssignedNextModalSection';

type AssignableUnitsProps = {
  selectedUnits: SelectedUnits;
  setSelectedUnits: Dispatch<SetStateAction<SelectedUnits>>;
  selectedVideoNum: number;
  selectedVideosEstimatedTime: number;
  setPreviewTask: Dispatch<
    SetStateAction<IncubatePlaybookTaskWithVideo | null>
  >;
  detailsDisabled: boolean;
};

export const AssignableContentUnitsAndDetails = ({
  selectedUnits,
  setSelectedUnits,
  selectedVideoNum,
  selectedVideosEstimatedTime,
  setPreviewTask,
  detailsDisabled,
}: AssignableUnitsProps) => {
  const { theme } = useTheme();
  const { minuteLabel, noVideosSelectedLabel, deselectAllLabel, videosLabel } =
    useCommonTranslations();

  const deselectAll = () => setSelectedUnits({});

  const noSelectedVideos = (
    <Typography variant="body1" fontStyle="italic" color="text.secondary">
      {noVideosSelectedLabel}
    </Typography>
  );

  return (
    <Box
      height="100%"
      bgcolor={theme === Theme.dark ? 'background.paper' : 'background.default'}
      py="1rem"
    >
      <Box height="65%" px="2rem" overflow="scroll">
        {selectedVideoNum ? (
          <>
            <Box
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2" sx={deselectSx} onClick={deselectAll}>
                {deselectAllLabel}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {videosLabel(selectedVideoNum)},{' '}
                {minuteLabel(selectedVideosEstimatedTime)}
              </Typography>
            </Box>
            {Object.keys(selectedUnits).map((unitId) => (
              <AssignableUnitCard
                key={unitId}
                unitId={unitId}
                selectedUnits={selectedUnits}
                setSelectedUnits={setSelectedUnits}
                setPreviewTask={setPreviewTask}
              />
            ))}
          </>
        ) : (
          noSelectedVideos
        )}
        <FrequentlyAssignedNextModalSection
          selectedUnits={selectedUnits}
          setSelectedUnits={setSelectedUnits}
          setPreviewTask={setPreviewTask}
        />
      </Box>
      <Divider />
      <AssignableContentDetails disabled={detailsDisabled} />
    </Box>
  );
};

const deselectSx = { cursor: 'pointer' };
