import { Grid } from '@mui/material';
import { useCustomMediaQuery } from 'hooks';
import { Dispatch, SetStateAction } from 'react';
import { DueDateSelect } from './DueDateSelect';
import { Notes } from './Notes';
import { TeamMembersSelect } from './TeamMembersSelect';

export const AssignableContentDetails = ({
  disabled = false,
  setMobileModalStep,
}: {
  disabled?: boolean;
  setMobileModalStep?: Dispatch<SetStateAction<string>>;
}) => {
  const { lessThenSm } = useCustomMediaQuery();

  return (
    <Grid
      container
      spacing={2}
      px={lessThenSm ? 0 : '2rem'}
      height="35%"
      overflow="scroll"
      py="0.5rem"
      mt="0"
    >
      <Grid item xs={12} sm={6} maxHeight="90px">
        <TeamMembersSelect
          setMobileModalStep={setMobileModalStep}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} maxHeight="90px">
        <DueDateSelect disabled={disabled} />
      </Grid>
      <Grid
        item
        xs={12}
        pb="1rem"
        height={lessThenSm ? '300px' : 'calc(100% - 70px)'}
        minHeight="160px"
      >
        <Notes disabled={disabled} />
      </Grid>
    </Grid>
  );
};
