import { alphabet } from 'common/consts';
import { PlaybookQuizAnswerWrapperProps } from 'components/Playbook/Tasks/Quiz/AnswerWrapper';
import { IncubatePlaybookTaskQuiz } from 'types/incubatePlaybook';

export const adaptQuestions = (
  questions: IncubatePlaybookTaskQuiz['questions']
) =>
  questions.map((question) => ({
    ...question,
    questionOptions: question.questionOptions.map((v, i) => ({
      ...v,
      mark: alphabet[i],
    })),
    answer: null as PlaybookQuizAnswerWrapperProps['answer'],
  }));
