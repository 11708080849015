import { PlaybookOriginalContent } from 'types/incubatePlaybook';
import { getAdaptedData } from './getAdaptedData';

export const adaptIncubatePlaybookData =
  (isEnterprise?: boolean) => (originalData?: PlaybookOriginalContent) => {
    if (!originalData) return;

    const {
      taskOrderList,
      firstTaskOrderList,
      allUnitData,
      ventureBuilderSections,
      playbookVideos,
      phasesWithStartAndEndVideo,
      assignableContent,
      assignableContentIndexMap,
    } = getAdaptedData(originalData.sanity?.phases, isEnterprise);

    const firstTaskId = taskOrderList[0].taskId;
    const lastTaskId = taskOrderList[taskOrderList.length - 1].taskId;

    return {
      ...originalData,
      taskOrderList,
      firstTaskOrderList,
      allUnitData,
      ventureBuilderSections,
      firstTaskId,
      lastTaskId,
      playbookVideos,
      phasesWithStartAndEndVideo,
      assignableContent,
      assignableContentIndexMap,
    };
  };
