import { useRouter } from 'next/router';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';

type RouteContextType = {
  previousRoute: undefined | string;
};

const EXCEPTIONS = ['/login'];

const useRouteUrlHistory = () => {
  const [previousRoute, setPreviousRouter] = useState('');
  const router = useRouter();

  useEffect(() => {
    const handleBeforeHistoryChange = (url: string) => {
      const [nextUrl] = url?.split('?') || [];

      if (
        !(EXCEPTIONS.includes(nextUrl) || EXCEPTIONS.includes(router.asPath)) &&
        nextUrl !== router.asPath
      ) {
        setPreviousRouter(router.asPath);
      }
    };
    router.events.on('beforeHistoryChange', handleBeforeHistoryChange);

    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange);
    };
  }, [router]);

  return { previousRoute };
};

const RouteContext = createContext<undefined | RouteContextType>(undefined);

const PreviousRouteProvider = ({ children }: { children: ReactNode }) => {
  const { previousRoute } = useRouteUrlHistory();

  return (
    <RouteContext.Provider value={{ previousRoute }}>
      {children}
    </RouteContext.Provider>
  );
};

const usePreviousRoute = () => {
  const context = useContext(RouteContext);
  if (context === undefined) {
    throw new Error(
      'usePreviousRoute must be used within a PreviousRouteProvider'
    );
  }

  return context;
};

export { PreviousRouteProvider, usePreviousRoute };
