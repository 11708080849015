import { SocketProps } from 'types/socket';
import { ZodTypeAny } from 'zod';
import { NotificationType } from './notificationConsts';

type SocketListenerProps<T = void, K = void> = {
  onSuccess?: (result?: T | K) => void;
  onFailure?: (message_key: NotificationType) => void;
  onEnd?: () => void;
  adaptFn?: (result?: T) => K;
  schema?: ZodTypeAny;
  topic: string;
};

export function socketListener<T = void, K = void>({
  onSuccess,
  onFailure,
  onEnd,
  adaptFn,
  schema,
  topic,
}: SocketListenerProps<T, K>) {
  return ({ status, message_key, result }: SocketProps<T>) => {
    if (status !== 200) {
      onFailure?.(message_key);
      onEnd?.();

      return;
    }

    const validData = schema?.safeParse?.(result);

    if (validData?.success === false) {
      console.error(topic, validData.error.format());
      onFailure?.(NotificationType.INVALID_DATA);

      return;
    }

    const responseData = adaptFn ? adaptFn(result) : result;
    onSuccess?.(responseData);
    onEnd?.();
  };
}
