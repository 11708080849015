import {
  useAccountTranslations,
  useClientTranslations,
  useCommonTranslations,
  useProductTranslations,
  useProjectTranslations,
  useUserTranslations,
} from 'translations/hooks';

export type notificationSeverity = 'error' | 'warning' | 'info' | 'success';

export enum NotificationActionType {
  VIEW_ALL_ASSIGNMENTS = 'VIEW_ALL_ASSIGNMENTS',
}

export enum NotificationType {
  DEFAULT = 'DEFAULT',
  // success
  USER_CREATION_INITIALIZED = 'USER_CREATION_INITIALIZED',
  USER_CREATION_INITIALIZED_EMAIL_NOT_SENT = 'USER_CREATION_INITIALIZED_EMAIL_NOT_SENT',
  USER_EMAIL_SENT = 'USER_EMAIL_SENT',
  USER_UPDATED = 'USER_UPDATED',
  USER_DEACTIVATED = 'USER_DEACTIVATED',
  USER_DELETED = 'USER_DELETED',
  CLIENT_DEACTIVATED = 'CLIENT_DEACTIVATED',
  PROJECT_DEACTIVATED = 'PROJECT_DEACTIVATED',
  PROJECT_REACTIVATED = 'PROJECT_REACTIVATED',
  USER_PASSWORD_RESET_EMAIL_SENT = 'USER_PASSWORD_RESET_EMAIL_SENT',
  USER_PASSWORD_CHANGED = 'USER_PASSWORD_CHANGED',
  CLIENT_CREATED = 'CLIENT_CREATED',
  CLIENT_UPDATED = 'CLIENT_UPDATED',
  PROJECT_UPDATED = 'PROJECT_UPDATED',
  PROJECT_RESOURCES_UPDATED = 'PROJECT_RESOURCES_UPDATED',
  RESOURCE_UPDATED = 'RESOURCE_UPDATED',
  EXISTING_PROJECT_USER_ADDED = 'EXISTING_PROJECT_USER_ADDED',
  PROJECT_USER_REMOVED = 'PROJECT_USER_REMOVED',
  TRIGGER_DATA_SEEDING = 'TRIGGER_DATA_SEEDING',
  PROFILE_UPDATED = 'PROFILE_UPDATED',
  USER_REACTIVATE_SUCCESSFUL = 'USER_REACTIVATE_SUCCESSFUL',
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PRODUCT_UPDATED = 'PRODUCT_UPDATED',
  THEME_UPDATED = 'THEME_UPDATED',
  NEW_VIDEO_ASSIGNMENT_CREATED = 'NEW_VIDEO_ASSIGNMENT_CREATED',
  FAN_SUCCESSFULLY_HIDDEN = 'FAN_SUCCESSFULLY_HIDDEN',
  ASSIGNMENT_UNIT_REMOVED = 'ASSIGNMENT_UNIT_REMOVED',
  ASSIGNMENT_UPDATED = 'ASSIGNMENT_UPDATED',
  // error
  ERROR = 'ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  DUPLICATE_NAME = 'DUPLICATE_NAME',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  NAME_EXISTS = 'NAME_EXISTS',
  OKTA_USER_EXISTS = 'OKTA_USER_EXISTS',
  OKTA_USER_ACTIVE = 'OKTA_USER_ACTIVE',
  OKTA_PASSWORD_CHANGE_FAILED = 'OKTA_PASSWORD_CHANGE_FAILED',
  OKTA_GROUP_NOT_FOUND = 'OKTA_GROUP_NOT_FOUND',
  ALREADY_ACCEPTED = 'ALREADY_ACCEPTED',
  EMAIL_INVITATION_ALREADY_ACCEPTED = 'EMAIL_INVITATION_ALREADY_ACCEPTED',
  EMAIL_INVITATION_EXPIRED = 'EMAIL_INVITATION_EXPIRED',
  PASSWORD_RESET_LINK_EXPIRED = 'PASSWORD_RESET_LINK_EXPIRED',
  LOGO_UPLOAD_FAILED = 'LOGO_UPLOAD_FAILED',
  IMAGE_UPLOAD_FAILED = 'IMAGE_UPLOAD_FAILED',
  MAP_UPLOAD_FAILED = 'MAP_UPLOAD_FAILED',
  UPLOAD_FAILED = 'FILE_UPLOAD_FAILED',
  PRODUCT_IN_USE = 'PRODUCT_IN_USE',
  PRODUCT_DISABLED = 'PRODUCT_DISABLED',
  FEEDBACK_ERROR = 'FEEDBACK_ERROR',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVITE_EXPIRED = 'INVITE_EXPIRED',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  STAKEHOLDER_NAME_TAKEN = 'STAKEHOLDER_NAME_TAKEN',
  STAKEHOLDER_MAP_NAME_TAKEN = 'STAKEHOLDER_MAP_NAME_TAKEN',
  HYPOTHESIS_EXISTS = 'HYPOTHESIS_EXISTS',
  HYPOTHESIS_FOR_STAKEHOLDER_EXISTS = 'HYPOTHESIS_FOR_STAKEHOLDER_EXISTS',
  TOP_VALUE_PROPOSITION_EXISTS = 'TOP_VALUE_PROPOSITION_EXISTS',
  TOP_VALUE_PROPOSITION_FOR_STAKEHOLDER_EXISTS = 'TOP_VALUE_PROPOSITION_FOR_STAKEHOLDER_EXISTS',
  PAIN_POINT_EXISTS = 'PAIN_POINT_EXISTS',
  PAIN_POINT_FOR_STAKEHOLDER_EXISTS = 'PAIN_POINT_FOR_STAKEHOLDER_EXISTS',
  TOP_PROTOTYPE_EXISTS = 'TOP_PROTOTYPE_EXISTS',
  TOP_PROTOTYPE_FOR_STAKEHOLDER_EXISTS = 'TOP_PROTOTYPE_FOR_STAKEHOLDER_EXISTS',
  USER_REACTIVATE_FAILED = 'USER_REACTIVATE_FAILED',
  IMAGE_UPLOAD_WRONG_TYPE = 'IMAGE_UPLOAD_WRONG_TYPE',
  IMAGE_UPLOAD_WRONG_SIZE = 'IMAGE_UPLOAD_WRONG_SIZE',
  DOCUMENT_UPLOAD_WRONG_TYPE = 'DOCUMENT_UPLOAD_WRONG_TYPE',
  DOCUMENT_UPLOAD_WRONG_SIZE = 'DOCUMENT_UPLOAD_WRONG_SIZE',
  FORBIDDEN_RESOURCE = 'FORBIDDEN_RESOURCE',
  USER_NOT_ASSIGNED_TO_PROJECT = 'USER_NOT_ASSIGNED_TO_PROJECT',
  FEATURES_CANNOT_BE_ADDED = 'FEATURES_CANNOT_BE_ADDED',
  FEATURE_DISABLED = 'FEATURE_DISABLED',
  INVALID_DATA = 'INVALID_DATA',
  TASK_NOT_FOUND = 'TASK_NOT_FOUND',
  UNIT_NOT_FOUND = 'UNIT_NOT_FOUND',
  MODULE_NOT_FOUND = 'MODULE_NOT_FOUND',
  PHASE_NOT_FOUND = 'PHASE_NOT_FOUND',
  PROJECT_DUPLICATED = 'PROJECT_DUPLICATED',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  NEW_PASSWORD_UNCHANGED = 'NEW_PASSWORD_UNCHANGED',
}

export const NotificationTypeMapper = (): {
  [key: string]: {
    message: string;
    severity: notificationSeverity;
    action?: NotificationActionType;
  };
} => {
  const {
    inviteSentLabel,
    emailSentLabel,
    passwordChangedLabel,
    seedingInProgressLabel,
    somethingWentWrongLabel,
    nameAlreadyExistsLabel,
    emailAlreadyExistsLabel,
    entityNotFoundLabel,
    logoUploadFailedLabel,
    imageUploadFailedLabel,
    imageWrongFileTypeLabel,
    imageTooBigLabel,
    tooManyRequestsLabel,
    forbiddenResourceLabel,
    uploadFailedLabel,
    resourcesUpdatedLabel,
    documentTooBigLabel,
    documentWrongFileTypeLabel,
    newVideoAssignmentCreatedLabel,
    frequentlyAssignedNextHiddenLabel,
    assignmentUpdatedLabel,
    assignedUnitRemovedLabel,
    taskNotFoundLabel,
    unitNotFoundLabel,
    moduleNotFoundLabel,
    phaseNotFoundLabel,
    wrongCredentialsLabel,
    newPasswordUnchangedLabel,
  } = useCommonTranslations();
  const {
    userDeactivatedLabel,
    userDeletedLabel,
    userUpdatedLabel,
    usersAddedLabel,
    userRemovedLabel,
    userReactivatedLabel,
    userAlreadyActiveLabel,
    userAlreadyExistsLabel,
    userAlreadyAcceptedInvitationLabel,
    userNotFoundLabel,
    userReactivateFailedLabel,
    userIsNotAssignedToAnyProjectLabel,
    featureCannotBeAddedLabel,
    featureDisabledLabel,
    themeSuccessfullyUpdatedLabel,
  } = useUserTranslations();
  const {
    clientDeactivatedLabel,
    clientSuccessfullyCreatedLabel,
    clientSuccessfullyUpdatedLabel,
  } = useClientTranslations();
  const {
    projectReactivatedLabel,
    projectDeactivatedLabel,
    projectSuccessfullyUpdatedLabel,
    projectResourcesUpdatedLabel,
    projectHasBeenDuplicatedLabel,
  } = useProjectTranslations();
  const {
    ifEmailExistsPasswordResetEmailWillBeSentLabel,
    youHaveSuccessfullyUpdatedYourProfileLabel,
    accessDeniedLabel,
    passwordResetLinkExpiredLabel,
    passwordUpdateFailedLabel,
    groupNotFoundLabel,
    invitationExpiredLabel,
    inviteExpiredLabel,
  } = useAccountTranslations();
  const {
    productSuccessfullyUpdatedLabel,
    productSuccessfullyCreatedLabel,
    cannotDeactivateProductInUseLabel,
    thisProductHasBeenDeactivatedLabel,
    prototypeAlreadyExistsLabel,
    prototypeForStakeholderAlreadyExistsLabel,
    valuePropositionAlreadyExistsLabel,
    valuePropositionForStakeholderAlreadyExistsLabel,
    painPointAlreadyExistsLabel,
    painPointForStakeholderAlreadyExistsLabel,
    stakeholderWithNameAlreadyExistsLabel,
    stakeholderMapWithNameAlreadyExistsLabel,
    hypothesisAlreadyExistsLabel,
    hypothesisForStakeholderAlreadyExistsLabel,
    failedToUploadStakeholderMapLabel,
  } = useProductTranslations();

  return {
    [NotificationType.DEFAULT]: {
      message: '',
      severity: 'info',
    },
    // success
    [NotificationType.USER_CREATION_INITIALIZED]: {
      message: inviteSentLabel,
      severity: 'success',
    },
    [NotificationType.USER_CREATION_INITIALIZED_EMAIL_NOT_SENT]: {
      message: inviteSentLabel,
      severity: 'success',
    },
    [NotificationType.USER_EMAIL_SENT]: {
      message: emailSentLabel,
      severity: 'success',
    },
    [NotificationType.USER_DEACTIVATED]: {
      message: userDeactivatedLabel,
      severity: 'success',
    },
    [NotificationType.USER_DELETED]: {
      message: userDeletedLabel,
      severity: 'success',
    },
    [NotificationType.USER_UPDATED]: {
      message: userUpdatedLabel,
      severity: 'success',
    },
    [NotificationType.CLIENT_DEACTIVATED]: {
      message: clientDeactivatedLabel,
      severity: 'success',
    },
    [NotificationType.PROJECT_DEACTIVATED]: {
      message: projectDeactivatedLabel,
      severity: 'success',
    },
    [NotificationType.PROJECT_REACTIVATED]: {
      message: projectReactivatedLabel,
      severity: 'success',
    },
    [NotificationType.USER_PASSWORD_RESET_EMAIL_SENT]: {
      message: ifEmailExistsPasswordResetEmailWillBeSentLabel,
      severity: 'info',
    },
    [NotificationType.USER_PASSWORD_CHANGED]: {
      message: passwordChangedLabel,
      severity: 'success',
    },
    [NotificationType.CLIENT_CREATED]: {
      message: clientSuccessfullyCreatedLabel,
      severity: 'success',
    },
    [NotificationType.CLIENT_UPDATED]: {
      message: clientSuccessfullyUpdatedLabel,
      severity: 'success',
    },
    [NotificationType.PROJECT_UPDATED]: {
      message: projectSuccessfullyUpdatedLabel,
      severity: 'success',
    },
    [NotificationType.PROJECT_RESOURCES_UPDATED]: {
      message: projectResourcesUpdatedLabel,
      severity: 'success',
    },
    [NotificationType.RESOURCE_UPDATED]: {
      message: resourcesUpdatedLabel,
      severity: 'success',
    },
    [NotificationType.EXISTING_PROJECT_USER_ADDED]: {
      message: usersAddedLabel,
      severity: 'success',
    },
    [NotificationType.PROJECT_USER_REMOVED]: {
      message: userRemovedLabel,
      severity: 'success',
    },
    [NotificationType.TRIGGER_DATA_SEEDING]: {
      message: seedingInProgressLabel,
      severity: 'success',
    },
    [NotificationType.PROFILE_UPDATED]: {
      message: youHaveSuccessfullyUpdatedYourProfileLabel,
      severity: 'success',
    },
    [NotificationType.USER_REACTIVATE_SUCCESSFUL]: {
      message: userReactivatedLabel,
      severity: 'success',
    },
    [NotificationType.PRODUCT_CREATED]: {
      message: productSuccessfullyCreatedLabel,
      severity: 'success',
    },
    [NotificationType.PRODUCT_UPDATED]: {
      message: productSuccessfullyUpdatedLabel,
      severity: 'success',
    },
    [NotificationType.THEME_UPDATED]: {
      message: themeSuccessfullyUpdatedLabel,
      severity: 'success',
    },
    [NotificationType.NEW_VIDEO_ASSIGNMENT_CREATED]: {
      message: newVideoAssignmentCreatedLabel,
      severity: 'success',
      action: NotificationActionType.VIEW_ALL_ASSIGNMENTS,
    },
    [NotificationType.FAN_SUCCESSFULLY_HIDDEN]: {
      message: frequentlyAssignedNextHiddenLabel,
      severity: 'success',
    },
    [NotificationType.ASSIGNMENT_UPDATED]: {
      message: assignmentUpdatedLabel,
      severity: 'success',
    },
    [NotificationType.ASSIGNMENT_UNIT_REMOVED]: {
      message: assignedUnitRemovedLabel,
      severity: 'success',
    },
    // error
    [NotificationType.ERROR]: {
      message: somethingWentWrongLabel,
      severity: 'error',
    },
    [NotificationType.SERVER_ERROR]: {
      message: somethingWentWrongLabel,
      severity: 'error',
    },
    [NotificationType.DUPLICATE_NAME]: {
      message: nameAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.NAME_EXISTS]: {
      message: nameAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.DUPLICATE_EMAIL]: {
      message: emailAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.EMAIL_EXISTS]: {
      message: emailAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.UNAUTHORIZED]: {
      message: accessDeniedLabel,
      severity: 'error',
    },
    [NotificationType.NOT_FOUND]: {
      message: entityNotFoundLabel,
      severity: 'error',
    },
    [NotificationType.OKTA_USER_EXISTS]: {
      message: userAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.OKTA_USER_ACTIVE]: {
      message: userAlreadyActiveLabel,
      severity: 'error',
    },
    [NotificationType.ALREADY_ACCEPTED]: {
      message: userAlreadyAcceptedInvitationLabel,
      severity: 'error',
    },
    [NotificationType.USER_NOT_FOUND]: {
      message: userNotFoundLabel,
      severity: 'error',
    },
    [NotificationType.USER_REACTIVATE_FAILED]: {
      message: userReactivateFailedLabel,
      severity: 'error',
    },
    [NotificationType.OKTA_PASSWORD_CHANGE_FAILED]: {
      message: passwordUpdateFailedLabel,
      severity: 'error',
    },
    [NotificationType.OKTA_GROUP_NOT_FOUND]: {
      message: groupNotFoundLabel,
      severity: 'error',
    },
    [NotificationType.WRONG_CREDENTIALS]: {
      message: wrongCredentialsLabel,
      severity: 'error',
    },
    [NotificationType.EMAIL_INVITATION_EXPIRED]: {
      message: invitationExpiredLabel,
      severity: 'error',
    },
    [NotificationType.PASSWORD_RESET_LINK_EXPIRED]: {
      message: passwordResetLinkExpiredLabel,
      severity: 'error',
    },
    [NotificationType.LOGO_UPLOAD_FAILED]: {
      message: logoUploadFailedLabel,
      severity: 'error',
    },
    [NotificationType.IMAGE_UPLOAD_FAILED]: {
      message: imageUploadFailedLabel,
      severity: 'error',
    },
    [NotificationType.PRODUCT_IN_USE]: {
      message: cannotDeactivateProductInUseLabel,
      severity: 'error',
    },
    [NotificationType.PRODUCT_DISABLED]: {
      message: thisProductHasBeenDeactivatedLabel,
      severity: 'error',
    },
    [NotificationType.MAP_UPLOAD_FAILED]: {
      message: failedToUploadStakeholderMapLabel,
      severity: 'error',
    },
    [NotificationType.UPLOAD_FAILED]: {
      message: uploadFailedLabel,
      severity: 'error',
    },
    [NotificationType.STAKEHOLDER_NAME_TAKEN]: {
      message: stakeholderWithNameAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.STAKEHOLDER_MAP_NAME_TAKEN]: {
      message: stakeholderMapWithNameAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.HYPOTHESIS_EXISTS]: {
      message: hypothesisAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.HYPOTHESIS_FOR_STAKEHOLDER_EXISTS]: {
      message: hypothesisForStakeholderAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.TOP_VALUE_PROPOSITION_EXISTS]: {
      message: valuePropositionAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.TOP_VALUE_PROPOSITION_FOR_STAKEHOLDER_EXISTS]: {
      message: valuePropositionForStakeholderAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.PAIN_POINT_EXISTS]: {
      message: painPointAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.PAIN_POINT_FOR_STAKEHOLDER_EXISTS]: {
      message: painPointForStakeholderAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.TOP_PROTOTYPE_EXISTS]: {
      message: prototypeAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.TOP_PROTOTYPE_FOR_STAKEHOLDER_EXISTS]: {
      message: prototypeForStakeholderAlreadyExistsLabel,
      severity: 'error',
    },
    [NotificationType.INVITE_EXPIRED]: {
      message: inviteExpiredLabel,
      severity: 'error',
    },
    [NotificationType.IMAGE_UPLOAD_WRONG_TYPE]: {
      message: imageWrongFileTypeLabel,
      severity: 'error',
    },
    [NotificationType.IMAGE_UPLOAD_WRONG_SIZE]: {
      message: imageTooBigLabel,
      severity: 'error',
    },
    [NotificationType.DOCUMENT_UPLOAD_WRONG_TYPE]: {
      message: documentWrongFileTypeLabel,
      severity: 'error',
    },
    [NotificationType.DOCUMENT_UPLOAD_WRONG_SIZE]: {
      message: documentTooBigLabel,
      severity: 'error',
    },
    [NotificationType.TOO_MANY_REQUESTS]: {
      message: tooManyRequestsLabel,
      severity: 'error',
    },
    [NotificationType.FORBIDDEN_RESOURCE]: {
      message: forbiddenResourceLabel,
      severity: 'error',
    },
    [NotificationType.USER_NOT_ASSIGNED_TO_PROJECT]: {
      message: userIsNotAssignedToAnyProjectLabel,
      severity: 'error',
    },
    [NotificationType.FEATURES_CANNOT_BE_ADDED]: {
      message: featureCannotBeAddedLabel,
      severity: 'error',
    },
    [NotificationType.FEATURE_DISABLED]: {
      message: featureDisabledLabel,
      severity: 'error',
    },
    [NotificationType.INVALID_DATA]: {
      message: 'Invalid Data! Please check browser console for errors!', // Don't translate this.
      severity: 'error',
    },
    [NotificationType.TASK_NOT_FOUND]: {
      message: taskNotFoundLabel,
      severity: 'error',
    },
    [NotificationType.UNIT_NOT_FOUND]: {
      message: unitNotFoundLabel,
      severity: 'error',
    },
    [NotificationType.MODULE_NOT_FOUND]: {
      message: moduleNotFoundLabel,
      severity: 'error',
    },
    [NotificationType.PHASE_NOT_FOUND]: {
      message: phaseNotFoundLabel,
      severity: 'error',
    },
    [NotificationType.NEW_PASSWORD_UNCHANGED]: {
      message: newPasswordUnchangedLabel,
      severity: 'error',
    },
    [NotificationType.PROJECT_DUPLICATED]: {
      message: projectHasBeenDuplicatedLabel, // Don't translate this.
      severity: 'success',
    },
  };
};

export type NotificationPropsType = {
  open: boolean;
  severity: notificationSeverity;
  message: string;
  action?: () => void;
};
