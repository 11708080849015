import ConfirmModal from 'components/Modal/ConfirmModal';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type Props = {
  isChanged: boolean;
  message?: string;
};

const defaultMessage = 'Changes that you made may not be saved.';

type NextRouteType = {
  nextRoute: null | string;
  confirmed: boolean;
};

export function useLeaveConfirm({
  isChanged,
  message = defaultMessage,
}: Props) {
  const router = useRouter();

  const [{ nextRoute, confirmed }, setNextRoute] = useState<NextRouteType>({
    nextRoute: null,
    confirmed: false,
  });

  const toNextRoute = () => setNextRoute({ nextRoute, confirmed: true });
  const stayHere = () => setNextRoute({ nextRoute: null, confirmed: false });

  // stop route change on page reload
  useBeforeUnload({ isChanged, message });

  useEffect(() => {
    const onRouteChangeStart = (route: string) => {
      if (!(isChanged && !confirmed)) return undefined;
      // set next route
      setNextRoute({ nextRoute: route, confirmed: false });

      // on history back route changes, so this will revert route change
      if (router.asPath !== window.location.pathname) {
        window.history.pushState('', '', router.asPath);
      }

      // emit error onRouteChangeStart to prevent default behavior (stop route change)
      router.events.emit('routeChangeError');
      throw 'routeChange aborted.';
    };

    const cleanUpFunction = () =>
      router.events.off('routeChangeStart', onRouteChangeStart);

    // define event handler for the routeChangeStart event
    router.events.on('routeChangeStart', onRouteChangeStart);

    // if user wants to proceed push next route
    if (nextRoute && confirmed) {
      router.push(nextRoute);

      return cleanUpFunction;
    }

    return cleanUpFunction;
  }, [router, confirmed, nextRoute, isChanged]);

  // nextRoute is set onRouteChange start. it will render confimation modal.
  if (!nextRoute) return null;

  return <ConfirmModal proceed={toNextRoute} cancel={stayHere} />;
}

// Prevent default behavior on page reload.
// The app listens to the event beforeunload, prevents its behavior,
// and shows the browser's default popup to confirm page leave.
// To show popup, event.returnValue must be a non-empty string
function useBeforeUnload({ isChanged, message = defaultMessage }: Props) {
  useEffect(() => {
    const eventListener = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      if (isChanged) {
        return (event.returnValue = message);
      }
    };

    if (isChanged) window.addEventListener('beforeunload', eventListener);
    else window.removeEventListener('beforeunload', eventListener);

    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, [isChanged, message]);
}
