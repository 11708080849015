// https://coda.io/d/_dPqe2BRd9b4/Predefined-values_suF8_#_ludIr
export enum socketTopics {
  // Global
  FEATURE_FLAGS_LOAD = 'processing|featureFlags|GetSingleItem',
  // Users
  SESSION_DATA = 'processing|user|AuthInfo',
  USER_LIST = 'processing|user|GetAllItems',
  USER_LOAD = 'processing|user|GetSingleItem',
  USER_CREATED = 'processing|user|CreateItem',
  USER_EMAIL_SENT = 'processing|notification|UserInvited',
  USER_ACCEPTED_INVITE = 'processing|notification|StatusUpdate',
  USER_VALIDATE_INVITE = 'processing|user|ValidateInvite',
  USER_PASSWORD_RESET = 'processing|notification|UserResetPassword',
  USER_UPDATED = 'processing|user|UpdateItem',
  USER_DEACTIVATED = 'processing|user|Deactivate',
  USER_REACTIVATED = 'processing|user|Reactivate',
  USER_DELETED = 'processing|user|DeleteItem',
  PROFILE_UPDATED = 'processing|user|UpdateProfile',
  // Clients
  CLIENT_LIST = 'processing|client|GetAllItems',
  CLIENT_CREATED = 'processing|client|CreateItem',
  CLIENT_LOAD = 'processing|client|GetSingleItem',
  CLIENT_UPDATE = 'processing|client|UpdateItem',
  CLIENT_PRESIGNED_URL = 'processing|client|GetUploadUrl',
  PLATFORM_PRESIGNED_URL = 'processing|platform|GetUploadUrl',
  CLIENT_DEACTIVATED = 'processing|client|Deactivate',
  ONLINE_PEOPLE_BY_CLIENT = 'processing|client|user|GetOnlineUsers',
  ONLINE_PEOPLE_BY_CLIENT_LIVE_UPDATES = 'processing|client|OnlineUsers|LiveUpdate',
  CLIENT_ENTERPRISE_RESOURCES = 'processing|client|GetEnterpriseResourceGroup',
  CLIENT_ENTERPRISE_RESOURCES_UPDATE = 'processing|client|UpdateEnterpriseResourceGroup',
  // Client users
  CLIENT_USERS_LIST = 'processing|client|ClientUsers',
  CLIENT_USER_LOAD = 'processing|client|user|GetSingleItem',
  CLIENT_USER_CREATED = 'processing|client|user|CreateItem',
  CLIENT_USER_REACTIVATED = 'processing|client|user|Reactivate',
  CLIENT_USER_DEACTIVATED = 'processing|client|user|Deactivate',
  // Client projects
  CLIENT_PROJECTS_LIST = 'processing|project|GetAllItems',
  PROJECT_ALL_LIST = 'processing|project|All',
  PROJECT_CREATED = 'processing|project|CreateItem',
  PROJECT_USERS_LIST = 'processing|project|user|GetAllItems',
  PROJECT_USER_CREATE = 'processing|project|user|CreateItem',
  PROJECT_USER_UPDATE = 'processing|project|user|UpdateItem',
  PROJECT_USER_DELETE = 'processing|project|user|DeleteItem',
  PROJECT_LOAD = 'processing|project|GetSingleItem',
  PROJECT_UPDATED = 'processing|project|UpdateItem',
  PROJECT_RESOURCES = 'processing|project|GetResourceGroup',
  PROJECT_RESOURCES_UPDATE = 'processing|project|UpdateResourceGroup',
  PROJECT_DEACTIVATED = 'processing|project|Deactivate',
  PROJECT_REACTIVATED = 'processing|project|Reactivate',
  PROJECT_DUPLICATED = 'processing|project|Duplicate',
  // Products
  PRODUCTS_LIST = 'processing|product|GetAllItems',
  CONTENT_LIST = 'processing|content|GetAllItems',
  FEATURE_LIST = 'processing|feature|GetAllItems',
  PRODUCT_CREATED = 'processing|product|CreateItem',
  PRODUCT_UPDATED = 'processing|product|UpdateItem',
  // Roles
  ROLES_LIST = 'processing|role|GetAllItems',
  // Incubate Playbook
  PLAYBOOK_INFO = 'processing|user|GetLastViewedInfo',
  SET_PLAYBOOK_INFO = 'processing|user|SetLastViewedInfo',
  INCUBATE_PLAYBOOK = 'processing|project|product|content',
  FAVORITE_IDS_STATE = 'processing|project|product|favoriteIds',
  FAVORITE_LIST = 'processing|project|product|favoriteList',
  FAVORITE_UPDATE = 'processing|project|product|favoriting',
  PROGRESS_STATE = 'processing|project|product|progressState',
  PROGRESS_UPDATE = 'processing|project|product|progressUpdate',
  VISITED_STATE = 'processing|project|product|visitedTasks',
  VISITED_UPDATE = 'processing|project|product|visited',
  GROUP_RESOURCES = 'processing|project|GetResourceGroup',
  ENTERPRISE_GROUP_RESOURCES = 'processing|client|GetResourceGroup|EnterpriseResources',
  QUIZ_STATE = 'processing|project|product|quizzesState',
  QUIZ_UPDATE = 'processing|project|product|quizStateUpdate',
  RECENT_LIST = 'processing|project|product|recents',
  SEARCH_LIST = 'processing|project|product|search',
  VIDEOS_WATCHED_TIME_STATE = 'processing|UserVideoProgressState',
  VIDEOS_WATCHED_TIME_UPDATE = 'processing|UserVideoProgressStateUpdate',
  // Venture Builder
  GET_VENTURE_BUILDER_UPDATED_INFO = 'processing|VentureBuilder|GetVentureBuilderData',
  VENTURE_BUILDER_UPDATED_INFO_LIVE_UPDATES = 'processing|VentureBuilder|GetUpdatedInfo',
  GET_WHO_MATTERS_MOST_DATA = 'processing|VentureBuilder|who-matters-most',
  UPDATE_WHO_MATTERS_MOST_DATA = 'processing|VentureBuilder|who-matters-most|Update',
  WHO_MATTERS_MOST_LIVE_UPDATES = 'processing|VentureBuilder|who-matters-most|LiveUpdates', // actual socket
  GET_STAKEHOLDER_MAP_DATA = 'processing|VentureBuilder|stakeholder-map',
  UPDATE_STAKEHOLDER_MAP_DATA = 'processing|VentureBuilder|stakeholder-map|Update',
  STAKEHOLDER_MAP_LIVE_UPDATES = 'processing|VentureBuilder|stakeholder-map|LiveUpdates', // actual socket
  GET_HYPOTHESIS_BACKLOG_DATA = 'processing|VentureBuilder|hypothesis-backlog',
  UPDATE_HYPOTHESIS_BACKLOG_DATA = 'processing|VentureBuilder|hypothesis-backlog|Update',
  HYPOTHESIS_BACKLOG_LIVE_UPDATES = 'processing|VentureBuilder|hypothesis-backlog|LiveUpdates', // actual socket
  GET_TOP_VALUE_PROPOSITIONS_DATA = 'processing|VentureBuilder|top-value-propositions',
  UPDATE_TOP_VALUE_PROPOSITIONS_DATA = 'processing|VentureBuilder|top-value-propositions|Update',
  TOP_VALUE_PROPOSITIONS_LIVE_UPDATES = 'processing|VentureBuilder|top-value-propositions|LiveUpdates', // actual socket
  GET_PAIN_POINTS_DATA = 'processing|VentureBuilder|prioritized-pain-points',
  UPDATE_PAIN_POINTS_DATA = 'processing|VentureBuilder|prioritized-pain-points|Update',
  PAIN_POINTS_LIVE_UPDATES = 'processing|VentureBuilder|prioritized-pain-points|LiveUpdates', // actual socket
  GET_TOP_PROTOTYPES_DATA = 'processing|VentureBuilder|top-prototypes',
  UPDATE_TOP_PROTOTYPES_DATA = 'processing|VentureBuilder|top-prototypes|Update',
  TOP_PROTOTYPES_LIVE_UPDATES = 'processing|VentureBuilder|top-prototypes|LiveUpdates', // actual socket
  GET_CURRENCY_DATA = 'processing|VentureBuilder|currency',
  UPDATE_CURRENCY_DATA = 'processing|VentureBuilder|currency|Update',
  CURRENCY_LIVE_UPDATES = 'processing|VentureBuilder|currency|LiveUpdates', // actual socket
  GET_MARKET_SIZE_DATA = 'processing|VentureBuilder|market-size',
  UPDATE_MARKET_SIZE_DATA = 'processing|VentureBuilder|market-size|Update',
  MARKET_SIZE_LIVE_UPDATES = 'processing|VentureBuilder|market-size|LiveUpdates', // actual socket
  GET_FUNDING_REQUEST_DATA = 'processing|VentureBuilder|funding-request',
  UPDATE_FUNDING_REQUEST_DATA = 'processing|VentureBuilder|funding-request|Update',
  FUNDING_REQUEST_LIVE_UPDATES = 'processing|VentureBuilder|funding-request|LiveUpdates', // actual socket
  // Team Progress
  GET_TEAM_PROGRESS = 'processing|TeamProgress',
  TEAM_PROGRESS_LIVE_UPDATES = 'processing|TeamProgress|LiveUpdates',
  // Assign Content
  CREATE_ASSIGNMENT = 'processing|assignments|CreateItem',
  UPDATE_ASSIGNMENT = 'processing|assignments|UpdateItem',
  GET_FREQUENTLY_ASSIGNED_NEXT = 'processing|assignments|GetAllFrequentlyAssignedNext',
  UPDATE_FREQUENTLY_ASSIGNED_NEXT = 'processing|assignments|UpdateFrequentlyAssignedNext',
  ASSIGNMENTS_LIST = 'processing|assignments|GetAllItems',
  ASSIGNMENT_LOAD = 'processing|assignments|GetSingleItem',
  ASSIGNMENT_UNIT_DELETE = 'processing|assignments|unit|DeleteItem',
  ALL_ASSIGNMENTS_LIST = 'processing|assignments|all|GetAllItems',
  ASSIGNMENT_WITH_UNIT_AND_TASK_ID = 'processing|assignments|with-task|GetSingleItem',
  GET_ALL_WATCHED_VIDEOS = 'processing|assignments|watched-videos|GetAllItems',
  TEAMMATES_WHO_WATCHED_VIDEO = 'processing|assignments|teammates-watched-video|GetAllItems',
}
