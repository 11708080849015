import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { TopPrototype, VentureBuilderChange } from 'types/ventureBuilder';
import { useGetTopPrototypesData } from './useGetTopPrototypesData';
import { useUpdateTopValuePropositionsData } from './useUpdateTopValuePropositionsData';

export const useTopPrototypesData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.TOP_PROTOTYPE,
  };

  const {
    topPrototypes,
    isLoading: isGetting,
    setTopPrototypes,
  } = useGetTopPrototypesData({
    defaultParams,
  });

  const { isLoading: isPosting, updateTopPrototypesData } =
    useUpdateTopValuePropositionsData({});

  type UpdateTopPrototypesProps = {
    payload: VentureBuilderChange<TopPrototype>;
    newData: TopPrototype[];
  };

  const updateTopPrototypes = ({
    payload,
    newData,
  }: UpdateTopPrototypesProps) => {
    const oldData = [...topPrototypes];

    setTopPrototypes(newData);
    updateTopPrototypesData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setTopPrototypes(oldData);
        },
      }
    );
  };

  return {
    topPrototypes,
    updateTopPrototypes,
    isGetting,
    isPosting,
  };
};
