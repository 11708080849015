import { ClientEnterpriseResourcesListRequest, ClientParams } from '.';
import { EligibleProjectsRequestProps } from './project';
import {
  ClientUserListRequestProps,
  ClientUserParams,
  OnlinePeopleByClientParams,
} from './users';

export type ClientInfoParams = ReturnType<typeof clientQueryKeys.clientInfo>;

export const clientQueryKeys = {
  all: { scope: 'client' } as const,
  singleBase: () => ({ ...clientQueryKeys.all, entity: 'single' } as const),
  single: (params: ClientParams['params']) =>
    ({ ...clientQueryKeys.singleBase(), params } as const),
  clientInfo: () =>
    ({
      ...clientQueryKeys.all,
      entity: 'client-info',
      params: undefined,
    } as const),
  usersListBase: () =>
    ({ ...clientQueryKeys.all, entity: 'users-list' } as const),
  usersList: (params: ClientUserListRequestProps['params']) =>
    ({ ...clientQueryKeys.usersListBase(), params } as const),
  usersEligibleForProjectListBase: () =>
    ({
      ...clientQueryKeys.all,
      entity: 'users-eligible-for-project-list',
    } as const),
  usersEligibleForProjectList: (params: ClientUserListRequestProps['params']) =>
    ({
      ...clientQueryKeys.usersEligibleForProjectListBase(),
      params,
    } as const),
  usersProjectsEligibleForUsersListBase: () =>
    ({
      ...clientQueryKeys.all,
      entity: 'projects-eligible-for-users-list',
    } as const),
  usersProjectsEligibleForUsersList: (
    params: EligibleProjectsRequestProps['params']
  ) =>
    ({
      ...clientQueryKeys.usersProjectsEligibleForUsersListBase(),
      params,
    } as const),
  singleUserBase: () =>
    ({ ...clientQueryKeys.all, entity: 'single-user-load' } as const),
  singleUser: (params: ClientUserParams['params']) =>
    ({ ...clientQueryKeys.singleUserBase(), params } as const),
  onlinePeopleByClientBase: () =>
    ({ ...clientQueryKeys.all, entity: 'online-people-by-client' } as const),
  onlinePeopleByClient: (params: OnlinePeopleByClientParams['params']) =>
    ({ ...clientQueryKeys.onlinePeopleByClientBase(), params } as const),
  enterpriseResourcesListBase: () =>
    ({ ...clientQueryKeys.all, entity: 'enterprise-resources-list' } as const),
  enterpriseResourcesClientUpdate: (params: { clientId: string }) =>
    ({ ...clientQueryKeys.enterpriseResourcesListBase(), params } as const),
  enterpriseResourcesList: (
    params: ClientEnterpriseResourcesListRequest['params']
  ) => ({ ...clientQueryKeys.enterpriseResourcesListBase(), params } as const),
};
