import { Box, Divider } from '@mui/material';
import { thumbnailHeight, thumbnailWidth } from 'common/consts';
import { useCustomMediaQuery } from 'hooks';
import {
  IncubatePlaybookSanity,
  IncubatePlaybookTaskActivity,
} from 'types/incubatePlaybook';
import { FeedbackModule } from '../FeedbackModule';
import { PlaybookFavoriteButton } from '../PlaybookFavoriteButton';
import { PlaybookVideo } from '../PlaybookVideo';
import VentureBuilderTaskWrapper from '../VentureBuilder/VentureBuilderTaskWrapper/VentureBuilderTaskWrapper';
import ActivityInstructions from './ActivityInstructions';
import { TaskContactExpert } from './TaskContactExpert';
import { TaskResources } from './TaskResources';
import { TaskWithVideoHeader } from './TaskWithVideoHeader';
import { TaskWrapper } from './TaskWrapper';

type PlaybookActivityTaskProps = {
  data: IncubatePlaybookTaskActivity;
  phaseTitle: string;
  phaseId: string;
  moduleId: string;
  moduleTitle: string;
  unitTitle: string;
  unitId: string;
  helpLink: IncubatePlaybookSanity['contactUrl'];
};

export const PlaybookActivityTask = ({
  data,
  phaseId,
  phaseTitle,
  moduleId,
  moduleTitle,
  unitId,
  unitTitle,
  helpLink,
}: PlaybookActivityTaskProps) => {
  const { lessThenSm } = useCustomMediaQuery();

  const trackData = {
    taskId: data._id,
    taskName: data.title,
    unitId,
    unitName: unitTitle,
    phaseId,
    phaseName: phaseTitle,
  };

  return (
    <Box display="flex" flexDirection="column" gap="30px">
      <TaskWrapper>
        <TaskWithVideoHeader
          {...{
            phaseId,
            moduleId,
            unitId,
            unitSlug: unitTitle,
            taskId: data._id,
            taskData: data,
          }}
        />
        <PlaybookVideo
          transcriptName={data.title}
          video={data.video}
          url={data?.video?.source?.embedUrl}
          trackData={trackData}
        >
          <PlaybookFavoriteButton
            itemId={data._id}
            itemName={data.title}
            itemPhaseName={phaseTitle}
            itemModuleName={moduleTitle}
            itemUnitName={unitTitle}
            itemImageURL={
              data.video?.source?.posterImage?.concat(
                `_${thumbnailWidth}x${thumbnailHeight}`
              ) ?? ''
            }
            itemType="task"
            itemLink=""
            dataTestId="save-to-favorites-task"
            iconOnly={lessThenSm}
          />
        </PlaybookVideo>
        <Divider sx={dividerStyle} />
        <ActivityInstructions instructions={data.instructions} />
      </TaskWrapper>

      <TaskResources
        resources={data.resources}
        moduleTitle={moduleTitle}
        phaseTitle={phaseTitle}
      />

      <VentureBuilderTaskWrapper sections={data?.ventureBuilders ?? []} />

      <FeedbackModule
        pageType="Activity Task"
        page={`${unitTitle} - ${data.title}`}
      />

      <TaskContactExpert helpLink={helpLink} />
    </Box>
  );
};

const dividerStyle = { my: '30px' };
