import { acceptedImageFileTypes, maxImageSize } from 'common/imageUpload';

export const validateImgType = (
  type: string,
  acceptedTypes: string[] = acceptedImageFileTypes
) => acceptedTypes.includes(type);

export const validateImgSize = (
  size: number,
  maxValue: number = maxImageSize
) => size <= maxValue;
