import { Box, Container, Grid, Typography } from '@mui/material';
import { playbookHeaderImageHeight } from 'common/consts';
import { useCustomMediaQuery } from 'hooks';
import { StandardSanityImageOptions } from 'types/incubatePlaybook';
import { urlFor } from 'utils';
import { PlaybookVideo } from './PlaybookVideo';

type PlaybookImageHeadingProps = {
  imageHeight?: null | number;
  image: StandardSanityImageOptions['image'];
  title: string;
  subtitle: string;
  videoUrl?: string;
};

export const PlaybookImageHeading = ({
  imageHeight,
  image,
  title,
  subtitle,
  videoUrl,
}: PlaybookImageHeadingProps) => {
  const { greaterThenSm } = useCustomMediaQuery();

  const imageUrl = urlFor(image)
    ?.height(imageHeight ?? playbookHeaderImageHeight)
    .url();

  return (
    <Box sx={imageHeadingWrapperStyle(greaterThenSm, imageUrl)}>
      <Container maxWidth="xl" sx={containerStyle}>
        <Grid container spacing={2} alignItems="center" my={2}>
          <Grid item xs={12} sm={5} color="white">
            <Typography fontSize={35} fontWeight={700} mb="5px">
              {title}
            </Typography>
            <Typography fontSize={18}>{subtitle}</Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box boxShadow="0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)">
              <PlaybookVideo url={videoUrl} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const imageHeadingWrapperStyle = (
  greaterThenSm: boolean,
  imageUrl?: string
) => ({
  minHeight: '400px',
  maxHeight: '600px',
  mt: greaterThenSm ? 0 : '-23px',
  mb: '30px',
  display: 'flex',
  alignItems: greaterThenSm ? 'center' : 'flex-end',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
});
const containerStyle = { zIndex: 5 };
