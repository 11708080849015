export enum topRoutes {
  nonAuth = '/nonAuth/',
  mach49 = '/mach49/',
  client = '/client/',
  playbook = '/playbook/',
  changePassword = '/change-password',
}

export enum mach49Routes {
  clients = '/mach49/clients',
  users = '/mach49/users',
  products = '/mach49/products',
  content = '/mach49/content',
  profile = '/mach49/profile',
  projects = '/mach49/projects',
}

export enum nonAuthRoutes {
  login = '/nonAuth/login',
  loginCallback = '/nonAuth/login/callback',
  resetPassword = '/nonAuth/reset-password',
  forgotPassword = '/nonAuth/forgot-password',
  acceptedInvite = '/nonAuth/accepted-invite',
  changeTempPassword = '/nonAuth/change-temp-password',
}

export enum clientRoutes {
  projects = '/client/[clientId]/projects',
  projectDetails = '/client/[clientId]/projects/[projectId]',
  projectResources = '/client/[clientId]/projects/[projectId]/[productId]/resources',
  products = '/client/[clientId]/products',
  profile = '/client/[clientId]/profile',
  settings = '/client/[clientId]/settings',
  users = '/client/[clientId]/users',
}

export enum playbookRoutes {
  home = '/playbook/[playbookSlug]/home',
  phase = '/playbook/[playbookSlug]/phase/[phaseSlug]',
  task = '/playbook/[playbookSlug]/[unitSlug]/task/[taskSlug]',
  favorites = '/playbook/[playbookSlug]/favorites',
  recents = '/playbook/[playbookSlug]/recents',
  search = '/playbook/[playbookSlug]/search',
  ventureBuilder = '/playbook/[playbookSlug]/venture-builder',
}
