import { useCallback, useState } from 'react';

export const useModalState = (defaultValue = false) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(defaultValue);

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const toggleModal = useCallback(() => setIsModalOpen((o) => !o), []);

  type Arr = [
    typeof isModalOpen,
    typeof openModal,
    typeof closeModal,
    typeof toggleModal
  ];
  const arr: Arr = [isModalOpen, openModal, closeModal, toggleModal];

  return { isModalOpen, openModal, closeModal, toggleModal, arr };
};
