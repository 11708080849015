import { Box } from '@mui/material';
import { ButtonText } from 'components/Inputs/Button';
import { ReactNode } from 'react';

type HeaderItemProps = {
  children: ReactNode;
  activePage: boolean;
  onClick: () => void;
  dataTestId?: string;
};

export const HeaderItem = ({
  children,
  activePage,
  onClick,
  dataTestId,
}: HeaderItemProps) => {
  return (
    <Box
      height="70px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      px="35px"
      borderBottom="2px solid"
      borderColor={activePage ? 'primary.main' : 'transparent'}
    >
      <ButtonText
        color={activePage ? 'primary' : undefined}
        onClick={onClick}
        data-testid={dataTestId}
      >
        {children}
      </ButtonText>
    </Box>
  );
};
