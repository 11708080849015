import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { useRef } from 'react';
import type {
  RumEvent,
  RumEventDomainContext,
} from '@datadog/browser-rum-core';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';

export const useDatadogInit = () => {
  const isInitialized = useRef(false);

  let hostParts = ['', 'dev'];
  let buildId = 'nextjs-server';

  if (typeof window !== 'undefined') {
    hostParts = window.location.host.match(/^(.+)\.shiftplatform\.ai$/) || [
      '',
      'dev',
    ];
    buildId = window.__NEXT_DATA__.buildId;
  }

  const env =
    hostParts[1] == 'app'
      ? 'prod'
      : hostParts[1] === 'staging'
      ? 'uat'
      : hostParts[1];

  const initConf: RumInitConfiguration = {
    applicationId: 'fcbf6acb-6368-4f1d-9a0d-bd5a67dd21a5',
    clientToken: 'pubc596e1c37c78fa2987aa49a86ddcf9e8',
    site: 'datadoghq.com',
    service: 'playbook-ui',
    env,
    version: buildId,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow' as const,
    allowedTracingUrls: [
      {
        match: /https:\/\/.*\.shiftplatform\.ai/,
        propagatorTypes: ['datadog', 'tracecontext'],
      },
      {
        match: /wss:\/\/.*\.shiftplatform\.ai/,
        propagatorTypes: ['datadog', 'tracecontext'],
      },
    ],
    beforeSend: (event: RumEvent, context: RumEventDomainContext) => {
      if (event.view?.url) {
        const url = new URL(event.view.url);
        event.context!.shift_personas = [
          {
            regex: /\/app\/projects\/([^/]{32,36}).*/,
            name: 'project_id',
          },
          {
            regex: /\/app\/projects\/[^/]+\/customer-segments\/([^/]{32,36}).*/,
            name: 'customer_segment_id',
          },
          {
            regex:
              /\/app\/projects\/[^/]+\/customer-segments\/create\/([^/]{32,36}).*/,
            name: 'persona_generation_id',
          },
        ].reduce((obj, config) => {
          const match = config.regex.exec(url.pathname);
          if (match) {
            return Object.assign(obj, { [config.name]: match[1] });
          }

          return obj;
        }, {});
      }

      return true;
    },
  };

  const logsInitConf: LogsInitConfiguration = {
    clientToken: 'pubc596e1c37c78fa2987aa49a86ddcf9e8',
    site: 'datadoghq.com',
    service: 'playbook-ui',
    env,
    version: buildId,
    sessionSampleRate: 100,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
  };

  if (!isInitialized.current && env !== 'dev') {
    datadogRum.init(initConf);
    datadogRum.startSessionReplayRecording();
    datadogLogs.init(logsInitConf);

    isInitialized.current = true;
  }
};
