import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export const ButtonText = ({ children, sx, ...props }: LoadingButtonProps) => {
  const btnStyle = {
    fontWeight: 500,
    color: props.color ?? 'text.secondary',
    ...sx,
  };

  return (
    <LoadingButton {...props} sx={btnStyle} variant="text">
      {children}
    </LoadingButton>
  );
};
