import { headerSocketTopic } from 'common/consts';
import { API } from 'services/api/utils';
import { DefaultRequest } from 'types/api';
import { VentureBuilderChange } from 'types/ventureBuilder';

export type GetVentureBuilderRequestParams = DefaultRequest<{
  clientId: string;
  projectId: string;
  slug: string;
  vbSocketId: string;
  productId: string;
}>;

export type SetVentureBuilderRequestParams<SectionContentObject = {}> =
  DefaultRequest<
    Omit<GetVentureBuilderRequestParams['params'], 'vbSocketId'> & {
      data: VentureBuilderChange<SectionContentObject>;
    }
  >;

export const getVentureBuilderDataRequest = ({
  params: { clientId, projectId, slug, vbSocketId, productId },
  topic,
}: GetVentureBuilderRequestParams) =>
  API.get(
    `/v1/clients/${clientId}/projects/${projectId}/venture-builder/${slug}?vbSocketId=${vbSocketId}&productId=${productId}`,
    { headers: { [headerSocketTopic]: topic } }
  );

export const setVentureBuilderDataRequest = ({
  params: { clientId, projectId, slug, data, productId },
  topic,
}: SetVentureBuilderRequestParams) =>
  API.patch(
    `/v1/clients/${clientId}/projects/${projectId}/venture-builder/${slug}?productId=${productId}`,
    data,
    { headers: { [headerSocketTopic]: topic } }
  );

export type GetVentureBuilderInfoRequestParams = DefaultRequest<{
  clientId: string;
  projectId: string;
  vbSocketId: string;
  productId: string;
}>;
export const getVentureBuilderInfoRequest = ({
  params: { clientId, projectId, vbSocketId, productId },
  topic,
}: GetVentureBuilderInfoRequestParams) =>
  API.get(
    `/v1/clients/${clientId}/projects/${projectId}/venture-builder?vbSocketId=${vbSocketId}&productId=${productId}`,
    { headers: { [headerSocketTopic]: topic } }
  );

export const ventureBuilderDisconnectRequest = ({
  clientId,
  projectId,
  vbSocketId,
}: GetVentureBuilderInfoRequestParams['params']) =>
  API.delete(
    `/v1/clients/${clientId}/projects/${projectId}/venture-builder/disconnect?vbSocketId=${vbSocketId}`,
    { headers: { [headerSocketTopic]: 'venture|builder|disconnect' } } // topic not used - this is just for consistency
  );
