import { useIntl } from 'react-intl';

export const useContentTranslations = () => {
  const { formatMessage } = useIntl();

  const contentLabel = formatMessage({
    id: 'content',
    defaultMessage: 'Content',
  });

  const manageAndCustomizeContentInSanityLabel = formatMessage({
    id: 'content.manageAndCustomizeSanity',
    defaultMessage: 'Manage and customize product content in Sanity',
  });

  const trigerSanityDataSeedLabel = formatMessage({
    id: 'content.trigerSanitySeed',
    defaultMessage: 'Trigger Sanity Data Seeding',
  });

  const manageContentLabel = formatMessage({
    id: 'content.manageContent',
    defaultMessage: 'Manage Content',
  });

  const customizeContentLabel = formatMessage({
    id: 'content.customizeContent',
    defaultMessage: 'Customize Content',
  });

  const selectContentLabel = formatMessage({
    id: 'content.selectContent',
    defaultMessage: 'Select Content',
  });

  const footerContentLabel = formatMessage({
    id: 'content.footerContent',
    defaultMessage: 'Footer Content',
  });

  return {
    footerContentLabel,
    selectContentLabel,
    contentLabel,
    manageAndCustomizeContentInSanityLabel,
    trigerSanityDataSeedLabel,
    manageContentLabel,
    customizeContentLabel,
  };
};
