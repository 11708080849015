import {
  permissionKey,
  permissionsMap,
  SessionDataPermissions,
} from 'common/userConsts';
import { InitialSessionData } from 'types/User';

export const getAdaptedPermissions = (
  permissions: InitialSessionData['permissions']
) =>
  Object.entries(permissionsMap).reduce<SessionDataPermissions>(
    (a, [key, value]) => {
      a[key as permissionKey] = permissions.includes(value);

      return a;
    },
    {}
  );
