import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {
  Avatar,
  AvatarGroup,
  Box,
  Fade,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCustomMediaQuery, useModalState, useProductFeatures } from 'hooks';
import { useMemo, useState } from 'react';
import { useGetAllWatchedVideos } from 'services/api/product/assignContent/useGetAllWatchedVideos';
import { useGetAssignmentWithUnitAndTaskId } from 'services/api/product/assignContent/useGetAssignmentWithUnitAndTaskId';
import { useGetTeammatesWhoWatchedVideo } from 'services/api/product/assignContent/useGetTeammatesWhoWatchedVideo';
import { useFeatureFlags } from 'services/api/useFeatureFlags';
import { useSessionData } from 'services/api/useSessionData';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookTaskActivity,
  IncubatePlaybookTaskConcept,
} from 'types/incubatePlaybook';
import { getInitials } from 'utils';
import { AssignContentModal } from '../AssignContent/AssignContentModal';
import { AssignVideoButton } from '../AssignContent/AssignVideoButton';
import { AssignmentDetailsTaskView } from '../AssignContent/AssignmentDetailsTaskView';
import { TaskTimeEstimate } from './TaskTimeEstimate';

type TaskWithVideoHeaderProps = {
  phaseId: string;
  moduleId: string;
  unitId: string;
  taskId: string;
  taskData: IncubatePlaybookTaskConcept | IncubatePlaybookTaskActivity;
};

export const TaskWithVideoHeader = ({
  taskId,
  taskData,
  unitId,
  moduleId,
  phaseId,
}: TaskWithVideoHeaderProps) => {
  const { permissions } = useSessionData({});
  const { allUnitData } = useIncubatePlaybookData();
  const { isModalOpen, openModal, closeModal } = useModalState();
  const { lessThenSm } = useCustomMediaQuery();

  const { ff } = useFeatureFlags({});
  const productFeatures = useProductFeatures();

  const isAssignmentsEnabled =
    ff.playbookAssignments &&
    productFeatures.assignContent &&
    permissions.canViewTeamProgress &&
    permissions.canViewAssignments;

  const { watchedLabel, usersWatchedThisVideoLabel } = useCommonTranslations();

  const { data: assignmentsDetailsData, isLoading: loadingAssignmentDetils } =
    useGetAssignmentWithUnitAndTaskId({
      defaultParams: { unitId, taskId },
      enabled: isAssignmentsEnabled,
    });

  const { teammates, isLoading: loadingTeammates } =
    useGetTeammatesWhoWatchedVideo({
      defaultParams: { videoId: taskData.video?._id! },
      enabled: isAssignmentsEnabled,
    });

  const { allWatchedVideos } = useGetAllWatchedVideos({
    enabled: isAssignmentsEnabled,
  });

  const usersWhoWatchedThisVideo = useMemo(
    () =>
      usersWatchedThisVideoLabel(teammates.map((item) => item.name).join(', ')),
    [usersWatchedThisVideoLabel, teammates]
  );

  const [activeAssignment, setActiveAssignment] = useState<string>();

  return (
    <>
      {isModalOpen && (
        <AssignContentModal
          open={isModalOpen}
          onClose={closeModal}
          preselectedUnits={
            activeAssignment ? undefined : [{ phaseId, moduleId, unitId }]
          }
          assignmentId={activeAssignment}
        />
      )}
      {lessThenSm && isAssignmentsEnabled && (
        <Box display="flex" justifyContent="space-between">
          <Fade in={!!assignmentsDetailsData && !loadingAssignmentDetils}>
            <Box display="flex" justifyContent="space-between" mb="15px">
              <AssignmentDetailsTaskView
                assignmentDetails={assignmentsDetailsData}
                unitId={unitId}
                unitName={allUnitData[unitId].title}
                openAssignModal={openModal}
                setActiveAssignment={setActiveAssignment}
              />
            </Box>
          </Fade>
          <Fade in={!loadingTeammates && teammates.length > 0}>
            <Tooltip arrow title={usersWhoWatchedThisVideo} placement="top">
              <AvatarGroup max={4} sx={avatarGroupSx}>
                {teammates.map((item) => (
                  <Avatar key={item.name} src={item.profileImg} sx={avatarSx}>
                    {getInitials(item.name)}
                  </Avatar>
                ))}
              </AvatarGroup>
            </Tooltip>
          </Fade>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" mb="15px">
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          <Typography
            variant="h6"
            mr="15px"
            my="auto"
            fontWeight="bold"
            data-testid="task-title"
          >
            {taskData.title}
          </Typography>
          {taskData?.video && isAssignmentsEnabled && (
            <Fade in={allWatchedVideos.includes(taskData.video._id)}>
              <Tooltip arrow title={watchedLabel} placement="right">
                <CheckOutlinedIcon color="success" />
              </Tooltip>
            </Fade>
          )}
        </Box>
        {!lessThenSm &&
          isAssignmentsEnabled &&
          !assignmentsDetailsData &&
          !loadingAssignmentDetils && <AssignVideoButton onClick={openModal} />}
        {!lessThenSm && isAssignmentsEnabled && !!assignmentsDetailsData && (
          <Fade in={!loadingTeammates && teammates.length > 0}>
            <Tooltip arrow title={usersWhoWatchedThisVideo} placement="top">
              <AvatarGroup max={4} sx={avatarGroupSx}>
                {teammates.map((item) => (
                  <Avatar key={item.name} src={item.profileImg} sx={avatarSx}>
                    {getInitials(item.name)}
                  </Avatar>
                ))}
              </AvatarGroup>
            </Tooltip>
          </Fade>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mb="15px"
        alignItems="center"
      >
        <TaskTimeEstimate task={taskData} />
        {lessThenSm &&
          isAssignmentsEnabled &&
          !assignmentsDetailsData &&
          !loadingAssignmentDetils && <AssignVideoButton onClick={openModal} />}
        {!lessThenSm && isAssignmentsEnabled && (
          <Fade in={!!assignmentsDetailsData && !loadingAssignmentDetils}>
            <Box display="flex" justifyContent="space-between">
              <AssignmentDetailsTaskView
                assignmentDetails={assignmentsDetailsData}
                unitId={unitId}
                unitName={allUnitData[unitId].title}
                openAssignModal={openModal}
                setActiveAssignment={setActiveAssignment}
              />
            </Box>
          </Fade>
        )}
      </Box>
    </>
  );
};

const avatarGroupSx = {
  '& .MuiAvatar-root': {
    width: '24px',
    height: '24px',
    fontSize: 15,
  },
};

const avatarSx = { width: '24px', height: '24px' };
