import { HocuspocusProvider } from '@hocuspocus/provider';
import VentureBuilderIcon from '@mui/icons-material/BuildOutlined';
import { Box, Typography } from '@mui/material';
import { getLocalStorageOktaAccessToken } from 'common/localStorage';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { SectionModes } from 'common/ventureBuilderConsts';
import CollaborativeEditor from 'components/Inputs/CollaborativeEditor/CollaborativeEditor';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useSessionData } from 'services/api/useSessionData';
import { useProductTranslations } from 'translations/hooks';
import { CollaborationUser } from 'types/User';
import { getProcessEnv } from 'utils';
import { getBaseLink } from 'utils/incubatePlaybook';

const colors = [
  '#958DF1',
  '#F98181',
  '#FBBC88',
  '#FAF594',
  '#70CFF8',
  '#94FADB',
  '#B9F18D',
];

const processEnv = getProcessEnv();

const getRandomElement = (list: string[]) =>
  list[Math.floor(Math.random() * list.length)];

const getRandomColor = () => getRandomElement(colors);

export const ChallengeStatement = ({ mode }: { mode: SectionModes }) => {
  const { sessionData } = useSessionData({});
  const [currentUser, setCurrentUser] = useState<CollaborationUser | null>(
    null
  );
  const router = useRouter();
  const { notify } = useNotifications();

  const { playbookInfo } = useGetPlaybookInfo({});
  const { challengeStatementLabel } = useProductTranslations();

  const oktaAccessToken = getLocalStorageOktaAccessToken();

  const { clientId, projectId, productId } = playbookInfo;

  const roomName = `${clientId}-${projectId}-challenge-statement?productId=${productId}`;
  const websocketProvider = useMemo(
    () =>
      new HocuspocusProvider({
        url: processEnv.NEXT_PUBLIC_HOCUSPOCUS_SERVER!,
        name: roomName,
        token: oktaAccessToken,
        parameters: {
          productId,
        },
        onAuthenticationFailed: (err) => {
          console.log('error--challenge-statement', err);
          notify(NotificationType.ERROR);
          router.push(`${getBaseLink(router.asPath)}/home`);
        },
      }),
    [roomName, oktaAccessToken, productId, notify, router]
  );

  useEffect(() => {
    setCurrentUser({
      name: sessionData?.user.name ?? 'user',
      color: getRandomColor(),
    });
  }, [sessionData, setCurrentUser]);

  return (
    <Box>
      {mode === SectionModes.TASK ? (
        <SectionTaskWrapper>
          <Box height="240px">
            <CollaborativeEditor
              user={currentUser}
              websocketProvider={websocketProvider}
              roomName={roomName}
            />
          </Box>
        </SectionTaskWrapper>
      ) : (
        <Box sx={boxStyle}>
          <Typography variant="h6" fontWeight="600" mb="10px">
            {challengeStatementLabel}
          </Typography>
          <Box height="240px">
            <CollaborativeEditor
              user={currentUser}
              websocketProvider={websocketProvider}
              roomName={roomName}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const boxStyle = {
  margin: '32px 0',
};

const SectionTaskWrapper = ({ children }: { children: ReactNode }) => {
  const {
    challengeStatementLabel,
    objectiveDocumentSectionInVBLabel,
    copyAndPasteChallengeStatementBelowIntoVBLabel,
  } = useProductTranslations();

  return (
    <>
      <Box display="flex" alignItems="center" mb="20px">
        <VentureBuilderIcon fontSize="small" />
        <Typography variant="h6" ml="10px">
          {objectiveDocumentSectionInVBLabel(challengeStatementLabel)}
        </Typography>
      </Box>
      <Typography variant="body1" mb="40px">
        {copyAndPasteChallengeStatementBelowIntoVBLabel}
      </Typography>
      {children}
    </>
  );
};
