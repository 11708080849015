import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import { ReactNode } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 2,
      retry: (_, err) => err !== NotificationType.INVALID_DATA,
    },
  },
});

export const ReactQueryProvider = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
