import { Box, CircularProgress } from '@mui/material';

export const PlaybookCircularLoader = () => {
  return (
    <Box
      width="100%"
      height="200px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={40} thickness={2} color="secondary" />
    </Box>
  );
};
