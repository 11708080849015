import { useQuery } from '@tanstack/react-query';
import { Theme } from 'common/enums';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import useLanguage from 'hooks/useLanguage';
import { useTheme } from 'next-themes';
import { useEffect } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { InitialSessionData, InitialSessionDataSchema } from 'types/User';
import { SocketHandlerProps } from 'types/socket';
import { getAdaptedPermissions, getAdaptedRoles } from 'utils';
import { queryKeys } from './queryKeys';
import { getSessionDataRequest } from './user';

type ResponseData = ReturnType<typeof adaptFn>;
export type SessionData = ResponseData;

const schema = InitialSessionDataSchema;

export const useSessionData = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<void, ResponseData>) => {
  const { changeAppLanguage } = useLanguage();
  const { notify } = useNotifications();
  const { setTheme } = useTheme();

  const successHandler = (result?: ResponseData) => {
    setTheme(result?.sessionData.user.selectedTheme ?? Theme.dark);
    onSuccess?.(result);
  };
  const failureHandler = (message_key?: NotificationType) => {
    message_key && notify(message_key);
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<unknown, InitialSessionData, ResponseData>({
    topic: socketTopics.SESSION_DATA,
    request: getSessionDataRequest,
    adaptFn,
    schema,
  });

  const { data, refetch } = useQuery([queryKeys.sessionData()], fetchFn, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  // LOCALE INIT AND CHANGE by user/profile language
  useEffect(() => {
    changeAppLanguage(data?.sessionData?.user?.language);
  }, [changeAppLanguage, data?.sessionData?.user?.language]);

  return {
    sessionData: data?.sessionData,
    permissions: data?.permissions ?? emptyPermissions,
    roles: data?.roles ?? emptyRoles,
    isSessionValid: !!data?.sessionData,
    refetchSessionData: refetch,
  };
};

const adaptFn = (sessionData?: InitialSessionData) => {
  if (!sessionData) return null;

  const permissions = getAdaptedPermissions(sessionData.permissions);
  const roles = getAdaptedRoles(sessionData);

  return { sessionData, permissions, roles };
};

const emptyRoles = getAdaptedRoles(undefined);
const emptyPermissions = getAdaptedPermissions([]);
