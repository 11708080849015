import { Box, Grid, Typography } from '@mui/material';
import { MuiIcon } from 'components/MuiIcon';
import { PlaybookButton } from 'components/Playbook';
import Img from 'next/image';
import { ReactNode, useCallback, useState } from 'react';
import { StandardSanityImageOptions } from 'types/incubatePlaybook';
import { urlFor } from 'utils';
import { ModalStyled } from '../ModalStyled';

type PlaybookModalProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  image: StandardSanityImageOptions['image'] | null;
  children: ReactNode;
  btnText: string;
  onClick: () => void;
};

export const PlaybookModal = ({
  title,
  open,
  onClose,
  image,
  children,
  btnText,
  onClick,
}: PlaybookModalProps) => {
  const [contentHeight, setContentHeight] = useState<number>(0);
  const img = urlFor(image)?.width(imgWidth).height(contentHeight).url();

  const setHeightRef = useCallback((e: HTMLDivElement) => {
    setContentHeight(e?.scrollHeight);
  }, []);

  return (
    <ModalStyled onClose={onClose} open={open} style={modalStyle}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={img ? 9 : 12}
          md={img ? 8.5 : 12}
          order={modalTextOrder}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="30px"
            gap="20px"
            width="100%"
            ref={setHeightRef}
          >
            <Box>
              <Typography variant="h4" mb="5px">
                {title}
              </Typography>
              <Box>{children}</Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <PlaybookButton onClick={onClick}>
                {btnText} <MuiIcon iconName="ArrowForward" sx={iconStyle} />
              </PlaybookButton>
            </Box>
          </Box>
        </Grid>
        {img && (
          <Grid
            item
            xs={12}
            sm={3}
            md={3.5}
            order={modalImgOrder}
            maxHeight={modalImgMaxHeight}
            overflow="hidden"
          >
            <Img
              src={img}
              alt={title}
              width={200}
              height={200}
              sizes="100vw"
              style={imgStyle}
              priority
            />
          </Grid>
        )}
      </Grid>
    </ModalStyled>
  );
};

const iconStyle = { marginLeft: '8px' };
const modalStyle = {
  p: 0,
  maxWidth: '900px',
  '&:focus': {
    outline: 'none',
  },
};
const imgStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover' as const,
};

const imgWidth = 271;

const modalTextOrder = { xs: 2, sm: 1 };
const modalImgOrder = { xs: 1, sm: 2 };
const modalImgMaxHeight = { xs: '150px', sm: '100%' };
