import { ClientHeader } from 'components/Header';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { useGetClientInfo } from 'services/api/client/useGetClientInfo';

export const ClientLayout = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const { clientId } = router.query;
  const { clientInfo } = useGetClientInfo({});

  useEffect(() => {
    if (
      clientId !== clientInfo?._id &&
      typeof clientId === 'string' &&
      clientInfo?._id
    ) {
      router.replace({
        query: { ...router.query, clientId: clientInfo?._id },
      });
    }
  }, [clientId, clientInfo, router]);

  return <ClientHeader>{children}</ClientHeader>;
};
