import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { IncubatePlaybookQuiz } from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import {
  IncubatePlaybookQuizUpdateParams,
  IncubatePlaybookQuizUpdateProps,
  setIncubatePlaybookQuizRequest,
} from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

type Request = IncubatePlaybookQuizUpdateProps['params'];

export const useSetIncubatePlaybookQuiz = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps) => {
  const queryClient = useQueryClient();
  const { playbookInfo } = useGetPlaybookInfo({});

  const successHandler = () => {
    onSuccess?.();
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFnMutation } = useFetch<Request, void>({
    topic: socketTopics.QUIZ_UPDATE,
    request: setIncubatePlaybookQuizRequest,
    shouldNotifyOnRestError: false,
  });

  const { mutate } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const setQuiz = useCallback(
    (params: IncubatePlaybookQuizUpdateParams) => {
      const { total, correct, itemId } = params;
      queryClient.setQueriesData<IncubatePlaybookQuiz>(
        [incubatePlaybookQueryKeys.quiz(playbookInfo)],
        (old) => ({
          ...old,
          [itemId]: {
            correct: correct,
            total: total,
            attempts: (old?.[itemId]?.attempts ?? 0) + 1,
          },
        })
      );
      mutate({ defaultParams: playbookInfo, params });
    },
    [playbookInfo, mutate, queryClient]
  );

  return { setQuiz };
};
