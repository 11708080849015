import AddIcon from '@mui/icons-material/Add';
import { ButtonOutlined } from 'components/Inputs/Button';
import { useCommonTranslations } from 'translations/hooks';

export const AssignVideoButton = ({ onClick }: { onClick: () => void }) => {
  const { assignVideoLabel } = useCommonTranslations();

  return (
    <ButtonOutlined color="secondary" sx={buttonSx} onClick={onClick}>
      <AddIcon sx={iconSx} /> {assignVideoLabel}
    </ButtonOutlined>
  );
};

const buttonSx = { borderRadius: '35px' };
const iconSx = { mr: '10px' };
