import { atom, useAtom } from 'jotai';

const initialRoute = atom<string | null>(null);

export const useLastRoute = () => {
  const [lastRoute, setLastRoute] = useAtom(initialRoute);

  return {
    lastRoute,
    setLastRoute,
  };
};
