import { documentFileTypes, maxDocumentSize } from 'common/documentFileUpload';

export const validateDocumentType = (
  type: string,
  acceptedTypes: string[] = documentFileTypes
) => acceptedTypes.includes(type);

export const validateDocumentSize = (
  size: number,
  maxValue: number = maxDocumentSize
) => size <= maxValue;
