import { Box, Typography } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { thumbnailHeight, thumbnailWidth } from 'common/consts';
import { useCustomMediaQuery } from 'hooks';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookTaskConcept } from 'types/incubatePlaybook';
import { FeedbackModule } from '../FeedbackModule';
import { PlaybookFavoriteButton } from '../PlaybookFavoriteButton';
import { PlaybookVideo } from '../PlaybookVideo';
import VentureBuilderTaskWrapper from '../VentureBuilder/VentureBuilderTaskWrapper/VentureBuilderTaskWrapper';
import { TaskResources } from './TaskResources';
import { TaskWithVideoHeader } from './TaskWithVideoHeader';
import { TaskWrapper } from './TaskWrapper';

type PlaybookConceptTaskProps = {
  data: IncubatePlaybookTaskConcept;
  phaseId: string;
  phaseTitle: string;
  moduleId: string;
  moduleTitle: string;
  unitId: string;
  unitTitle: string;
};

export const PlaybookConceptTask = ({
  data,
  phaseId,
  phaseTitle,
  moduleId,
  moduleTitle,
  unitId,
  unitTitle,
}: PlaybookConceptTaskProps) => {
  const { lessThenSm } = useCustomMediaQuery();
  const { keyTakeawaysLabel } = useCommonTranslations();

  const trackData = {
    taskId: data._id,
    taskName: data.title,
    unitId,
    unitName: unitTitle,
    phaseId,
    phaseName: phaseTitle,
  };

  return (
    <Box display="flex" flexDirection="column" gap="30px">
      <TaskWrapper>
        <TaskWithVideoHeader
          {...{
            phaseId,
            moduleId,
            unitId,
            unitSlug: unitTitle,
            taskId: data._id,
            taskData: data,
          }}
        />

        <PlaybookVideo
          transcriptName={data.title}
          url={data?.video?.source?.embedUrl}
          video={data?.video}
          trackData={trackData}
        >
          <PlaybookFavoriteButton
            itemId={data._id}
            itemName={data.title}
            itemPhaseName={phaseTitle}
            itemModuleName={moduleTitle}
            itemUnitName={unitTitle}
            itemImageURL={
              data.video?.source?.posterImage?.concat(
                `_${thumbnailWidth}x${thumbnailHeight}`
              ) ?? ''
            }
            itemType="task"
            itemLink=""
            dataTestId="save-to-favorites-task"
            iconOnly={lessThenSm}
          />
        </PlaybookVideo>
      </TaskWrapper>

      {data.content && (
        <TaskWrapper>
          <Box pt="22px">
            <Typography fontSize={20} fontWeight={600} mb="30px">
              {keyTakeawaysLabel}
            </Typography>
            <PortableText
              value={data.content}
              components={{
                list: {
                  number: ({ children }) => <ol style={olStyle}>{children}</ol>,
                },
              }}
            />
          </Box>
        </TaskWrapper>
      )}

      <TaskResources
        resources={data.resources}
        moduleTitle={moduleTitle}
        phaseTitle={phaseTitle}
      />

      <VentureBuilderTaskWrapper sections={data?.ventureBuilders ?? []} />

      <FeedbackModule
        pageType="Concept Task"
        page={`${unitTitle} - ${data.title}`}
      />
    </Box>
  );
};

const olStyle = { lineHeight: '24px' };
