import { Box, Typography } from '@mui/material';
import Link from 'next/link';

type HelpDropdownItemProps = {
  title: string;
  subtitle: string;
  link: string;
  newTab?: boolean;
};

export const HelpDropdownItem = ({
  title,
  subtitle,
  link,
  newTab,
}: HelpDropdownItemProps) => {
  return (
    <Link href={link} style={linkStyle} target={newTab ? '_blank' : '_self'}>
      <Box py="6px" px={2}>
        <Typography variant="body1" color="secondary">
          {title}
        </Typography>
        <Typography fontSize={12} sx={subtitleStyle}>
          {subtitle}
        </Typography>
      </Box>
    </Link>
  );
};

const subtitleStyle = { opacity: 0.6 };
const linkStyle = { width: '100%' };
