import { Box, Typography } from '@mui/material';
import { mailToSupport } from 'common/consts';
import { Dropdown, HelpDropdown } from 'components/Inputs/Dropdown';
import { useLogout, usePermissions } from 'hooks';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import {
  useAccountTranslations,
  useCommonTranslations,
} from 'translations/hooks';
import { MenuThemeSwitcher } from './MenuThemeSwitcher';
import { ProfileMenuAvatar } from './ProfileMenuAvatar';

export const ProfileMenu = () => {
  const router = useRouter();
  const { logout } = useLogout();
  const { sessionData, isPlatformUser } = usePermissions();

  const profileHandler = useCallback(() => {
    const profileUrl = isPlatformUser
      ? '/profile'
      : `/client/${router.query.clientId}/profile`;

    window.open(profileUrl, '_blank', 'noreferrer');
  }, [isPlatformUser, router]);

  const isProfile = router.asPath.split('/').at(-1) === 'profile';

  const { myProfileLabel, logOutLabel } = useAccountTranslations();
  const { techSupportLabel, accountHelpLabel } = useCommonTranslations();

  const contentList = [
    {
      title: myProfileLabel,
      selected: isProfile,
      onClick: profileHandler,
    },
    { ...MenuThemeSwitcher() },
    {
      title: logOutLabel,
      onClick: logout,
      topDivider: true,
    },
  ];

  const helpDropdownList = [
    {
      title: techSupportLabel,
      subtitle: accountHelpLabel,
      link: mailToSupport,
    },
  ];

  return (
    <Box display="flex" alignItems="center">
      <Box mr={3.5}>
        <HelpDropdown list={helpDropdownList} />
      </Box>
      <Typography variant="body2" marginRight="10px">
        {sessionData?.user.name}
      </Typography>
      <Dropdown
        dropdownEl={<ProfileMenuAvatar active={isProfile} />}
        list={contentList}
      />
    </Box>
  );
};
