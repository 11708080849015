import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { IncubatePlaybookFavoriteIds } from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import {
  IncubatePlaybookFavoriteUpdateParams,
  IncubatePlaybookFavoriteUpdateProps,
  setIncubatePlaybookFavoriteRequest,
} from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

type Request = IncubatePlaybookFavoriteUpdateProps['params'];

export const useSetIncubatePlaybookFavorite = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps) => {
  const queryClient = useQueryClient();
  const { playbookInfo } = useGetPlaybookInfo({});

  const successHandler = () => {
    onSuccess?.();
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFnMutation } = useFetch<Request, void>({
    topic: socketTopics.FAVORITE_UPDATE,
    request: setIncubatePlaybookFavoriteRequest,
    shouldNotifyOnRestError: false,
  });

  const { mutate } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const setFavorite = useCallback(
    (params: IncubatePlaybookFavoriteUpdateParams) => {
      queryClient.invalidateQueries([
        incubatePlaybookQueryKeys.favoriteListBase(),
      ]);
      queryClient.setQueriesData<IncubatePlaybookFavoriteIds>(
        [incubatePlaybookQueryKeys.favoriteIds(playbookInfo)],
        (old) => ({ ...old, [params.itemId]: params.isFavorite })
      );
      mutate({ defaultParams: playbookInfo, params });
    },
    [playbookInfo, mutate, queryClient]
  );

  return { setFavorite };
};
