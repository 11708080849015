import { useQuery } from '@tanstack/react-query';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import {
  IncubatePlaybookResources,
  IncubatePlaybookResourcesSchema,
} from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import {
  IncubatePlaybookRecentsEnterpriseResources,
  getIncubatePlaybookEnterpriseResourcesRequest,
} from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

const schema = IncubatePlaybookResourcesSchema;

export type ResponseData = IncubatePlaybookResources;
type Request = IncubatePlaybookRecentsEnterpriseResources['params'];

export const useGetIncubatePlaybookEnterpriseResources = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<void, ResponseData>) => {
  const { playbookInfo, isPlaybookInfoValid } = useGetPlaybookInfo({});

  const successHandler = (result?: ResponseData) => {
    onSuccess?.(result);
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.ENTERPRISE_GROUP_RESOURCES,
    request: getIncubatePlaybookEnterpriseResourcesRequest,
    shouldNotifyOnRestError: false,
    schema,
  });

  const { data } = useQuery(
    [
      incubatePlaybookQueryKeys.enterpriseResources({
        clientId: playbookInfo.clientId,
        productId: playbookInfo.productId,
      }),
    ],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled: enabled && isPlaybookInfoValid,
    }
  );

  return { enterpriseResourcesData: data };
};
