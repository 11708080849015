import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from '@hello-pangea/dnd';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { ButtonText } from 'components/Inputs/Button';
import { Link } from 'components/Inputs/Link';
import { useState } from 'react';
import {
  useCommonTranslations,
  useProductTranslations,
} from 'translations/hooks';
import { Stakeholder, TopValueProposition } from 'types/ventureBuilder';
import { TopValuePropositionsListState } from '.';
import { TopValuePropositionsListItem } from './TopValuePropositionsListItem';

type TopValuePropositionsListProps = {
  isLoading: boolean;
  data: TopValueProposition[];
  stakeholders: Stakeholder[];
  collapseList: boolean;
  state: TopValuePropositionsListState;
  onAdd: (addNewShouldBeOnTopOfTheList: boolean) => () => void;
  onEdit: (topValueProposition: TopValueProposition) => void;
  onCancel: () => void;
  onDelete: (topValueProposition: TopValueProposition) => void;
  onSave: (topValueProposition: TopValueProposition) => boolean;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
};

const TopValuePropositionsList = ({
  isLoading,
  data,
  stakeholders,
  collapseList,
  state,
  onAdd,
  onEdit,
  onCancel,
  onDelete,
  onSave,
  onDragEnd,
}: TopValuePropositionsListProps) => {
  const [showAll, setShowAll] = useState(!collapseList);

  const setShowAllTrue = () => setShowAll(true);

  const topValuePropositionsToShow = showAll
    ? data
    : data.slice(0, COLLAPSED_LIST_LIMIT);
  const showAllButtonVisible = !showAll && data.length > COLLAPSED_LIST_LIMIT;

  const {
    topValuePropositionsLabel,
    valuePropositionNameLabel,
    stakeholderLabel,
    avgNPSLabel,
    plusNewValuePropositionLabel,
    avgPainScoreLabel,
  } = useProductTranslations();

  const { showAllLabel } = useCommonTranslations();

  return (
    <Box>
      <Typography variant="h6" fontWeight="600">
        {topValuePropositionsLabel}
      </Typography>
      <Box margin="10px 0" overflow="scroll">
        <Grid
          container
          sx={listHeaderStyle}
          flexDirection="row"
          minWidth="600px"
        >
          <Grid
            container
            item
            xs={9}
            columnGap={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container item xs={0.5} />
            <Grid container item xs={3}>
              <Typography fontWeight={500}>
                {valuePropositionNameLabel}
              </Typography>
            </Grid>

            <Grid container item xs={3}>
              <Typography fontWeight={500}>{stakeholderLabel}</Typography>
            </Grid>

            <Grid container item xs={2}>
              <Typography fontWeight={500}>{avgNPSLabel}</Typography>
            </Grid>

            <Grid container item xs={2.5}>
              <Typography fontWeight={500}>{avgPainScoreLabel}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && data.length === 0 && (
          <Box sx={loaderWrapperStyle}>
            <CircularProgress size={30} />
          </Box>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="hypothesis-drop-zone">
            {(provided) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {data.length ? (
                  topValuePropositionsToShow.map(
                    (topValueProposition, index) => (
                      <TopValuePropositionsListItem
                        key={topValueProposition._id}
                        index={index}
                        data={topValueProposition}
                        stakeholders={stakeholders}
                        state={state}
                        onEdit={onEdit}
                        onCancel={onCancel}
                        onDelete={onDelete}
                        onSave={onSave}
                      />
                    )
                  )
                ) : (
                  <EmptyState />
                )}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <ButtonText
          color="secondary"
          disabled={state.type !== VentureBuilderItemAction.VIEW}
          onClick={onAdd(!showAll && data.length >= COLLAPSED_LIST_LIMIT)}
        >
          {plusNewValuePropositionLabel}
        </ButtonText>
        {showAllButtonVisible && (
          <Link sx={linkStyle} color="secondary" onClick={setShowAllTrue}>
            {showAllLabel}
          </Link>
        )}
      </Box>
    </Box>
  );
};

const loaderWrapperStyle = { margin: '10px', textAlign: 'center' };
const linkStyle = { marginTop: '8px' };

const listHeaderStyle = {
  minHeight: '50px',
  borderBottom: '1px solid',
  borderColor: 'action.disabledBackground',
  padding: '12px 0 8px 10px',
};

const COLLAPSED_LIST_LIMIT = 3;

const EmptyState = () => {
  const { noAddedValuePropositionsLabel } = useProductTranslations();

  return (
    <Box my="8px">
      <Typography fontWeight={200}>{noAddedValuePropositionsLabel}</Typography>
    </Box>
  );
};

export default TopValuePropositionsList;
