import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography } from '@mui/material';
import { ClosingPlaybookModal } from 'components/Modal/IncubatePlaybook/ClosingPlaybookModal';
import { useModalState } from 'hooks';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import { getBaseLink } from 'utils/incubatePlaybook';
import { PlaybookButton } from '../PlaybookButton';

type PlaybookUnitNavigationButtonsProps = {
  currentTaskIndex: number;
  firstUnitTaskId: string;
  lastUnitTaskId: string;
  currentUnitId: string;
};

export const PlaybookUnitNavigationButtons = ({
  currentTaskIndex,
  firstUnitTaskId,
  lastUnitTaskId,
  currentUnitId,
}: PlaybookUnitNavigationButtonsProps) => {
  const router = useRouter();

  const { taskQuiz } = router.query;

  const { sanity, taskOrderList, firstTaskId, lastTaskId, allUnitData } =
    useIncubatePlaybookData();

  const { isModalOpen, openModal, closeModal } = useModalState();

  const clickHandler = useCallback(
    ({ unitSlug, taskSlug }: { unitSlug: string; taskSlug: string }) => {
      const baseLink = getBaseLink(router.asPath);
      router.push(`${baseLink}/${unitSlug}/task/${taskSlug}`);
    },
    [router]
  );

  const currentTaskId = taskOrderList[currentTaskIndex].taskId;
  const isLastTask = lastTaskId === currentTaskId;

  const previousClickHandler = useCallback(() => {
    const newTaskData = taskOrderList[currentTaskIndex - 1];
    if (newTaskData?.taskSlug) {
      clickHandler(newTaskData);
    }
  }, [clickHandler, currentTaskIndex, taskOrderList]);

  const goHomeHandler = useCallback(() => {
    const baseLink = getBaseLink(router.asPath);
    router.push(`${baseLink}/home`);
  }, [router]);

  const nextClickHandler = useCallback(() => {
    if (isLastTask) {
      !!sanity.popovers ? openModal() : goHomeHandler();

      return;
    }

    const newTaskData = taskOrderList[currentTaskIndex + 1];

    if (newTaskData?.taskSlug) {
      clickHandler(newTaskData);
    }
  }, [
    clickHandler,
    currentTaskIndex,
    goHomeHandler,
    isLastTask,
    openModal,
    sanity.popovers,
    taskOrderList,
  ]);

  const currentUnit = allUnitData[currentUnitId];
  const prevUnitTitle =
    currentTaskId === firstUnitTaskId ? currentUnit?.prevTitle : undefined;
  const nextUnitTitle =
    currentTaskId === lastUnitTaskId ? currentUnit?.nextTitle : undefined;

  const { nextLabel, previousLabel, finishLabel } = useCommonTranslations();

  if (taskQuiz === 'active') return null;

  return (
    <Box
      display="flex"
      alignItems="end"
      justifyContent="space-between"
      mb="200px"
      p="0 30px"
      mt="30px"
      flexDirection="row-reverse"
      flexWrap="wrap"
    >
      <ClosingPlaybookModal
        open={isModalOpen}
        popovers={sanity.popovers}
        onClose={closeModal}
        onBackToHome={goHomeHandler}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="end"
        width="50%"
        minWidth="130px"
      >
        {nextUnitTitle && <ButtonDesc title={nextUnitTitle} alignRight />}
        <Box>
          <PlaybookButton onClick={nextClickHandler}>
            {isLastTask ? finishLabel : nextLabel}
            <ArrowForwardIcon sx={arrowNextStyle} />
          </PlaybookButton>
        </Box>
      </Box>
      {firstTaskId !== currentTaskId && (
        <Box justifyContent="start" width="50%" minWidth="130px">
          {prevUnitTitle && <ButtonDesc title={prevUnitTitle} />}
          <Box mb={isLastTask ? '30px' : 0}>
            <PlaybookButton onClick={previousClickHandler}>
              <ArrowBackIcon sx={arrowPreviousStyle} />
              {previousLabel}
            </PlaybookButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ButtonDesc = ({
  title,
  alignRight = false,
}: {
  title: string;
  alignRight?: boolean;
}) => (
  <Typography
    fontSize={20}
    fontWeight={600}
    mb="5px"
    textAlign={alignRight ? 'right' : 'left'}
  >
    {title}
  </Typography>
);

const arrowPreviousStyle = { marginRight: '8px', marginLeft: '-5px' };
const arrowNextStyle = { marginLeft: '8px', marginRight: '-5px' };
