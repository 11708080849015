import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookInProgress } from 'types/incubatePlaybook';
import { getBaseLink } from 'utils/incubatePlaybook';
import { PlaybookButton } from './PlaybookButton';

export const PlaybookInProgress = ({
  data,
}: {
  data?: IncubatePlaybookInProgress;
}) => {
  const router = useRouter();
  const { taskOrderList } = useIncubatePlaybookData();

  const { continueLabel, inProgressLabel } = useCommonTranslations();

  const clickHandler = () => {
    const item = taskOrderList.find((x) => x.taskId === data?.taskId);
    const baseLink = getBaseLink(router.asPath);

    if (item) {
      router.push(`${baseLink}/${item.unitSlug}/task/${item.taskSlug}`);
    }
  };

  if (!data) return null;

  return (
    <Box>
      <Grid container alignItems="center" spacing={1}>
        <Grid
          item
          xs={6}
          md={12}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          pr={2}
        >
          <LocationOnOutlinedIcon sx={iconStyle} />
          <Typography variant="caption">{inProgressLabel}</Typography>
        </Grid>
        <Grid item xs={6} md={12} display="flex" justifyContent="flex-end">
          <PlaybookButton onClick={clickHandler}>
            {continueLabel}
          </PlaybookButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const iconStyle = { mr: 0.5, color: 'text.secondary' };
