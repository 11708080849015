import { Box, Grid, Typography } from '@mui/material';
import ConfirmDeleteModal from 'components/Modal/VentureBuilder/ConfirmDeleteModal';
import { EditMapModal } from 'components/Modal/VentureBuilder/EditMapModal';
import { ExpandedMapModal } from 'components/Modal/VentureBuilder/ExpandedMapModal';
import { useModalState } from 'hooks';
import { useState } from 'react';
import { useProductTranslations } from 'translations/hooks';
import { StakeholderMap } from 'types/ventureBuilder';
import { StakeholderMapItem } from './StakeholderMapItem';
import StakeholderMapUpload from './StakeholderMapUpload';

type StakeholderMapListProps = {
  stakeholderMaps: StakeholderMap[];
  onAdd: (file: File) => void;
  onDelete: (map: StakeholderMap) => void;
  onEdit: (map: StakeholderMap) => void;
  isUploading: boolean;
};
export const StakeholderMapList = ({
  stakeholderMaps,
  onAdd,
  onDelete,
  onEdit,
  isUploading,
}: StakeholderMapListProps) => {
  const {
    arr: [isEditOpen, openEdit, closeEdit],
  } = useModalState();
  const {
    arr: [isDeleteOpen, openDelete, closeDelete],
  } = useModalState();
  const {
    arr: [isExpandOpen, openExpand, closeExpand],
  } = useModalState();
  const [currentMap, setCurrentMap] = useState<StakeholderMap | null>(null);

  // events
  const editOpenHandler = (shMap: StakeholderMap) => {
    setCurrentMap(shMap);
    openEdit();
  };
  const deleteOpenHandler = (shMap: StakeholderMap) => {
    setCurrentMap(shMap);
    openDelete();
  };
  const expandOpenHandler = (shMap: StakeholderMap) => {
    setCurrentMap(shMap);
    openExpand();
  };
  const deleteCancel = () => {
    closeDelete();
    !isEditOpen && setCurrentMap(null);
  };
  const deleteProceed = () => {
    currentMap && onDelete(currentMap);
    closeDelete();
    isEditOpen && closeEdit();
    setCurrentMap(null);
  };
  const editClose = () => {
    closeEdit();
    setCurrentMap(null);
  };
  const editSave = (changedShMap: StakeholderMap) => {
    onEdit(changedShMap);
    closeEdit();
    setCurrentMap(null);
  };
  const expandClose = () => {
    closeExpand();
    !isEditOpen && setCurrentMap(null);
  };
  const uploadMap = (image: File) => {
    onAdd(image);
  };

  const { stakeholderMapLabel } = useProductTranslations();

  return (
    <>
      {isEditOpen && currentMap && (
        <EditMapModal
          stakeholderMap={currentMap}
          onClose={editClose}
          onSave={editSave}
          onDelete={deleteOpenHandler}
          onExpand={expandOpenHandler}
          isOpen={isEditOpen}
        />
      )}
      {isDeleteOpen && (
        <ConfirmDeleteModal
          name={currentMap?.name}
          cancel={deleteCancel}
          proceed={deleteProceed}
        />
      )}
      {isExpandOpen && currentMap && (
        <ExpandedMapModal
          stakeholderMap={currentMap}
          onClose={expandClose}
          isOpen={isExpandOpen}
        />
      )}
      <Box>
        <Typography variant="h6" fontWeight="600" marginBottom="8px">
          {stakeholderMapLabel}
        </Typography>
        <Grid container gap="12px">
          <>
            {stakeholderMaps.map((map) => (
              <Grid key={map._id} item>
                <StakeholderMapItem
                  stakeholderMap={map}
                  editHandler={editOpenHandler}
                  deleteHandler={deleteOpenHandler}
                  expandHandler={expandOpenHandler}
                />
              </Grid>
            ))}
          </>
          <Grid item>
            <StakeholderMapUpload
              onUpload={uploadMap}
              isLoading={isUploading}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
