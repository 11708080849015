import { Search as SearchIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputBase,
  SxProps,
} from '@mui/material';
import { useBrand, useCustomMediaQuery } from 'hooks';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getTextColor } from 'utils';

type SearchFieldProps = {
  label: string;
  loading?: boolean;
  debounceTime?: number;
  autoFocus?: boolean;
  onSearch: (value: string) => void;
  style: {
    width?: number | string;
    height?: number;
    borderRadius?: string;
    primaryBgColor?: string;
    borderColor?: string;
  };
  searchOnChange?: boolean;
  innerStyle: {
    mx?: number | string;
    position?: string;
  };
  searchLength?: number;
  collapseOnSm?: boolean;
  colorBasedOnTheme?: boolean;
  searchOnClear?: boolean;
};

export const SearchFieldBasic = ({
  label,
  loading,
  debounceTime = 500,
  autoFocus = true,
  onSearch,
  searchOnChange = true,
  style: { width, height, borderRadius, primaryBgColor, borderColor },
  innerStyle: { mx, position },
  searchLength = 3,
  collapseOnSm = true,
  colorBasedOnTheme = true,
  searchOnClear = false,
}: SearchFieldProps) => {
  const { query } = useRouter();
  const { theme } = useTheme();
  const { brand } = useBrand();
  const { colors } = brand;

  const { lessThenSm } = useCustomMediaQuery();

  const [value, setValue] = useState('');

  const searchTimeoutRef = useRef<number>();

  const [expanded, setExpanded] = useState<boolean>(
    (!(lessThenSm && collapseOnSm) || query?.searchingFor) as boolean
  );

  useEffect(
    () =>
      setExpanded(
        (!(lessThenSm && collapseOnSm) || query?.searchingFor) as boolean
      ),
    [collapseOnSm, lessThenSm, query?.searchingFor]
  );

  useEffect(() => {
    setValue((query?.searchingFor as string) ?? '');
  }, [query]);

  const searchHandlerEnter = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code == 'Enter' && value.length >= 3) {
        onSearch(value);
      }
    },
    [onSearch, value]
  );

  const searchHandlerChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      clearTimeout(searchTimeoutRef.current);
      const value = e.target.value;
      const trimmedValue = value.trim();
      setValue(value);

      searchTimeoutRef.current = window.setTimeout(() => {
        if (searchOnChange && trimmedValue.length >= searchLength) {
          onSearch(trimmedValue);
        }
      }, debounceTime);
    },
    [debounceTime, onSearch, searchLength, searchOnChange]
  );

  const bgColor = primaryBgColor;

  return (
    <Box
      sx={searchWrapperStyle(
        expanded,
        bgColor,
        borderColor,
        height,
        width,
        borderRadius,
        mx,
        position
      )}
    >
      <InputBase
        fullWidth
        placeholder={label}
        autoFocus={autoFocus}
        autoComplete="off"
        value={value}
        onKeyDownCapture={searchHandlerEnter}
        onChange={searchHandlerChange}
        inputProps={{ 'aria-label': 'search' }}
        startAdornment={
          <InputAdornment position="start">
            {loading ? (
              <CircularProgress size={22} />
            ) : (
              <SearchIcon
                sx={searchIconSx(
                  expanded,
                  colorBasedOnTheme,
                  colors?.brandColor,
                  theme
                )}
                onClick={() => lessThenSm && setExpanded(!expanded)}
              />
            )}
          </InputAdornment>
        }
        endAdornment={
          value &&
          lessThenSm && (
            <IconButton
              onClick={() => {
                setValue('');
                if (searchOnClear) onSearch('');
              }}
            >
              <CloseIcon sx={clearIconSx} />
            </IconButton>
          )
        }
      />
    </Box>
  );
};

const searchIconSx = (
  expanded: boolean,
  colorBasedOnTheme: boolean,
  color?: string,
  theme?: string
) => ({
  color: colorBasedOnTheme
    ? expanded
      ? 'text.secondary'
      : getTextColor(color, theme)
    : 'text.secondary',
});

const clearIconSx = { color: 'text.secondary' };

const searchWrapperStyle = (
  expanded: boolean,
  bgColor?: string,
  borderColor?: string,
  height?: number,
  width?: number | string,
  borderRadius?: string,
  mx?: number | string,
  position?: string
) =>
  ({
    mx: mx !== undefined ? mx : '10px',
    pl: '15px',
    pr: '7px',
    py: '9px',
    borderRadius: borderRadius,
    width: expanded ? width : '45px',
    height: height,
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    bgcolor: expanded ? bgColor : 'transparent',
    border: expanded ? '1px solid' : 0,
    borderColor,
    transition: 'width 0.75s cubic-bezier(0.000, 0.795, 0.000, 1.000)',
    color: 'text.secondary',
    position: position !== undefined ? position : 'static',
    right: 0,
  } as SxProps);
