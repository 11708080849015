import { TextField, TextFieldProps, Typography } from '@mui/material';
import { Theme } from 'common/enums';
import { useTheme } from 'next-themes';
import { forwardRef } from 'react';
import {
  NumberFormatBaseProps,
  NumberFormatValues,
  NumericFormat,
} from 'react-number-format';

export type InputCurrencyFieldOnChangeProps = {
  key: string;
  value?: number;
};

type NumberFormatCustomProps = {
  onChange: ({ key, value }: InputCurrencyFieldOnChangeProps) => void;
  name: string;
  disabled: boolean;
};

type InputCurrencyFieldProps = Partial<TextFieldProps> &
  NumberFormatCustomProps & {
    label: string;
    prefix: string;
  };

const NumberFormatCustom = forwardRef<
  NumberFormatBaseProps,
  NumberFormatCustomProps
>(function NumberFormatCustom({ onChange, ...other }, ref) {
  const handleChange = ({ floatValue }: NumberFormatValues) =>
    onChange({ key: other.name, value: floatValue });

  const inputStyle = {
    cursor: other?.disabled ? 'not-allowed' : 'text',
  };

  return (
    <NumericFormat
      {...other}
      onValueChange={handleChange}
      getInputRef={ref}
      thousandSeparator
      decimalScale={0}
      style={inputStyle}
    />
  );
});

export const InputCurrencyField = ({
  name,
  label,
  prefix,
  value,
  onChange,
  ...rest
}: InputCurrencyFieldProps) => {
  const { theme } = useTheme();

  const getPrefixColor = () => {
    const isDark = theme === Theme.dark;

    if (value) {
      return isDark ? 'common.white' : 'grey.900';
    }

    return 'grey.500';
  };

  return (
    <TextField
      color="secondary"
      label={label}
      name={name}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        startAdornment: !prefix ? null : (
          <Typography color={getPrefixColor()}>{prefix}</Typography>
        ),
      }}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};
