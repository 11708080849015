import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TreeView } from '@mui/lab';
import { Box, ListItemButton, Typography } from '@mui/material';
import { Tooltip } from 'components/Inputs/Tooltip';
import { MuiIcon } from 'components/MuiIcon';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { IncubatePlaybookPhase } from 'types/incubatePlaybook';
import { getBaseLink } from 'utils/incubatePlaybook';
import CustomTreeItem, { activeStyle } from './CustomTreeItem';

export const PlaybookSidebarPhases = ({ isOpen }: { isOpen: boolean }) => {
  const router = useRouter();

  const { sanity, allUnitData, taskOrderList } = useIncubatePlaybookData();
  const { phases } = sanity;

  const { taskSlug, phaseSlug } = router.query;

  const onPhaseClick = (slug: string) => () => {
    const baseLink = getBaseLink(router.asPath);
    router.push(`${baseLink}/phase/${slug}`);
  };

  let activePhaseId: string | undefined;
  let activeModuleId: string | undefined;
  let activeUnitId: string | undefined;
  if (taskSlug) {
    const task = taskOrderList.find((t) => t.taskSlug === taskSlug);
    if (task) {
      const { moduleId, phaseId } = allUnitData[task.unitId];
      activePhaseId = phaseId;
      activeModuleId = moduleId;
      activeUnitId = task.unitId;
    }
  } else if (phaseSlug) {
    activePhaseId = phases?.find(({ slug }) => slug.current === phaseSlug)?._id;
  }

  const [isTitleOverflowed, setIsTitleOverflow] = useState(false);

  const textElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!textElementRef.current) return;

    setIsTitleOverflow(
      textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth
    );
  }, []);

  return (
    <Box>
      {isOpen && (
        <Tooltip
          title={sanity.title}
          placement="bottom"
          disableHoverListener={!isTitleOverflowed}
        >
          <Typography
            ref={textElementRef}
            variant="body2"
            color="text.secondary"
            fontWeight={500}
            p="10px 15px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {sanity.title}
          </Typography>
        </Tooltip>
      )}
      <Box display="flex" justifyContent="center">
        <TreeView
          defaultCollapseIcon={<ExpandMore sx={iconSx} />}
          defaultExpandIcon={<ChevronRight sx={iconSx} />}
          sx={treeViewStyle}
        >
          {isOpen ? (
            phases?.map(
              ({ title, _id: phaseId, modules, icon, slug: _phaseSlug }) => (
                <Box display="flex" key={phaseId}>
                  <CustomTreeItem
                    sx={phasesStyle}
                    icon={icon}
                    nodeId={phaseId}
                    link={`phase/${_phaseSlug.current}`}
                    isActive={activePhaseId === phaseId}
                    label={PhaseTreeLabel({ title, icon })}
                    dataTestId="side-nav-phase"
                  >
                    {modules?.map(({ _id, title, units, slug }) => (
                      <CustomTreeItem
                        key={_id}
                        nodeId={_id}
                        label={title}
                        link={`phase/${_phaseSlug.current}#${slug.current}`}
                        isActive={activeModuleId === _id}
                        isModule
                        sx={modulesStyle}
                        dataTestId="side-nav-unit"
                      >
                        {units?.map(({ title, _id, tasks, slug: unitSlug }) => (
                          <CustomTreeItem
                            key={_id}
                            nodeId={_id}
                            link={`${unitSlug.current}/task/${tasks?.[0].slug.current}`}
                            isActive={activeUnitId === _id}
                            isUnit
                            label={title}
                            sx={unitsStyle}
                            expandable={false}
                            dataTestId="side-nav-task"
                          />
                        ))}
                      </CustomTreeItem>
                    ))}
                  </CustomTreeItem>
                </Box>
              )
            )
          ) : (
            <Box>
              {phases?.map(({ icon, _id, title, slug }) => (
                <Tooltip key={_id} title={title} placement="right">
                  <ListItemButton
                    key={_id}
                    onClick={onPhaseClick(slug.current)}
                    sx={collapsedButtonStyle(activePhaseId === _id)}
                  >
                    <Box
                      display="flex"
                      height="62px"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                    >
                      <MuiIcon iconName={icon} sx={iconStyle} />
                    </Box>
                  </ListItemButton>
                </Tooltip>
              ))}
            </Box>
          )}
        </TreeView>
      </Box>
    </Box>
  );
};

const PhaseTreeLabel = ({ title, icon }: Partial<IncubatePlaybookPhase>) => (
  <Box display="flex" alignContent="center">
    <MuiIcon iconName={icon} sx={iconStyle} />
    <Typography ml="5px">{title}</Typography>
  </Box>
);

const treeViewStyle = { padding: 0, flexGrow: 1, overflowY: 'auto' };
const phasesStyle = {
  width: '100%',
  '& .MuiSvgIcon-root': { cursor: 'pointer' },
};
const modulesStyle = { pl: '8px', width: '100%', fontSize: '14px' };
const unitsStyle = { pl: '6px', width: '100%', fontSize: '14px' };
const iconStyle = { color: 'action.active' };
const collapsedButtonStyle = (isActive?: boolean) => ({
  padding: '8px',
  ...(isActive ? activeStyle : {}),
});
const iconSx = { color: 'text.secondary' };
