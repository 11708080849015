import { Box, Paper, Typography } from '@mui/material';
import { useBrand } from 'hooks';
import Img from 'next/image';
import { CSSProperties } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useClient } from 'store/rq/clientData';

export const PlaybookFooter = () => {
  const { client } = useClient();
  const { brand } = useBrand();
  const title = client!.name;
  const { logos, footerCode } = brand;

  return (
    <Paper elevation={16} sx={footerPaperSx}>
      <Box
        maxWidth="100%"
        minHeight="168px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          overflow="hidden"
        >
          <Box marginTop="20px" width={200} height={50} textAlign="center">
            {logos?.largeLogo ? (
              <Img
                src={logos?.largeLogo}
                alt="logo"
                style={imgStyle}
                data-testid="playbook-footer-image"
                width={200}
                height={200}
                sizes="(max-width: 768px) 100vw,
                      (max-width: 1200px) 50vw,
                      33vw"
                priority
              />
            ) : (
              <Typography
                noWrap
                fontSize={40}
                data-testid="playbook-footer-text"
              >
                {title}
              </Typography>
            )}
          </Box>
          {footerCode && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              overflow="hidden"
              data-testid="playbook-footer-markdown"
            >
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                components={{ img: customImageRenderer }}
              >
                {footerCode}
              </ReactMarkdown>
            </Box>
          )}
          <Box marginY="12px" fontSize="14px">
            &copy; 2013 - {currentYear} Mach49 LLC
          </Box>
          <Box margin="0px" fontSize="12px">
            <a href="https://www.shiftplatform.ai/terms-of-use" target="_blank">
              Terms of Service
            </a>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

//This custom renderer changes how images are rendered
//we use it to constrain the max width of an image to its container
const customImageRenderer = ({
  alt,
  src,
  title,
}: {
  alt?: string;
  src?: string;
  title?: string;
}) =>
  src ? (
    <Img
      alt={alt ?? 'footer pic'}
      src={src}
      title={title}
      style={footerImageStyle}
      width={200}
      height={200}
      sizes="100vw"
      priority
    />
  ) : null;

const currentYear = new Date().getFullYear();

const footerImageStyle = { width: '100%', height: '100%', maxWidth: '40%' };

const footerPaperSx = { boxShadow: 'none' };

const imgStyle = {
  height: ' 100%',
  width: '100%',
  objectFit: 'contain',
} as CSSProperties;
