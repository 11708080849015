import { Grid, Typography } from '@mui/material';
import { useCommonTranslations } from 'translations/hooks';
import { PlaybookButton } from '../../PlaybookButton';

type PlaybookQuizResultsBarProps = {
  correct: number;
  total: number;
  onClick: () => void;
};

export const PlaybookQuizResultsBar = ({
  correct,
  total,
  onClick,
}: PlaybookQuizResultsBarProps) => {
  const { resultsLabel, retakeLabel, correctLabel } = useCommonTranslations();

  return (
    <Grid container sx={resultsBarSx} rowSpacing={2}>
      <Grid container item xs={4} justifyContent="flex-start">
        <Typography my="auto">{resultsLabel}</Typography>
      </Grid>
      <Grid container item xs={4} justifyContent="center">
        <Typography my="auto">{`${correct}/${total} ${correctLabel}`}</Typography>
      </Grid>
      <Grid container item xs={4} justifyContent="flex-end">
        <PlaybookButton onClick={onClick}>{retakeLabel}</PlaybookButton>
      </Grid>
    </Grid>
  );
};

const resultsBarSx = {
  minHeight: '82px',
  border: '1px solid',
  borderColor: 'border.main',
  borderRadius: '4px',
  px: '1.5rem',
  pt: '4px',
  pb: '20px',
  alignItems: 'center',
  justifyContent: 'space-between',
};
