import RecentIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import VentureBuilderIcon from '@mui/icons-material/BuildOutlined';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlineOutlined';
import { Box, ListItemButton, Typography } from '@mui/material';
import { Tooltip } from 'components/Inputs/Tooltip';
import { useProductFeatures } from 'hooks';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useGetUserOnProject } from 'services/api/client/project/useGetUserOnProject';
import { useFeatureFlags } from 'services/api/useFeatureFlags';
import { useSessionData } from 'services/api/useSessionData';
import { useCommonTranslations } from 'translations/hooks';
import { getProcessEnv } from 'utils';
import { getBaseLink } from 'utils/incubatePlaybook';
import { activeStyle } from './SidebarPhases/CustomTreeItem';

const processEnv = getProcessEnv();

export const PlaybookSidebarLinks = ({ isOpen }: { isOpen: boolean }) => {
  const router = useRouter();
  const { permissions } = useSessionData({});

  const routes = router.route.split('/');
  const currentPage = routes[7];

  const productFeatures = useProductFeatures();
  const { ff } = useFeatureFlags({});

  const isVentureBuilderEnabled =
    productFeatures.ventureBuilder &&
    processEnv.NEXT_PUBLIC_DISABLE_VENTURE_BUILDER !== 'true';

  const isAssignmentsEnabled =
    ff.playbookAssignments &&
    productFeatures.assignContent &&
    permissions.canViewAssignments &&
    permissions.canViewTeamProgress;

  const isTeamProgressEnabled =
    productFeatures.assignContent && !ff.playbookAssignments;

  const { isCurrentUserOnProject } = useGetUserOnProject();

  const clickHandler = useCallback(
    (link: string) => () => {
      if (link !== currentPage) {
        const baseLink = getBaseLink(router.asPath);
        router.push(`${baseLink}/${link}`);
      }
    },
    [currentPage, router]
  );

  const {
    homeLabel,
    favoritesLabel,
    recentsLabel,
    ventureBuilderLabel,
    assignmentsLabel,
    teamLabel,
  } = useCommonTranslations();

  const sidebarItems: SidebarItem[] = [
    {
      title: homeLabel,
      link: 'home',
      Icon: HomeIcon,
      show: true,
    },
    {
      title: assignmentsLabel,
      link: 'assignments',
      Icon: AssignmentOutlinedIcon,
      show: isAssignmentsEnabled,
    },
    {
      title: teamLabel,
      link: 'team',
      Icon: PeopleIcon,
      show: isTeamProgressEnabled && permissions.canViewTeamProgress,
    },
    {
      title: favoritesLabel,
      link: 'favorites',
      Icon: FavoriteIcon,
      show: isCurrentUserOnProject,
    },
    {
      title: recentsLabel,
      link: 'recents',
      Icon: RecentIcon,
      show: true,
    },
    {
      title: ventureBuilderLabel,
      link: 'venture-builder',
      Icon: VentureBuilderIcon,
      show: isVentureBuilderEnabled,
    },
  ];

  return (
    <Box mt="30px">
      {sidebarItems
        .filter(({ show }) => show)
        .map(({ title, Icon, link }) =>
          isOpen ? (
            <ListItemButton
              key={title}
              sx={link === currentPage ? activeStyle : {}}
              onClick={clickHandler(link)}
            >
              <Box
                display="flex"
                height="37px"
                alignItems="center"
                justifyContent={isOpen ? 'left' : 'center'}
                width={isOpen ? '100%' : '48px'}
                data-testid="buisness"
              >
                <Icon sx={iconStyle} />
                <Typography noWrap marginLeft="11px">
                  {title}
                </Typography>
              </Box>
            </ListItemButton>
          ) : (
            <Tooltip key={title} title={title} placement="right">
              <ListItemButton
                key={title}
                sx={link === currentPage ? activeStyle : {}}
                onClick={clickHandler(link)}
              >
                <Box
                  display="flex"
                  height="62px"
                  alignItems="center"
                  justifyContent={isOpen ? 'left' : 'center'}
                  width={isOpen ? '100%' : '48px'}
                  data-testid="buisness"
                >
                  <Icon sx={iconStyle} />
                </Box>
              </ListItemButton>
            </Tooltip>
          )
        )}
    </Box>
  );
};

const iconStyle = { color: 'action.active' };

type SidebarItem = {
  title: string;
  link: string;
  Icon: typeof HomeIcon;
  show?: boolean;
};
