import { SocketContext } from 'store/context/SocketProvider';
import { useContext } from 'react';

const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context)
    throw new Error('Socket context must be used inside a provider');

  return context;
};

export default useSocket;
