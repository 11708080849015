import { VentureBuilderItemAction } from 'common/enums';
import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { StakeholderMap, VentureBuilderChange } from 'types/ventureBuilder';
import { useGetStakeholderMapData } from './useGetStakeholderMapData';
import { useUpdateStakeholderMapData } from './useUpdateStakeholderMapData';

export const useStakeholderMapData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.STAKEHOLDER_MAP,
  };

  const {
    stakeholderMaps,
    isLoading: isGetting,
    setStakeholderMaps,
  } = useGetStakeholderMapData({ defaultParams });

  const { isLoading: isPosting, updateStakeholderMapData } =
    useUpdateStakeholderMapData({});
  const updateStakeholderMaps = ({
    payload,
  }: {
    payload: VentureBuilderChange<StakeholderMap>;
  }) => {
    const oldMaps = [...stakeholderMaps];
    const newStakeholderMaps = [...stakeholderMaps];

    switch (payload.action) {
      case VentureBuilderItemAction.ADD:
        newStakeholderMaps.push(payload.data);
      case VentureBuilderItemAction.EDIT:
        const index = newStakeholderMaps.findIndex(
          (map) => map._id === payload.data._id
        );
        newStakeholderMaps.splice(index, 1, payload.data);
      case VentureBuilderItemAction.DELETE:
        newStakeholderMaps.filter(({ _id }) => _id !== payload.data._id);
    }

    setStakeholderMaps(newStakeholderMaps);

    updateStakeholderMapData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setStakeholderMaps(oldMaps);
        },
      }
    );
  };

  return {
    stakeholderMaps,
    updateStakeholderMaps,
    isGetting,
    isPosting,
  };
};
