import { useEffect, useRef } from 'react';

export const useEffectOnce = (fn: () => void) => {
  const initRef = useRef<boolean>(false);

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      fn();
    }
  }, [fn]);
};
