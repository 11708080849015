import { useQueryClient } from '@tanstack/react-query';
import { incubatePlaybookQueryKeys } from 'services/api/product/incubatePlaybook/incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { IncubatePlaybook } from 'types/incubatePlaybook';

export const useIncubatePlaybookData = () => {
  const queryClient = useQueryClient();
  const { playbookInfo } = useGetPlaybookInfo({});

  const data = queryClient.getQueryData<IncubatePlaybook>([
    incubatePlaybookQueryKeys.data(playbookInfo),
  ]);

  return data!;
};
