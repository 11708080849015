import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import { Box, Paper, Typography } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { Theme, incubatePlaybookTaskEnum } from 'common/enums';
import { useCustomMediaQuery } from 'hooks';
import { useTheme } from 'next-themes';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookTaskWithVideo } from 'types/incubatePlaybook';
import { convertMilliseconds } from 'utils';
import { millisecondsIn } from 'utils/time';
import { PlaybookVideo } from '../PlaybookVideo';
import ActivityInstructions from '../Tasks/ActivityInstructions';

type TaskPreviewProps = {
  task: IncubatePlaybookTaskWithVideo | null;
  selected: boolean;
  setPreviewTask: Dispatch<
    SetStateAction<IncubatePlaybookTaskWithVideo | null>
  >;
};

export const TaskPreview = ({
  task,
  selected,
  setPreviewTask,
}: TaskPreviewProps) => {
  const { theme } = useTheme();
  const { lessThenSm } = useCustomMediaQuery();
  const {
    minuteLabel,
    backLabel,
    capitalSelectedLabel,
    notSelectedLabel,
    summaryLabel,
  } = useCommonTranslations();

  const videoTime = task?.video?.source?.duration
    ? task.video?.source?.duration * 1000
    : 0;

  const videoTimeLabel = useMemo(
    () =>
      videoTime
        ? minuteLabel(
            convertMilliseconds(videoTime, millisecondsIn.minutes, 'up')
          )
        : '',
    [minuteLabel, videoTime]
  );

  if (!task) return <></>;

  return (
    <Paper
      sx={paperSx(theme as Theme, lessThenSm)}
      elevation={lessThenSm ? 1 : 0}
    >
      <>
        <Box p="1rem 1.5rem">
          <Typography
            variant="body2"
            alignItems="center"
            display="flex"
            width="fit-content"
            sx={cursorPointerSx}
            onClick={() => setPreviewTask(null)}
          >
            <KeyboardArrowLeftRoundedIcon /> {backLabel}
          </Typography>
        </Box>
        <Box
          p="1rem 2.5rem"
          height="calc(100% - 3.5rem)"
          overflow="scroll"
          bgcolor={taskPreviewBgColor(theme as Theme, lessThenSm)}
        >
          <Typography variant="h6" fontWeight={600} gutterBottom>
            {task.title}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {videoTimeLabel}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              fontStyle="italic"
              gutterBottom
            >
              {selected ? capitalSelectedLabel : notSelectedLabel}
            </Typography>
          </Box>
          <Box borderRadius="15px" overflow="hidden">
            <PlaybookVideo
              transcriptName={task.title}
              video={task.video}
              url={task?.video?.source?.embedUrl}
              hideSpeakerAndTranscript
            />
          </Box>
          <Typography variant="body1" mt={2} gutterBottom>
            {summaryLabel}
          </Typography>
          {task._type === incubatePlaybookTaskEnum.taskConcept && (
            <PortableText
              value={task.content}
              components={{
                list: {
                  number: ({ children }) => <ol style={olStyle}>{children}</ol>,
                },
              }}
            />
          )}

          {task._type === incubatePlaybookTaskEnum.taskActivity && (
            <ActivityInstructions
              instructions={task.instructions}
              taskVariant={false}
            />
          )}
        </Box>
      </>
    </Paper>
  );
};

const olStyle = { lineHeight: '24px', fontSize: '0.875rem' };

const paperSx = (theme: Theme, lessThenSm: boolean) => ({
  position: 'absolute',
  height: '100%',
  width: lessThenSm ? '100%' : 'calc(100% - 8px)',
  zIndex: 5,
  top: lessThenSm ? 30 : 0,
  left: 0,
  bgcolor: theme === Theme.dark ? 'background.paper' : 'background.default',
  ml: lessThenSm ? 0 : '8px',
});

const cursorPointerSx = { cursor: 'pointer' };

const taskPreviewBgColor = (theme: Theme, lessThenSm: boolean) =>
  lessThenSm
    ? 'background.paper'
    : theme === Theme.dark
    ? 'background.paper'
    : 'background.default';
