import Excel from 'assets/Resources/Thumbnails/Excel.png';
import Pdf from 'assets/Resources/Thumbnails/PDF.png';
import PowerPoint from 'assets/Resources/Thumbnails/PowerPoint.png';
import Word from 'assets/Resources/Thumbnails/Word.png';
import { EnterpriseResourceTypes } from 'common/enums';

export const enterpriseResourceThumbnailMap = {
  [EnterpriseResourceTypes.PDF]: Pdf,
  [EnterpriseResourceTypes.WORD]: Word,
  [EnterpriseResourceTypes.EXCEL]: Excel,
  [EnterpriseResourceTypes.POWERPOINT]: PowerPoint,
};
