import { Box, Divider, Paper, Typography } from '@mui/material';
import { incubatePlaybookTaskEnum } from 'common/enums';
import { useMemo } from 'react';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookSanity,
  PlaybookVideo as PlaybookVideoType,
} from 'types/incubatePlaybook';
import { useTimeUtils } from 'utils/time';
import { PlaybookQuizForm } from '.';
import { PlaybookVideo } from '../../PlaybookVideo';
import { TaskContactExpert } from '../TaskContactExpert';
import { ExitButton } from './ExitButton';
import { PlaybookQuizHeading } from './Heading';
import { PlaybookQuizQuestionTitle } from './QuestionTitle';
import { PlaybookQuizQuestionWrapper } from './QuestionWrapper';
import { PlaybookQuizResultsBar } from './ResultsBar';

type PlaybookQuizReviewProps = {
  form: PlaybookQuizForm;
  unitId: string;
  onExitQuiz: () => void;
  onRetakeQuiz: () => void;
};

export const PlaybookQuizReview = ({
  form,
  unitId,
  onExitQuiz,
  onRetakeQuiz,
}: PlaybookQuizReviewProps) => {
  const { sanity, allUnitData } = useIncubatePlaybookData();

  const data = useMemo(() => {
    const taskData = allUnitData[unitId].tasks?.reduce<
      Record<
        string,
        { taskTitle: string; video: PlaybookVideoType['source'] | undefined }
      >
    >((a, b) => {
      if (b._type === incubatePlaybookTaskEnum.taskConcept) {
        return {
          ...a,
          [b._id]: {
            taskTitle: b.title,
            video: b.video?.source,
          },
        };
      }

      return a;
    }, {});
    const uniqueVideos: Record<string, boolean> = {};

    return form
      .filter(({ answer }) => answer?.incorrect)
      .map((x) => {
        const item = taskData?.[x.taskConcept?._ref ?? ''];
        const taskTitle = item?.taskTitle ?? '';
        const videoItem = item?.video;
        const videoId = videoItem?.embedUrl ?? '';
        const video = uniqueVideos[videoId] ? undefined : videoItem;
        uniqueVideos[videoId] = true;

        return { ...x, video, taskTitle };
      });
  }, [allUnitData, form, unitId]);

  const allCorrect = data.length === 0;

  return (
    <Paper elevation={1}>
      <Box maxWidth="917px">
        <Box p="20px" pb="30px" borderRadius="4px">
          <PlaybookQuizHeading
            totalQuestions={form.length}
            allCorrect={allCorrect}
            onClick={onExitQuiz}
          />
          <PlaybookQuizResultsBar
            correct={form.length - data.length}
            total={form.length}
            onClick={onRetakeQuiz}
          />
        </Box>
        {allCorrect && (
          <Box pl="20px" pb="30px">
            <ExitButton onClick={onExitQuiz} />
          </Box>
        )}
        {!allCorrect && (
          <Box px="20px" pt="50px" pb="30px">
            <Box px="20px">
              <ReviewHeading />
              {data.map((q, i) => (
                <Box
                  key={i}
                  p="30px"
                  mb="30px"
                  border="1px solid"
                  borderColor="border.main"
                >
                  <PlaybookQuizQuestionTitle
                    order={i + 1}
                    content={q.content}
                  />
                  <Box my="30px">
                    <PlaybookQuizQuestionWrapper
                      items={q.questionOptions}
                      answer={q.answer}
                    />
                  </Box>
                  <ReviewVideo video={q.video} title={q.taskTitle} />
                </Box>
              ))}
            </Box>
            <ReviewFooter
              helpLink={sanity?.contactUrl}
              onExitQuiz={onExitQuiz}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

const ReviewHeading = () => {
  const { reviewLabel, reviewMissedQuestionsAndRevisitContentLabel } =
    useCommonTranslations();

  return (
    <Box mb="30px">
      <Typography mb="10px" fontSize={20} fontWeight={600}>
        {reviewLabel}
      </Typography>
      <Typography>{reviewMissedQuestionsAndRevisitContentLabel}</Typography>
    </Box>
  );
};

const ReviewVideo = ({
  video,
  title,
}: {
  video?: PlaybookVideoType['source'];
  title: string;
}) => {
  const { formatSecondsToMinutes } = useTimeUtils();
  if (!video) return null;

  return (
    <>
      <Divider />
      <Box my="30px">
        <Typography mb="10px" fontWeight={600} fontSize={20}>
          {title}
        </Typography>
        <Typography fontSize={14}>
          {formatSecondsToMinutes(video.duration)}
        </Typography>
      </Box>
      <PlaybookVideo url={video?.embedUrl} />
    </>
  );
};

const ReviewFooter = ({
  helpLink,
  onExitQuiz,
}: Pick<PlaybookQuizReviewProps, 'onExitQuiz'> & {
  helpLink: IncubatePlaybookSanity['contactUrl'];
}) => (
  <Box px="20px">
    <TaskContactExpert helpLink={helpLink} />
    <ExitButton onClick={onExitQuiz} />
  </Box>
);
