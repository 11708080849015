import { Box, Divider, ListItemButton, Typography } from '@mui/material';
import { ProductType } from 'common/enums';
import { setLocalStoragePlaybookInfoCache } from 'common/localStorage';
import { ListItemLink } from 'components/Header/Projects/Playbook/PlaybookProfile';
import { useCallback } from 'react';
import { useGetClientProject } from 'services/api/client/project/useGetClientProject';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useFeatureFlags } from 'services/api/useFeatureFlags';
import { useProductTranslations } from 'translations/hooks';
import { getProcessEnv } from 'utils';

const {
  NEXT_PUBLIC_CHAT_URL: chatLink,
  NEXT_PUBLIC_PERSONAS_URL: personasLink,
} = getProcessEnv();

export const PlaybookSidebarProducts = ({ isOpen }: { isOpen: boolean }) => {
  const { playbookInfo } = useGetPlaybookInfo({});
  const { clientId, projectId, productId } = playbookInfo;
  const { ff } = useFeatureFlags({});

  const { data } = useGetClientProject({
    defaultParams: {
      clientId,
      projectId,
    },
  });

  const productClickHandler = useCallback(
    (playbookSlug: string, productID: string, productType: ProductType) =>
      () => {
        if (productType !== ProductType.chat) {
          setLocalStoragePlaybookInfoCache({
            playbookSlug,
            playbookInfo: {
              clientId,
              projectId,
              productId: productID,
              productType,
            },
            timestamp: new Date(),
          });
        }
      },
    [clientId, projectId]
  );

  const { myProductsLabel } = useProductTranslations();

  if (!isOpen || !data) return null;

  return (
    <Box>
      <Divider sx={dividerStyle} />
      <Typography
        fontSize={14}
        fontWeight={500}
        sx={opacityStyle}
        mb="22px"
        px="16px"
      >
        {myProductsLabel}
      </Typography>
      <Box display="flex" flexDirection="column" gap="12px">
        {data.products
          .filter(({ type }) => !(type === ProductType.chat && !ff.chatbot))
          .map(({ title, productId: productID, slugId, type }) => (
            <Box key={productID} display="flex" alignItems="center">
              {productID === productId ? (
                <Box px="16px">
                  <Typography>{title}</Typography>
                </Box>
              ) : (
                <ListItemButton sx={listItemStyle}>
                  <ListItemLink
                    title={title}
                    link={
                      type === ProductType.chat
                        ? chatLink
                        : type === ProductType.personas
                        ? personasLink
                        : `/playbook/${slugId}/home`
                    }
                    onClick={productClickHandler(slugId, productID, type)}
                  />
                </ListItemButton>
              )}
            </Box>
          ))}
      </Box>
      <Divider sx={dividerStyle} />
    </Box>
  );
};

const opacityStyle = { opacity: 0.6 };
const dividerStyle = { my: '10px' };
const listItemStyle = { p: 0 };
