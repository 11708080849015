import { useQuery } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import {
  PlaybookLastInfoData,
  PlaybookLastInfoDataSchema,
} from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import { getLastPlaybookInfoRequest } from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';

const schema = PlaybookLastInfoDataSchema;

type ResponseData = PlaybookLastInfoData;
type Request = void;

export const useGetLastPlaybookInfo = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled,
}: SocketHandlerProps<Request, ResponseData>) => {
  const { notify } = useNotifications();

  const successHandler = (result?: ResponseData) => {
    onSuccess?.(result);
  };
  const failureHandler = (message_key?: NotificationType) => {
    if (message_key && message_key !== NotificationType.NOT_FOUND) {
      notify(message_key);
    }
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.PLAYBOOK_INFO,
    request: getLastPlaybookInfoRequest,
    schema,
  });

  useQuery([incubatePlaybookQueryKeys.lastInfo()], fetchFn, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
    retry: 1,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  });
};
