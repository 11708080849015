import { PortableText } from '@portabletext/react';
import { SanityPopoverTypeEnum } from 'common/enums';
import { useCommonTranslations } from 'translations/hooks';
import { SanityPopover } from 'types/incubatePlaybook';
import { PlaybookModal } from './PlaybookModal';

type ClosingPlaybookModalProps = {
  open: boolean;
  popovers?: SanityPopover[];
  onClose: () => void;
  onBackToHome: () => void;
};

export const ClosingPlaybookModal = ({
  open,
  popovers,
  onClose,
  onBackToHome,
}: ClosingPlaybookModalProps) => {
  const data = popovers?.find(
    ({ type, activated }) => activated && type === SanityPopoverTypeEnum.closing
  );

  const isOpen = open;

  const { congratulationsLabel, backToHomeLabel } = useCommonTranslations();

  if (!data || !isOpen) return null;

  return (
    <PlaybookModal
      title={congratulationsLabel}
      open={isOpen}
      onClose={onClose}
      onClick={onBackToHome}
      btnText={backToHomeLabel}
      image={data.image}
    >
      {data.content && <PortableText value={data.content} />}
    </PlaybookModal>
  );
};
