import { useQuery } from '@tanstack/react-query';
import { useTableProps } from 'hooks';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { formatTableParams, useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { ClientUserSchema } from 'types/User';
import { defaultListResponse } from 'types/api';
import { z } from 'zod';
import { ProjectUsersListRequestProps, getProjectUsersListRequest } from '.';
import { projectQueryKeys } from './projectQueryKeys';

const schema = defaultListResponse(ClientUserSchema);

type ResponseData = z.infer<typeof schema>;

type Request = ProjectUsersListRequestProps['params'];

export const useGetAllUserOnProject = () => {
  const { playbookInfo, isPlaybookInfoValid } = useGetPlaybookInfo({});
  const { clientId, projectId } = playbookInfo;

  const { sort } = useTableProps({});

  const params = formatTableParams({
    sort: sort.value,
    pageSize: 100,
    limit: 100,
    offset: 0,
  });

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.PROJECT_USERS_LIST,
    request: getProjectUsersListRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [
      projectQueryKeys.listUsers({
        clientId,
        projectId,
        params,
      }),
    ],
    fetchFn,
    {
      enabled: isPlaybookInfoValid,
    }
  );

  return {
    data,
    isLoading,
  };
};
