import { PAGE_SIZE, TableSortModel } from 'components/Table';

type FormatTableParamsProps = {
  page?: number;
  search?: string;
  sort: TableSortModel;
  sortMap?: Record<string, string>;
  filterBy?: string;
  pageSize?: number;
  limit?: number;
  offset?: number;
  forContextSwitching?: boolean;
  status?: string;
};

export const formatTableParams = ({
  page = 0,
  search,
  sort,
  sortMap,
  filterBy = 'name,email',
  pageSize = PAGE_SIZE,
  limit,
  offset,
  status,
}: FormatTableParamsProps) => {
  const searchFilter = search
    ? {
        filterBy,
        filterValue: search,
      }
    : {};

  const statusFilter =
    status && status !== 'all'
      ? {
          filterBy: 'status',
          filterValue: status,
        }
      : {};

  return {
    limit: limit ?? pageSize,
    offset: offset ?? page * pageSize,
    sortBy: sort
      .map(({ field: sortField }) => sortMap?.[sortField] ?? sortField)
      .join(','),
    sortOrder: sort.map(({ sort: sortOrder }) => sortOrder || '').join(','),
    ...searchFilter,
    ...statusFilter,
  };
};
