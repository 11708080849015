import { Container } from '@mui/material';
import { ReactNode } from 'react';

export const PlaybookContentWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <Container>{children}</Container>;
};
