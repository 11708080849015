import { ClientRoles, PlatformRoles } from 'common/userConsts';
import { InitialSessionData } from 'types/User';

export const getAdaptedRoles = (sessionData?: InitialSessionData) => {
  const userHasRole = (role: PlatformRoles | ClientRoles) =>
    !!sessionData?.roles?.includes(role);

  // all
  const isPlatformUser = !!sessionData?.roles?.some((role) =>
    Object.keys(PlatformRoles).includes(role)
  );
  const isClientUser = !!sessionData?.roles.some((role) =>
    Object.keys(ClientRoles).includes(role)
  );

  // platform
  const isUserPlatformSuperAdmin = userHasRole(
    PlatformRoles.platform_super_admin
  );
  const isUserPlatformAdmin = userHasRole(PlatformRoles.platform_admin);
  const isUserPlatformAnalyst = userHasRole(PlatformRoles.platform_analyst);

  // client
  const isUserClientAdmin = userHasRole(ClientRoles.client_admin);
  const isUserClientUser = userHasRole(ClientRoles.client_user);

  // custom
  const isAdminUser = isPlatformUser || isUserClientAdmin;

  return {
    isPlatformUser,
    isClientUser,
    isAdminUser,
    isUserPlatformSuperAdmin,
    isUserPlatformAdmin,
    isUserPlatformAnalyst,
    isUserClientAdmin,
    isUserClientUser,
  };
};
