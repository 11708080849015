import VentureBuilderIcon from '@mui/icons-material/BuildOutlined';
import { Box, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { SectionModes } from 'common/ventureBuilderConsts';
import { ReactNode, useMemo, useState } from 'react';
import { useWhoMattersMostData } from 'services/api/product/ventureBuilder/whoMattersMost/useWhoMattersMostData';
import { useProductTranslations } from 'translations/hooks';
import { Stakeholder } from 'types/ventureBuilder';
import StakeholdersList from './StakeholdersList';
import { TEMP_ID } from './StakeholdersListItem';

export type WhoMattersMostState =
  | {
      type: VentureBuilderItemAction.VIEW;
    }
  | {
      type: VentureBuilderItemAction.EDIT;
      data: Stakeholder;
      index: number;
    }
  | {
      type: VentureBuilderItemAction.ADD;
      data: Stakeholder;
      index: number;
    };

export const WhoMattersMost = ({ mode }: { mode: SectionModes }) => {
  const { notify } = useNotifications();

  const { stakeholders, isGetting, updateWhoMattersMost } =
    useWhoMattersMostData();

  const [state, setState] = useState<WhoMattersMostState>({
    type: VentureBuilderItemAction.VIEW,
  });

  const stakeholdersLocal = useMemo(() => {
    const tmpStakeholders = [...stakeholders];

    if (state.type === VentureBuilderItemAction.VIEW) return tmpStakeholders;

    const currentIndex =
      state.index > tmpStakeholders.length
        ? tmpStakeholders.length
        : state.index;

    if (state.type === VentureBuilderItemAction.ADD) {
      tmpStakeholders.splice(currentIndex, 0, state.data);

      return tmpStakeholders;
    }

    if (state.type === VentureBuilderItemAction.EDIT) {
      const isDeleted = !tmpStakeholders.some(
        (sh) => sh._id === state?.data?._id
      );
      if (isDeleted)
        setState(
          (prevState) =>
            ({
              ...prevState,
              type: VentureBuilderItemAction.ADD,
            } as WhoMattersMostState)
        );
      tmpStakeholders.splice(currentIndex, 1, state.data);

      return tmpStakeholders;
    }

    return tmpStakeholders;
  }, [stakeholders, state]);

  // events
  const handleAdd = (addNewShouldBeOnTopOfTheList: boolean) => () => {
    setState({
      type: VentureBuilderItemAction.ADD,
      data: { _id: TEMP_ID, name: '' },
      index: addNewShouldBeOnTopOfTheList ? 0 : stakeholdersLocal.length,
    });
  };

  const handleEdit = (stakeholder: Stakeholder) => {
    setState({
      type: VentureBuilderItemAction.EDIT,
      data: stakeholder,
      index: stakeholdersLocal.findIndex((sh) => sh._id === stakeholder._id),
    });
  };

  const handleCancel = () => {
    setState({ type: VentureBuilderItemAction.VIEW });
  };

  const handleDelete = (stakeholder: Stakeholder) => {
    const newStakeholders = stakeholders.filter(
      (sh) => sh._id !== stakeholder._id
    );

    updateWhoMattersMost({
      payload: { action: VentureBuilderItemAction.DELETE, data: stakeholder },
      newStakeholders,
    });
  };

  const stakeholderHasError = (stakeholder: Stakeholder): boolean => {
    if (state.type === VentureBuilderItemAction.VIEW) return true;

    const clearString = (str: string) => str.trim()?.toLocaleLowerCase();

    let nameExistOnTheSameIndex = false;
    const doesNameExists = stakeholders.some((sh, i) => {
      const isNameEqual =
        clearString(sh.name) === clearString(stakeholder.name);
      nameExistOnTheSameIndex = isNameEqual && state.index === i;

      return isNameEqual;
    });

    if (doesNameExists && !nameExistOnTheSameIndex) {
      notify(NotificationType.STAKEHOLDER_NAME_TAKEN);
    }

    if (doesNameExists && nameExistOnTheSameIndex) {
      setState({ type: VentureBuilderItemAction.VIEW });
    }

    return doesNameExists;
  };

  const handleSave = (stakeholder: Stakeholder) => {
    if (stakeholderHasError(stakeholder)) return false;

    const newStakeholders = [...stakeholders];
    if (state.type === VentureBuilderItemAction.ADD) {
      newStakeholders.push(stakeholder);
    } else if (state.type === VentureBuilderItemAction.EDIT) {
      newStakeholders.splice(state.index, 1, stakeholder);
    }

    updateWhoMattersMost({
      payload: { action: state.type, data: stakeholder },
      newStakeholders,
    });

    setState({ type: VentureBuilderItemAction.VIEW });

    return true;
  };

  const stakeholdersList = (
    <StakeholdersList
      isLoading={isGetting}
      collapseList={mode === SectionModes.TASK}
      stakeholders={stakeholdersLocal}
      state={state}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onCancel={handleCancel}
      onDelete={handleDelete}
      onSave={handleSave}
    />
  );

  return mode === SectionModes.TASK ? (
    <SectionTaskWrapper>{stakeholdersList}</SectionTaskWrapper>
  ) : (
    <Box sx={boxStyle}>{stakeholdersList}</Box>
  );
};

const boxStyle = {
  margin: '32px 0',
};

const SectionTaskWrapper = ({ children }: { children: ReactNode }) => {
  const {
    objectiveDocumentSectionInVBLabel,
    whoMattersMostLabel,
    addNewStakeholdersBelowIntoYourVBLabel,
  } = useProductTranslations();

  return (
    <>
      <Box display="flex" alignItems="center" gap="10px">
        <VentureBuilderIcon />
        <Typography fontSize="20px">
          {objectiveDocumentSectionInVBLabel(whoMattersMostLabel)}
        </Typography>
      </Box>
      <Typography margin="10px 0 30px 0">
        {addNewStakeholdersBelowIntoYourVBLabel}
      </Typography>
      {children}
    </>
  );
};
