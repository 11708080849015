import TimerIcon from '@mui/icons-material/TimerOutlined';
import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useCommonTranslations } from 'translations/hooks';
import { useTimeUtils } from 'utils/time';

type TimeEstimateComponentProps = {
  value?: number | string | null;
  variant?: Variant;
  justValue?: boolean;
  hideClock?: boolean;
};

export const TimeEstimateComponent = ({
  variant = 'body2',
  value,
  justValue,
  hideClock,
}: TimeEstimateComponentProps) => {
  const { suggestedTimeFrameLabel } = useCommonTranslations();
  const { formatMinutesToReadableTime } = useTimeUtils();

  const getTime = (value: string | number) =>
    typeof value === 'string' ? value : formatMinutesToReadableTime(value);

  return (
    <>
      {value && (
        <Box display="flex" gap="8px" sx={hideClock ? boxStyle : {}}>
          {!hideClock && <TimerIcon />}
          <Typography variant={variant} my="auto">
            {`${justValue ? '' : `${suggestedTimeFrameLabel}: `}${getTime(
              value
            )}`}
          </Typography>
        </Box>
      )}
    </>
  );
};

const boxStyle = { color: 'text.secondary' };
