import { ascending, descending } from 'd3';
import { UserProgress } from 'types/TeamProgress';

export type UserProgressSort = 'asc' | 'desc' | '';

export const sortUserProgressData =
  (userId: string, sort: UserProgressSort, phaseKey: string) =>
  (a: UserProgress, b: UserProgress) => {
    const isANone =
      a.assignmentProgress[phaseKey] === -1 ||
      a.assignmentProgress[phaseKey] === undefined;
    const isBNone =
      b.assignmentProgress[phaseKey] === -1 ||
      b.assignmentProgress[phaseKey] === undefined;

    if (isBNone) {
      return -1;
    }
    if (isANone) {
      return 1;
    }

    switch (sort) {
      case 'asc':
        return ascending(
          a.assignmentProgress[phaseKey],
          b.assignmentProgress[phaseKey]
        );
      case 'desc':
        return descending(
          a.assignmentProgress[phaseKey],
          b.assignmentProgress[phaseKey]
        );
      default: {
        if (a.userId === userId) {
          return -1;
        } else if (b.userId === userId) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      }
    }
  };
