import { IncubatePlaybookProps } from 'services/api/product/incubatePlaybook';
import {
  GetAllAssignmentsListProps,
  GetAssignmentInfoProps,
  GetAssignmentWithUnitAndTaskIdProps,
  GetAssignmentsListProps,
  GetTeammatesWhoWatchedVideoProps,
} from '.';

export const assignmentsQueryKeys = {
  all: { scope: 'assignments' } as const,
  assignmentsListBase: () =>
    ({ ...assignmentsQueryKeys.all, entity: 'assignments-list' } as const),
  assignmentsList: (params: GetAssignmentsListProps['params']) =>
    ({ ...assignmentsQueryKeys.assignmentsListBase(), params } as const),
  frequentlyAssignedNextBase: () =>
    ({
      ...assignmentsQueryKeys.all,
      entity: 'frequently-assigned-next',
    } as const),
  frequentlyAssignedNext: (params: IncubatePlaybookProps['params']) =>
    ({
      ...assignmentsQueryKeys.frequentlyAssignedNextBase(),
      hidden: false,
      params,
    } as const),
  hiddenFrequentlyAssignedNext: (params: IncubatePlaybookProps['params']) =>
    ({
      ...assignmentsQueryKeys.frequentlyAssignedNextBase(),
      hidden: true,
      params,
    } as const),
  single: (params: GetAssignmentInfoProps['params']) =>
    ({
      ...assignmentsQueryKeys.frequentlyAssignedNextBase(),
      entity: 'assignments-info',
      params,
    } as const),
  allAssignmentsListBase: () =>
    ({
      ...assignmentsQueryKeys.all,
      entity: 'all-assignments-list',
    } as const),
  allAssignmentsList: (params: GetAllAssignmentsListProps['params']) =>
    ({
      ...assignmentsQueryKeys.allAssignmentsListBase(),
      params,
    } as const),
  singleFromTaskBase: () =>
    ({
      ...assignmentsQueryKeys.all,
      entity: 'assignment-info-from-task',
    } as const),
  singleWithTaskAndUnitId: (
    params: GetAssignmentWithUnitAndTaskIdProps['params']
  ) => ({
    ...assignmentsQueryKeys.singleFromTaskBase(),
    params,
  }),
  allWatchedVideosBase: () => ({
    ...assignmentsQueryKeys.all,
    entity: 'all-watched-videos',
  }),
  allWatchedVideos: (params: IncubatePlaybookProps['params']) => ({
    ...assignmentsQueryKeys.allWatchedVideosBase(),
    params,
  }),
  teammatesWhoWatchedVideoBase: () => ({
    ...assignmentsQueryKeys.all,
    entity: 'teammates-who-watched-video',
  }),
  teammatesWhoWatchedVideo: (
    params: GetTeammatesWhoWatchedVideoProps['params']
  ) => ({
    ...assignmentsQueryKeys.teammatesWhoWatchedVideoBase(),
    params,
  }),
};
