import { Box } from '@mui/material';
import { PlaybookQuizAnswer } from './Answer';

export type PlaybookQuizAnswer = {
  mark: string;
  content: string;
};
export type PlaybookQuizAnswerWrapperProps = {
  answer: null | {
    correct: PlaybookQuizAnswer;
    incorrect?: PlaybookQuizAnswer;
  };
};

export const PlaybookQuizAnswerWrapper = ({
  answer,
}: PlaybookQuizAnswerWrapperProps) => {
  return (
    <Box width="100%" display="flex" flexDirection="column" gap="20px">
      {answer?.incorrect && <PlaybookQuizAnswer {...answer.incorrect} />}
      {answer?.correct && <PlaybookQuizAnswer {...answer.correct} correct />}
    </Box>
  );
};
