import { NotificationType, OnlinePeopleStatus, Theme } from 'common/enums';
import { ALL_ROLES, UserStatuses } from 'common/userConsts';
import { Languages } from 'translations/TranslationsProvider';
import { z } from 'zod';

const NotificationSchema = z.object({
  name: z.string(),
  type: z.nativeEnum(NotificationType),
  content: z.unknown(),
  read: z.boolean(),
});

export type Notification = z.infer<typeof NotificationSchema>;
export const RoleSchema = z.object({
  _id: z.string().optional(),
  clientId: z.string().optional(),
  key: z.nativeEnum(ALL_ROLES),
  name: z.string(),
  permissions: z.array(z.string()),
});
export type Role = z.infer<typeof RoleSchema>;

export const UsersClientsSchema = z.object({
  _id: z.string(),
  name: z.string(),
});
export type UsersClients = z.infer<typeof UsersClientsSchema>;

export const UserSchema = z.object({
  // _id: z.string(),
  userId: z.string(),
  name: z.string(),
  email: z.string().email(),
  roles: z.array(RoleSchema),
  language: z.nativeEnum(Languages).optional(),
  isOwner: z.boolean().optional(),
  status: z.nativeEnum(UserStatuses),
  oktaUserId: z.string().optional(),
  notifications: z.array(NotificationSchema).optional(),
  isProjectAdmin: z.boolean().optional(),
  profileImg: z.string().optional(),
  selectedTheme: z.nativeEnum(Theme).optional(),
  clients: z.array(UsersClientsSchema).optional(),
  lastLoggedIn: z.string().optional(),
});
export type User = z.infer<typeof UserSchema>;

export const ClientUserSchema = UserSchema.merge(
  z.object({
    projectIds: z.array(z.string()).optional(),
  })
);
export type ClientUser = z.infer<typeof UserSchema>;

export const InitialSessionDataSchema = z.object({
  user: UserSchema,
  permissions: z.array(z.string()),
  roles: z.array(z.nativeEnum(ALL_ROLES)),
});
export type InitialSessionData = z.infer<typeof InitialSessionDataSchema>;

export type CollaborationUser = {
  name: string;
  color: string;
};

export const OnlinePeopleStateSchema = z.record(
  z.object({ status: z.nativeEnum(OnlinePeopleStatus), lastActive: z.string() })
);
export type OnlinePeopleState = {
  [userId: string]: { status: OnlinePeopleStatus; lastActive: string };
};
