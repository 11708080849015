import { Editor } from '@tiptap/core';
import { Fragment } from 'react';
import styles from 'styles/CollaborativeEditor.module.scss';
import MenuItem from '../MenuItem/MenuItem';

const MenuBar = ({ editor }: { editor: Editor }) => {
  const items = [
    {
      icon: 'FormatBold',
      title: 'Bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => !editor.isDestroyed && editor.isActive('bold'),
    },
    {
      icon: 'FormatItalic',
      title: 'Italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => !editor.isDestroyed && editor.isActive('italic'),
    },
    {
      icon: 'FormatUnderlined',
      title: 'Underline',
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => !editor.isDestroyed && editor.isActive('underline'),
    },
    {
      type: 'divider',
    },
    {
      icon: 'FormatListBulletedRounded',
      title: 'Bullet List',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => !editor.isDestroyed && editor.isActive('bulletList'),
    },
    {
      icon: 'FormatListNumberedRounded',
      title: 'Ordered List',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => !editor.isDestroyed && editor.isActive('orderedList'),
    },
  ];

  return (
    <div className={styles['editor__header']}>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.type === 'divider' ? (
            <div className={styles['divider']} />
          ) : (
            <MenuItem {...item} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default MenuBar;
