import Underline from '@tiptap/extension-underline';
import { EditorContent, JSONContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import styles from 'styles/CollaborativeEditor.module.scss';
import MenuBar from '../CollaborativeEditor/MenuBar/MenuBar';

type TextEditorProps = {
  disabled: boolean;
  content: JSONContent;
  onChange?: (content: JSONContent) => void;
  onChangeAll?: (content: { json: JSONContent; text: string }) => void;
};

export const TextEditor = ({
  disabled,
  content,
  onChange,
  onChangeAll,
}: TextEditorProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Underline,
    ],
    content,
    onUpdate: ({ editor }) => {
      onChange?.(editor.getJSON());
      onChangeAll?.({
        json: editor.getJSON(),
        text: editor.getText(),
      });
    },
  });

  return (
    <div
      className={`${styles['editor']} ${
        disabled ? styles['editor__disabled'] : ''
      }`}
    >
      {editor && <MenuBar editor={editor} />}
      <EditorContent
        editor={editor}
        className={`${styles['editor__content']}`}
      />
    </div>
  );
};
