import { useCustomMediaQuery } from 'hooks';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';
import styles from 'styles/notify.module.scss';

const NotificationsManager = ({ children }: { children: ReactNode }) => {
  const { lessThenSm } = useCustomMediaQuery();

  return (
    <SnackbarProvider
      maxSnack={MAXIMUM_NOTIFICATIONS}
      anchorOrigin={snackbarAnchorOriginStyle}
      style={alertStyle(lessThenSm)}
      classes={classes}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotificationsManager;

const classes = { containerRoot: styles['notify'] };
const alertStyle = (media: boolean) => ({
  maxWidth: '1150px',
  width: media ? '100%' : '80vw',
  justifyContent: 'space-between',
});
const snackbarAnchorOriginStyle: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const MAXIMUM_NOTIFICATIONS = 5;
