import { useEffect, useRef } from 'react';

export const useSetInterval = (fn: () => void, intervalTime = 5000) => {
  const intervalRef = useRef<number>();
  const initRef = useRef<boolean>(false);

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      fn();
    }

    intervalRef.current = window.setInterval(() => {
      fn();
    }, intervalTime);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [fn, intervalTime]);
};
