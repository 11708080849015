import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Paper, Typography } from '@mui/material';
import { defaultResourceImgLink, resourceTooltip } from 'common/consts';
import { ResourceTypes } from 'common/enums';
import { Link } from 'components/Inputs/Link';
import { Tooltip } from 'components/Inputs/Tooltip';
import { MuiIcon } from 'components/MuiIcon';
import { saveAs } from 'file-saver';
import { usePlaybookResources } from 'hooks/usePlaybookResources';
import { getResourceLink } from 'utils/incubatePlaybook';
import { PlaybookFavoriteButton } from './PlaybookFavoriteButton';
import { PlaybookResourceIcon } from './PlaybookResourceIcon';

type PlaybookResourceItemProps = {
  title: string;
  module: string;
  unit?: string;
  phase: string;
  imgSrc?: string;
  type: string;
  favoritesId: string;
  handleClick?: () => void;
  resourceSlug?: string;
};

export const PlaybookResourceItem = ({
  title,
  module,
  unit,
  phase,
  imgSrc,
  type,
  favoritesId,
  handleClick,
  resourceSlug,
}: PlaybookResourceItemProps) => {
  const { resourcesData, isEnterprise } = usePlaybookResources();
  const resourceLink = getResourceLink(resourcesData[resourceSlug ?? '']);
  const downloadHandler = () => {
    if (resourceLink) {
      const link = resourceLink.split('?')[0];
      const fileName = decodeURIComponent(link.split('/').pop() as string);
      saveAs(link, fileName);
    }
  };

  return resourceLink || type !== 'resource' ? (
    <Paper sx={paperStyle} elevation={0}>
      <Box>
        <Box
          width="304px"
          height="200px"
          borderRadius="10px 10px 0 0"
          position="relative"
          sx={getResourceImageStyle(imgSrc || defaultResourceImgLink)}
        />
        <Box
          minHeight="216px"
          p="16px"
          display="flex"
          flexDirection="column"
          gap="20px"
          justifyContent="space-between"
          position="relative"
        >
          <Box
            display="flex"
            flexDirection="row"
            gap="20px"
            justifyContent="space-between"
            alignItems="top"
          >
            <Tooltip
              title={resourceTooltip}
              placement="bottom-start"
              enabled={!resourceLink && !handleClick}
              maxWidth={272}
            >
              <Box maxWidth="80%">
                {isEnterprise ? (
                  <Link color="secondary" onClick={downloadHandler}>
                    {title}
                  </Link>
                ) : (
                  <Link
                    onClick={handleClick}
                    href={resourceLink}
                    disabled={!resourceLink && !handleClick}
                    target="_blank"
                    color="secondary"
                  >
                    {title}
                  </Link>
                )}
              </Box>
            </Tooltip>
            <Box height="25px" width="25px">
              {type === 'task' && taskIcon(favoritesId)}
              {type !== 'task' &&
                type !== 'unit' &&
                resourceIcon(type as ResourceTypes)}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            position="absolute"
            top="100px"
          >
            <Box>
              {unit && type === 'task' && (
                <Typography mt="5px" variant="subtitle2" sx={subtitleStyle}>
                  {unit}
                </Typography>
              )}
            </Box>
          </Box>
          <PlaybookFavoriteButton
            itemId={favoritesId}
            itemName={title}
            itemPhaseName={phase}
            itemModuleName={module}
            itemUnitName={unit}
            itemImageURL={imgSrc}
            itemType={type}
            itemLink={resourceSlug ?? ''}
            dataTestId="save-to-favorites-resource"
          />
        </Box>
      </Box>
    </Paper>
  ) : (
    <></>
  );
};

const paperStyle = {
  borderRadius: '10px',
  boxShadow: '0px 0px 0px 1px #E0E0E0',
  width: '304px',
};
const subtitleStyle = { opacity: 0.6 };
const iconBoxStyle = {
  backgroundColor: 'action.hover',
  boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.25)',
  borderRadius: '5px',
};

const getResourceImageStyle = (imgUrl: string) => ({
  backgroundImage: `url(${imgUrl})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
});

const taskIcon = (id: string) => {
  if (id.match(/taskactivity/i))
    return <MuiIcon iconName="AssignmentOutlined" opacity="0.54" />;
  if (id.match(/taskconcept/i)) return <PlayCircleOutlineIcon opacity="0.54" />;
};

const resourceIcon = (type: ResourceTypes) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="25px"
      height="25px"
      sx={iconBoxStyle}
    >
      <PlaybookResourceIcon type={type} />
    </Box>
  );
};
