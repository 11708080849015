import { AllRoles, ClientRoles, PlatformRoles } from 'common/userConsts';
import { useRouter } from 'next/router';
import { useSessionData } from 'services/api/useSessionData';
import { useClient } from 'store/rq/clientData';
import { InitialSessionData, Role } from 'types/User';

export const usePermissions = () => {
  const router = useRouter();
  const { sessionData, permissions, roles } = useSessionData({});
  const { client } = useClient();

  const userAdminRoles: string[] = [
    PlatformRoles.platform_admin,
    PlatformRoles.platform_super_admin,
    PlatformRoles.platform_analyst,
    ClientRoles.client_admin,
  ];

  const userClientRoles: string[] = [
    ClientRoles.client_admin,
    ClientRoles.client_user,
  ];

  const userHasRole = (role: PlatformRoles | ClientRoles): boolean =>
    !!sessionData?.roles?.some((sessionRoles) => sessionRoles.match(role));

  const restrictedAccessRedirect = (
    condition: boolean,
    rerouteUrl: string = '/'
  ) => {
    if (!condition) router.push(rerouteUrl);
  };

  const findIsPlatformUser = (roles: Role[] | InitialSessionData['roles']) =>
    roles.some((role) => {
      const target = typeof role === 'object' ? role.key : role;

      return Object.keys(PlatformRoles).includes(target);
    });
  const isPlatformUser = !!sessionData?.roles?.some((role) =>
    Object.keys(PlatformRoles).includes(role)
  );

  const isUserPlatformAdmin = userHasRole(PlatformRoles.platform_admin);

  const isUserPlatformOrClientAdmin = !!sessionData?.roles.some(
    (role: AllRoles) => userAdminRoles.includes(role)
  );

  const isUserClient = !!sessionData?.roles.some((role: AllRoles) =>
    userClientRoles.includes(role)
  );

  const isUserClientAdmin = userHasRole(ClientRoles.client_admin);

  if (sessionData?.roles[0] === ClientRoles.client_admin && client) {
    // TODO: expand if user can be on multiple clients
    const loggedInUser = client?.users.find(
      (user) => user.userId === sessionData.user.userId
    );
    if (loggedInUser?.isOwner) {
      permissions.canInviteClientOwner = true;
      permissions.canUpdateClientOwner = true;
      permissions.canDeactivateClientOwner = true;
      permissions.canReactivateClientOwner = true;
    }
  }

  return {
    sessionData,
    permissions,
    roles,
    userHasRole,
    restrictedAccessRedirect,
    isPlatformUser,
    findIsPlatformUser,
    isUserPlatformAdmin,
    isUserPlatformOrClientAdmin,
    isUserClient,
    isUserClientAdmin,
  };
};
