import { ClientStatus, userRegistration } from 'common/enums';
import { ProjectStatus } from 'common/projectConsts';
import { ClientRoles } from 'common/userConsts';
import { z } from 'zod';
import { ClientFeaturesSchema } from './Feature';
import { ProductSchema } from './Product';
import { UserSchema } from './User';

export const BrandSchema = z.object({
  footerCode: z.string().optional(),
  logos: z
    .object({
      smallLogo: z.string().optional(),
      largeLogo: z.string().optional(),
      smallLogoDark: z.string().optional(),
      largeLogoDark: z.string().optional(),
    })
    .optional(),
  colors: z
    .object({
      brandColor: z.string().optional(),
    })
    .optional(),
});
export type Brand = z.infer<typeof BrandSchema>;

export const CompanyInfoSchema = z.object({
  name: z.string(),
  address: z.string(),
  phone: z.string(),
  country: z.string(),
  state: z.string(),
  zipCode: z.string(),
});

export const VentureBuilderDetailsSchema = z.object({
  time: z.string(),
  userId: z.string(),
  _id: z.string(),
  username: z.string(),
});
export type VentureBuilderDetails = z.infer<typeof VentureBuilderDetailsSchema>;

export const ProjectSchema = z.object({
  _id: z.string(),
  name: z.string(),
  users: z.array(UserSchema),
  products: z.array(ProductSchema),
  status: z.nativeEnum(ProjectStatus),
  clientId: z.string(),
  clientName: z.string().optional(),
  clientStatus: z.nativeEnum(ClientStatus).optional(),
  clientOwners: z.array(UserSchema).optional(),
  // ventureBuilderDetails: VentureBuilderDetailsSchema.optional(),
  brand: BrandSchema.optional(),
});
export type Project = z.infer<typeof ProjectSchema>;

export const ProjectForClientSchema = ProjectSchema.partial({
  clientId: true,
});
export type ProjectForClient = z.infer<typeof ProjectForClientSchema>;

export const ClientSchema = z.object({
  _id: z.string(),
  name: z.string(),
  brand: BrandSchema.optional(),
  projects: z.array(ProjectForClientSchema),
  users: z.array(UserSchema),
  // TODO Licenses type
  // licenses: z.array(z.unknown());
  companyInfo: z.array(CompanyInfoSchema),
  userRegistration: z.nativeEnum(userRegistration),
  oktaGroupId: z.string(),
  // slug: z.string(),
  status: z.nativeEnum(ClientStatus),
  products: z.array(ProductSchema),
  features: ClientFeaturesSchema.optional(),
});
export type Client = z.infer<typeof ClientSchema>;

export const ClientListSchema = ClientSchema.omit({ projects: true }).merge(
  z.object({
    projects: z.array(ProjectSchema.pick({ _id: true, status: true })),
  })
);
export type ClientList = z.infer<typeof ClientListSchema>;

export const ClientUserInvitationSchema = z.object({
  name: z.string(),
  email: z.string(),
  roles: z.nativeEnum(ClientRoles).array(),
  isOwner: z.boolean(),
});
export type ClientUserInvitation = z.infer<typeof ClientUserInvitationSchema>;
