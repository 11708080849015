export enum PlatformRoles {
  platform_super_admin = 'platform_super_admin',
  platform_admin = 'platform_admin',
  platform_analyst = 'platform_analyst',
}

export const invitePlatformUserRoles = Object.keys(PlatformRoles);

export enum ClientRoles {
  client_admin = 'client_admin',
  client_support = 'client_support',
  client_user = 'client_user',
  certification_candidate = 'certification_candidate',
}
export const ALL_ROLES = { ...PlatformRoles, ...ClientRoles };
export type AllRoles = keyof typeof ALL_ROLES;

export const inviteClientUserRoles = Object.keys(ClientRoles).filter(
  (r) => r !== ClientRoles.client_support
);

export const UserRoleLabels = {
  [PlatformRoles.platform_super_admin]: 'Super Admin',
  [PlatformRoles.platform_admin]: 'Multi-Client Admin',
  [PlatformRoles.platform_analyst]: 'Platform Analyst',
  [ClientRoles.client_admin]: 'Client Admin',
  // [ClientRoles.client_support]: 'Client Support',
  [ClientRoles.client_user]: 'Client User',
  [ClientRoles.certification_candidate]: 'Certification Candidate',
};

export enum UserStatuses {
  active = 'active',
  deactivated = 'deactivated',
  pending = 'pending',
  expired = 'expired',
  email_not_sent = 'email_not_sent',
}

export const UserStatusColors = {
  [UserStatuses.active]: 'success.main',
  [UserStatuses.deactivated]: 'error.main',
  [UserStatuses.pending]: 'gray',
  [UserStatuses.expired]: 'error.main',
  [UserStatuses.email_not_sent]: 'error.main',
};

export enum permissionsMap {
  canSeePlatformUsersList = 'platform_users_list',
  canViewPlatformUsers = 'platform_users_view',
  canUpdatePlatformSuperAdmin = 'update_platform_super_admin',
  canUpdatePlatformAdmin = 'update_platform_admin',
  canUpdatePlatformUser = 'update_platform_support',
  canAssignPlatformSuperAdmin = 'assign_platform_super_admin',
  canAssignPlatformAdmin = 'assign_platform_admin',
  canAssignPlatformAnalyst = 'assign_platform_support',
  canDeactivatePlatformSuperAdmin = 'deactivate_platform_super_admin',
  canDeactivatePlatformAdmin = 'deactivate_platform_admin',
  canDeactivatePlatformUser = 'deactivate_platform_support',
  canReactivatePlatformSuperAdmin = 'reactivate_platform_super_admin',
  canReactivatePlatformAdmin = 'reactivate_platform_admin',
  canReactivatePlatformUser = 'reactivate_platform_support',
  canSeeClientUsersList = 'client_users_list',
  canViewClientUsers = 'client_users_view',
  canInviteClientOwner = 'client_users_invite_owner',
  canInviteClientAdmin = 'client_users_invite_admin',
  canInviteClientSupport = 'client_users_invite_support',
  canInviteClientUser = 'client_users_invite_regular',
  canUpdateClientOwner = 'client_users_update_owner',
  canUpdateClientAdmin = 'client_users_update_admin',
  canUpdateClientSupport = 'client_users_update_support',
  canUpdateClientUser = 'client_users_update_regular',
  canUpdateCertificateCandidate = 'client_users_update_regular',
  canDeactivateClientOwner = 'client_users_deactivate_owner',
  canDeactivateClientAdmin = 'client_users_deactivate_admin',
  canDeactivateClientSupport = 'client_users_deactivate_support',
  canDeactivateClientUser = 'client_users_deactivate_regular',
  canDeactivateCertificateCandidate = 'client_users_deactivate_regular',
  canReactivateClientOwner = 'client_users_reactivate_owner',
  canReactivateClientAdmin = 'client_users_reactivate_admin',
  canReactivateClientSupport = 'client_users_reactivate_support',
  canReactivateClientUser = 'client_users_reactivate_regular',
  canReactivateCertificateCandidate = 'client_users_reactivate_regular',
  canViewClientList = 'clients_list',
  canViewClientListAllProjects = 'clients_list_all_projects',
  canDeactivateClient = 'client_deactivate',
  canDeactivateClientProjectView = 'deactivated_client_project_view',
  canViewClient = 'clients_view',
  canCreateClient = 'clients_create',
  canSeeClientContent = 'contents_list',
  canSeeClientProducts = 'client_products_list',
  canUpdateClientProducts = 'client_products_update',
  canUpdateWhiteLabeling = 'client_white_labeling_update',
  canListProjects = 'projects_list',
  canViewProjects = 'list_all_projects',
  canCreateProject = 'projects_create',
  canViewProject = 'projects_view',
  canUpdateProject = 'projects_update',
  canViewProjectUsers = 'project_users_list',
  canAddExistingUsersToProject = 'project_users_invite_existing',
  canAddNewUsersToProject = 'project_users_invite_new',
  canPromoteProjectAdmin = 'project_users_make_admin',
  canDemoteProjectAdmin = 'project_users_lose_admin',
  canRemoveProjectUsers = 'project_users_remove',
  canDeactivateProject = 'projects_deactivate',
  canReactivateProject = 'project_reactivate',
  canSeedSanityData = 'seed_products_content',
  canViewResources = 'resources_view',
  canUpdateResources = 'resources_update',
  canViewEnterpriseResources = 'enterprise_resources_view',
  canUpdateEnterpriseResources = 'enterprise_resources_update',
  canSeePlatformProducts = 'platform_products_list',
  canUpdatePlatformProducts = 'platform_products_update_features',
  canCreatePlatformProducts = 'platform_products_create',
  canDeleteUser = 'delete_user',
  canManageProjectFlags = 'project_flagging',
  // todo - update permission
  canViewTeamProgress = 'view_team_progress',
  canViewAssignments = 'assignments_view',
  canEditAssignments = 'assignments_update',
  canDeleteAssignments = 'assignments_delete',
  canCreateAssignments = 'assignments_create',
  canViewChat = 'chatbot_access',
  canViewPersonas = 'personas_access',
}
export type permissionKey = keyof typeof permissionsMap;

export type SessionDataPermissions = {
  [K in permissionKey]?: boolean;
};

export const roleToPermissionMapper: {
  [key in PlatformRoles | ClientRoles]: permissionKey;
} = {
  [PlatformRoles.platform_super_admin]: 'canUpdatePlatformSuperAdmin',
  [PlatformRoles.platform_admin]: 'canUpdatePlatformAdmin',
  [PlatformRoles.platform_analyst]: 'canUpdatePlatformUser',
  [ClientRoles.client_admin]: 'canUpdateClientAdmin',
  [ClientRoles.client_support]: 'canUpdateClientSupport',
  [ClientRoles.client_user]: 'canUpdateClientUser',
  [ClientRoles.certification_candidate]: 'canUpdateCertificateCandidate',
};

export const deactivationPermissionMapper: {
  [key in PlatformRoles | ClientRoles]: permissionKey;
} = {
  [PlatformRoles.platform_super_admin]: 'canDeactivatePlatformSuperAdmin',
  [PlatformRoles.platform_admin]: 'canDeactivatePlatformAdmin',
  [PlatformRoles.platform_analyst]: 'canDeactivatePlatformUser',
  [ClientRoles.client_admin]: 'canDeactivateClientAdmin',
  [ClientRoles.client_support]: 'canDeactivateClientSupport',
  [ClientRoles.client_user]: 'canDeactivateClientUser',
  [ClientRoles.certification_candidate]: 'canDeactivateCertificateCandidate',
};

export const reactivationPermissionMapper: {
  [key in PlatformRoles | ClientRoles]: permissionKey;
} = {
  [PlatformRoles.platform_super_admin]: 'canReactivatePlatformSuperAdmin',
  [PlatformRoles.platform_admin]: 'canReactivatePlatformAdmin',
  [PlatformRoles.platform_analyst]: 'canReactivatePlatformUser',
  [ClientRoles.client_admin]: 'canReactivateClientAdmin',
  [ClientRoles.client_support]: 'canReactivateClientSupport',
  [ClientRoles.client_user]: 'canReactivateClientUser',
  [ClientRoles.certification_candidate]: 'canReactivateCertificateCandidate',
};
