import VentureBuilderIcon from '@mui/icons-material/BuildOutlined';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { Currencies, VentureBuilderItemAction } from 'common/enums';
import { SectionModes, Slugs } from 'common/ventureBuilderConsts';
import { useCurrencyData } from 'services/api/product/ventureBuilder/currency/useCurrencyData';
import { useProductTranslations } from 'translations/hooks';

export const currenciesMap = [
  { id: Currencies.USD, name: 'US Dollar', sign: '$' },
  { id: Currencies.EUR, name: 'Euro', sign: '€' },
  { id: Currencies.JPY, name: 'Japanese Yen', sign: '¥' },
  { id: Currencies.GBP, name: 'United Kingdom Pound', sign: '£' },
];

export const Currency = ({ mode }: { mode: SectionModes }) => {
  const isTaskViewMode = mode === SectionModes.TASK;

  const { currency, updateCurrency } = useCurrencyData();
  const { documentFollowingInBusinessSectionOfVBLabel, selectCurrencyLabel } =
    useProductTranslations();

  const handleChange = (event: any) => {
    const { value } = event.target;
    updateCurrency({
      payload: {
        action: VentureBuilderItemAction.EDIT,
        data: { currency: value },
      },
    });
  };

  return (
    <Grid container item sx={wrapperStyle(isTaskViewMode)} columnGap={2}>
      {isTaskViewMode && (
        <Grid item>
          <Box display="flex" alignItems="center" gap="10px" mb="30px">
            <VentureBuilderIcon />
            <Typography>
              {documentFollowingInBusinessSectionOfVBLabel}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item sm={getInputWidthInGrid(isTaskViewMode)}>
        <TextField
          name={Slugs.CURRENCY}
          label={selectCurrencyLabel}
          color="secondary"
          select
          value={currency}
          fullWidth
          onChange={handleChange}
        >
          {currenciesMap?.map(({ id, name, sign }) => (
            <MenuItem key={id} value={id}>
              {`(${sign}) ${id} - ${name}`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

const wrapperStyle = (isTaskView: boolean) => ({
  flexFlow: 'column',
  marginTop: isTaskView ? 0 : '30px',
});

const getInputWidthInGrid = (isTaskView: boolean) => {
  if (isTaskView) return 4.5;

  return 3.8;
};
