import { MuiIcon } from 'components/MuiIcon';

const MenuItem = ({
  icon,
  title,
  action,
  isActive,
}: {
  icon?: string;
  title?: string;
  action?: () => boolean;
  isActive?: () => boolean;
}) => (
  <button
    className={`menu-item${isActive && isActive() ? ' is-active' : ''}`}
    onClick={action}
    title={title}
  >
    <MuiIcon className="remix" iconName={icon} />
  </button>
);

export default MenuItem;
