import axios from 'axios';
import { FileType } from 'types/FileType';

type FileUploadRequestParams = { url: string; file: File };

export const uploadFileRequest = ({ url, file }: FileUploadRequestParams) => {
  return axios.put(url, file, {
    headers: {
      'Content-Type': file.type as FileType,
    },
  });
};
