import { ClientStatus } from 'common/enums';

type FormatInfiniteParamsProps = {
  search: string;
  limit?: number;
  offset?: number;
  status?: ClientStatus | 'all';
};

export const formatInfiniteParams = ({
  search,
  limit = 18,
  offset = 0,
  status,
}: FormatInfiniteParamsProps) => {
  const statusFilter =
    status && status !== 'all'
      ? {
          filterBy: 'status',
          filterValue: status,
        }
      : {};
  const searchFilter = search
    ? {
        filterBy: 'name',
        filterValue: search,
      }
    : {};

  return {
    limit,
    offset,
    ...searchFilter,
    ...statusFilter,
  };
};
