import ArrowBack from '@mui/icons-material/ArrowBackIosSharp';
import { Box, Divider, Drawer, IconButton } from '@mui/material';
import { useCustomMediaQuery } from 'hooks';
import { useCallback, useState } from 'react';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import {
  PlaybookSidebarHelp,
  PlaybookSidebarLinks,
  PlaybookSidebarLogo,
  PlaybookSidebarPhases,
  PlaybookSidebarProducts,
} from '..';

export const PlaybookSidebar = () => {
  const { lessThenMd } = useCustomMediaQuery();

  const { sanity } = useIncubatePlaybookData();

  const [isOpen, setIsOpen] = useState(true);

  const openToggleHandler = useCallback(() => setIsOpen((o) => !o), []);

  return (
    <Drawer
      PaperProps={drawerPaperProps}
      open={isOpen}
      variant="permanent"
      sx={getDrawerStyle(isOpen, lessThenMd)}
    >
      <Box width={isOpen ? 256 : 80} mt="48px" sx={transitionStyle}>
        <Box
          position="fixed"
          top={0}
          left={isOpen ? 216 : 24}
          height="48px"
          zIndex={1}
          display="flex"
          alignItems="center"
          justifyContent={isOpen ? 'right' : 'center'}
          pr={isOpen ? '10px' : 0}
        >
          <Box borderRadius="50%">
            <IconButton onClick={openToggleHandler}>
              <ArrowBack fontSize="small" sx={getArrowStyle(isOpen)} />
            </IconButton>
          </Box>
        </Box>
        <Box pb="8px">
          <PlaybookSidebarLogo isOpen={isOpen} />
          <PlaybookSidebarLinks isOpen={isOpen} />
          <Divider sx={dividerStyle} />
          <PlaybookSidebarPhases isOpen={isOpen} />
          <PlaybookSidebarProducts isOpen={isOpen} />
          <PlaybookSidebarHelp isOpen={isOpen} helpLink={sanity?.contactUrl} />
        </Box>
      </Box>
    </Drawer>
  );
};

const transitionStyle = { transition: 'width 0.2s' };
const dividerStyle = {
  marginTop: '10px',
  marginBottom: '10px',
};
const getArrowStyle = (isOpen: boolean) => ({
  color: 'text.secondary',
  width: '16px',
  height: '16px',
  transform: `rotate(${isOpen ? 0 : 180}deg)`,
  transition: 'transform 0.2s',
});
const getDrawerStyle = (isOpen: boolean, matches: boolean) => ({
  display: { xs: 'none', sm: 'block' },
  width: isOpen && !matches ? 256 : 80,
  transition: 'width 0.2s',
  '&.MuiDrawer-root .MuiDrawer-paperAnchorDockedLeft': {
    border: 'unset',
    overflowX: 'hidden',
    boxShadow: 12,
  },
});

const drawerPaperProps = { elevation: 15 };
