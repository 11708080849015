import { headerSocketTopic } from 'common/consts';
import { ProductType } from 'common/enums';
import { DefaultRequest } from 'types/api';
import { API } from './utils';

export type GetProductsParams = DefaultRequest<{
  filterValue?: string;
}>;

export type ProductCreateRequestProps = DefaultRequest<{
  title: string;
  contents: string[];
  features: string[];
  type: ProductType;
}>;

export const getProductsRequest = ({
  params: { filterValue },
  topic,
}: GetProductsParams) =>
  API.get('/v1/products', {
    params: { limit: 50, filterBy: 'title', filterValue },
    headers: { [headerSocketTopic]: topic },
  });

export const triggerDataSeedingRequest = (callback: () => void) =>
  API.get('/v1/maintenance/seed-products-content', {
    headers: { [headerSocketTopic]: 'trigger|data|seeding' }, // topic not used - this is just for consistency
  }).then(() => {
    callback();
  });

export const getContentRequest = ({ topic }: DefaultRequest) =>
  API.get('/v2/contents', {
    params: { limit: 50, offset: 0 },
    headers: { [headerSocketTopic]: topic },
  });

export const createProductRequest = ({
  params,
  topic,
}: ProductCreateRequestProps) =>
  API.post('/v2/products', params, { headers: { [headerSocketTopic]: topic } });

export type ProductUpdateRequestProps = DefaultRequest<{
  productId: string;
  featureId: string;
  isEnabled: boolean;
}>;
export const updateProductFeatureRequest = ({
  params: { productId, featureId, isEnabled },
  topic,
}: ProductUpdateRequestProps) =>
  API.patch(
    `/v2/products/${productId}/features/${featureId}`,
    { isEnabled },
    { headers: { [headerSocketTopic]: topic } }
  );

export const getFeatureRequest = ({ topic }: DefaultRequest) =>
  API.get('/v2/features', {
    params: { limit: 50, offset: 0 },
    headers: { [headerSocketTopic]: topic },
  });
