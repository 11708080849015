import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from '@hello-pangea/dnd';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { ButtonText } from 'components/Inputs/Button';
import { Link } from 'components/Inputs/Link';
import { useState } from 'react';
import {
  useCommonTranslations,
  useProductTranslations,
} from 'translations/hooks';
import { Hypothesis, Stakeholder } from 'types/ventureBuilder';
import { HypothesisBacklogListState } from '.';
import { HypothesisBacklogListItem } from './HypothesisBacklogListItem';

type HypothesisBacklogListProps = {
  isLoading: boolean;
  data: Hypothesis[];
  stakeholders: Stakeholder[];
  isTaskMode: boolean;
  state: HypothesisBacklogListState;
  onAdd: (addNewShouldBeOnTopOfTheList: boolean) => () => void;
  onEdit: (hypothesis: Hypothesis) => void;
  onCancel: () => void;
  onDelete: (hypothesis: Hypothesis) => void;
  onSave: (hypothesis: Hypothesis) => boolean;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
};

const HypothesisBacklogList = ({
  isLoading,
  data,
  stakeholders,
  isTaskMode,
  state,
  onAdd,
  onEdit,
  onCancel,
  onDelete,
  onSave,
  onDragEnd,
}: HypothesisBacklogListProps) => {
  const [showAll, setShowAll] = useState(!isTaskMode);

  const setShowAllTrue = () => setShowAll(true);

  const hypothesisToShow = showAll ? data : data.slice(0, COLLAPSED_LIST_LIMIT);
  const showAllButtonVisible = !showAll && data.length > COLLAPSED_LIST_LIMIT;

  const {
    hypothesisBacklogLabel,
    hypothesisLabel,
    stakeholderLabel,
    plusNewHypothesisLabel,
  } = useProductTranslations();
  const { statusLabel, showAllLabel } = useCommonTranslations();

  return (
    <Box>
      <Typography variant="h6" fontWeight="600">
        {hypothesisBacklogLabel}
      </Typography>
      <Box margin="10px 0" overflow="scroll">
        <Grid
          container
          sx={listHeaderStyle(isTaskMode)}
          flexDirection="row"
          minWidth="600px"
        >
          <Grid
            container
            item
            xs={9}
            columnGap={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid container item xs={0.5} />

            <Grid container item xs={3.5}>
              <Typography fontWeight={500}>{hypothesisLabel}</Typography>
            </Grid>

            <Grid container item xs={3.5}>
              <Typography fontWeight={500}>{stakeholderLabel}</Typography>
            </Grid>

            <Grid container item xs={3.5}>
              <Typography fontWeight={500}>{statusLabel}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && data.length === 0 && (
          <Box sx={loaderWrapperStyle}>
            <CircularProgress size={30} />
          </Box>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="hypothesis-drop-zone">
            {(provided) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {data.length ? (
                  hypothesisToShow.map((hypothesis, index) => (
                    <HypothesisBacklogListItem
                      key={hypothesis._id}
                      isTaskMode={isTaskMode}
                      index={index}
                      data={hypothesis}
                      stakeholders={stakeholders}
                      state={state}
                      onEdit={onEdit}
                      onCancel={onCancel}
                      onDelete={onDelete}
                      onSave={onSave}
                    />
                  ))
                ) : (
                  <EmptyState />
                )}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <ButtonText
          color="secondary"
          disabled={state.type !== VentureBuilderItemAction.VIEW}
          onClick={onAdd(!showAll && data.length >= COLLAPSED_LIST_LIMIT)}
        >
          {plusNewHypothesisLabel}
        </ButtonText>
        {showAllButtonVisible && (
          <Link sx={linkStyle} onClick={setShowAllTrue} color="secondary">
            {showAllLabel}
          </Link>
        )}
      </Box>
    </Box>
  );
};

const loaderWrapperStyle = { margin: '10px', textAlign: 'center' };
const linkStyle = { marginTop: '8px' };

const listHeaderStyle = (isTaskMode: boolean) => ({
  minHeight: '50px',
  borderBottom: '1px solid',
  borderColor: 'action.disabledBackground',
  padding: `12px 0 8px ${isTaskMode ? '10px' : '0'}`,
});

const COLLAPSED_LIST_LIMIT = 3;

const EmptyState = () => {
  const { noAddedHypotheseLabel } = useProductTranslations();

  return (
    <Box my="8px">
      <Typography fontWeight={200}>{noAddedHypotheseLabel}</Typography>
    </Box>
  );
};

export default HypothesisBacklogList;
