import { useMutation } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useLogout } from 'hooks';
import { useCallback } from 'react';
import { socketTopics } from 'services/sockets/topics';
import { SocketHandlerProps } from 'types/socket';
import { User } from 'types/User';
import { updateProfileRequest, UpdateProfileRequest } from '.';
import { useFetch } from '../utils';

type ResponseData = User;
type Request = UpdateProfileRequest['params'];

export const useUpdateProfile = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<Pick<Request, 'userId'>, ResponseData>) => {
  const { notify } = useNotifications();
  const { logout } = useLogout();

  const successHandler = useCallback(
    (result?: ResponseData) => {
      notify(NotificationType.PROFILE_UPDATED);
      onSuccess?.(result);
    },
    [notify, onSuccess]
  );

  const successThemeHandler = useCallback(
    (result?: ResponseData) => {
      notify(NotificationType.THEME_UPDATED);
      onSuccess?.(result);
    },
    [notify, onSuccess]
  );

  const successChangePasswordHandler = useCallback(() => {
    notify(NotificationType.USER_PASSWORD_CHANGED);
    onSuccess?.();
    logout();
  }, [logout, notify, onSuccess]);

  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFnMutation } = useFetch<Request, ResponseData>({
    topic: socketTopics.PROFILE_UPDATED,
    request: updateProfileRequest,
  });

  const { mutate, isLoading } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const { mutate: _updateTheme, isLoading: isThemeUpdating } = useMutation(
    fetchFnMutation,
    {
      onSuccess: successThemeHandler,
      onError: failureHandler,
    }
  );

  const { mutate: _changePassword, isLoading: isChangingPassword } =
    useMutation(fetchFnMutation, {
      onSuccess: successChangePasswordHandler,
      onError: failureHandler,
    });

  const updateProfile = (payload: Request['payload']) =>
    mutate({ ...defaultParams!, payload });

  const updateTheme = (
    selectedTheme: Request['payload']['selectedTheme'],
    userId?: Request['userId']
  ) => {
    if (!defaultParams?.userId && !userId) return;

    return _updateTheme({
      userId: (userId ?? defaultParams?.userId) as string,
      payload: { selectedTheme },
    });
  };

  const changePassword = (
    newPassword: Request['payload']['newPassword'],
    oldPassword: Request['payload']['oldPassword'],
    userId?: Request['userId']
  ) => {
    if (!defaultParams?.userId && !userId) return;

    return _changePassword({
      userId: (userId ?? defaultParams?.userId) as string,
      payload: { newPassword, oldPassword },
    });
  };

  return {
    isLoading,
    updateProfile,
    isThemeUpdating,
    updateTheme,
    changePassword,
    isChangingPassword,
  };
};
