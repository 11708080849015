import { ProductFeature } from 'common/enums';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useClient } from 'store/rq/clientData';
import {
  ClientProductFeatures,
  ProductFeatures,
  ProductFeaturesKey,
} from 'types/Feature';

export const useProductFeatures = (productId?: string) => {
  const { client } = useClient();
  const { playbookInfo } = useGetPlaybookInfo({});

  return adaptFn(
    client?.features,
    playbookInfo.projectId,
    productId ?? playbookInfo.productId
  );
};

const adaptFn = (
  features?: ClientProductFeatures,
  projectId: string = '',
  productId: string = ''
) =>
  Object.entries(ProductFeature).reduce<ProductFeatures>((a, [key, value]) => {
    if (!features || !projectId || !productId) return a;

    const products = features[projectId][productId];
    a[key as ProductFeaturesKey] = !!products?.[value];

    return a;
  }, {});
