import { useQuery, useQueryClient } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useTableProps } from 'hooks';
import { useCallback } from 'react';
import { formatTableParams, useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { ProjectSchema } from 'types/Client';
import { defaultListResponse } from 'types/api';
import { SocketHandlerProps } from 'types/socket';
import { z } from 'zod';
import { ProjectListRequestParamsProps, getClientProjectsRequest } from '.';
import { projectQueryKeys } from './projectQueryKeys';

const schema = defaultListResponse(ProjectSchema);

type ResponseData = z.infer<typeof schema>;
type Request = ProjectListRequestParamsProps['params'];

export const useGetClientProjectsList = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<Pick<Request, 'clientId'> & { limit?: number }>) => {
  const queryClient = useQueryClient();
  const { notify } = useNotifications();

  const { page, sort, search, pageSize, status } = useTableProps({
    pageSize: defaultParams?.limit,
  });

  const successHandler = useCallback(() => {
    onSuccess?.();
  }, [onSuccess]);
  const failureHandler = useCallback(
    (message_key: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const params = formatTableParams({
    page: page.value,
    search: search.value,
    sort: sort.value,
    pageSize: pageSize.value,
    status: status.value,
  });

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.CLIENT_PROJECTS_LIST,
    request: getClientProjectsRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [projectQueryKeys.list({ clientId: defaultParams!.clientId, params })],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled,
    }
  );

  const resetClientProjectsList = useCallback(() => {
    queryClient.invalidateQueries([projectQueryKeys.listBase()]);
  }, [queryClient]);

  return {
    resetClientProjectsList,
    isLoading,
    data,
    page,
    pageSize,
    sort,
    search,
    status,
  };
};
