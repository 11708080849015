import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { Hypothesis, VentureBuilderChange } from 'types/ventureBuilder';
import { useGetHypothesisBacklogData } from './useGetHypothesisBacklogData';
import { useUpdateHypothesisBacklogData } from './useUpdateHypothesisBacklogData';

export const useHypothesisBacklogData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.HYPOTHESIS_BACKLOG,
  };

  const {
    hypothesisBacklog,
    isLoading: isGetting,
    setHypothesisBacklog,
  } = useGetHypothesisBacklogData({
    defaultParams,
  });

  const { isLoading: isPosting, updateHypothesisBacklogData } =
    useUpdateHypothesisBacklogData({});

  type UpdateHypothesisBacklogProps = {
    payload: VentureBuilderChange<Hypothesis>;
    newData: Hypothesis[];
  };

  const updateHypothesisBacklog = ({
    payload,
    newData,
  }: UpdateHypothesisBacklogProps) => {
    const oldHypothesis = [...hypothesisBacklog];

    setHypothesisBacklog(newData);
    updateHypothesisBacklogData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setHypothesisBacklog(oldHypothesis);
        },
      }
    );
  };

  return {
    hypothesisBacklog,
    updateHypothesisBacklog,
    isGetting,
    isPosting,
  };
};
