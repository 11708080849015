import { Alert, Box, Divider, Typography } from '@mui/material';
import { MuiIcon } from 'components/MuiIcon';
import { useCommonTranslations } from 'translations/hooks';
import { ExitButton } from './ExitButton';

type PlaybookQuizHeadingProps = {
  totalQuestions: number;
  allCorrect?: boolean;
  onClick: () => void;
};

export const PlaybookQuizHeading = ({
  totalQuestions,
  allCorrect,
  onClick,
}: PlaybookQuizHeadingProps) => {
  const {
    quizLabel,
    numberOfQuestionsLabel,
    congratulationsAllQuestionsCorrectlyLabel,
  } = useCommonTranslations();

  return (
    <Box mb="30px" width="100%">
      <ExitButton onClick={onClick} />
      <Box my="30px" display="flex" gap="50px" alignItems="center">
        <Typography fontSize={20} fontWeight={600} data-testid="task-title">
          {quizLabel}
        </Typography>
        <Typography>{numberOfQuestionsLabel(totalQuestions)}</Typography>
      </Box>
      <Divider />
      {allCorrect && (
        <Box mt="30px">
          <Alert
            severity="success"
            icon={
              <Box mt="5px" height="22px">
                <MuiIcon iconName="CheckCircleOutlined" fontSize="inherit" />
              </Box>
            }
          >
            <Typography fontSize="20px" fontWeight={600}>
              {congratulationsAllQuestionsCorrectlyLabel}
            </Typography>
          </Alert>
        </Box>
      )}
    </Box>
  );
};
