import { useQuery } from '@tanstack/react-query';
import useNotifications from 'common/useNotifications';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';

import { ProductType } from 'common/enums';
import { NotificationType } from 'common/notificationConsts';
import {
  IncubatePlaybook,
  PlaybookContentSchema,
  PlaybookOriginalContent,
} from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import { adaptIncubatePlaybookData } from 'utils/incubatePlaybook';
import { IncubatePlaybookProps, getIncubatePlaybookDataRequest } from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

const schema = PlaybookContentSchema;

type ResponseData = PlaybookOriginalContent;
type AdaptedData = IncubatePlaybook;
type Request = IncubatePlaybookProps['params'];

export const useGetIncubatePlaybookData = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<void, AdaptedData>) => {
  const { notify } = useNotifications();
  const { playbookInfo, isPlaybookInfoValid, productType } = useGetPlaybookInfo(
    {}
  );

  const successHandler = (result?: AdaptedData) => {
    onSuccess?.(result);
  };
  const failureHandler = (message_key: NotificationType) => {
    notify(message_key);
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<Request, ResponseData, AdaptedData>({
    topic: socketTopics.INCUBATE_PLAYBOOK,
    request: getIncubatePlaybookDataRequest,
    adaptFn: adaptIncubatePlaybookData(
      productType === ProductType.enterprisePlaybook
    ),
    schema,
  });

  const { data } = useQuery(
    [incubatePlaybookQueryKeys.data(playbookInfo)],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled: enabled && isPlaybookInfoValid,
    }
  );

  return { sanityId: data?.sanity?._id };
};
