import { z } from 'zod';

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
);

export const NotesSchema = z.union([
  literalSchema,
  z.array(jsonSchema),
  z.record(jsonSchema),
]);
export type Notes = z.infer<typeof NotesSchema>;

const TaskAssignmentSchema = z.object({
  taskName: z.string(),
  videoId: z.string(),
  videoLength: z.number(),
});

const UnitAssignmentSchema = z.object({
  unitName: z.string(),
  moduleId: z.string(),
  moduleName: z.string(),
  phaseId: z.string(),
  phaseName: z.string(),
  tasks: z.record(TaskAssignmentSchema),
});

export const AssignmentSchema = z.object({
  notes: NotesSchema,
  dueDate: z.date(),
  assignees: z.array(z.string()),
  assignments: z.record(UnitAssignmentSchema),
});

export type Assignment = z.infer<typeof AssignmentSchema>;
export type UnitAssignment = z.infer<typeof UnitAssignmentSchema>;
export type TaskAssignment = z.infer<typeof TaskAssignmentSchema>;

export const FrequentlyAssignedNextSchema = z.object({ unitId: z.string() });

export type FrequentlyAssignedNext = z.infer<
  typeof FrequentlyAssignedNextSchema
>;

export const FrequentlyAssignedNextArraySchema = z.array(
  FrequentlyAssignedNextSchema
);

export type FrequentlyAssignedNextArray = z.infer<
  typeof FrequentlyAssignedNextArraySchema
>;

export enum AssignmentProgress {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  watched = 'watched',
}

export const AssignmentProgressSchema = z.nativeEnum(AssignmentProgress);

export const AssignmentCardSchema = z.object({
  assignmentId: z.string(),
  unitId: z.string(),
  tasks: z.array(
    z.object({
      taskId: z.string(),
      isVideoWatched: z.boolean(),
    })
  ),
  dueDate: z.string(),
  notesPlainText: z.string(),
  assignedBy: z.object({ name: z.string(), profileImg: z.string().optional() }),
  teamProgress: z.number(),
  progress: AssignmentProgressSchema,
});

export type AssignmentCard = z.infer<typeof AssignmentCardSchema>;

export const AssignmentInfoSchema = z.object({
  notes: NotesSchema.optional(),
  notesPlainText: z.string().optional(),
  dueDate: z.string(),
  assignees: z.array(z.string()),
  assignments: z.record(z.array(z.string())),
});

export type AssignmentInfo = z.infer<typeof AssignmentInfoSchema>;

export const AssignmentTableRowSchema = z.object({
  assignmentId: z.string(),
  unitId: z.string(),
  dueDate: z.string(),
  createdAt: z.string(),
  notesPlainText: z.string(),
  assignedBy: z.object({ name: z.string(), profileImg: z.string().optional() }),
  overallTeamProgress: z.number(),
  progress: AssignmentProgressSchema,
  teamProgress: z.array(
    z.object({
      userId: z.string(),
      name: z.string(),
      profileImg: z.string().optional(),
      progress: z.number(),
    })
  ),
  tasks: z.array(
    z.object({
      taskId: z.string(),
      isVideoWatched: z.boolean(),
    })
  ),
});

export type AssignmentTableRow = z.infer<typeof AssignmentTableRowSchema>;

export const AssignmentFromTaskSchema = z
  .object({
    assignmentId: z.string(),
    dueDate: z.string(),
    notesPlainText: z.string().optional(),
    assignedBy: z.object({
      name: z.string(),
      profileImg: z.string().optional(),
    }),
    teamProgress: z.number(),
    progress: AssignmentProgressSchema,
  })
  .nullable();

export type AssignmentFromTask = z.infer<typeof AssignmentFromTaskSchema>;

export const AllWatchedVideosSchema = z.array(z.string());

export type AllWatchedVideos = z.infer<typeof AllWatchedVideosSchema>;

export const TeammateWhoWatchedVideo = z.object({
  name: z.string(),
  profileImg: z.string().optional(),
});

export const TeammatesWhoWatchedVideoArraySchema = z.array(
  TeammateWhoWatchedVideo
);

export type TeammateWhoWatchedVideo = z.infer<typeof TeammateWhoWatchedVideo>;

export type TeammatesWhoWatchedVideoArray = z.infer<
  typeof TeammatesWhoWatchedVideoArraySchema
>;
