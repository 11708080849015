import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { MarketSize, VentureBuilderChange } from 'types/ventureBuilder';
import { useGetMarketSize } from './useGetMarketSize';
import { useUpdateMarketSizeData } from './useUpdateMarketSizeData';

export const useMarketSizeData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.MARKET_SIZE,
  };

  const {
    marketSize,
    isLoading: isGetting,
    setMarketSize,
  } = useGetMarketSize({
    defaultParams,
  });

  const { isLoading: isPosting, updateMarketSizeData } =
    useUpdateMarketSizeData({});

  type UpdateCurrencyProps = {
    payload: VentureBuilderChange<MarketSize>;
  };

  const updateMarketSize = ({ payload }: UpdateCurrencyProps) => {
    const oldData = { ...marketSize };

    setMarketSize({ ...oldData, ...payload.data });
    updateMarketSizeData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setMarketSize(oldData);
        },
      }
    );
  };

  return {
    marketSize,
    updateMarketSize,
    isGetting,
    isPosting,
  };
};
