import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { PainPoint, VentureBuilderChange } from 'types/ventureBuilder';
import { useGetPainPointsData } from './useGetPainPointsData';
import { useUpdatePainPointsData } from './useUpdatePainPointsData';

export const usePainPointsData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.PRIORITIZED_PAIN_POINTS,
  };

  const {
    painPointsData,
    isLoading: isGetting,
    setPainPoints,
  } = useGetPainPointsData({
    defaultParams,
  });

  const { isLoading: isPosting, updatePainPointsData } =
    useUpdatePainPointsData({});

  type UpdatePainPointsProps = {
    payload: VentureBuilderChange<PainPoint>;
    newData: PainPoint[];
  };

  const updatePainPoints = ({ payload, newData }: UpdatePainPointsProps) => {
    const oldData = [...painPointsData];

    setPainPoints(newData);
    updatePainPointsData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setPainPoints(oldData);
        },
      }
    );
  };

  return {
    painPointsData,
    updatePainPoints,
    isGetting,
    isPosting,
  };
};
