import { Avatar, Box, Typography } from '@mui/material';

type PlaybookQuizQuestionTitleProps = {
  order: number;
  content: string;
};

export const PlaybookQuizQuestionTitle = ({
  order,
  content,
}: PlaybookQuizQuestionTitleProps) => {
  return (
    <Box minHeight="24px" display="flex" gap="20px">
      <Avatar sx={orderStyle}>{order}</Avatar>
      <Typography my="auto">{content}</Typography>
    </Box>
  );
};

const orderStyle = {
  height: '26px',
  width: '26px',
  backgroundColor: 'secondary.main',
  color: 'text.defaultContrast',
  fontSize: 14,
};
