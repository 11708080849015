import { Alert, AlertTitle, Typography } from '@mui/material';
import { useCommonTranslations } from 'translations/hooks';

type PlaybookQuizAnswerProps = {
  mark: string;
  content: string;
  correct?: boolean;
};

export const PlaybookQuizAnswer = ({
  mark,
  content,
  correct,
}: PlaybookQuizAnswerProps) => {
  const { markIsCorrectOrIncorrectLabel } = useCommonTranslations();

  return (
    <Alert severity={correct ? 'success' : 'warning'}>
      <AlertTitle>{markIsCorrectOrIncorrectLabel(mark, correct)}</AlertTitle>
      <Typography variant="body2">{content}</Typography>
    </Alert>
  );
};
