import { useQuery, useQueryClient } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useFilterItem, useTableProps } from 'hooks';
import { useCallback } from 'react';
import { formatTableParams, useFetch } from 'services/api/utils';
import { formatFilters } from 'services/api/utils/formatFilters';
import { socketTopics } from 'services/sockets/topics';
import { defaultListResponse } from 'types/api';
import { IncubatePlaybookFavoriteSchema } from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import { z } from 'zod';
import {
  IncubatePlaybookFavoriteListParams,
  getIncubatePlaybookFavoriteListRequest,
} from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

const schema = defaultListResponse(IncubatePlaybookFavoriteSchema);

type ResponseData = z.infer<typeof schema>;
type Request = IncubatePlaybookFavoriteListParams['params'];

export const useGetIncubatePlaybookFavoriteList = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<void, ResponseData>) => {
  const queryClient = useQueryClient();
  const { notify } = useNotifications();
  const { playbookInfo, isPlaybookInfoValid } = useGetPlaybookInfo({});

  const phaseFilter = useFilterItem({});
  const resourceFilter = useFilterItem({});

  const { page, sort, pageSize } = useTableProps({
    pageSize: 10,
    sort: [{ field: 'dateAdded', sort: 'desc' }],
  });

  const successHandler = useCallback(() => {
    onSuccess?.();
  }, [onSuccess]);

  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );

  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const filters = [
    { name: 'itemPhaseName', value: phaseFilter.value },
    { name: 'itemType', value: resourceFilter.value },
  ];
  const { filterBy, filterValue, searchValue } = formatFilters(filters);

  const params = formatTableParams({
    page: page.value,
    sort: sort.value,
    sortMap: { itemPhaseName: 'phaseOrderNumber' },
    filterBy,
    search: filterValue,
    pageSize: pageSize.value,
  });

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.FAVORITE_LIST,
    request: getIncubatePlaybookFavoriteListRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [
      incubatePlaybookQueryKeys.favoriteList({
        defaultParams: playbookInfo,
        params,
      }),
    ],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      staleTime: 1000 * 60,
      enabled: enabled && isPlaybookInfoValid,
    }
  );

  const resetIncubatePlaybookFavoriteList = useCallback(() => {
    queryClient.invalidateQueries([
      incubatePlaybookQueryKeys.favoriteListBase(),
    ]);
  }, [queryClient]);

  return {
    resetIncubatePlaybookFavoriteList,
    isLoading,
    data,
    page,
    sort,
    searchValue,
    pageSize,
    phaseFilter,
    resourceFilter,
  };
};
