import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { JSONContent } from '@tiptap/core';
import { Theme } from 'common/enums';
import { ButtonContained } from 'components/Inputs/Button';
import PlaybookSearchableTree from 'components/Playbook/AssignContent/PlaybookSearchableTree';
import { useCustomMediaQuery } from 'hooks';
import { useTheme } from 'next-themes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetAllUserOnProject } from 'services/api/client/project/useGetAllProjectUsersList';
import { useCreateAssignment } from 'services/api/product/assignContent/useCreateAssignment';
import { useGetAssignmentInfo } from 'services/api/product/assignContent/useGetAssignmentInfo';
import { useUpdateAssignment } from 'services/api/product/assignContent/useUpdateAssignment';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import {
  AssignmentInfo,
  TaskAssignment,
  UnitAssignment,
} from 'types/AssignContent';
import {
  IncubatePlaybookTaskWithVideo,
  SelectedUnits,
} from 'types/incubatePlaybook';
import { convertMilliseconds } from 'utils';
import { millisecondsIn } from 'utils/time';
import { ModalStyled } from '../../Modal/ModalStyled';
import { PlaybookCircularLoader } from '../PlaybookCircularLoader';
import { AssignableContentUnitsAndDetails } from './AssignableContentUnitsAndDetails';
import { PlaybookSearchableList } from './PlaybookSearchableList';
import { TaskPreview } from './TaskPreview';

export type AssignContentFormData = {
  dueDate: Date | null;
  assignees: string[];
  notes: { json: JSONContent; text: string };
};

type AssignContentModalProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  preselectedUnits?: {
    unitId: string;
    moduleId: string;
    phaseId: string;
  }[];
  assignmentId?: string;
};

const emptyJsonContent: JSONContent = {
  type: 'doc',
  content: [],
};

export const AssignContentModal = ({
  open,
  onClose,
  onSuccess,
  preselectedUnits = [],
  assignmentId,
}: AssignContentModalProps) => {
  const { theme } = useTheme();
  const { lessThenSm } = useCustomMediaQuery();
  const { data, isLoading } = useGetAssignmentInfo({
    defaultParams: { assignmentId },
    onSuccess: (result) => {
      if (result) {
        const notes = {
          json: result.notes as JSONContent,
          text: result.notesPlainText,
        };
        reset({
          notes,
          assignees: result.assignees,
          dueDate: new Date(result.dueDate),
        });
        setSelectedUnits(getPreselectedUnitsWithTasks(result.assignments));
      }
    },
  });
  const { updateAssignment, isUpdating } = useUpdateAssignment({
    defaultParams: { assignmentId },
    onSuccess: () => {
      onSuccess && onSuccess();
      onClose();
    },
  });
  const { createAssignment, isCreating } = useCreateAssignment({
    onSuccess: () => {
      onSuccess && onSuccess();
      onClose();
    },
  });
  const { data: usersOnProject, isLoading: isLoadingUsersOnProject } =
    useGetAllUserOnProject();
  const { assignableContent, allUnitData } = useIncubatePlaybookData();

  const {
    assignLabel,
    saveAndAssignLabel,
    assignNewVideosLabel,
    editVideosAssignmentLabel,
    minuteLabel,
    videosLabel,
    noVideosSelectedLabel,
    inTotalLabel,
    continueLabel,
    saveLabel,
    discardLabel,
  } = useCommonTranslations();

  const [mobileModalStep, setMobileModalStep] = useState('assignmentVideos');
  const [previewTask, setPreviewTask] =
    useState<IncubatePlaybookTaskWithVideo | null>(null);

  const formMethods = useForm<AssignContentFormData>({
    defaultValues: {
      dueDate: data?.dueDate ? new Date(data?.dueDate) : null,
      assignees: data?.assignees ?? [],
      notes: {
        json: (data?.notes as JSONContent) ?? emptyJsonContent,
        text: data?.notesPlainText ?? '',
      },
    },
  });

  const {
    reset,
    getValues,
    formState: { dirtyFields },
  } = formMethods;

  useEffect(() => {
    if (!isLoadingUsersOnProject && !assignmentId) {
      reset({
        ...getValues(),
        assignees: usersOnProject?.items.map((member) => member.userId) ?? [],
      });
    }
  }, [assignmentId, getValues, isLoadingUsersOnProject, reset, usersOnProject]);

  const getPreselectedUnitsWithTasks = useCallback(
    (assignments?: AssignmentInfo['assignments']) => {
      if (assignmentId) {
        return Object.entries(
          assignments ?? data?.assignments ?? {}
        ).reduce<SelectedUnits>((acc, [unitId, tasks]) => {
          const unitData = allUnitData[unitId];

          acc[unitId] = {
            id: unitId,
            title: unitData.title,
            tasks,
          };

          return acc;
        }, {});
      }

      return preselectedUnits.reduce<SelectedUnits>((acc, { unitId }) => {
        const unitData = allUnitData[unitId];
        const { title, assignableTasks } = unitData;

        acc[unitId] = {
          id: unitId,
          title,
          tasks: assignableTasks.map((task) => task._id),
        };

        return acc;
      }, {});
    },
    [allUnitData, assignmentId, data?.assignments, preselectedUnits]
  );

  const [selectedUnits, setSelectedUnits] = useState<SelectedUnits>(
    getPreselectedUnitsWithTasks()
  );

  const expandedNodes = useMemo(() => {
    if (assignmentId) {
      return Object.keys(data?.assignments ?? {})?.flatMap((unitId) => {
        const { phaseId, moduleId } = allUnitData[unitId];

        return [phaseId, moduleId];
      });
    } else {
      return (
        preselectedUnits.flatMap(({ phaseId, moduleId }) => [
          phaseId,
          moduleId,
        ]) ?? []
      );
    }
  }, [allUnitData, assignmentId, data?.assignments, preselectedUnits]);

  const selectedVideoNum = Object.keys(selectedUnits).reduce(
    (accumulator, unitId) => {
      return accumulator + (selectedUnits[unitId]?.tasks?.length ?? 0);
    },
    0
  );

  const selectedVideosEstimatedTime = useMemo(() => {
    const selectedUnitTasksTime = (unitId: string) => {
      if (!selectedUnits[unitId]?.tasks) return 0;
      else
        return (
          selectedUnits[unitId]?.tasks?.reduce((acc, taskId) => {
            const task = allUnitData[unitId].assignableTasks.find(
              (task) => task._id === taskId
            );

            if (!task) return acc;

            return (
              acc +
              convertMilliseconds(
                task.video?.source?.duration
                  ? task.video?.source?.duration * 1000
                  : 0,
                millisecondsIn.minutes,
                'up'
              )
            );
          }, 0) ?? 0
        );
    };

    return Object.keys(selectedUnits).reduce((timeAcc, unitId) => {
      return timeAcc + selectedUnitTasksTime(unitId);
    }, 0);
  }, [allUnitData, selectedUnits]);

  const isPreviewTaskSelected = useMemo(() => {
    return (
      previewTask &&
      Object.keys(selectedUnits).some((unitId) => {
        return selectedUnits[unitId]?.tasks?.includes(previewTask._id);
      })
    );
  }, [previewTask, selectedUnits]);

  const onModalClose = () => {
    onClose();
  };

  const setNextStep = () => {
    setMobileModalStep('assignmentDetails');
  };

  const createNewAssignment = () => {
    const assignmentsUnitData = {} as Record<string, UnitAssignment>;
    Object.keys(selectedUnits).forEach((unitId) => {
      const assignmentsTaskData = {} as Record<string, TaskAssignment>;
      selectedUnits[unitId]?.tasks?.forEach((taskId) => {
        const task = allUnitData[unitId].assignableTasks.find(
          (task) => task._id === taskId
        ) as IncubatePlaybookTaskWithVideo;

        assignmentsTaskData[taskId] = {
          taskName: task.title,
          videoId: task.video!._id,
          videoLength: task.video!.source.duration * 1000,
        };
      });

      assignmentsUnitData[unitId] = {
        unitName: allUnitData[unitId].title,
        moduleId: allUnitData[unitId].moduleId,
        moduleName: allUnitData[unitId].moduleTitle,
        phaseId: allUnitData[unitId].phaseId,
        phaseName: allUnitData[unitId].phaseTitle,
        tasks: assignmentsTaskData,
      };
    });

    const assignmentsDetails = formMethods.getValues();

    const params = {
      ...assignmentsDetails,
      dueDate: assignmentsDetails.dueDate!,
      assignments: assignmentsUnitData,
      notes: assignmentsDetails.notes.json,
      notesPlainText: assignmentsDetails.notes.text
        .replaceAll('\n\n', ' ')
        .replaceAll('\n', ' '),
    };

    if (assignmentId) {
      updateAssignment(params);
    } else {
      createAssignment(params);
    }
  };

  const handleTeamSelectSave = () => {
    formMethods.reset(formMethods.getValues());
    setMobileModalStep('assignmentDetails');
  };

  const handleTeamSelectReset = () => {
    formMethods.reset();
    setMobileModalStep('assignmentDetails');
  };

  const title = assignmentId ? editVideosAssignmentLabel : assignNewVideosLabel;

  return (
    <ModalStyled
      onClose={onModalClose}
      open={open}
      style={modalStyle(lessThenSm, theme)}
      fullScreen={lessThenSm}
      elevation={1}
    >
      <>
        <DialogTitle fontWeight={700} sx={lessThenSm ? { p: 0 } : {}}>
          {!lessThenSm && title}
          {onModalClose ? (
            <IconButton
              aria-label="close"
              onClick={onModalClose}
              sx={closeIconSx}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent sx={dialogContentSx(lessThenSm)}>
          {isLoading && assignmentId ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <PlaybookCircularLoader />
            </Box>
          ) : (
            <FormProvider {...formMethods}>
              {lessThenSm ? (
                <PlaybookSearchableList
                  mobileModalStep={mobileModalStep}
                  setMobileModalStep={setMobileModalStep}
                  content={assignableContent}
                  selectedUnits={selectedUnits}
                  setSelectedUnits={setSelectedUnits}
                  previewTask={previewTask}
                  setPreviewTask={setPreviewTask}
                  isPreviewTaskSelected={isPreviewTaskSelected ?? false}
                  label={title}
                />
              ) : (
                <Grid container>
                  <Grid item xs={4} height="100%">
                    <PlaybookSearchableTree
                      content={assignableContent}
                      selectedUnits={selectedUnits}
                      setSelectedUnits={setSelectedUnits}
                      expandedNodes={expandedNodes}
                    />
                  </Grid>
                  <Grid item xs={8} height="100%" pl={1} position="relative">
                    <AssignableContentUnitsAndDetails
                      selectedUnits={selectedUnits}
                      setSelectedUnits={setSelectedUnits}
                      selectedVideoNum={selectedVideoNum}
                      selectedVideosEstimatedTime={selectedVideosEstimatedTime}
                      setPreviewTask={setPreviewTask}
                      detailsDisabled={!selectedVideoNum}
                    />
                    {previewTask && (
                      <TaskPreview
                        task={previewTask}
                        setPreviewTask={setPreviewTask}
                        selected={isPreviewTaskSelected ?? false}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </FormProvider>
          )}
        </DialogContent>
        <DialogActions
          sx={dialogActionsSx(
            lessThenSm,
            mobileModalStep,
            Object.keys(dirtyFields).includes('assignees') ?? false
          )}
        >
          {lessThenSm && mobileModalStep !== 'assignmentAssignees' && (
            <Box>
              {selectedVideoNum ? (
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    {videosLabel(selectedVideoNum)}
                    <br />
                    {minuteLabel(selectedVideosEstimatedTime)} {inTotalLabel}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  {noVideosSelectedLabel}
                </Typography>
              )}
            </Box>
          )}
          {(mobileModalStep === 'assignmentVideos' ||
            mobileModalStep === 'frequentlyAssignedNext') &&
            lessThenSm && (
              <ButtonContained
                color="secondary"
                sx={buttonSx}
                disabled={!selectedVideoNum}
                onClick={setNextStep}
              >
                {continueLabel}
              </ButtonContained>
            )}
          {(mobileModalStep === 'assignmentDetails' || !lessThenSm) && (
            <ButtonContained
              color="secondary"
              sx={buttonSx}
              loading={isCreating || isUpdating}
              disabled={!selectedVideoNum}
              onClick={formMethods.handleSubmit(createNewAssignment)}
            >
              {assignmentId ? saveAndAssignLabel : assignLabel}
            </ButtonContained>
          )}
          {mobileModalStep === 'assignmentAssignees' && lessThenSm && (
            <>
              <Typography
                variant="body2"
                color="secondary"
                onClick={handleTeamSelectReset}
                sx={cursorPointerSx}
              >
                {discardLabel}
              </Typography>

              <ButtonContained
                color="secondary"
                sx={buttonSx}
                disabled={!formMethods.formState.isDirty}
                loading={isCreating || isUpdating}
                onClick={handleTeamSelectSave}
              >
                {saveLabel}
              </ButtonContained>
            </>
          )}
        </DialogActions>
      </>
    </ModalStyled>
  );
};

const cursorPointerSx = { cursor: 'pointer' };

const dialogContentSx = (lessThenSm: boolean) => ({
  display: lessThenSm ? 'block' : 'flex',
});

const dialogActionsSx = (
  lessThenSm: boolean,
  mobileModalStep: string,
  isDirty: boolean
) => ({
  display:
    !lessThenSm || mobileModalStep !== 'assignmentAssignees' || isDirty
      ? 'flex'
      : 'none',
  justifyContent: lessThenSm ? 'space-between' : 'flex-end',
  alignItems: 'center',
  ...(lessThenSm ? mobileActionsStyle : {}),
});

const mobileActionsStyle = {
  mb: '-20px',
  p: '20px 30px',
  bgcolor: 'background.paper',
};

const mobileModalStyle = {
  maxHeight: 'unset',
  margin: 0,
  minHeight: 'unset',
  width: '100%',
  minWidth: 'unset',
};

const buttonSx = { borderRadius: '35px' };

const closeIconSx = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'text.secondary',
  zIndex: 6,
};

const modalStyle = (lessThenSm: boolean, theme?: string) => ({
  p: lessThenSm ? '30px 0 20px 0' : '20px',
  height: '100%',
  minWidth: '720px',
  maxWidth: '1000px',
  width: '95%',
  ...(lessThenSm ? mobileModalStyle : {}),
  backgroundColor:
    theme === Theme.dark
      ? 'background.paper'
      : lessThenSm
      ? 'background.default'
      : 'background.paper',
});
