import CheckIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, Typography } from '@mui/material';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookUnit } from 'types/incubatePlaybook';
import { getPortableToPlainText } from 'utils/incubatePlaybook';
import { TaskWrapper } from './TaskWrapper';

type PlaybookIntroductionTaskProps = {
  title: string;
  summary: IncubatePlaybookUnit['summary'];
  objectives: IncubatePlaybookUnit['objectives'];
  image: IncubatePlaybookUnit['image'];
};

export const PlaybookIntroductionTask = ({
  title,
  summary,
  objectives,
  image,
}: PlaybookIntroductionTaskProps) => {
  const {
    heresWhatYourTeamWillWantToAccomplishLabel,
    objectivesWithSingularLabel,
  } = useCommonTranslations();

  return (
    <Box display="flex" flexDirection="column" gap="30px">
      <TaskWrapper image={image}>
        <Typography
          mb="20px"
          fontSize={20}
          fontWeight={600}
          data-testid="task-title"
        >
          {title}
        </Typography>
        <Typography>{getPortableToPlainText(summary)}</Typography>
      </TaskWrapper>
      <TaskWrapper>
        <Typography mb="10px" fontSize={20} fontWeight={600}>
          {objectivesWithSingularLabel(objectives?.length)}
        </Typography>
        <Typography mb="25px" fontSize={14}>
          {heresWhatYourTeamWillWantToAccomplishLabel}
        </Typography>
        <Box display="flex" flexDirection="column" gap="8px">
          {objectives?.map((x) => (
            <Box key={x} display="flex" gap="12px">
              <CheckIcon sx={iconStyle} />
              <Typography>{x}</Typography>
            </Box>
          ))}
        </Box>
      </TaskWrapper>
    </Box>
  );
};

export const iconStyle = { opacity: 0.6 };
