import { ProjectStatus } from 'common/projectConsts';
import { UserStatuses } from 'common/userConsts';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

const possibleStatuses = { ...UserStatuses, ...ProjectStatus };

export const useCommonTranslations = () => {
  const { formatMessage } = useIntl();

  const noResultsLabel = formatMessage({
    id: 'common.noResults',
    defaultMessage: 'No results.',
  });

  const cancelLabel = formatMessage({
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  });

  const startDateLabel = formatMessage({
    id: 'common.startDate',
    defaultMessage: 'Start Date',
  });

  const endDateLabel = formatMessage({
    id: 'common.endDate',
    defaultMessage: 'End Date',
  });

  const nameLabel = formatMessage({
    id: 'common.name',
    defaultMessage: 'Name',
  });

  const settingsLabel = formatMessage({
    id: 'common.settings',
    defaultMessage: 'Settings',
  });

  const emailLabel = formatMessage({
    id: 'common.email',
    defaultMessage: 'Email',
  });

  const deleteLabel = formatMessage({
    id: 'common.delete',
    defaultMessage: 'Delete',
  });

  const ownerLabel = formatMessage({
    id: 'common.owner',
    defaultMessage: 'Owner',
  });

  const inviteLabel = formatMessage({
    id: 'common.invite',
    defaultMessage: 'Invite',
  });

  const configureFeatures = formatMessage({
    id: 'common.configureFeatures',
    defaultMessage: 'Configure Features',
  });

  const uploadLabel = formatMessage({
    id: 'common.upload',
    defaultMessage: 'Upload',
  });

  const uploadPhotoBySizeLabel = (width: number, height: number) =>
    formatMessage(
      {
        id: 'common.ploadPhotoBySize',
        defaultMessage:
          'Upload a {width, number} by {height, number} pixel in a PNG, JPG, or SVG format',
      },
      {
        width,
        height,
      }
    );

  const uploadPhotoLabel = formatMessage({
    id: 'common.uploadPhoto',
    defaultMessage: 'Upload photo',
  });

  const passwordLabel = formatMessage({
    id: 'common.password',
    defaultMessage: 'Password',
  });

  const roleLabel = formatMessage({
    id: 'common.role',
    defaultMessage: 'Role',
  });

  const selectRoleLabel = formatMessage({
    id: 'common.selectRole',
    defaultMessage: 'Select Role',
  });

  const saveChangesLabel = formatMessage({
    id: 'common.saveChanges',
    defaultMessage: 'Save changes',
  });

  const saveLabel = formatMessage({
    id: 'common.save',
    defaultMessage: 'Save',
  });

  const saveAndAssignLabel = formatMessage({
    id: 'common.saveAndAssign',
    defaultMessage: 'save and assign',
  });

  const deactivateLabel = formatMessage({
    id: 'common.deactivate',
    defaultMessage: 'Deactivate',
  });

  const statusLabel = formatMessage({
    id: 'common.status',
    defaultMessage: 'Status',
  });

  const editLabel = formatMessage({
    id: 'common.edit',
    defaultMessage: 'Edit',
  });

  const detailsLabel = formatMessage({
    id: 'common.details',
    defaultMessage: 'Details',
  });

  const resendInviteLabel = formatMessage({
    id: 'common.resendInvite',
    defaultMessage: 'Resend Invite',
  });

  const resolvedStatusLabel = (status?: string) =>
    formatMessage(
      {
        id: 'common.resolvedStatus',
        defaultMessage: `{status, select, 
            active {Active} 
            deactivated {Deactivated} 
            pending {Pending} 
            expired {Expired} 
            closed {Closed} 
            email_not_sent {Email Invite Failed}
            other {}
          }`,
      },
      {
        status: status || '',
      }
    );

  const notAssignetLabel = formatMessage({
    id: 'common.notAsigned',
    defaultMessage: 'Not assigned',
  });

  const seeMoreLabel = formatMessage({
    id: 'common.seeMore',
    defaultMessage: 'See More',
  });

  const seemoreWithNumInParenthesesLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.seemoreWithNumInParentheses',
        defaultMessage: 'See more {num, number}',
      },
      { num }
    );

  const seeLessLabel = formatMessage({
    id: 'common.seeless',
    defaultMessage: 'See less',
  });

  const showMoreWithNumLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.showMoreWithNum',
        defaultMessage: 'See {num, number} More',
      },
      { num }
    );

  const collapseLabel = formatMessage({
    id: 'common.collapse',
    defaultMessage: 'Collapse',
  });

  const activeLabel = formatMessage({
    id: 'common.active',
    defaultMessage: 'Active',
  });

  const nowLabel = formatMessage({
    id: 'common.now',
    defaultMessage: 'Now',
  });

  const licenseLabel = formatMessage({
    id: 'common.license',
    defaultMessage: 'License',
  });

  const whiteLabelingLabel = formatMessage({
    id: 'common.whiteLabeling',
    defaultMessage: 'White Labeling',
  });

  const markdownLabel = formatMessage({
    id: 'common.markdown',
    defaultMessage: 'Markdown',
  });

  const typeMarkdownHereLabel = formatMessage({
    id: 'common.typeMarkdownHere',
    defaultMessage: 'Type markdown here',
  });

  const logosLabel = formatMessage({
    id: 'common.logos',
    defaultMessage: 'Logos',
  });

  const logosDarkLabel = formatMessage({
    id: 'common.logos.dark',
    defaultMessage: 'Logos for Dark Mode',
  });

  const logosDarkSubtitleLabel = formatMessage({
    id: 'common.logos.dark.subtitle',
    defaultMessage:
      'Only display when the dark mode is toggled on by the user. Make sure it is visible on a dark background.',
  });

  const logoPreviewLabel = formatMessage({
    id: 'common.logoPreview',
    defaultMessage: 'Logo Preview',
  });

  const largeLabel = formatMessage({
    id: 'common.large',
    defaultMessage: 'Large',
  });

  const smallLabel = formatMessage({
    id: 'common.small',
    defaultMessage: 'Small',
  });

  const brandColorLabel = formatMessage({
    id: 'common.brandColor',
    defaultMessage: 'Brand Color',
  });

  const hexLabel = formatMessage({
    id: 'common.hex',
    defaultMessage: 'Hex',
  });

  const choseColorLabel = formatMessage({
    id: 'common.choseColor',
    defaultMessage: 'Choose color',
  });

  const addToSelectedWithNumLabel = (itemsNum: number) =>
    formatMessage(
      {
        id: 'common.addToSelectedWithNum',
        defaultMessage: 'Add to selected ({itemsNum, number})',
      },
      {
        itemsNum,
      }
    );

  const customLabel = formatMessage({
    id: 'common.custom',
    defaultMessage: 'Custom',
  });

  const defaultLabel = formatMessage({
    id: 'common.default',
    defaultMessage: 'Default',
  });

  const customizeLabel = formatMessage({
    id: 'common.customize',
    defaultMessage: 'Customize',
  });

  const configureResourcesLabel = formatMessage({
    id: 'common.configureResources',
    defaultMessage: 'Configure Resources',
  });

  const resourcesLabel = formatMessage({
    id: 'common.resources',
    defaultMessage: 'Resources',
  });

  const inviteSelectedWithNumLabel = (itemsNum: number) =>
    formatMessage(
      {
        id: 'common.inviteSelectedWithNum',
        defaultMessage: 'Invite selected ({itemsNum, number})',
      },
      {
        itemsNum,
      }
    );

  const resetToDefaultSettingsLabel = formatMessage({
    id: 'common.resetToDefaultSettings',
    defaultMessage: 'Reset to Default Settings',
  });

  const footerContentLabel = formatMessage({
    id: 'common.footerContent',
    defaultMessage: 'Footer Content',
  });

  const saveChangesQuestionLabel = formatMessage({
    id: 'common.saveChangesQuestion',
    defaultMessage: 'Save Changes?',
  });

  const unsavedChangesWillBeLostLabel = formatMessage({
    id: 'common.unsavedChangesWillBeLost',
    defaultMessage: 'Your unsaved changes will be lost.',
  });

  const discardChangesLabel = formatMessage({
    id: 'common.discardChanges',
    defaultMessage: 'Discard Changes',
  });

  const reactivateLabel = formatMessage({
    id: 'common.reactivate',
    defaultMessage: 'Reactivate',
  });

  const continueWithReactivationQuestionLabel = formatMessage({
    id: 'common.continueWithReactivationQuestion',
    defaultMessage: 'Are you sure you want to continue with reactivation?',
  });

  const inviteSentLabel = formatMessage({
    id: 'common.inviteSent',
    defaultMessage: 'Invite sent',
  });

  const emailSentLabel = formatMessage({
    id: 'common.emailSent',
    defaultMessage: 'Email sent',
  });

  const passwordChangedLabel = formatMessage({
    id: 'common.passwordChanged',
    defaultMessage: 'Password changed',
  });

  const seedingInProgressLabel = formatMessage({
    id: 'common.seedingInProgress',
    defaultMessage: 'Seeding in progress',
  });

  const somethingWentWrongLabel = formatMessage({
    id: 'common.somethingWentWrong',
    defaultMessage: 'Something went wrong',
  });

  const nameAlreadyExistsLabel = formatMessage({
    id: 'common.nameAlreadyExists',
    defaultMessage: 'Name already exists',
  });

  const emailAlreadyExistsLabel = formatMessage({
    id: 'common.emailAlreadyExists',
    defaultMessage: 'Email already exists',
  });

  const entityNotFoundLabel = formatMessage({
    id: 'common.entityNotFound',
    defaultMessage: 'Entity not found',
  });

  const logoUploadFailedLabel = formatMessage({
    id: 'common.logoUploadFailed',
    defaultMessage: 'Logo upload failed',
  });

  const imageUploadFailedLabel = formatMessage({
    id: 'common.imageUploadFailed',
    defaultMessage: 'Image upload failed',
  });

  const imageTooBigLabel = formatMessage({
    id: 'common.imageTooBig',
    defaultMessage: 'Image size is too big! Please use picture below 3MB.',
  });

  const imageWrongFileTypeLabel = formatMessage({
    id: 'common.imageWrongFileType',
    defaultMessage: 'Wrong file type! Please use Image file type.',
  });

  const documentTooBigLabel = formatMessage({
    id: 'common.documentTooBig',
    defaultMessage: 'Document size is too big! Please use file below 10MB.',
  });

  const documentWrongFileTypeLabel = formatMessage({
    id: 'common.documentWrongFileType',
    defaultMessage:
      'Wrong file type! Please use PDF, Word, Excel or Power Point file type.',
  });

  const tooManyRequestsLabel = formatMessage({
    id: 'common.tooManyRequests',
    defaultMessage: 'Too many requests',
  });

  const forbiddenResourceLabel = formatMessage({
    id: 'common.forbiddenResource',
    defaultMessage: 'Forbidden resource',
  });

  const searchClientsOrProjectsLabel = formatMessage({
    id: 'common.searchClientsOrProjects',
    defaultMessage: 'Search Clients or Projects',
  });

  const suggestedTimeFrameLabel = formatMessage({
    id: 'common.suggestedTimeFrame',
    defaultMessage: 'Suggested Time Frame',
  });

  const weekLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.week',
        defaultMessage: '{num, plural, =0 {} one {# week} other {# weeks}}',
      },
      { num }
    );

  const dayLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.day',
        defaultMessage: '{num, plural, =0 {} one {# day} other {# days}}',
      },
      { num }
    );

  const hourLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.hour',
        defaultMessage: '{num, plural, =0 {} one {# hour} other {# hours}}',
      },
      { num }
    );

  const minuteLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.minute',
        defaultMessage: '{num, plural, =0 {} one {# minute} other {# minutes}}',
      },
      { num }
    );

  const saveOrSavedToFavsLabel = (saved: boolean) =>
    formatMessage(
      {
        id: 'common.saveOrSavedToFavs',
        defaultMessage:
          '{saved, select, true {Saved} other {Save}} to Favorites',
      },
      { saved }
    );

  const inProgressLabel = formatMessage({
    id: 'common.inProgress',
    defaultMessage: 'In Progress',
  });

  const homeLabel = formatMessage({
    id: 'common.home',
    defaultMessage: 'Home',
  });

  const ventureBuilderLabel = formatMessage({
    id: 'common.ventureBuilder',
    defaultMessage: 'Venture Builder',
  });

  const favoritesLabel = formatMessage({
    id: 'common.favorites',
    defaultMessage: 'Favorites',
  });

  const recentsLabel = formatMessage({
    id: 'common.recents',
    defaultMessage: 'Recents',
  });

  const phaseLabel = formatMessage({
    id: 'common.phase',
    defaultMessage: 'Phase',
  });

  const moduleLabel = formatMessage({
    id: 'common.module',
    defaultMessage: 'Module',
  });

  const unitLabel = formatMessage({
    id: 'common.unit',
    defaultMessage: 'Unit',
  });

  const attachmentLabel = formatMessage({
    id: 'common.attachment',
    defaultMessage: 'Attachment',
  });

  const taskLabel = formatMessage({
    id: 'common.task',
    defaultMessage: 'Task',
  });

  const transcriptLabel = formatMessage({
    id: 'common.transcript',
    defaultMessage: 'Transcript',
  });

  const closeLabel = formatMessage({
    id: 'common.close',
    defaultMessage: 'Close',
  });

  const fullListLabel = formatMessage({
    id: 'common.fullList',
    defaultMessage: 'Full List',
  });

  const noTaskLabel = formatMessage({
    id: 'common.noTask',
    defaultMessage: 'No task',
  });

  const instructionsLabel = formatMessage({
    id: 'common.instructions',
    defaultMessage: 'Instructions',
  });

  const stillNeedHelpQuestionLabel = formatMessage({
    id: 'common.stillNeedHelpQuestion',
    defaultMessage: 'Still Need Help?',
  });

  const needHelpQuestionLabel = formatMessage({
    id: 'common.needHelpQuestion',
    defaultMessage: 'Need Help?',
  });

  const contactShiftExpertLabel = formatMessage({
    id: 'common.contactShiftExpert',
    defaultMessage: 'Contact a Shift AI expert',
  });

  const contactShiftTechSupportLabel = formatMessage({
    id: 'common.contactShiftTechSupport',
    defaultMessage: 'Contact Shift AI Tech support',
  });

  const contactTechSupportLabel = formatMessage({
    id: 'common.contactTechSupport',
    defaultMessage: 'Contact Tech Support',
  });

  const techSupportLabel = formatMessage({
    id: 'common.techSupport',
    defaultMessage: 'Tech Support',
  });

  const accountHelpLabel = formatMessage({
    id: 'common.accountHelp',
    defaultMessage: 'Account Help',
  });

  const addHelpSuffixLabel = (label: string) =>
    formatMessage(
      {
        id: 'common.addHelpSuffix',
        defaultMessage: '{label} Help',
      },
      { label }
    );

  const keyTakeawaysLabel = formatMessage({
    id: 'common.keyTakeaways',
    defaultMessage: 'Key Takeaways',
  });

  const objectivesWithSingularLabel = (count: number = 2) =>
    formatMessage(
      {
        id: 'common.objectivesWithSingular',
        defaultMessage: '{count, plural, one {Objective} other {Objectives}}',
      },
      { count }
    );

  const heresWhatYourTeamWillWantToAccomplishLabel = formatMessage({
    id: 'common.heresWhatYourTeamWillWantToAccomplish',
    defaultMessage: 'Here’s what your team will want to accomplish',
  });

  const hasYourTeamCompletedFollowingObjectivesQuestionLabel = formatMessage({
    id: 'common.hasYourTeamCompletedFollowingObjectivesQuestion',
    defaultMessage: 'Has your team completed the following objectives?',
  });

  const markIsCorrectOrIncorrectLabel = (mark: string, correct?: boolean) =>
    formatMessage(
      {
        id: 'common.quiz.markIsCorrectOrIncorrect',
        defaultMessage:
          '{mark} is {correct, select, true {Correct} other {Incorrect}}',
      },
      { mark, correct }
    );

  const numberOfQuestionsLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.quiz.numberOfQuestions',
        defaultMessage:
          '{num, plural, =0 {} one {# Question} other {# Questions}}',
      },
      { num }
    );

  const quizLabel = formatMessage({
    id: 'common.quiz',
    defaultMessage: 'Quiz',
  });

  const exitQuizLabel = formatMessage({
    id: 'common.quiz.exitQuiz',
    defaultMessage: 'Exit quiz',
  });

  const startQuizLabel = formatMessage({
    id: 'common.quiz.startQuiz',
    defaultMessage: 'Start quiz',
  });

  const congratulationsAllQuestionsCorrectlyLabel = formatMessage({
    id: 'common.quiz.congratulationsAllQuestionsCorrectly',
    defaultMessage: 'Congratulations! You answered all questions correctly.',
  });

  const previousLabel = formatMessage({
    id: 'common.previous',
    defaultMessage: 'Previous',
  });

  const nextLabel = formatMessage({
    id: 'common.next',
    defaultMessage: 'Next',
  });

  const finishLabel = formatMessage({
    id: 'common.finish',
    defaultMessage: 'Finish',
  });

  const submitLabel = formatMessage({
    id: 'common.submit',
    defaultMessage: 'Submit',
  });

  const ofLabel = formatMessage({
    id: 'common.of',
    defaultMessage: 'of',
  });

  const resultsLabel = formatMessage({
    id: 'common.results',
    defaultMessage: 'Results',
  });

  const retakeLabel = formatMessage({
    id: 'common.retako',
    defaultMessage: 'Retake',
  });

  const correctLabel = formatMessage({
    id: 'common.correct',
    defaultMessage: 'Correct',
  });

  const reviewLabel = formatMessage({
    id: 'common.review',
    defaultMessage: 'Review',
  });

  const reviewMissedQuestionsAndRevisitContentLabel = formatMessage({
    id: 'common.quiz.reviewMissedQuestionsAndRevisitContent',
    defaultMessage:
      'Let’s review the questions you missed and revisit the content',
  });

  const testYourKnowledgeOfTitleLabel = (title: string) =>
    formatMessage(
      {
        id: 'common.quiz.testYourKnowledgeOfTitle',
        defaultMessage: 'Test your knowledge of {title}',
      },
      { title }
    );

  const dateAddedLabel = formatMessage({
    id: 'common.dateAdded',
    defaultMessage: 'Date Added',
  });

  const lastViewedLabel = formatMessage({
    id: 'common.lastViewed',
    defaultMessage: 'Last Viewed',
  });

  const noRecentActivityLabel = formatMessage({
    id: 'common.noRecentActivity',
    defaultMessage: 'You don’t have any recent activity to show.',
  });

  const filterByPhaseLabel = formatMessage({
    id: 'common.filterByPhase',
    defaultMessage: 'Filter by Phase',
  });

  const filterByFileTypeLabel = formatMessage({
    id: 'common.filterByFileType',
    defaultMessage: 'Filter by File Type',
  });

  const searchLabel = formatMessage({
    id: 'common.search',
    defaultMessage: 'Search',
  });

  const searchResultsLabel = formatMessage({
    id: 'common.searchResults',
    defaultMessage: 'Search results',
  });

  const weCouldntFindMatchesForItemLabel = (item: string | string[] = '') =>
    formatMessage(
      {
        id: 'common.weCouldntFindMatchesForItem',
        defaultMessage: 'We couldn’t find any matches for “{item}”.',
      },
      { item }
    );

  const checkYourSpellingLabel = formatMessage({
    id: 'common.checkYourSpelling',
    defaultMessage: 'Check your spelling',
  });

  const broadenSearchByFewerOrGeneralWordsLabel = formatMessage({
    id: 'common.broadenSearchByFewerOrGeneralWords',
    defaultMessage: 'Broaden the search by using fewer or more general words',
  });

  const tryAlternativeWordsLabel = formatMessage({
    id: 'common.tryAlternativeWords',
    defaultMessage: 'Try alternative words',
  });

  const nothingFoundTryChangingFiltersLabel = formatMessage({
    id: 'common.nothingFoundTryChangingFilters',
    defaultMessage: 'Nothing found. Try changing your filters.',
  });

  const noFavsClickOnIconToAddLabel = (icon: ReactElement) =>
    formatMessage(
      {
        id: 'common.noFavsClickOnIconToAdd',
        defaultMessage:
          "You haven''t added any favorites yet! Click on the {icon} icon to add something to your favorites.",
      },
      { icon }
    );

  const welcomeToYourTitleLabel = (title: string) =>
    formatMessage(
      {
        id: 'common.welcomeToYourTitle',
        defaultMessage: 'Welcome to Your {title}',
      },
      { title }
    );

  const congratulationsLabel = formatMessage({
    id: 'common.congratulations',
    defaultMessage: 'Congratulations!',
  });

  const backToHomeLabel = formatMessage({
    id: 'common.backToHome',
    defaultMessage: 'Back to home',
  });

  const getStartedLabel = formatMessage({
    id: 'common.getStarted',
    defaultMessage: 'Get started',
  });

  const fileTypeLabel = formatMessage({
    id: 'common.fileType',
    defaultMessage: 'File Type',
  });

  const noFilterLabel = formatMessage({
    id: 'common.noFilter',
    defaultMessage: 'No Filter',
  });

  const searchPlaybookLabel = formatMessage({
    id: 'common.searchPlaybook',
    defaultMessage: 'Search Playbook',
  });

  const didYouFindWhatYouWereLookingForLabel = formatMessage({
    id: 'common.feedback.didYouFindWhatYouWereLookingFor',
    defaultMessage: 'Did you find what you were looking for?',
  });

  const wasThisUsefulQuestionLabel = formatMessage({
    id: 'common.feedback.wasThisUsefulQuestion',
    defaultMessage: 'Was this useful?',
  });

  const greatWhatsWorkingQuestionLabel = formatMessage({
    id: 'common.feedback.greatWhatsWorkingQuestion',
    defaultMessage: 'Great! What’s working?',
  });

  const howWeCanMakeThisBetterQuestionLabel = formatMessage({
    id: 'common.feedback.howWeCanMakeThisBetterQuestion',
    defaultMessage: 'How we can make this better?',
  });

  const iDidntFindWhatIWasLookingForLabel = formatMessage({
    id: 'common.feedback.iDidntFindWhatIWasLookingFor',
    defaultMessage: 'I didn’t find what I was looking for',
  });

  const notReallyUsefulLabel = formatMessage({
    id: 'common.feedback.notReallyUseful',
    defaultMessage: 'Not really useful',
  });

  const iFoundExactlyWhatINeededLabel = formatMessage({
    id: 'common.feedback.iFoundExactlyWhatINeeded',
    defaultMessage: 'I found exactly what I needed',
  });

  const veryUsefulLabel = formatMessage({
    id: 'common.feedback.veryUseful',
    defaultMessage: 'Very useful',
  });

  const shareFeedbackLabel = formatMessage({
    id: 'common.feedback.shareFeedback',
    defaultMessage: 'Share feedback',
  });

  const thankYouForYourFeedbackLabel = formatMessage({
    id: 'common.feedback.thankYouForYourFeedback',
    defaultMessage: 'Thank you for your feedback!',
  });

  const lastEditWasMadeAtTimeByUserLabel = (time: string, name: string) =>
    formatMessage(
      {
        id: 'common.lastEditWasMadeAtTimeByUser',
        defaultMessage: 'Last edit was made {time} by {name}',
      },
      { time, name }
    );

  const showAllLabel = formatMessage({
    id: 'common.showAll',
    defaultMessage: 'Show All',
  });

  const downloadLabel = formatMessage({
    id: 'common.download',
    defaultMessage: 'Download',
  });

  const pngOrJpgAcceptedLabel = formatMessage({
    id: 'common.pngOrJpgAccepted',
    defaultMessage: 'PNG or JPG Accepted',
  });

  const areYouSureYouWantToDeleteNameLabel = (name?: string) =>
    formatMessage(
      {
        id: 'common.areYouSureYouWantToDeleteName',
        defaultMessage: 'Are you sure you want to delete {name}?',
      },
      { name }
    );

  const teamProgressLabel = (progress?: string) =>
    formatMessage(
      {
        id: 'common.teamProgress',
        defaultMessage: '{progress}% Watched',
      },
      { progress }
    );

  const dueDateLeftLabel = (date?: string) =>
    formatMessage(
      {
        id: 'common.dueDateLeft',
        defaultMessage: '({date} left)',
      },
      { date }
    );

  const dueDateLeftNoParenthesesLabel = (date?: string) =>
    formatMessage(
      {
        id: 'common.dueDateLeftNoParentheses',
        defaultMessage: '{date} left',
      },
      { date }
    );

  const videosTimeInTotalLabel = (time?: number) =>
    formatMessage(
      {
        id: 'common.videosTimeInTotal',
        defaultMessage: '{time} min in total',
      },
      { time }
    );

  const allLabel = formatMessage({
    id: 'common.all',
    defaultMessage: 'All',
  });

  const shiftLabel = formatMessage({
    id: 'common.shift',
    defaultMessage: 'Shift AI',
  });

  const languageLabel = formatMessage({
    id: 'common.language',
    defaultMessage: 'Language',
  });

  const languageSelectLabel = formatMessage({
    id: 'common.language.selectLanguage',
    defaultMessage: 'Select your default language',
  });

  const teamLabel = formatMessage({
    id: 'common.team',
    defaultMessage: 'Team',
  });

  const assignedAndNotStartedLabel = formatMessage({
    id: 'common.assignedAndNotStarted',
    defaultMessage: 'Assigned and Not Started',
  });

  const watchedLabel = formatMessage({
    id: 'common.watched',
    defaultMessage: 'Watched',
  });

  const meParenthesesLabel = formatMessage({
    id: 'common.meParentheses',
    defaultMessage: '(Me)',
  });

  const resolvedVideoStatusLabel = (status?: string) =>
    formatMessage(
      {
        id: 'common.resolvedVideoStatus',
        defaultMessage: `{status, select, 
            inProgress {In Progress} 
            watched {Watched} 
            assignedNotStarted {Assigned and Not Started}
            notStarted {Not Started} 
            other {}
          }`,
      },
      {
        status: status || '',
      }
    );

  const assignedVideoStatusLabel = (status?: string) =>
    formatMessage(
      {
        id: 'common.assignedVideoStatus',
        defaultMessage: `{status, select, 
            inProgress {In Progress} 
            watched {Watched} 
            notStarted {Not Started} 
            other {}
          }`,
      },
      {
        status: status || '',
      }
    );

  const assignLabel = formatMessage({
    id: 'common.assign',
    defaultMessage: 'Assign',
  });

  const exploreLabel = formatMessage({
    id: 'common.explore',
    defaultMessage: 'Explore',
  });

  const assignNewVideosLabel = formatMessage({
    id: 'common.assignNewVideos',
    defaultMessage: 'Assign New Videos',
  });

  const editVideosAssignmentLabel = formatMessage({
    id: 'common.editVideosAssignment',
    defaultMessage: 'Edit Video Assignment',
  });

  const myVideoAssignmentsLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.myVideoAssignments',
        defaultMessage: 'My Video Assignments ({num})',
      },
      { num }
    );

  const uploadResourcesLabel = formatMessage({
    id: 'common.uploadResources',
    defaultMessage: 'Upload Resources',
  });

  const uploadFailedLabel = formatMessage({
    id: 'common.uploadFailed',
    defaultMessage: 'Upload Failed!',
  });

  const resourcesUpdatedLabel = formatMessage({
    id: 'common.resourcesUpdated',
    defaultMessage: 'Resource updated',
  });

  const deleteResourceLabel = formatMessage({
    id: 'common.deleteResource',
    defaultMessage: 'Delete Resource',
  });

  const deleteResourceMsgLabel = formatMessage({
    id: 'common.deleteResourceMsg',
    defaultMessage: 'You are about to delete a resource',
  });

  const noEnterpriseResourcesLabel = formatMessage({
    id: 'common.noEnterpriseResources',
    defaultMessage: 'No Enterprise Resources are assigned for this client.',
  });

  const uploadNewLabel = formatMessage({
    id: 'common.uploadNew',
    defaultMessage: 'upload new',
  });

  const previewLabel = formatMessage({
    id: 'common.preview',
    defaultMessage: 'Preview',
  });

  const noVideosSelectedLabel = formatMessage({
    id: 'common.noVideosSelected',
    defaultMessage: 'No Videos Selected',
  });

  const deselectAllLabel = formatMessage({
    id: 'common.deselectAll',
    defaultMessage: 'Deselect All',
  });

  const videosLabel = (num: number) =>
    formatMessage(
      {
        id: 'common.videos',
        defaultMessage: '{num, plural, =0 {} one {# video} other {# videos}}',
      },
      { num }
    );

  const inTotalLabel = formatMessage({
    id: 'common.inTotal',
    defaultMessage: 'in total',
  });

  const selectedLabel = formatMessage({
    id: 'common.selected',
    defaultMessage: 'selected',
  });

  const capitalSelectedLabel = formatMessage({
    id: 'common.capitalSelected',
    defaultMessage: 'Selected',
  });

  const backLabel = formatMessage({
    id: 'common.back',
    defaultMessage: 'Back',
  });

  const notSelectedLabel = formatMessage({
    id: 'common.notSelected',
    defaultMessage: 'Not Selected',
  });

  const summaryLabel = formatMessage({
    id: 'common.summary',
    defaultMessage: 'Summary',
  });

  const continueLabel = formatMessage({
    id: 'common.continue',
    defaultMessage: 'Continue',
  });

  const unauthorizedPageLabel = formatMessage({
    id: 'common.unauthorizedPage',
    defaultMessage: "You don't have access to this page.",
  });

  const unauthorizedPageDescriptionLabel = formatMessage({
    id: 'common.unauthorizedPageDescription',
    defaultMessage:
      'Make sure the URL is correct. If it is, ask an admin for permission to see this page.',
  });

  const newVideoAssignmentCreatedLabel = formatMessage({
    id: 'common.newVideoAssignmentCreated',
    defaultMessage: 'New video assignment created',
  });

  const notesLabel = formatMessage({
    id: 'common.notes',
    defaultMessage: 'Notes',
  });

  const discardLabel = formatMessage({
    id: 'common.discard',
    defaultMessage: 'Discard',
  });

  const dueDateLabel = formatMessage({
    id: 'common.dueDate',
    defaultMessage: 'Due Date',
  });

  const assigneesLabel = formatMessage({
    id: 'common.assignees',
    defaultMessage: 'Assignee(s)',
  });

  const allTeamMembersLabel = formatMessage({
    id: 'common.allTeamMembers',
    defaultMessage: 'All team members',
  });

  const viewAllAssignmentsLabel = formatMessage({
    id: 'common.viewAllAssignments',
    defaultMessage: 'View All Assignments',
  });

  const assignmentsLabel = formatMessage({
    id: 'common.assignments',
    defaultMessage: 'Assignments',
  });

  const frequentlyAssignedNextLabel = formatMessage({
    id: 'common.frequentlyAssignedNext',
    defaultMessage: 'Frequently Assigned Next',
  });

  const frequentlyAssignedNextHiddenLabel = formatMessage({
    id: 'common.frequentlyAssignedNextHidden',
    defaultMessage: 'Frequently Assigned Next successfully hidden',
  });

  const assignVideosLabel = formatMessage({
    id: 'common.assignVideos',
    defaultMessage: 'Assign Videos',
  });

  const assignVideoLabel = formatMessage({
    id: 'common.assignVideo',
    defaultMessage: 'Assign Video',
  });

  const backToAssignmentsLabel = formatMessage({
    id: 'common.backToAssignments',
    defaultMessage: 'Back to Assignments',
  });

  const noFrequentlyAssignedNextTopicsLabel = formatMessage({
    id: 'common.noFrequentlyAssignedNextTopics',
    defaultMessage: 'No frequently assigned next topics',
  });

  const topicsLabel = formatMessage({
    id: 'common.topics',
    defaultMessage: 'Topics',
  });

  const videoCountLabel = formatMessage({
    id: 'common.videoCount',
    defaultMessage: 'Video Count',
  });

  const hideLabel = formatMessage({
    id: 'common.hide',
    defaultMessage: 'Hide',
  });

  const viewAllLabel = formatMessage({
    id: 'common.viewAll',
    defaultMessage: 'View All',
  });

  const noTeamMembersLabel = formatMessage({
    id: 'common.noTeamMembers',
    defaultMessage: 'No team members',
  });

  const viewAssignmentDetailsLabel = formatMessage({
    id: 'common.viewAssignmentDetails',
    defaultMessage: 'view assignment details',
  });

  const noVideosAssignedLabel = formatMessage({
    id: 'common.noVideosAssigned',
    defaultMessage: 'No Videos Assigned.',
  });

  const assignedByLabel = formatMessage({
    id: 'common.assignedBy',
    defaultMessage: 'Assigned by',
  });

  const teamProgressInfoLabel = formatMessage({
    id: 'common.teamProgressInfo',
    defaultMessage: 'Team Progress',
  });

  const yourProgressLabel = formatMessage({
    id: 'common.yourProgress',
    defaultMessage: 'Your Progress',
  });

  const taskNotFoundLabel = formatMessage({
    id: 'common.taskNotFound',
    defaultMessage: 'Task not found',
  });

  const unitNotFoundLabel = formatMessage({
    id: 'common.unitNotFound',
    defaultMessage: 'Unit not found',
  });

  const moduleNotFoundLabel = formatMessage({
    id: 'common.moduleNotFound',
    defaultMessage: 'Module not found',
  });

  const phaseNotFoundLabel = formatMessage({
    id: 'common.phaseNotFound',
    defaultMessage: 'Phase not found',
  });

  const assignmentUpdatedLabel = formatMessage({
    id: 'common.assignmentUpdated',
    defaultMessage: 'Assignment updated',
  });

  const assignedUnitRemovedLabel = formatMessage({
    id: 'common.assignedUnitRemoved',
    defaultMessage: 'Unit is removed from the assignment',
  });

  const deleteAssignedUnitTitleLabel = formatMessage({
    id: 'common.deleteAssignedUnitTitle',
    defaultMessage: 'Delete Assignment',
  });

  const deleteAssignedUnitTextLabel = formatMessage({
    id: 'common.deleteAssignedUnitText',
    defaultMessage:
      'Your team will no longer receive notifications about this assignment.',
  });

  const deleteAssignedUnitAlertTitleLabel = (unitName: string) =>
    formatMessage(
      {
        id: 'common.deleteAssignedUnitAlertTitle',
        defaultMessage: 'You are about to delete {unitName} Assignment',
      },
      { unitName }
    );

  const allAssignmentsLabel = formatMessage({
    id: 'common.allAssignments',
    defaultMessage: 'All Assignments',
  });

  const myProgressLabel = formatMessage({
    id: 'common.myProgress',
    defaultMessage: 'My Progress',
  });

  const noAssignmentsClickToCreateLabel = formatMessage({
    id: 'common.noAssignmentsClickToCreate',
    defaultMessage:
      "You currently don't have any assignments. Click on the button above to create your first assignment.",
  });

  const filterByMyProgressLabel = formatMessage({
    id: 'common.filterByProgress',
    defaultMessage: 'Filter by My Progress',
  });

  const createdOnLabel = formatMessage({
    id: 'common.createdOn',
    defaultMessage: 'Created On',
  });

  const progressLabel = formatMessage({
    id: 'common.progress',
    defaultMessage: 'Progress',
  });

  const notStartedLabel = formatMessage({
    id: 'common.notStarted',
    defaultMessage: 'Not Started',
  });

  const usersWatchedThisVideoLabel = (users: string) =>
    formatMessage(
      {
        id: 'common.usersWatchedThisVideo',
        defaultMessage: '{users} watched this video',
      },
      { users }
    );

  const allPhasesLabel = formatMessage({
    id: 'common.allPhases',
    defaultMessage: 'All Phases',
  });

  const pastDueDateLabel = formatMessage({
    id: 'common.pastDueDate',
    defaultMessage: 'Past Due Date',
  });

  const deleteTaskDeleteUnitLabel = formatMessage({
    id: 'common.deleteTaskDeleteUnit',
    defaultMessage:
      'This is only selected task within the unit. If you remove this task you will also remove this unit.',
  });

  const wrongCredentialsLabel = formatMessage({
    id: 'common.wrongCredentials',
    defaultMessage: 'The credentials provided were incorrect',
  });

  const newPasswordUnchangedLabel = formatMessage({
    id: 'common.newPasswordUnchanged',
    defaultMessage: 'New password cannot be the same as the old password.',
  });

  const cannotSaveWhileChangingPasswordLabel = formatMessage({
    id: 'common.cannotSaveWhileChangingPassword',
    defaultMessage: 'Cannot save changes while changing password.',
  });

  return {
    cannotSaveWhileChangingPasswordLabel,
    newPasswordUnchangedLabel,
    wrongCredentialsLabel,
    deleteTaskDeleteUnitLabel,
    pastDueDateLabel,
    allPhasesLabel,
    usersWatchedThisVideoLabel,
    dueDateLeftNoParenthesesLabel,
    notStartedLabel,
    progressLabel,
    createdOnLabel,
    filterByMyProgressLabel,
    noAssignmentsClickToCreateLabel,
    myProgressLabel,
    allAssignmentsLabel,
    assignVideoLabel,
    viewAllLabel,
    hideLabel,
    videoCountLabel,
    topicsLabel,
    noFrequentlyAssignedNextTopicsLabel,
    backToAssignmentsLabel,
    frequentlyAssignedNextLabel,
    assignVideosLabel,
    frequentlyAssignedNextHiddenLabel,
    assignmentsLabel,
    noTeamMembersLabel,
    viewAllAssignmentsLabel,
    allTeamMembersLabel,
    assigneesLabel,
    dueDateLabel,
    discardLabel,
    notesLabel,
    newVideoAssignmentCreatedLabel,
    continueLabel,
    summaryLabel,
    notSelectedLabel,
    capitalSelectedLabel,
    backLabel,
    selectedLabel,
    inTotalLabel,
    videosLabel,
    deselectAllLabel,
    noVideosSelectedLabel,
    previewLabel,
    exploreLabel,
    myVideoAssignmentsLabel,
    assignNewVideosLabel,
    editVideosAssignmentLabel,
    assignLabel,
    resolvedVideoStatusLabel,
    assignedVideoStatusLabel,
    meParenthesesLabel,
    watchedLabel,
    assignedAndNotStartedLabel,
    teamLabel,
    allLabel,
    shiftLabel,
    areYouSureYouWantToDeleteNameLabel,
    teamProgressLabel,
    dueDateLeftLabel,
    videosTimeInTotalLabel,
    pngOrJpgAcceptedLabel,
    downloadLabel,
    showAllLabel,
    lastEditWasMadeAtTimeByUserLabel,
    thankYouForYourFeedbackLabel,
    didYouFindWhatYouWereLookingForLabel,
    wasThisUsefulQuestionLabel,
    greatWhatsWorkingQuestionLabel,
    howWeCanMakeThisBetterQuestionLabel,
    iDidntFindWhatIWasLookingForLabel,
    notReallyUsefulLabel,
    iFoundExactlyWhatINeededLabel,
    veryUsefulLabel,
    shareFeedbackLabel,
    searchPlaybookLabel,
    noFilterLabel,
    fileTypeLabel,
    welcomeToYourTitleLabel,
    getStartedLabel,
    backToHomeLabel,
    congratulationsLabel,
    noFavsClickOnIconToAddLabel,
    nothingFoundTryChangingFiltersLabel,
    filterByFileTypeLabel,
    tryAlternativeWordsLabel,
    broadenSearchByFewerOrGeneralWordsLabel,
    checkYourSpellingLabel,
    weCouldntFindMatchesForItemLabel,
    searchResultsLabel,
    searchLabel,
    filterByPhaseLabel,
    noRecentActivityLabel,
    lastViewedLabel,
    dateAddedLabel,
    ventureBuilderLabel,
    favoritesLabel,
    recentsLabel,
    needHelpQuestionLabel,
    finishLabel,
    testYourKnowledgeOfTitleLabel,
    startQuizLabel,
    reviewMissedQuestionsAndRevisitContentLabel,
    reviewLabel,
    correctLabel,
    resultsLabel,
    retakeLabel,
    previousLabel,
    nextLabel,
    submitLabel,
    ofLabel,
    congratulationsAllQuestionsCorrectlyLabel,
    numberOfQuestionsLabel,
    quizLabel,
    exitQuizLabel,
    markIsCorrectOrIncorrectLabel,
    hasYourTeamCompletedFollowingObjectivesQuestionLabel,
    objectivesWithSingularLabel,
    heresWhatYourTeamWillWantToAccomplishLabel,
    keyTakeawaysLabel,
    contactShiftExpertLabel,
    contactShiftTechSupportLabel,
    contactTechSupportLabel,
    techSupportLabel,
    accountHelpLabel,
    addHelpSuffixLabel,
    stillNeedHelpQuestionLabel,
    instructionsLabel,
    noTaskLabel,
    fullListLabel,
    closeLabel,
    taskLabel,
    transcriptLabel,
    phaseLabel,
    moduleLabel,
    unitLabel,
    attachmentLabel,
    homeLabel,
    inProgressLabel,
    saveOrSavedToFavsLabel,
    weekLabel,
    dayLabel,
    hourLabel,
    minuteLabel,
    suggestedTimeFrameLabel,
    seemoreWithNumInParenthesesLabel,
    seeLessLabel,
    tooManyRequestsLabel,
    forbiddenResourceLabel,
    imageWrongFileTypeLabel,
    documentTooBigLabel,
    documentWrongFileTypeLabel,
    imageTooBigLabel,
    logoUploadFailedLabel,
    imageUploadFailedLabel,
    entityNotFoundLabel,
    nameAlreadyExistsLabel,
    emailAlreadyExistsLabel,
    somethingWentWrongLabel,
    seedingInProgressLabel,
    passwordChangedLabel,
    emailSentLabel,
    inviteSentLabel,
    continueWithReactivationQuestionLabel,
    reactivateLabel,
    deleteLabel,
    discardChangesLabel,
    unsavedChangesWillBeLostLabel,
    saveChangesQuestionLabel,
    footerContentLabel,
    resetToDefaultSettingsLabel,
    resourcesLabel,
    inviteSelectedWithNumLabel,
    configureResourcesLabel,
    customizeLabel,
    defaultLabel,
    customLabel,
    addToSelectedWithNumLabel,
    choseColorLabel,
    uploadPhotoBySizeLabel,
    uploadLabel,
    logoPreviewLabel,
    hexLabel,
    brandColorLabel,
    logosLabel,
    logosDarkLabel,
    logosDarkSubtitleLabel,
    largeLabel,
    smallLabel,
    typeMarkdownHereLabel,
    markdownLabel,
    whiteLabelingLabel,
    licenseLabel,
    activeLabel,
    nowLabel,
    collapseLabel,
    showMoreWithNumLabel,
    settingsLabel,
    seeMoreLabel,
    notAssignetLabel,
    ownerLabel,
    saveLabel,
    saveAndAssignLabel,
    resolvedStatusLabel,
    statusLabel,
    selectRoleLabel,
    deactivateLabel,
    saveChangesLabel,
    roleLabel,
    passwordLabel,
    uploadPhotoLabel,
    configureFeatures,
    nameLabel,
    emailLabel,
    inviteLabel,
    noResultsLabel,
    cancelLabel,
    startDateLabel,
    endDateLabel,
    resendInviteLabel,
    editLabel,
    detailsLabel,
    searchClientsOrProjectsLabel,
    languageLabel,
    languageSelectLabel,
    uploadResourcesLabel,
    uploadFailedLabel,
    resourcesUpdatedLabel,
    deleteResourceLabel,
    deleteResourceMsgLabel,
    noEnterpriseResourcesLabel,
    uploadNewLabel,
    unauthorizedPageLabel,
    unauthorizedPageDescriptionLabel,
    viewAssignmentDetailsLabel,
    noVideosAssignedLabel,
    assignedByLabel,
    teamProgressInfoLabel,
    yourProgressLabel,
    taskNotFoundLabel,
    unitNotFoundLabel,
    moduleNotFoundLabel,
    phaseNotFoundLabel,
    assignmentUpdatedLabel,
    assignedUnitRemovedLabel,
    deleteAssignedUnitTitleLabel,
    deleteAssignedUnitTextLabel,
    deleteAssignedUnitAlertTitleLabel,
  };
};
