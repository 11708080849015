import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { SocketHandlerProps } from 'types/socket';
import {
  IncubatePlaybookFeedbackParams,
  sendIncubatePlaybookFeedbackRequest,
} from '.';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

export const useSendIncubatePlaybookFeedback = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps) => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const successHandler = () => {
    onSuccess?.();
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { mutate: mutateRating } = useMutation(
    sendIncubatePlaybookFeedbackRequest
  );

  const sendRating = useCallback(
    (params: IncubatePlaybookFeedbackParams) => {
      mutateRating({ defaultParams: playbookInfo, params });
    },
    [mutateRating, playbookInfo]
  );

  const { mutate, isLoading } = useMutation(
    sendIncubatePlaybookFeedbackRequest,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
    }
  );

  const sendFeedback = useCallback(
    (params: IncubatePlaybookFeedbackParams) => {
      mutate({ defaultParams: playbookInfo, params });
    },
    [mutate, playbookInfo]
  );

  return {
    sendRating,
    sendFeedback,
    isLoading,
  };
};
