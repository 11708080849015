import { PAGE_SIZE, TableSortModel } from 'components/Table';

type SearchFilter = {
  filterBy: string;
  value?: string;
};

type FormatSearchParamsProps = {
  page?: number;
  filters: SearchFilter[];
  sort: TableSortModel;
  sortMap?: Record<string, string>;
  pageSize?: number;
  limit?: number;
  offset?: number;
};

export const formatSearchParams = ({
  page = 0,
  sort,
  sortMap,
  filters,
  pageSize = PAGE_SIZE,
  limit,
  offset,
}: FormatSearchParamsProps) => {
  const allFilters = filters
    .filter(({ value }) => value)
    .reduce(
      (a, { filterBy, value }) => ({
        ...a,
        [filterBy]: value,
      }),
      {}
    );
  const { field: sortField, sort: sortOrder } = sort[0];

  return {
    limit: limit ?? pageSize,
    offset: offset ?? page * pageSize,
    sortBy: sortMap?.[sortField] ?? sortField,
    sortOrder: sortOrder || '',
    ...allFilters,
  };
};
