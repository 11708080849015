import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useGetUserOnProject } from 'services/api/client/project/useGetUserOnProject';
import { useGetIncubatePlaybookFavoriteList } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookFavoriteList';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import { getBaseLink } from 'utils/incubatePlaybook';
import { PlaybookResourceItem } from './PlaybookResourceItem';
import { PlaybookSectionTitle } from './PlaybookSectionTitle';

export const PlaybookFavoriteSection = () => {
  const router = useRouter();

  const { data } = useGetIncubatePlaybookFavoriteList({});
  const { isCurrentUserOnProject } = useGetUserOnProject();

  const { allUnitData, taskOrderList } = useIncubatePlaybookData();

  const getTaskOrUnitLink = useCallback(
    (id: string, type?: string) => {
      if (type?.match(/task|unit/i)) {
        const isUnit = type === 'unit';

        let taskSlug: string | undefined;
        let unitSlug: string | undefined;

        if (isUnit) {
          taskSlug = allUnitData[id]?.tasks?.[0]?.slug.current;
          unitSlug = allUnitData[id]?.slug.current;
        } else {
          const item = taskOrderList.find((x) => x.taskId === id);
          taskSlug = item?.taskSlug;
          unitSlug = item?.unitSlug;
        }

        const baseLink = getBaseLink(router.asPath);

        if (taskSlug && unitSlug) {
          return () => router.push(`${baseLink}/${unitSlug}/task/${taskSlug}`);
        }
      }
    },
    [allUnitData, router, taskOrderList]
  );

  const { favoritesLabel } = useCommonTranslations();

  if (!isCurrentUserOnProject) return null;

  return (
    <>
      <PlaybookSectionTitle
        title={favoritesLabel}
        spacing={12}
        mt={14.25}
        mb={4.25}
      />
      <Box
        display="flex"
        gap="22px"
        width="100%"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {data?.items
          ?.slice(0, 3)
          .map(
            ({
              itemId,
              itemName,
              itemPhaseName,
              itemType,
              itemModuleName,
              itemUnitName,
              itemImageURL,
              itemLink,
            }) => (
              <PlaybookResourceItem
                key={itemId}
                title={itemName}
                phase={itemPhaseName}
                module={itemModuleName}
                unit={itemUnitName}
                favoritesId={itemId}
                type={itemType}
                imgSrc={itemImageURL}
                handleClick={getTaskOrUnitLink(itemId, itemType)}
                resourceSlug={itemLink}
              />
            )
          )}
      </Box>
    </>
  );
};
