import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { Currency, VentureBuilderChange } from 'types/ventureBuilder';
import { useGetCurrency } from './useGetCurrency';
import { useUpdateCurrencyData } from './useUpdateCurrencyData';

export type DefaultParams = {
  clientId: string;
  projectId: string;
  slug: Slugs;
};

export const useCurrencyData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.CURRENCY,
  };

  const {
    currency,
    isLoading: isGetting,
    setCurrency,
  } = useGetCurrency({
    defaultParams,
  });

  const { isLoading: isPosting, updateCurrencyData } = useUpdateCurrencyData(
    {}
  );

  type UpdateCurrencyProps = {
    payload: VentureBuilderChange<Currency>;
  };

  const updateCurrency = ({ payload }: UpdateCurrencyProps) => {
    const oldData = currency;

    setCurrency(payload.data);
    updateCurrencyData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setCurrency({ currency: oldData });
        },
      }
    );
  };

  return {
    currency,
    updateCurrency,
    isGetting,
    isPosting,
  };
};
