import { OktaAuth } from '@okta/okta-auth-js';
import { getProcessEnv } from 'utils';
import { datadogRum } from '@datadog/browser-rum';

const processEnv = getProcessEnv();

const isValid =
  processEnv.NEXT_PUBLIC_OKTA_BASE_URL &&
  processEnv.NEXT_PUBLIC_OKTA_CLIENT_ID &&
  processEnv.NEXT_PUBLIC_LOGIN_CALLBACK;

export default isValid &&
  new OktaAuth({
    issuer: processEnv.NEXT_PUBLIC_OKTA_BASE_URL,
    clientId: processEnv.NEXT_PUBLIC_OKTA_CLIENT_ID,
    redirectUri: processEnv.NEXT_PUBLIC_LOGIN_CALLBACK,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    devMode: false,
    transformAuthState: async (oktaAuth, authState) => {
      if (!authState.isAuthenticated) {
        return authState;
      }

      const user = await oktaAuth.token.getUserInfo();
      if (!user) {
        return authState;
      }
      datadogRum.setUser({
        name: user.name,
        email: user.email,
        id: user.sub,
      });

      return authState;
    },
  });
