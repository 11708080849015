import { useIntl } from 'react-intl';

export const useAccountTranslations = () => {
  const { formatMessage } = useIntl();

  const myProfileLabel = formatMessage({
    id: 'account.myProfile',
    defaultMessage: 'My Profile',
  });

  const deactivateAccountLabel = formatMessage({
    id: 'account.deactivate',
    defaultMessage: 'Deactivate account',
  });

  const deactivateAccountCapitalizedLabel = formatMessage({
    id: 'account.deactivateAccountCapitalized',
    defaultMessage: 'Deactivate Account',
  });

  const aboutToDeactivateYourAccountLabel = formatMessage({
    id: 'account.aboutToDeactivateYourAccount',
    defaultMessage: 'You are about to deactivate your account',
  });

  const deactivationYourAccountConsequenceLabel = formatMessage({
    id: 'account.deactivationYourAccountConsequence',
    defaultMessage:
      'Your account will be deactivated and you will no longer have access to your projects or Venture Builder.',
  });

  const logOutLabel = formatMessage({
    id: 'account.logOut',
    defaultMessage: 'Log Out',
  });

  const darkLightModeLabel = (mode?: string) =>
    formatMessage(
      {
        id: 'account.darkLightMode',
        defaultMessage: '{mode, select, light {Light} other {Dark}} Mode',
      },
      {
        mode,
      }
    );

  const ifEmailExistsPasswordResetEmailWillBeSentLabel = formatMessage({
    id: 'account.ifEmailExistsPasswordResetEmailWillBeSent',
    defaultMessage:
      'If your email exists in our system, a password reset email will be sent shortly',
  });

  const youHaveSuccessfullyUpdatedYourProfileLabel = formatMessage({
    id: 'account.youHaveSuccessfullyUpdatedYourProfile',
    defaultMessage: 'You have successfully updated your profile.',
  });

  const accessDeniedLabel = formatMessage({
    id: 'account.accessDenied',
    defaultMessage: 'Access denied',
  });

  const passwordUpdateFailedLabel = formatMessage({
    id: 'account.passwordUpdateFailed',
    defaultMessage: 'Password update failed.',
  });

  const groupNotFoundLabel = formatMessage({
    id: 'account.groupNotFound',
    defaultMessage: 'Group not found',
  });

  const invitationExpiredLabel = formatMessage({
    id: 'account.invitationExpired',
    defaultMessage: 'Invitation expired',
  });

  const inviteExpiredLabel = formatMessage({
    id: 'account.inviteExpired',
    defaultMessage: 'Invite expired',
  });

  const passwordResetLinkExpiredLabel = formatMessage({
    id: 'account.passwordResetLinkExpired',
    defaultMessage: 'Password reset link expired',
  });

  return {
    inviteExpiredLabel,
    passwordUpdateFailedLabel,
    groupNotFoundLabel,
    invitationExpiredLabel,
    passwordResetLinkExpiredLabel,
    accessDeniedLabel,
    youHaveSuccessfullyUpdatedYourProfileLabel,
    ifEmailExistsPasswordResetEmailWillBeSentLabel,
    deactivateAccountCapitalizedLabel,
    darkLightModeLabel,
    logOutLabel,
    myProfileLabel,
    deactivateAccountLabel,
    aboutToDeactivateYourAccountLabel,
    deactivationYourAccountConsequenceLabel,
  };
};
