import { Box, Divider, Typography } from '@mui/material';
import { ReactNode } from 'react';

type PlaybookSectionTitleProps = {
  title: string;
  spacing?: number;
  mt?: number | string;
  mb?: number | string;
  mx?: number | string;
  maxWidth?: number | string;
  children?: ReactNode;
};

export const PlaybookSectionTitle = ({
  title,
  spacing = 20,
  mt,
  mb,
  mx,
  maxWidth,
  children,
}: PlaybookSectionTitleProps) => {
  return (
    <Box width="100%" mt={mt} mb={mb} mx={mx} maxWidth={maxWidth}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={`${spacing}px`}
      >
        <Typography fontSize={24} fontWeight={700}>
          {title}
        </Typography>
        <Box>{children}</Box>
      </Box>
      <Divider />
    </Box>
  );
};
