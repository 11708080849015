import {
  Avatar,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetAllUserOnProject } from 'services/api/client/project/useGetAllProjectUsersList';
import { useCommonTranslations } from 'translations/hooks';
import { getInitials } from 'utils';

const TeamMembersSelectMobile = () => {
  const { data: usersOnProject } = useGetAllUserOnProject();
  const { setValue, watch } = useFormContext();

  const { allTeamMembersLabel, noTeamMembersLabel } = useCommonTranslations();

  const selectedMembers: string[] = watch('assignees');

  const selectAll = useMemo(
    () =>
      !!usersOnProject?.count &&
      selectedMembers.length === usersOnProject?.count,
    [selectedMembers, usersOnProject]
  );

  const handleSelectAll = () => {
    if (!selectAll) {
      setValue(
        'assignees',
        usersOnProject?.items.map((member) => member.userId),
        { shouldDirty: true }
      );
    } else {
      setValue('assignees', [], { shouldDirty: true });
    }
  };

  const handleMemberSelect = (value: string) => () => {
    if (value === 'all') {
      handleSelectAll();
    } else {
      const currentIndex = selectedMembers.indexOf(value);
      const newChecked = [...selectedMembers];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setValue('assignees', newChecked, { shouldDirty: true });
    }
  };

  return (
    <List>
      {!!usersOnProject?.count ? (
        <ListItem key="all" sx={listItemSx}>
          <ListItemButton onClick={handleMemberSelect('all')} dense>
            <Checkbox
              color="secondary"
              edge="start"
              checked={selectedMembers.length === usersOnProject?.count}
              tabIndex={-1}
              disableRipple
            />
            <ListItemText id="all" primary={allTeamMembersLabel} />
          </ListItemButton>
        </ListItem>
      ) : (
        <ListItem sx={listItemSx}>
          <ListItemButton dense>
            <ListItemText primary={noTeamMembersLabel} />
          </ListItemButton>
        </ListItem>
      )}
      {usersOnProject?.items.map((member) => (
        <ListItem key={member.userId} sx={memberListItemSx}>
          <ListItemButton
            role={undefined}
            onClick={handleMemberSelect(member.userId)}
            dense
          >
            <Checkbox
              color="secondary"
              edge="start"
              checked={selectedMembers.includes(member.userId)}
              tabIndex={-1}
              disableRipple
            />
            <Avatar src={member.profileImg} sx={avatarSx}>
              {getInitials(member.name)}
            </Avatar>
            <ListItemText
              id={member.userId}
              primary={member.name}
              sx={{ ml: 1 }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default TeamMembersSelectMobile;

const avatarSx = { width: 32, height: 32 };
const memberListItemSx = { px: 0, pl: 4 };
const listItemSx = { px: 0 };
