import { headerSocketTopic } from 'common/consts';
import { Theme, UserStatus, UserTypes } from 'common/enums';
import { PlatformRoles } from 'common/userConsts';
import { Languages } from 'translations/TranslationsProvider';
import { DefaultParams, DefaultRequest } from 'types/api';
import { API } from '../utils';

export type UserTypeParam = {
  type?: UserTypes;
  status?: UserStatus;
};

export type UserListRequestProps = DefaultRequest<{
  params: DefaultParams;
  type?: UserTypes;
}>;

export const getSessionDataRequest = ({ topic }: DefaultRequest) =>
  API.get('/v2/users/auth-info', { headers: { [headerSocketTopic]: topic } });

export const getUsersListRequest = ({
  params: { type, params },
  topic,
}: UserListRequestProps) =>
  API.get(`/v2/users/${type}`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export type GetMachUserRequest = DefaultRequest<{ userId: string }>;
export const getUserRequest = ({
  params: { userId },
  topic,
}: GetMachUserRequest) =>
  API.get(`/v1/users/${userId}`, { headers: { [headerSocketTopic]: topic } });

export type MachUserInvitation = DefaultRequest<{
  name: string;
  email: string;
  roles: PlatformRoles[];
}>;

export const inviteMachUserRequest = ({ params, topic }: MachUserInvitation) =>
  API.post('/v2/users', params, {
    headers: { [headerSocketTopic]: topic },
  });

export type EditMachUserParams = DefaultRequest<{
  userId: string;
  data: {
    name?: string;
    roles?: PlatformRoles[];
    selectedTheme?: Theme;
  };
}>;
export const editUserRequest = ({
  params: { userId, data },
  topic,
}: EditMachUserParams) =>
  API.patch(`/v1/users/${userId}`, data, {
    headers: { [headerSocketTopic]: topic },
  });

export type InviteAcceptedParams = DefaultRequest<{
  id: string;
  name: string;
  email: string;
  password: string;
}>;

export const userInviteAcceptedRequest = ({
  params,
  topic,
}: InviteAcceptedParams) =>
  API.post(
    '/v2/users/accepted-invite',
    { ...params, accepted: true },
    { headers: { [headerSocketTopic]: topic } }
  );

export type ValidateInviteParams = DefaultRequest<{
  email: string;
}>;
export const userValidateInviteRequest = ({
  params,
  topic,
}: ValidateInviteParams) =>
  API.post('v2/users/validate-invite', params, {
    headers: { [headerSocketTopic]: topic },
  });

export type UserResetPasswordProps = DefaultRequest<{
  newPassword: string;
  hash: string;
}>;
export const userResetPasswordRequest = ({
  params,
  topic,
}: UserResetPasswordProps) =>
  API.patch('/v1/users/reset-password', params, {
    headers: { [headerSocketTopic]: topic },
  });

export const forgotUsersPasswordRequest = (email: string) =>
  API.post(
    '/v1/users/reset-password',
    { email },
    { headers: { [headerSocketTopic]: 'forgot|users|password' } } // topic not used - this is just for consistency
  );

export type UserChangePasswordProps = DefaultRequest<{
  newPassword: string;
  email: string;
}>;
export const changeUsersPasswordRequest = ({
  params,
  topic,
}: UserChangePasswordProps) =>
  API.patch('/v1/users/change-password', params, {
    headers: { [headerSocketTopic]: topic },
  });

export type UserResendInviteProps = DefaultRequest<{ id: string }>;
export const resendInviteRequest = ({
  params: { id },
  topic,
}: UserResendInviteProps) =>
  API.post(`/v2/users/${id}/resend-invite`, undefined, {
    headers: { [headerSocketTopic]: topic },
  });

export type ChangeUserProps = DefaultRequest<{ userId: string }>;

export const deactivateUserProfileRequest = ({
  params: { userId },
  topic,
}: ChangeUserProps) =>
  API.delete(`/v1/users/${userId}/profile`, {
    headers: { [headerSocketTopic]: topic },
  });

export const deactivateUserRequest = ({
  params: { userId },
  topic,
}: ChangeUserProps) =>
  API.delete(`/v2/users/${userId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export const deleteUserRequest = ({
  params: { userId },
  topic,
}: ChangeUserProps) =>
  API.delete(`/v2/users/complete-deletion/${userId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export type UpdateProfileRequest = DefaultRequest<{
  userId: string;
  payload: {
    name?: string;
    profileImg?: string;
    newPassword?: string;
    oldPassword?: string;
    language?: Languages;
    selectedTheme?: Theme;
  };
}>;
export const updateProfileRequest = ({
  params: { userId, payload },
  topic,
}: UpdateProfileRequest) =>
  API.patch(`/v1/users/${userId}/profile`, payload, {
    headers: { [headerSocketTopic]: topic },
  });

export const reactivateUserRequest = ({
  params: { userId },
  topic,
}: ChangeUserProps) =>
  API.patch(`/v2/users/${userId}/reactivate`, undefined, {
    headers: { [headerSocketTopic]: topic },
  });

export type ChangeUserThemeParams = {
  userId: string;
  theme: Theme;
};
