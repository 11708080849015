import { useQuery } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import {
  TeammatesWhoWatchedVideoArray,
  TeammatesWhoWatchedVideoArraySchema,
} from 'types/AssignContent';
import { SocketHandlerProps } from 'types/socket';
import {
  GetTeammatesWhoWatchedVideoProps,
  GetTeammatesWhoWatchedVideoPropsParams,
  getTeammatesWhoWatchedVideoRequest,
} from '.';
import { assignmentsQueryKeys } from './assignmentsQueryKeys';

const schema = TeammatesWhoWatchedVideoArraySchema;

type ResponseData = TeammatesWhoWatchedVideoArray;
type Request = GetTeammatesWhoWatchedVideoProps['params'];

export const useGetTeammatesWhoWatchedVideo = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<
  GetTeammatesWhoWatchedVideoPropsParams,
  ResponseData
>) => {
  const { notify } = useNotifications();
  const { playbookInfo } = useGetPlaybookInfo({});

  const successHandler = useCallback(
    (result?: ResponseData) => {
      onSuccess?.(result);
    },
    [onSuccess]
  );

  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );

  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.TEAMMATES_WHO_WATCHED_VIDEO,
    request: getTeammatesWhoWatchedVideoRequest,
    schema,
  });

  const videoId = defaultParams?.videoId as string;

  const { data, isLoading } = useQuery(
    [
      assignmentsQueryKeys.teammatesWhoWatchedVideo({
        defaultParams: playbookInfo,
        params: defaultParams!,
      }),
    ],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled: enabled && !!videoId,
    }
  );

  return {
    teammates: data ?? [],
    isLoading,
  };
};
