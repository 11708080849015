import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from '@hello-pangea/dnd';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { VentureBuilderItemAction } from 'common/enums';
import { ButtonText } from 'components/Inputs/Button';
import { Link } from 'components/Inputs/Link';
import { useState } from 'react';
import {
  useCommonTranslations,
  useProductTranslations,
} from 'translations/hooks';
import { PainPoint, Stakeholder } from 'types/ventureBuilder';
import { PrioritizedPainPointsListState } from '.';
import { PrioritizedPainPointsListItem } from './PrioritizedPainPointsListItem';

type PrioritizedPainPointsListProps = {
  isLoading: boolean;
  data: PainPoint[];
  stakeholders: Stakeholder[];
  collapseList: boolean;
  state: PrioritizedPainPointsListState;
  onAdd: (addNewShouldBeOnTopOfTheList: boolean) => () => void;
  onEdit: (painPoint: PainPoint) => void;
  onCancel: () => void;
  onDelete: (painPoint: PainPoint) => void;
  onSave: (painPoint: PainPoint) => boolean;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
};

const PrioritizedPainPointsList = ({
  isLoading,
  data,
  stakeholders,
  collapseList,
  state,
  onAdd,
  onEdit,
  onCancel,
  onDelete,
  onSave,
  onDragEnd,
}: PrioritizedPainPointsListProps) => {
  const [showAll, setShowAll] = useState(!collapseList);

  const painPointsToShow = showAll ? data : data.slice(0, COLLAPSED_LIST_LIMIT);
  const showAllButtonVisible = !showAll && data.length > COLLAPSED_LIST_LIMIT;

  const setShowAllTrue = () => setShowAll(true);

  const {
    prioritizedPainPointsLabel,
    stakeholderLabel,
    painPointLabel,
    plusNewPainPointLabel,
  } = useProductTranslations();

  const { cancelLabel } = useCommonTranslations();

  return (
    <Box>
      <Typography variant="h6" fontWeight="600">
        {prioritizedPainPointsLabel}
      </Typography>
      <Box margin="10px 0" overflow="scroll">
        <Grid
          container
          sx={listHeaderStyle}
          flexDirection="row"
          minWidth="600px"
        >
          <Grid container item xs={9} columnGap={1} alignItems="center">
            <Grid container item xs={0.5} />

            <Grid container item xs={5.5}>
              <Typography fontWeight={500}>{painPointLabel}</Typography>
            </Grid>

            <Grid container item xs={5.5}>
              <Typography fontWeight={500}>{stakeholderLabel}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && data.length === 0 && (
          <Box sx={loaderWrapperStyle}>
            <CircularProgress size={30} />
          </Box>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="pain-points-drop-zone">
            {(provided) => (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {data.length
                  ? painPointsToShow.map((painPoint, index) => (
                      <PrioritizedPainPointsListItem
                        key={painPoint._id}
                        index={index}
                        data={painPoint}
                        stakeholders={stakeholders}
                        state={state}
                        onEdit={onEdit}
                        onCancel={onCancel}
                        onDelete={onDelete}
                        onSave={onSave}
                      />
                    ))
                  : emptyState}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <ButtonText
          color="secondary"
          disabled={state.type !== VentureBuilderItemAction.VIEW}
          onClick={onAdd(!showAll && data.length >= COLLAPSED_LIST_LIMIT)}
        >
          {plusNewPainPointLabel}
        </ButtonText>
        {showAllButtonVisible && (
          <Link sx={linkStyle} color="secondary" onClick={setShowAllTrue}>
            {cancelLabel}
          </Link>
        )}
      </Box>
    </Box>
  );
};

const loaderWrapperStyle = { margin: '10px', textAlign: 'center' };

const listHeaderStyle = {
  minHeight: '50px',
  borderBottom: '1px solid',
  borderColor: 'action.disabledBackground',
  padding: '12px 0 8px 10px',
};
const linkStyle = { marginTop: '8px' };

const COLLAPSED_LIST_LIMIT = 3;

const emptyState = (
  <Box my="8px">
    <Typography fontWeight={200}>No pain points added yet</Typography>
  </Box>
);

export default PrioritizedPainPointsList;
