import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ProductType } from 'common/enums';
import {
  getLocalStoragePlaybookInfoCache,
  removeLocalStoragePlaybookInfoCache,
} from 'common/localStorage';
import { NotificationType } from 'common/notificationConsts';
import { topRoutes } from 'common/routes';
import useNotifications from 'common/useNotifications';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useSessionData } from 'services/api/useSessionData';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import {
  PlaybookInfoData,
  PlaybookInfoDataSchema,
} from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import {
  PlaybookInfoProps,
  getPlaybookInfoRequest,
  setPlaybookInfoRequest,
} from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';

const schema = PlaybookInfoDataSchema;

type ResponseData = PlaybookInfoData & {
  createdAt: string;
  slugId: string;
  updatedAt: string;
  userId: string;
};
type Request = PlaybookInfoProps['params'];

export const useGetPlaybookInfo = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<void, ResponseData>) => {
  const queryClient = useQueryClient();
  const { query, route } = useRouter();
  const { notify } = useNotifications();
  const { isSessionValid } = useSessionData({});

  const playbookSlug = query.playbookSlug as string;
  const isPlaybookRoute = route.startsWith(topRoutes.playbook);

  const successHandler = (result?: ResponseData) => {
    onSuccess?.(result);
  };
  const failureHandler = (message_key?: NotificationType) => {
    message_key && notify(message_key);
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const getInitialData = useCallback(() => {
    const initialData = getLocalStoragePlaybookInfoCache(playbookSlug);
    if (!initialData) return;
    const { playbookInfo, timestamp } = initialData;
    const difference = new Date().getTime() - new Date(timestamp).getTime();
    const isValidCache = difference / 1000 < MAX_SECONDS_VALID;

    if (isValidCache) {
      queryClient.setQueryData(
        [incubatePlaybookQueryKeys.info({ playbookSlug })],
        () => playbookInfo
      );
    }

    removeLocalStoragePlaybookInfoCache(playbookSlug);
  }, [playbookSlug, queryClient]);

  const { fetchFn } = useFetch<Request, ResponseData>({
    topic: socketTopics.PLAYBOOK_INFO,
    request: getPlaybookInfoRequest,
    schema,
  });

  const { data, isLoading } = useQuery(
    [incubatePlaybookQueryKeys.info({ playbookSlug })],
    fetchFn,
    {
      initialData: getInitialData,
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      retry: 1,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: enabled && isPlaybookRoute && isSessionValid && !!playbookSlug,
    }
  );

  const { mutate } = useMutation(setPlaybookInfoRequest);
  const saveCurrentPlaybookInfo = useCallback(() => {
    if (data && playbookSlug && !isLoading) {
      const params = getPlaybookInfo(data);
      mutate({ params, playbookSlug });
    }
  }, [data, playbookSlug, isLoading, mutate]);

  return {
    playbookInfo: getPlaybookInfo(data),
    playbookSlug,
    productType: data?.productType as ProductType,
    isLoading,
    saveCurrentPlaybookInfo,
    isPlaybookInfoValid: !!data && !!playbookSlug,
  };
};

const MAX_SECONDS_VALID = 20;

const getPlaybookInfo = (data?: ResponseData) => {
  if (!data) return {} as Omit<PlaybookInfoData, 'productType'>;
  const { clientId, projectId, productId } = data;

  return { clientId, projectId, productId };
};
