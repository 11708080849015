import { useIntl } from 'react-intl';

export const useProjectTranslations = () => {
  const { formatMessage } = useIntl();

  const projectsLabel = formatMessage({
    id: 'projects',
    defaultMessage: 'Projects',
  });

  const projectLabel = formatMessage({
    id: 'project',
    defaultMessage: 'Project',
  });

  const projectStatusLabel = formatMessage({
    id: 'projects.projectStatus',
    defaultMessage: 'Project Status',
  });

  const selectProjectsLabel = formatMessage({
    id: 'projects.selectProjects',
    defaultMessage: 'Select Projects',
  });

  const myProjectsLabel = formatMessage({
    id: 'projects.myProjects',
    defaultMessage: 'My Projects',
  });

  const projectAdminLabel = formatMessage({
    id: 'projects.projectAdmin',
    defaultMessage: 'Project Admin',
  });

  const projectNameLabel = formatMessage({
    id: 'projects.projectName',
    defaultMessage: 'Project Name',
  });

  const activeProjectsLabel = formatMessage({
    id: 'projects.activeProjects',
    defaultMessage: 'Active Projects',
  });

  const createNewProjectPlusLabel = formatMessage({
    id: 'projects.createNewProjectPlusLabel',
    defaultMessage: '+ create new project',
  });

  const addNewProjectLabel = formatMessage({
    id: 'projects.addNewProject',
    defaultMessage: 'Add New Project',
  });

  const addProjectLabel = formatMessage({
    id: 'projects.addProject',
    defaultMessage: 'Add Project',
  });

  const addToProjectLabel = formatMessage({
    id: 'projects.addToProject',
    defaultMessage: 'Add to Project',
  });

  const searchProjectsLabel = formatMessage({
    id: 'projects.search',
    defaultMessage: 'Search Projects',
  });

  const noCreatedProjectsLabel = formatMessage({
    id: 'projects.noCreatedProjects',
    defaultMessage: 'You haven’t created any projects yet.',
  });

  const noAssignedProjectsLabel = formatMessage({
    id: 'projects.noAssignedProjects',
    defaultMessage: 'You are not assigned to any projects yet.',
  });

  const createFirstProjectLabel = formatMessage({
    id: 'projects.createFirstProject',
    defaultMessage: 'Create your first project.',
  });

  const backToProjectsLabel = formatMessage({
    id: 'projects.backToProjects',
    defaultMessage: 'Back to Projects',
  });

  const deactivateProjectLabel = formatMessage({
    id: 'projects.deactivateProject',
    defaultMessage: 'Deactivate Project',
  });

  const removeFromProjectLabel = formatMessage({
    id: 'projects.removeFromProject',
    defaultMessage: 'Remove from Project',
  });

  const removeAsProjectAdminLabel = formatMessage({
    id: 'projects.removeAsProjectAdmin',
    defaultMessage: 'Remove As Project Admin',
  });

  const makeProjectAdminLabel = formatMessage({
    id: 'projects.makeProjectAdmin',
    defaultMessage: 'Make Project Admin',
  });

  const projectDetailsLabel = formatMessage({
    id: 'projects.projectDetails',
    defaultMessage: 'Project Details',
  });

  const reactivateProjectLabel = formatMessage({
    id: 'projects.reactivateProject',
    defaultMessage: 'Reactivate Project',
  });

  const aboutToReactivateProjectWithNameLabel = (projectName: string) =>
    formatMessage(
      {
        id: 'projects.aboutToReactivateProjectWithName',
        defaultMessage: 'You are about to reactivate the project {projectName}',
      },
      {
        projectName,
      }
    );

  const projectDeactivatedLabel = formatMessage({
    id: 'projects.projectDeactivated',
    defaultMessage: 'Project deactivated',
  });

  const projectReactivatedLabel = formatMessage({
    id: 'projects.projectReactivated',
    defaultMessage: 'Project reactivated',
  });

  const projectSuccessfullyUpdatedLabel = formatMessage({
    id: 'projects.projectSuccessfullyUpdated',
    defaultMessage: 'Project successfully updated',
  });

  const projectResourcesUpdatedLabel = formatMessage({
    id: 'projects.projectResourcesUpdated',
    defaultMessage: 'Project resources updated',
  });

  const noAssignedUsersOnProjectLabel = formatMessage({
    id: 'projects.noAssignedUsersOnProject',
    defaultMessage: 'There are no assigned users on this project.',
  });

  const projectHasBeenDuplicatedLabel = formatMessage({
    id: 'projects.projectHasBeenDuplicated',
    defaultMessage: 'Project has been duplicated',
  });

  const duplicateProjectLabel = formatMessage({
    id: 'projects.duplicateProject',
    defaultMessage: 'Duplicate Project',
  });

  return {
    duplicateProjectLabel,
    projectHasBeenDuplicatedLabel,
    noAssignedUsersOnProjectLabel,
    projectResourcesUpdatedLabel,
    projectSuccessfullyUpdatedLabel,
    projectReactivatedLabel,
    projectDeactivatedLabel,
    aboutToReactivateProjectWithNameLabel,
    reactivateProjectLabel,
    projectDetailsLabel,
    makeProjectAdminLabel,
    removeAsProjectAdminLabel,
    removeFromProjectLabel,
    deactivateProjectLabel,
    projectStatusLabel,
    backToProjectsLabel,
    addToProjectLabel,
    selectProjectsLabel,
    addProjectLabel,
    noAssignedProjectsLabel,
    createFirstProjectLabel,
    noCreatedProjectsLabel,
    searchProjectsLabel,
    projectNameLabel,
    addNewProjectLabel,
    createNewProjectPlusLabel,
    activeProjectsLabel,
    projectAdminLabel,
    projectsLabel,
    myProjectsLabel,
    projectLabel,
  };
};
