import { Grid } from '@mui/material';
import { ReactNode } from 'react';

export const PlaybookGridWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Grid container spacing={4}>
      {children}
    </Grid>
  );
};
