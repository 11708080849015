import { Avatar, IconButton } from '@mui/material';
import { MuiIcon } from 'components/MuiIcon';
import { useSessionData } from 'services/api/useSessionData';

export const ProfileMenuAvatar = ({ active }: { active?: boolean }) => {
  const { sessionData } = useSessionData({});

  const profileImg = sessionData?.user?.profileImg;

  if (!profileImg && !active) return <DefaultIcon />;

  return (
    <Avatar src={profileImg}>
      <DefaultIcon />
    </Avatar>
  );
};

const DefaultIcon = () => (
  <IconButton>
    <MuiIcon iconName="PermIdentity" sx={iconSx} />
  </IconButton>
);

const iconSx = { color: 'text.secondary' };
