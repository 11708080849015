import { TableSortModel } from 'components/Table';
import { getProcessEnv } from 'utils';

const processEnv = getProcessEnv();

export const OKTA_LOGOUT_ENDPOINT = '/v1/logout';
export const TOKEN_PARAMS = {
  scopes: ['openid', 'profile', 'email'],
  idpScopes: ['openid', 'profile', 'email'],
  idp: processEnv.NEXT_PUBLIC_OKTA_GOOGLE_IDP,
};

export const defaultSortModel: TableSortModel = [
  {
    field: 'name',
    sort: 'asc',
  },
];
// helpers
// Password RegExp: Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
export const PASSWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
export const URL_REGEX =
  /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const helpLink =
  'https://calendly.com/d/d5r-stq-6pf/digital-playbook-contact-an-expert';
export const defaultResourceImgLink =
  'https://2401-default-content.s3.amazonaws.com/Placeholder_Image-Resource.jpg';
export const defaultPlaybookCardImgLink =
  'https://2401-default-content.s3.amazonaws.com/Placeholder+Images-Phase_Unit.jpg';
export const defaultProductContentCardImgLink =
  'https://2401-default-content.s3.amazonaws.com/Placeholder_Product-Content.png';

export const thumbnailHeight = 400;
export const thumbnailWidth = 608;

export const playbookHeaderImageHeight = 5600;

export const playbookCardImageHeight = 340;
export const playbookCardImageWidth = 932;

export const productContentCardImageHeight = 276;
export const productContentCardImageWidth = 738;

export const resourceTooltip =
  'Your resources haven’t been configured yet. Contact your team lead.';

export const headerSocketTopic = 'x-socket-topic';

export const mailToSupport = `mailto:${processEnv.NEXT_PUBLIC_SUPPORT_MAIL_TO}`;
