import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { NotificationType } from 'common/notificationConsts';
import { socketListener } from 'common/socketListener';
import useNotifications from 'common/useNotifications';
import useSocket from 'common/useSocket';
import { useCallback } from 'react';
import { socketTopics } from 'services/sockets/topics';
import { ZodTypeAny } from 'zod';

type UseFetchFnProps<Request, Response, AdaptedResponse> = {
  topic: socketTopics;
  ignoreTopicSuffix?: boolean;
  request: (params: {
    params: Request;
    topic: string;
  }) => Promise<AxiosResponse>;
  adaptFn?: (data?: Response) => AdaptedResponse;
  shouldNotifyOnRestError?: boolean;
  schema?: ZodTypeAny;
};

type UseDirectFetchFnProps<Request, Response, AdaptedResponse> = {
  request: (params: { params: Request }) => Promise<AxiosResponse>;
  adaptFn?: (data?: Response) => AdaptedResponse;
  shouldNotifyOnRestError?: boolean;
  schema?: ZodTypeAny;
};

export function useFetch<Request, Response = void, AdaptedResponse = void>({
  topic: topicBase,
  ignoreTopicSuffix,
  request,
  adaptFn,
  shouldNotifyOnRestError = true,
  schema,
}: UseFetchFnProps<Request, Response, AdaptedResponse>) {
  const { socket } = useSocket();
  const { notify } = useNotifications();

  const topicSuffix = ignoreTopicSuffix ? '' : generateRandomTopicSuffix();
  const topic = topicBase + topicSuffix;

  const fetchFn = useCallback(
    ({ queryKey }: QueryFunctionContext<Array<{ params: Request }>>) => {
      return new Promise<Response | AdaptedResponse | undefined>(
        (resolve, reject) => {
          socket?.once(
            topic,
            socketListener({
              onSuccess: resolve,
              onFailure: reject,
              adaptFn,
              schema,
              topic,
            })
          );
          request({ params: queryKey[0].params, topic }).catch(
            ({ message_key, message }) => {
              socket?.removeAllListeners(topic);
              if (shouldNotifyOnRestError) {
                notify(NotificationType.ERROR);
              }
              reject({ message_key, message });
            }
          );
        }
      );
    },
    [socket, topic, adaptFn, schema, request, shouldNotifyOnRestError, notify]
  );

  const fetchFnDefault = useCallback(
    (params: Request) => () => {
      return new Promise<Response | AdaptedResponse | undefined>(
        (resolve, reject) => {
          socket?.once(
            topic,
            socketListener({
              onSuccess: resolve,
              onFailure: reject,
              adaptFn,
              schema,
              topic,
            })
          );
          request({ params, topic }).catch(({ message_key, message }) => {
            socket?.removeAllListeners(topic);
            if (shouldNotifyOnRestError) {
              notify(NotificationType.ERROR);
            }
            reject({ message_key, message });
          });
        }
      );
    },
    [socket, topic, adaptFn, schema, request, shouldNotifyOnRestError, notify]
  );

  const fetchFnMutation = useCallback(
    (params: Request) => {
      return new Promise<Response | undefined>((resolve, reject) => {
        socket?.once(
          topic,
          socketListener({
            onSuccess: resolve,
            onFailure: reject,
            schema,
            topic,
          })
        );
        request({ params, topic }).catch(({ message_key, message }) => {
          socket?.removeAllListeners(topic);
          if (shouldNotifyOnRestError) {
            notify(NotificationType.ERROR);
          }
          reject({ message_key, message });
        });
      });
    },
    [socket, topic, schema, request, shouldNotifyOnRestError, notify]
  );

  const fetchFnInfinite = useCallback(
    (initialParams: Request) =>
      (newParams: QueryFunctionContext<Array<{ params: Request }>>) => {
        return new Promise<Response | undefined>((resolve, reject) => {
          socket?.once(
            topic,
            socketListener({
              onSuccess: resolve,
              onFailure: reject,
              schema,
              topic,
            })
          );
          const params = newParams?.pageParam ?? initialParams;

          request({ params, topic }).catch(({ message_key, message }) => {
            socket?.removeAllListeners(topic);
            if (shouldNotifyOnRestError) {
              notify(NotificationType.ERROR);
            }
            reject({ message_key, message });
          });
        });
      },
    [socket, topic, schema, request, shouldNotifyOnRestError, notify]
  );

  return {
    fetchFn,
    fetchFnDefault,
    fetchFnMutation,
    fetchFnInfinite,
    topic,
    topicSuffix,
  };
}

const generateRandomTopicSuffix = () => '-' + Math.random().toString();

export function useDirectFetch<
  Request,
  Response = void,
  AdaptedResponse = void
>({
  request,
  adaptFn,
  shouldNotifyOnRestError = true,
  schema,
}: UseDirectFetchFnProps<Request, Response, AdaptedResponse>) {
  const { notify } = useNotifications();

  const fetchFn = useCallback(
    ({ queryKey }: QueryFunctionContext<Array<{ params: Request }>>) => {
      return new Promise<Response | AdaptedResponse | undefined>(
        (resolve, reject) => {
          request({ params: queryKey[0].params })
            .then((response) => resolve(response.data))
            .catch(({ message_key, message }) => {
              if (shouldNotifyOnRestError) {
                notify(NotificationType.ERROR);
              }
              reject({ message_key, message });
            });
        }
      );
    },
    [request, shouldNotifyOnRestError, notify]
  );

  const fetchFnDefault = useCallback(
    (params: Request) => () => {
      return new Promise<Response | AdaptedResponse | undefined>(
        (resolve, reject) => {
          request({ params })
            .then((response) => resolve(response.data))
            .catch(({ message_key, message }) => {
              if (shouldNotifyOnRestError) {
                notify(NotificationType.ERROR);
              }
              reject({ message_key, message });
            });
        }
      );
    },
    [request, shouldNotifyOnRestError, notify]
  );

  const fetchFnMutation = useCallback(
    (params: Request) => {
      return new Promise<Response | undefined>((resolve, reject) => {
        request({ params })
          .then((response) => resolve(response.data))
          .catch(({ message_key, message }) => {
            if (shouldNotifyOnRestError) {
              notify(NotificationType.ERROR);
            }
            reject({ message_key, message });
          });
      });
    },
    [request, shouldNotifyOnRestError, notify]
  );

  const fetchFnInfinite = useCallback(
    (initialParams: Request) =>
      (newParams: QueryFunctionContext<Array<{ params: Request }>>) => {
        return new Promise<Response | undefined>((resolve, reject) => {
          const params = newParams?.pageParam ?? initialParams;
          request({ params })
            .then((response) => resolve(response.data))
            .catch(({ message_key, message }) => {
              if (shouldNotifyOnRestError) {
                notify(NotificationType.ERROR);
              }
              reject({ message_key, message });
            });
        });
      },
    [request, shouldNotifyOnRestError, notify]
  );

  return {
    fetchFn,
    fetchFnDefault,
    fetchFnMutation,
    fetchFnInfinite,
  };
}
