import { Box } from '@mui/material';
import { useCustomMediaQuery } from 'hooks';
import Img from 'next/image';

export const TaskImage = ({ imageUrl }: { imageUrl?: string | null }) => {
  const { lessThenSm } = useCustomMediaQuery();

  const borderRadius = lessThenSm ? '0' : '10px 10px 0 0';

  if (!imageUrl) return null;

  return (
    <Box borderRadius={borderRadius} overflow="hidden" maxHeight="268px">
      <Img
        key={imageUrl}
        src={imageUrl}
        alt="task-image"
        width={730}
        height={350}
        style={imgStyle}
        sizes="100vw"
        priority
      />
    </Box>
  );
};

const imgStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain' as const,
};
