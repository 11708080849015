import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export const ButtonOutlined = ({
  children,
  loading,
  sx,
  ...props
}: LoadingButtonProps) => {
  const btnStyle = {
    ...outlinedButtonStyle,
    ...sx,
  };

  return (
    <LoadingButton
      sx={btnStyle}
      variant="outlined"
      loading={loading}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

export const outlinedButtonStyle = {
  fontWeight: 500,
  borderRadius: '10px',
};
