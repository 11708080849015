import {
  GetVentureBuilderInfoRequestParams,
  GetVentureBuilderRequestParams,
} from '.';

export type GetWhoMattersMostDataProps = ReturnType<
  typeof ventureBuilderQueryKeys.stakeholders
>;

export const ventureBuilderQueryKeys = {
  all: { scope: 'venture-builder' } as const,
  stakeholders: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'stakeholders',
      params,
    } as const),
  hypothesisBacklog: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'hypothesisBacklog',
      params,
    } as const),
  topValuePropositions: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'topValuePropositions',
      params,
    } as const),
  painPoints: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'painPoints',
      params,
    } as const),
  topPrototypes: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'topPrototypes',
      params,
    } as const),
  currency: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'currency',
      params,
    } as const),
  marketSize: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'marketSize',
      params,
    } as const),
  info: (params: GetVentureBuilderInfoRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'info',
      params,
    } as const),
  fundingRequest: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'fundingRequest',
      params,
    } as const),
  stakeholderMaps: (params: GetVentureBuilderRequestParams['params']) =>
    ({
      ...ventureBuilderQueryKeys.all,
      entity: 'stakeholderMaps',
      params,
    } as const),
};
