import { acceptedImageFileTypes } from 'common/imageUpload';
import { ChangeEvent } from 'react';

type ImageUploadInputProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  accept?: string[];
};

export const ImageUploadInput = ({
  onChange,
  accept = acceptedImageFileTypes,
}: ImageUploadInputProps) => (
  <input hidden accept={accept.join(', ')} type="file" onChange={onChange} />
);
