// import { Palette } from '@mui/material/styles';

export const dark: any = {
  // MAYBE-TODO: adjust type
  mode: 'dark',
  primary: {
    main: '#22E77F',
  },
  secondary: {
    main: '#22E77F',
    // contrastText: '#fff',
  },

  navigation: {
    main: '#121212',
  },
  text: {
    default: '#fff',
    defaultContrast: '#000',
    secondaryContrast: 'rgba(0, 0, 0, 0.6)',
    subtitle: '#fff',
    body1: 'rgba(255, 255, 255, 0.7)',
    primaryText: '#22E77F',
  },
  border: {
    main: 'rgba(255, 255, 255, 0.12)',
  },
  quiz: {
    correct: 'rgba(46, 125, 50, 0.1)',
    correctText: 'rgb(200,227,198)',
    incorrect: 'rgba(237, 108, 2, 0.1)',
    incorrectText: 'rgb(249,221,174)',
  },
  background: {
    default: '#1E1F21',
    paper: '#121212',
    changePassword: 'transparent',
    changePasswordInput: 'transparent',
    playbook: '#121212',
    icon: '#F3F4F6',
    cardActionsWrapper: 'rgba(255, 255, 255, 0.18)',
    playbookSearch: 'rgb(53,53,53)',
    playbookSearchBorder: 'rgba(255, 255, 255, 0.23)',
    autofill: '#2a2a2a',
    clientOwners: 'rgba(255, 255, 255, 0.09)',
    videoInProgress: 'rgb(34, 231, 127)',
    videoWatched: 'rgb(0, 182, 96)',
    videoNotStarted: '#545454',
    videoAssignedNotStarted: '#6D6D6D',
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    navigation: Palette['primary'];
  }

  interface PaletteOptions {
    navigation?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    navigation: true;
  }
}
