import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  CardContent,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { ButtonOutlined } from 'components/Inputs/Button';
import { Dispatch, SetStateAction } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import {
  IncubatePlaybookTaskWithVideo,
  SelectedUnits,
} from 'types/incubatePlaybook';
import { convertMilliseconds } from 'utils';
import { millisecondsIn } from 'utils/time';

type AssignableUnitCardTaskProps = {
  task: IncubatePlaybookTaskWithVideo;
  selected: boolean;
  onlySelected: boolean;
  setSelectedUnits: Dispatch<SetStateAction<SelectedUnits>>;
  handleTaskToggle: (taskId: string) => void;
  setPreviewTask: Dispatch<
    SetStateAction<IncubatePlaybookTaskWithVideo | null>
  >;
};

export const AssignableUnitCardTask = ({
  task,
  selected,
  onlySelected,
  setSelectedUnits,
  handleTaskToggle,
  setPreviewTask,
}: AssignableUnitCardTaskProps) => {
  const { minuteLabel, previewLabel, deleteTaskDeleteUnitLabel } =
    useCommonTranslations();

  const videoTime = task.video?.source?.duration
    ? task.video?.source?.duration * 1000
    : null;

  const onCheckboxChangeHandler = () => {
    handleTaskToggle(task._id);
  };

  const setPreviewTaskHandler = () => {
    setPreviewTask(task);
  };

  return (
    <CardContent sx={contentSx}>
      <Grid container>
        <Grid item xs={7} display="flex">
          <Tooltip
            title={onlySelected ? deleteTaskDeleteUnitLabel : ''}
            placement="top"
          >
            <Checkbox
              color="secondary"
              disableRipple
              checked={selected}
              onChange={onCheckboxChangeHandler}
            />
          </Tooltip>
          <Box>
            <Typography variant="body2">{task.title}</Typography>
            <Typography variant="caption" color="text.secondary">
              {videoTime
                ? minuteLabel(
                    convertMilliseconds(videoTime, millisecondsIn.minutes, 'up')
                  )
                : ''}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={5}
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
        >
          <ButtonOutlined
            color="secondary"
            sx={buttonSx}
            onClick={setPreviewTaskHandler}
          >
            {previewLabel}
            <ArrowForwardIcon sx={buttonIconSx} />
          </ButtonOutlined>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default AssignableUnitCardTask;

const buttonSx = { borderRadius: '80px' };

const buttonIconSx = { width: '0.875rem', height: '0.875rem', ml: 1 };

const contentSx = {
  py: 1,
  pl: 0,
  '&:hover': {
    backgroundColor: 'action.hover',
  },
};
