import { z } from 'zod';

export type DefaultRequest<T = any> = {
  topic?: string;
  params: T extends Object ? T : void;
};

export type DefaultListResponse<T> = {
  items: T[];
  count: number;
};
export function defaultListResponse<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    items: z.array(schema),
    count: z.number(),
  });
}

export type DefaultParams = {
  limit: number;
  offset: number;
  sortBy?: string;
  sortOrder?: string;
  filterBy?: string;
  filterValue?: string;
  status?: string;
  forContextSwitching?: boolean;
};
