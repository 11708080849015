import { useQuery } from '@tanstack/react-query';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import {
  IncubatePlaybookVideosWatchedTime,
  IncubatePlaybookVideosWatchedTimeSchema,
} from 'types/incubatePlaybook';
import { SocketHandlerProps } from 'types/socket';
import { getIncubatePlaybookVideosWatchedTimeRequest } from '.';
import { incubatePlaybookQueryKeys } from './incubatePlaybookQueryKeys';
import { useGetPlaybookInfo } from './useGetPlaybookInfo';

const schema = IncubatePlaybookVideosWatchedTimeSchema;

export type ResponseData = IncubatePlaybookVideosWatchedTime;

export const useGetIncubatePlaybookVideosWatchedTime = ({
  onSuccess,
  onFailure,
  onEnd,
  enabled = true,
}: SocketHandlerProps<void, ResponseData>) => {
  const { isPlaybookInfoValid } = useGetPlaybookInfo({});

  const successHandler = (result?: ResponseData) => {
    onSuccess?.(result);
  };
  const failureHandler = () => {
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<unknown, ResponseData>({
    topic: socketTopics.VIDEOS_WATCHED_TIME_STATE,
    request: getIncubatePlaybookVideosWatchedTimeRequest,
    shouldNotifyOnRestError: false,
    schema,
  });

  const { data, isLoading } = useQuery(
    [incubatePlaybookQueryKeys.videosWatchedTime()],
    fetchFn,
    {
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      enabled: enabled && isPlaybookInfoValid,
    }
  );

  return { data, isVideosWatchedTimeLoading: isLoading };
};
