enum sessionStorageKeys {
  socketLiveId = 'live-socket-id',
}

const getSessionStorage = (key: sessionStorageKeys) => {
  const item = sessionStorage.getItem(key);

  try {
    if (item) {
      return JSON.parse(item);
    }
  } catch {}
};
const setSessionStorage = (key: sessionStorageKeys, value: unknown) => {
  const v = JSON.stringify(value);
  sessionStorage.setItem(key, v);
};
const deleteSessionStorage = (key: sessionStorageKeys) => {
  sessionStorage.removeItem(key);
};

// socketId
export const getSessionStorageSocketId = (): string | null => {
  return getSessionStorage(sessionStorageKeys.socketLiveId);
};
export const setSessionStorageSocketId = (socketId: string) => {
  setSessionStorage(sessionStorageKeys.socketLiveId, socketId);
};
