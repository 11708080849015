import { Avatar, Box, SvgIcon, Typography } from '@mui/material';
import { toPlainText } from '@portabletext/react';
import Transcript from 'assets/Icons/Transcript.svg';
import { Link } from 'components/Inputs/Link';
import { useCustomMediaQuery } from 'hooks';
import { ReactNode, useMemo } from 'react';
import { useCommonTranslations } from 'translations/hooks';
import { PlaybookVideo } from 'types/incubatePlaybook';
import { getInitials } from 'utils';

const PlaybookVideoMetadata = ({
  video,
  transcriptName,
  hideSpeakerAndTranscript,
  children,
}: {
  video?: PlaybookVideo;
  transcriptName?: string;
  hideSpeakerAndTranscript: boolean;
  children?: ReactNode;
}) => {
  const speaker = video?.speakers[0];
  const transcriptHref = useMemo(
    () =>
      video?.transcript &&
      URL.createObjectURL(new Blob([toPlainText(video.transcript)])),
    [video?.transcript]
  );
  const { lessThenSm } = useCustomMediaQuery();

  const { transcriptLabel } = useCommonTranslations();

  if (!(video?.transcript && speaker)) return <></>;

  return (
    <Box
      display="flex"
      justifyContent={!hideSpeakerAndTranscript ? 'space-between' : 'flex-end'}
      mt="20px"
      flexWrap="wrap"
    >
      {!hideSpeakerAndTranscript && (
        <>
          <Box
            display="flex"
            alignItems="center"
            width={lessThenSm ? '100%' : 'initial'}
          >
            <Box width="48px">
              <Avatar src={speaker?.profileImg?.asset.url}>
                {speaker && getInitials(speaker.name)}
              </Avatar>
            </Box>
            <Box flex="1">
              <Typography fontSize="16px">{speaker?.name}</Typography>
              <Typography fontWeight="400" fontSize="12px">
                {speaker?.title}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            width={lessThenSm ? '100%' : 'initial'}
            justifyContent={lessThenSm ? 'space-between' : 'flex-start'}
          >
            <Box display="flex" alignItems="center" mt={lessThenSm ? 2 : 1}>
              <Link
                href={transcriptHref}
                download={transcriptNameFormatter(transcriptName)}
                sx={linkStyle}
              >
                <SvgIcon
                  component={Transcript}
                  color="secondary"
                  viewBox="0 0 23 21"
                />
                <Typography ml="4px" noWrap color="secondary">
                  {transcriptLabel}
                </Typography>
              </Link>
            </Box>
            <Box
              mt={lessThenSm ? 2 : 1}
              ml={2}
              display="flex"
              alignItems="center"
            >
              {children}
            </Box>
          </Box>
        </>
      )}
      {hideSpeakerAndTranscript && (
        <Box mt={1} display="flex" alignItems="center">
          {children}
        </Box>
      )}
    </Box>
  );
};
const linkStyle = { display: 'flex', alignItems: 'center', fill: 'primary' };

const transcriptNameFormatter = (name?: string) =>
  name
    ? name.toLowerCase().replaceAll(' ', '-').concat('.txt')
    : 'transcript.txt';

export default PlaybookVideoMetadata;
