import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import { projectQueryKeys } from 'services/api/client/project/projectQueryKeys';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { SocketHandlerProps } from 'types/socket';
import { DeleteAssignmentProps, deleteAssignmentUnitRequest } from '.';
import { assignmentsQueryKeys } from './assignmentsQueryKeys';

type Request = DeleteAssignmentProps['params'];

export const useDeleteAssignment = ({
  defaultParams,
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<Request>) => {
  const { notify } = useNotifications();
  const queryClient = useQueryClient();

  const assignmentId = defaultParams?.assignmentId;

  const refreshAssignments = useCallback(() => {
    if (assignmentId) {
      queryClient.refetchQueries([
        assignmentsQueryKeys.single({ assignmentId }),
      ]);
    }
    queryClient.invalidateQueries([assignmentsQueryKeys.singleFromTaskBase()]);
    queryClient.invalidateQueries([assignmentsQueryKeys.assignmentsListBase()]);
    queryClient.invalidateQueries([
      assignmentsQueryKeys.allAssignmentsListBase(),
    ]);
    queryClient.invalidateQueries([
      assignmentsQueryKeys.frequentlyAssignedNextBase(),
    ]);
    queryClient.invalidateQueries([projectQueryKeys.teamProgressBase()]);
  }, [assignmentId, queryClient]);

  const successHandler = useCallback(() => {
    notify(NotificationType.ASSIGNMENT_UNIT_REMOVED);
    onSuccess?.();
    refreshAssignments();
  }, [notify, onSuccess, refreshAssignments]);
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFnMutation } = useFetch<Request>({
    topic: socketTopics.ASSIGNMENT_UNIT_DELETE,
    request: deleteAssignmentUnitRequest,
  });

  const { mutate, isLoading } = useMutation(fetchFnMutation, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
  });

  const deleteAssignmentUnit = useCallback(() => {
    if (defaultParams) {
      mutate(defaultParams);
    }
  }, [defaultParams, mutate]);

  return {
    deleteAssignmentUnit,
    isLoading,
  };
};
