import { Box, Grid } from '@mui/material';
import { PlaybookQuizForm } from '.';
import {
  PlaybookQuizAnswerWrapper,
  PlaybookQuizAnswerWrapperProps,
} from './AnswerWrapper';
import { PlaybookQuizQuestionItem } from './QuestionItem';

type PlaybookQuizQuestionWrapperProps = {
  items: PlaybookQuizForm[0]['questionOptions'];
  answer: PlaybookQuizAnswerWrapperProps['answer'];
  onClick?: (mark: string) => () => void;
};

export const PlaybookQuizQuestionWrapper = ({
  items,
  answer,
  onClick,
}: PlaybookQuizQuestionWrapperProps) => {
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" flexDirection="column" gap="30px">
          {items.map(({ mark, answerOption }) => (
            <PlaybookQuizQuestionItem
              key={mark}
              mark={mark}
              content={answerOption}
              active={answer === null}
              correct={answer?.correct.mark === mark}
              incorrect={answer?.incorrect?.mark === mark}
              onClick={onClick?.(mark)}
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={5}>
        <PlaybookQuizAnswerWrapper answer={answer} />
      </Grid>
    </Grid>
  );
};
