import { useQuery } from '@tanstack/react-query';
import { FeatureFlagKeys } from 'common/enums';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { FeatureFlagSchema, FeatureFlags } from 'types/FeatureFlags';
import { SocketHandlerProps } from 'types/socket';
import { getFeatureFlagsRequest } from '.';
import { queryKeys } from './queryKeys';
import { useSessionData } from './useSessionData';

const schema = FeatureFlagSchema;

type ResponseData = FeatureFlags;
type Request = void;
type FFKey = keyof typeof FeatureFlagKeys;
type AdaptedResponse = Record<FFKey, boolean>;

export const useFeatureFlags = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<void, AdaptedResponse>) => {
  const { notify } = useNotifications();
  const { isSessionValid } = useSessionData({});

  const successHandler = (result?: AdaptedResponse) => {
    onSuccess?.(result);
  };
  const failureHandler = (message_key: NotificationType) => {
    notify(message_key);
    onFailure?.();
  };
  const endHandler = () => {
    onEnd?.();
  };

  const { fetchFn } = useFetch<Request, ResponseData, AdaptedResponse>({
    topic: socketTopics.FEATURE_FLAGS_LOAD,
    request: getFeatureFlagsRequest,
    schema,
    adaptFn,
  });

  const { data } = useQuery([queryKeys.featureFlags()], fetchFn, {
    onSuccess: successHandler,
    onError: failureHandler,
    onSettled: endHandler,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: isSessionValid,
  });

  return {
    ff: data ?? ({} as AdaptedResponse),
  };
};

const adaptFn = (ff?: ResponseData) => {
  if (!ff) return {} as AdaptedResponse;

  return Object.entries(FeatureFlagKeys).reduce<AdaptedResponse>(
    (acc, [key, value]) => {
      acc[key as FFKey] = ff[value];

      return acc;
    },
    {} as AdaptedResponse
  );
};
