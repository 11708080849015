import { Box, ListItemButton, Typography } from '@mui/material';

export type PlaybookQuizQuestionItemProps = {
  mark: string;
  content: string;
  active: boolean;
  correct?: boolean;
  incorrect?: boolean;
  onClick?: () => void;
};

export const PlaybookQuizQuestionItem = ({
  mark,
  content,
  active,
  correct,
  incorrect,
  onClick,
}: PlaybookQuizQuestionItemProps) => {
  return (
    <ListItemButton
      sx={btnStyle}
      className="question-item"
      disabled={!active}
      onClick={onClick}
    >
      <Box
        width="100%"
        px="12px"
        py="16px"
        border="1px solid"
        borderRadius="4px"
        overflow="hidden"
        sx={getBorderColor(correct, incorrect)}
      >
        <Typography sx={opacityStyle}>{`${mark}. ${content}`}</Typography>
      </Box>
    </ListItemButton>
  );
};

const getBorderColor =
  (correct?: boolean, incorrect?: boolean) =>
  ({ palette }: any) => {
    let borderColor = 'border.main';
    if (correct) {
      borderColor = palette.success.main;
    }
    if (incorrect) {
      borderColor = palette.warning.main;
    }

    return {
      borderColor,
    };
  };
const opacityStyle = { opacity: 0.6 };
const btnStyle = {
  padding: 0,
  '&.MuiListItemButton-root.Mui-disabled.question-item': {
    opacity: 1,
  },
};
