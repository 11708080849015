import {
  getLocalStorageSocketId,
  setLocalStorageSocketId,
} from 'common/localStorage';
import { setSessionStorageSocketId } from 'common/sessionStorage';
import { useEffectOnce } from 'hooks';
import React, { ReactNode, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { SocketContextType } from 'types/context';
import { getProcessEnv } from 'utils';

const processEnv = getProcessEnv();

const SocketProvider = ({ children }: SocketProviderProps) => {
  const [socket, setSocket] = useState<Socket<
    DefaultEventsMap,
    DefaultEventsMap
  > | null>(null);

  useEffectOnce(() => {
    const localStorageSocketId = getLocalStorageSocketId();
    const socket = io(processEnv.NEXT_PUBLIC_BACKEND_API_URL!, {
      transports: ['websocket'],
      query: {
        socketId: localStorageSocketId,
      },
    });
    socket.on('reconnect_attempt', () => {
      socket.io.opts.query = {
        socketId: localStorageSocketId,
      };
    });
    socket.on('connect', () => {
      if (!localStorageSocketId) setLocalStorageSocketId(socket.id);
      setSessionStorageSocketId(socket.id);
      setSocket(socket);
    });
  });

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;

type SocketProviderProps = {
  children: ReactNode;
};

export const SocketContext = React.createContext<SocketContextType>({
  socket: null,
});
