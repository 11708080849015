import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Box, ListItemButton, Typography } from '@mui/material';
import { mailToSupport } from 'common/consts';
import { HelpDropdown, HelpDropdownItem } from 'components/Inputs/Dropdown';
import { Tooltip } from 'components/Inputs/Tooltip';
import { useProductFeatures } from 'hooks';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';

export const PlaybookSidebarHelp = ({
  isOpen,
  helpLink,
  isMobile = false,
}: {
  isOpen: boolean;
  helpLink?: string | null;
  isMobile?: boolean;
}) => {
  const { sanity } = useIncubatePlaybookData();
  const productFeatures = useProductFeatures();

  const {
    needHelpQuestionLabel,
    contactShiftExpertLabel,
    techSupportLabel,
    accountHelpLabel,
    addHelpSuffixLabel,
  } = useCommonTranslations();
  if (!helpLink || !productFeatures.contactExpert) return null;

  const helpDropdownList = [
    {
      title: contactShiftExpertLabel,
      subtitle: addHelpSuffixLabel(sanity.title),
      link: sanity?.contactUrl ?? '',
      newTab: true,
    },
    {
      title: techSupportLabel,
      subtitle: accountHelpLabel,
      link: mailToSupport,
    },
  ];

  return (
    <Box mt="32px" mb="8px">
      {!isOpen && !isMobile && productFeatures.contactExpert && (
        <HelpDropdown
          list={helpDropdownList}
          basic
          dropdownEl={
            <Tooltip title={contactShiftExpertLabel} placement="right">
              <ListItemButton>
                <Box display="flex" alignItems="center" height="60px" m="auto">
                  <HelpIcon sx={helpIconClosedStyle} />
                </Box>
              </ListItemButton>
            </Tooltip>
          }
        />
      )}
      {isOpen && !isMobile && (
        <>
          <Box pl={2} display="flex" mb={2} gap="7px">
            <HelpIcon sx={colorStyle} />
            <Typography color="secondary.main">
              {needHelpQuestionLabel}
            </Typography>
          </Box>
          {helpDropdownList.map(({ title, subtitle, link, newTab }) => (
            <ListItemButton key={title} sx={listItemStyle}>
              <HelpDropdownItem
                title={title}
                subtitle={subtitle}
                link={link}
                newTab={newTab}
              />
            </ListItemButton>
          ))}
        </>
      )}
      {isMobile && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" mb="17px" gap="7px">
            <HelpIcon sx={colorStyle} />
            <Typography color="secondary.main">
              {needHelpQuestionLabel}
            </Typography>
          </Box>
          {helpDropdownList.map(({ title, subtitle, link, newTab }) => (
            <ListItemButton key={title} sx={listItemStyle}>
              <HelpDropdownItem
                title={title}
                subtitle={subtitle}
                link={link}
                newTab={newTab}
              />
            </ListItemButton>
          ))}
        </Box>
      )}
    </Box>
  );
};

const listItemStyle = {
  p: 0,
};

const btnStyleMobile = {
  borderRadius: '80px',
  fontSize: 13,
  width: '90vw',
};

const colorStyle = {
  color: 'secondary.main',
};
const helpIconClosedStyle = {
  cursor: 'pointer',
  color: 'secondary.main',
};
