import { Theme } from 'common/enums';
import merge from 'lodash/merge';
import { useTheme } from 'next-themes';
import { useMemo } from 'react';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useClient } from 'store/rq/clientData';
import { Brand } from '../types/Client';

export const useBrand = () => {
  const { theme } = useTheme();
  const { playbookInfo, isPlaybookInfoValid } = useGetPlaybookInfo({});

  const { client } = useClient();

  let brand = client?.brand ?? {};

  if (isPlaybookInfoValid) {
    const projectBrand: Brand | undefined = client?.projects.find(
      (project) => project._id === playbookInfo.projectId
    )?.brand;

    brand = merge(brand, projectBrand);
  }

  const logos = useMemo(() => {
    const modePrefix = theme === Theme.dark ? 'Dark' : '';

    const getLogoBySizeAndMode = (
      logos: Brand['logos'] | undefined,
      key: 'smallLogo' | 'largeLogo',
      mode: 'Dark' | ''
    ) => {
      const logoKeyDark = `${key}Dark` as keyof Brand['logos'];
      const logoKeyByMode = `${key}${mode}` as keyof Brand['logos'];

      return logos?.[logoKeyByMode] || logos?.[key] || logos?.[logoKeyDark];
    };

    return {
      smallLogo: getLogoBySizeAndMode(brand?.logos, 'smallLogo', modePrefix),
      largeLogo: getLogoBySizeAndMode(brand?.logos, 'largeLogo', modePrefix),
    };
  }, [brand?.logos, theme]);

  return {
    brand: { ...brand, logos },
  };
};
