import { ProductFeature } from 'common/enums';
import { z } from 'zod';

export type ProductFeaturesKey = keyof typeof ProductFeature;
export type ProductFeatures = {
  [key in ProductFeaturesKey]?: boolean;
};

export const ClientFeaturesSchema = z.record(
  z.record(z.record(z.nativeEnum(ProductFeature), z.boolean()))
);
export type ClientProductFeatures = z.infer<typeof ClientFeaturesSchema>;

export type ProjectProductFeaturesUpdate = {
  productId: string;
  featureId: string;
  featureValue: boolean;
};
