import { LayoutSwitchType } from 'components/LayoutSwitch';
import { ClientsTab } from 'pages/mach49/clients';
import { PlaybookInfoData } from 'types/incubatePlaybook';

enum localStorageKeys {
  oktaTokenStorage = 'okta-token-storage',
  socketAccessStorage = 'socket-access-storage',
  introductionPopup = 'introduction-popup',
  tempEmailStorage = 'temp-email-storage',
  playbookInfo = 'playbook-info',
  clientsPage = 'clients-page',
}

const getLocalStorage = (key: localStorageKeys) => {
  const item = localStorage.getItem(key);

  try {
    if (item) {
      return JSON.parse(item);
    }
  } catch {}
};
const setLocalStorage = (key: localStorageKeys, value: unknown) => {
  const v = JSON.stringify(value);
  localStorage.setItem(key, v);
};
const deleteLocalStorage = (key: localStorageKeys) => {
  localStorage.removeItem(key);
};

// okta access token
export const getLocalStorageOktaAccessToken = (): string | undefined => {
  const oktaData = getLocalStorage(localStorageKeys.oktaTokenStorage);

  return oktaData?.accessToken?.accessToken;
};
export const removeLocalStorageOktaAccessToken = () => {
  deleteLocalStorage(localStorageKeys.oktaTokenStorage);
};

// socketId
export const getLocalStorageSocketId = (): string | null => {
  return getLocalStorage(localStorageKeys.socketAccessStorage);
};
export const setLocalStorageSocketId = (socketId: string) => {
  setLocalStorage(localStorageKeys.socketAccessStorage, socketId);
};

// local visited
export const setLocalStoragePlaybookVisited = (localItemId: string) => {
  const localItems = getLocalStoragePlaybookVisitedAll();
  localItems[localItemId] = true;
  setLocalStorage(localStorageKeys.introductionPopup, localItems);
};
export const isLocalStoragePlaybookVisited = (localItemId: string) => {
  const localItems = getLocalStoragePlaybookVisitedAll();

  return !!localItems[localItemId];
};
const getLocalStoragePlaybookVisitedAll = (): Record<string, boolean> => {
  return getLocalStorage(localStorageKeys.introductionPopup) ?? {};
};

// temp email exchange storage
export const setLocalStorageEmailExchange = (value: string) => {
  setLocalStorage(localStorageKeys.tempEmailStorage, value);
};
export const getLocalStorageEmailExchange = () => {
  return getLocalStorage(localStorageKeys.tempEmailStorage);
};
export const resetLocalStorageEmailExchange = () => {
  deleteLocalStorage(localStorageKeys.tempEmailStorage);
};

// playbook info
export type PlaybookCache = {
  playbookSlug: string;
  playbookInfo: PlaybookInfoData;
  timestamp: Date;
};
export const setLocalStoragePlaybookInfoCache = (cacheData: PlaybookCache) => {
  const playbookCache = getLocalStoragePlaybookInfoCacheAll();
  playbookCache[cacheData.playbookSlug] = cacheData;
  setLocalStorage(localStorageKeys.playbookInfo, playbookCache);
};
export const getLocalStoragePlaybookInfoCache = (playbookSlug?: string) => {
  if (!playbookSlug) return;
  const playbookCache = getLocalStoragePlaybookInfoCacheAll();

  return playbookCache[playbookSlug];
};
export const removeLocalStoragePlaybookInfoCache = (playbookSlug: string) => {
  const playbookCache = getLocalStoragePlaybookInfoCacheAll();
  playbookCache[playbookSlug] = undefined;

  setLocalStorage(localStorageKeys.playbookInfo, playbookCache);
};

const getLocalStoragePlaybookInfoCacheAll = (): Record<
  string,
  PlaybookCache | undefined
> => {
  return getLocalStorage(localStorageKeys.playbookInfo) ?? {};
};

export type ClientsPageSettings = {
  activeTab: ClientsTab;
  activeLayout: LayoutSwitchType;
};
export const setLocalStorageClientsPage = (props: ClientsPageSettings) => {
  setLocalStorage(localStorageKeys.clientsPage, props);
};

export const getLocalStorageClientsPage = (): ClientsPageSettings => {
  return getLocalStorage(localStorageKeys.clientsPage);
};
