import { HypothesisBacklogStatus } from 'common/ventureBuilderConsts';
import { useIntl } from 'react-intl';

export const useProductTranslations = () => {
  const { formatMessage } = useIntl();

  const myProductsLabel = formatMessage({
    id: 'products.myProducts',
    defaultMessage: 'My Products',
  });

  const productsLabel = formatMessage({
    id: 'products',
    defaultMessage: 'Products',
  });

  const searchProductsLabel = formatMessage({
    id: 'products.search',
    defaultMessage: 'Search products',
  });

  const addNewProductPlusLabel = formatMessage({
    id: 'products.addNewPlus',
    defaultMessage: '+ add new product',
  });

  const addNewProductLabel = formatMessage({
    id: 'products.addNewProduct',
    defaultMessage: 'Add New Product',
  });

  const productNameLabel = formatMessage({
    id: 'products.productName',
    defaultMessage: 'Product Name',
  });

  const productContentLabel = formatMessage({
    id: 'products.productContent',
    defaultMessage: 'Product Content',
  });

  const addProductLabel = formatMessage({
    id: 'products.addProduct',
    defaultMessage: 'Add Product',
  });

  const noAvailableProductsLabel = formatMessage({
    id: 'products.noAvailableProducts',
    defaultMessage: 'No available products.',
  });

  const customizeFooterLogosBrandForProductsLabel = formatMessage({
    id: 'products.customizeFooterLogosBrandForProducts',
    defaultMessage: 'Customize footer, logo and brand colors across products.',
  });

  const productSuccessfullyCreatedLabel = formatMessage({
    id: 'products.productSuccessfullyCreated',
    defaultMessage: 'Product successfully created',
  });

  const productSuccessfullyUpdatedLabel = formatMessage({
    id: 'products.productSuccessfullyUpdated',
    defaultMessage: 'Product successfully updated',
  });

  const cannotDeactivateProductInUseLabel = formatMessage({
    id: 'products.cannotDeactivateProductInUse',
    defaultMessage: 'Can not deactivate product that is in use.',
  });

  const thisProductHasBeenDeactivatedLabel = formatMessage({
    id: 'products.thisProductHasBeenDeactivated',
    defaultMessage: 'This product has been deactivated.',
  });

  // VB

  const failedToUploadStakeholderMapLabel = formatMessage({
    id: 'products.ventureBuilder.failedToUploadStakeholderMap',
    defaultMessage: 'Failed to upload the Stakeholder map.',
  });

  const stakeholderWithNameAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.stakeholderWithNameAlreadyExists',
    defaultMessage: 'Stakeholder with that name already exists.',
  });

  const stakeholderMapWithNameAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.stakeholderMapWithNameAlreadyExists',
    defaultMessage: 'Stakeholder Map with that name already exists.',
  });

  const hypothesisAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.hypothesisAlreadyExists',
    defaultMessage: 'Hypothesis already exists.',
  });

  const hypothesisForStakeholderAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.hypothesisForStakeholderAlreadyExists',
    defaultMessage: 'Hypothesis for this stakeholder already exists.',
  });

  const valuePropositionAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.valuePropositionAlreadyExists',
    defaultMessage: 'Value Proposition already exists.',
  });

  const valuePropositionForStakeholderAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.valuePropositionForStakeholderAlreadyExists',
    defaultMessage: 'Value Proposition for this stakeholder already exists.',
  });

  const painPointAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.painPointAlreadyExists',
    defaultMessage: 'Pain Point already exists.',
  });

  const painPointForStakeholderAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.painPointForStakeholderAlreadyExists',
    defaultMessage: 'Pain Point for this stakeholder already exists.',
  });

  const prototypeAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.prototypeAlreadyExists',
    defaultMessage: 'Prototype already exists.',
  });

  const prototypeForStakeholderAlreadyExistsLabel = formatMessage({
    id: 'products.ventureBuilder.prototypeForStakeholderAlreadyExists',
    defaultMessage: 'Top Prototype for this stakeholder already exists.',
  });

  const goToMyVentureBuilderLabel = formatMessage({
    id: 'products.ventureBuilder.goToMyVentureBuilder',
    defaultMessage: 'Go to my Venture Builder',
  });

  const objectiveDocumentSectionInVBLabel = (section: string) =>
    formatMessage(
      {
        id: 'products.ventureBuilder.objectiveDocumentSectionInVB',
        defaultMessage: 'Objective: Document {section} in Venture Builder',
      },
      { section }
    );

  const whoMattersMostLabel = formatMessage({
    id: 'products.ventureBuilder.whoMattersMost',
    defaultMessage: 'Who Matters Most',
  });

  const topValuePropositionsLabel = formatMessage({
    id: 'products.ventureBuilder.topValuePropositions',
    defaultMessage: 'Top Value Propositions',
  });

  const topPrototypesLabel = formatMessage({
    id: 'products.ventureBuilder.topPrototypes',
    defaultMessage: 'Top Prototypes',
  });

  const stakeholderMapLabel = formatMessage({
    id: 'products.ventureBuilder.stakeholderMap',
    defaultMessage: 'Stakeholder Map',
  });

  const prioritizedPainPointsLabel = formatMessage({
    id: 'products.ventureBuilder.prioritizedPainPoints',
    defaultMessage: 'Prioritized Pain Points',
  });

  const marketSizeLabel = formatMessage({
    id: 'products.ventureBuilder.marketSize',
    defaultMessage: 'Market Size',
  });

  const tamLabel = formatMessage({
    id: 'products.ventureBuilder.marketSize.tam',
    defaultMessage: 'Total Addressable Market (TAM)',
  });

  const samLabel = formatMessage({
    id: 'products.ventureBuilder.marketSize.sam',
    defaultMessage: 'Service Addressable Market (SAM)',
  });

  const somLabel = formatMessage({
    id: 'products.ventureBuilder.marketSize.som',
    defaultMessage: 'Service Obtainable Market (SOM)',
  });

  const hypothesisBacklogLabel = formatMessage({
    id: 'products.ventureBuilder.hypothesisBacklog',
    defaultMessage: 'Hypothesis Backlog',
  });

  const fundingRequestLabel = formatMessage({
    id: 'products.ventureBuilder.fundingRequest',
    defaultMessage: 'Funding Request',
  });

  const challengeStatementLabel = formatMessage({
    id: 'products.ventureBuilder.challengeStatement',
    defaultMessage: 'Challenge Statement',
  });

  const copyAndPasteChallengeStatementBelowIntoVBLabel = formatMessage({
    id: 'products.ventureBuilder.challengeStatement.copyAndPasteChallengeStatementBelowIntoVB',
    defaultMessage:
      "Copy and paste your Challenge Statement below into your team's VentureBuilder",
  });

  const documentFollowingInBusinessSectionOfVBLabel = formatMessage({
    id: 'products.ventureBuilder.currency.documentFollowingInBusinessSectionOfVB',
    defaultMessage:
      'Document the following in the Business section of your Venture Builder',
  });

  const selectCurrencyLabel = formatMessage({
    id: 'products.ventureBuilder.currency.selectCurrency',
    defaultMessage: 'Select Currency',
  });

  const objectiveDocumentHypothesesInVBLabel = formatMessage({
    id: 'products.ventureBuilder.hypothesisBacklog.objectiveDocumentHypothesesInVB',
    defaultMessage: 'Objective: Document Hypotheses in Venture Builder',
  });

  const documentAllNewHypothesesInHupothesisBacklogInVBLabel = formatMessage({
    id: 'products.ventureBuilder.hypothesisBacklog.documentAllNewHypothesesInHupothesisBacklogInVB',
    defaultMessage:
      'Document all new hypotheses in your hypothesis backlog under your team Venture Builder.',
  });

  const stakeholderLabel = formatMessage({
    id: 'products.ventureBuilder.stakeholder',
    defaultMessage: 'Stakeholder',
  });

  const stakeholdersLabel = formatMessage({
    id: 'products.ventureBuilder.whoMattersMost.stakeholders',
    defaultMessage: 'Stakeholders',
  });

  const plusNewStakeholderLabel = formatMessage({
    id: 'products.ventureBuilder.whoMattersMost.plusNewStakeholder',
    defaultMessage: '+ new stakeholder',
  });

  const hypothesisLabel = formatMessage({
    id: 'products.ventureBuilder.hypothesis',
    defaultMessage: 'Hypothesis',
  });

  const plusNewHypothesisLabel = formatMessage({
    id: 'products.ventureBuilder.hypothesisBacklog.plusNewHypothesis',
    defaultMessage: '+ new hypothesis',
  });

  const noAddedHypotheseLabel = formatMessage({
    id: 'products.ventureBuilder.hypothesisBacklog.noAddedHypothese',
    defaultMessage: 'No hypotheses added yet',
  });

  const documentAllPrioritizedPainPointsInVBLabel = formatMessage({
    id: 'products.ventureBuilder.prioritizedPainPoints.documentAllPrioritizedPainPointsInVB',
    defaultMessage:
      'Document all prioritized pain points in your team Venture Builder.',
  });

  const documentAllTopPrototypesInVBLabel = formatMessage({
    id: 'products.ventureBuilder.topPrototypes.documentAllTopPrototypesInVB',
    defaultMessage: 'Document all top prototypes in your team Venture Builder.',
  });

  const documentAllTopValuePropositionsInVBLabel = formatMessage({
    id: 'products.ventureBuilder.topValuePropositions.documentAllTopValuePropositionsInVB',
    defaultMessage:
      'Document all top value propositions in your team Venture Builder.',
  });

  const plusNewValuePropositionLabel = formatMessage({
    id: 'products.ventureBuilder.topValuePropositions.plusNewValueProposition',
    defaultMessage: '+ new value proposition',
  });

  const prototypeNameLabel = formatMessage({
    id: 'products.ventureBuilder.topPrototypes.prototypeName',
    defaultMessage: 'Prototype Name',
  });

  const painPointLabel = formatMessage({
    id: 'products.ventureBuilder.prioritizedPainPoints.painPoint',
    defaultMessage: 'Pain Point',
  });

  const plusNewPainPointLabel = formatMessage({
    id: 'products.ventureBuilder.prioritizedPainPoints.plusNewPainPoint',
    defaultMessage: '+ new pain point',
  });

  const plusNewPrototypeLabel = formatMessage({
    id: 'products.ventureBuilder.topPrototypes.plusNewPrototype',
    defaultMessage: '+ new prototype',
  });

  const addNewStakeholdersBelowIntoYourVBLabel = formatMessage({
    id: 'products.ventureBuilder.whoMattersMost.addNewStakeholdersBelowIntoYourVB',
    defaultMessage:
      "Add your new stakeholders below into your team's Venture Builder",
  });

  const stakeholderNameLabel = formatMessage({
    id: 'products.ventureBuilder.whoMattersMost.stakeholderName',
    defaultMessage: 'Stakeholder Name',
  });

  const noAddedStakeholdersLabel = formatMessage({
    id: 'products.ventureBuilder.whoMattersMost.noAddedStakeholders',
    defaultMessage: 'No stakeholders added yet',
  });

  const noAddedPrototypesLabel = formatMessage({
    id: 'products.ventureBuilder.topPrototypes.noAddedPrototypes',
    defaultMessage: 'No prototypes added yet',
  });

  const noAddedValuePropositionsLabel = formatMessage({
    id: 'products.ventureBuilder.topValuePropositions.noAddedValuePropositions',
    defaultMessage: 'No value propositions added yet',
  });

  const valuePropositionNameLabel = formatMessage({
    id: 'products.ventureBuilder.valuePropositionName',
    defaultMessage: 'Value Proposition Name',
  });

  const avgNPSLabel = formatMessage({
    id: 'products.ventureBuilder.avgNPSLabel',
    defaultMessage: 'Average NPS',
  });

  const avgPainScoreLabel = formatMessage({
    id: 'products.ventureBuilder.avgPainScoreLabel',
    defaultMessage: 'Average Pain Score',
  });

  const resolveHypothesisBacklogStatusLabel = (key: HypothesisBacklogStatus) =>
    formatMessage(
      {
        id: 'products.ventureBuilder.hypothesysBacklog.resolveHypothesisBacklogStatus',
        defaultMessage:
          '{key, select, validated {VALIDATED} invalidated {INVALIDATED} other {CURRENTLY TESTING}}',
      },
      { key }
    );

  const shiftPlaybookLabel = formatMessage({
    id: 'products.shiftPlaybook',
    defaultMessage: 'Shift AI Playbook',
  });

  return {
    shiftPlaybookLabel: shiftPlaybookLabel,
    resolveHypothesisBacklogStatusLabel,
    noAddedValuePropositionsLabel,
    avgPainScoreLabel,
    plusNewValuePropositionLabel,
    documentAllTopValuePropositionsInVBLabel,
    valuePropositionNameLabel,
    noAddedPrototypesLabel,
    avgNPSLabel,
    plusNewPrototypeLabel,
    prototypeNameLabel,
    documentAllTopPrototypesInVBLabel,
    stakeholderNameLabel,
    noAddedStakeholdersLabel,
    plusNewStakeholderLabel,
    stakeholdersLabel,
    addNewStakeholdersBelowIntoYourVBLabel,
    plusNewPainPointLabel,
    painPointLabel,
    documentAllPrioritizedPainPointsInVBLabel,
    tamLabel,
    samLabel,
    somLabel,
    noAddedHypotheseLabel,
    plusNewHypothesisLabel,
    stakeholderLabel,
    hypothesisLabel,
    documentAllNewHypothesesInHupothesisBacklogInVBLabel,
    objectiveDocumentHypothesesInVBLabel,
    selectCurrencyLabel,
    documentFollowingInBusinessSectionOfVBLabel,
    copyAndPasteChallengeStatementBelowIntoVBLabel,
    objectiveDocumentSectionInVBLabel,
    challengeStatementLabel,
    fundingRequestLabel,
    hypothesisBacklogLabel,
    marketSizeLabel,
    prioritizedPainPointsLabel,
    stakeholderMapLabel,
    topPrototypesLabel,
    topValuePropositionsLabel,
    whoMattersMostLabel,
    goToMyVentureBuilderLabel,
    myProductsLabel,
    prototypeAlreadyExistsLabel,
    prototypeForStakeholderAlreadyExistsLabel,
    valuePropositionAlreadyExistsLabel,
    valuePropositionForStakeholderAlreadyExistsLabel,
    painPointAlreadyExistsLabel,
    painPointForStakeholderAlreadyExistsLabel,
    stakeholderWithNameAlreadyExistsLabel,
    stakeholderMapWithNameAlreadyExistsLabel,
    hypothesisAlreadyExistsLabel,
    hypothesisForStakeholderAlreadyExistsLabel,
    failedToUploadStakeholderMapLabel,
    cannotDeactivateProductInUseLabel,
    thisProductHasBeenDeactivatedLabel,
    productSuccessfullyCreatedLabel,
    productSuccessfullyUpdatedLabel,
    customizeFooterLogosBrandForProductsLabel,
    noAvailableProductsLabel,
    addProductLabel,
    productContentLabel,
    productNameLabel,
    addNewProductLabel,
    productsLabel,
    searchProductsLabel,
    addNewProductPlusLabel,
  };
};
