import FavoriteEmptyIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteFullIcon from '@mui/icons-material/FavoriteOutlined';
import { Box, Typography } from '@mui/material';
import { ButtonOutlined } from 'components/Inputs/Button';
import { useCallback, useMemo } from 'react';
import { useGetUserOnProject } from 'services/api/client/project/useGetUserOnProject';
import { useGetIncubatePlaybookFavoriteIds } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookFavoriteIds';
import { useSetIncubatePlaybookFavorite } from 'services/api/product/incubatePlaybook/useSetIncubatePlaybookFavorite';
import { useCommonTranslations } from 'translations/hooks';

type PlaybookFavoriteButtonProps = {
  itemId: string;
  itemName: string;
  itemUnitName?: string;
  itemModuleName?: string;
  itemPhaseName: string;
  itemType: string;
  itemLink: string;
  itemImageURL?: string;
  iconOnly?: boolean;
  dataTestId?: string;
};

export const PlaybookFavoriteButton = ({
  iconOnly,
  dataTestId = 'save-to-favorites',
  ...props
}: PlaybookFavoriteButtonProps) => {
  const { favoriteIds } = useGetIncubatePlaybookFavoriteIds({});
  const { setFavorite } = useSetIncubatePlaybookFavorite({});
  const { isCurrentUserOnProject } = useGetUserOnProject();

  const value = useMemo(() => {
    return !!favoriteIds[props.itemId];
  }, [favoriteIds, props.itemId]);

  const clickHandler = useCallback(() => {
    setFavorite({ ...props, isFavorite: !value });
  }, [props, setFavorite, value]);

  if (!isCurrentUserOnProject) return null;

  return (
    <PlaybookFavoriteButtonBasic
      iconOnly={iconOnly}
      value={value}
      onClick={clickHandler}
      dataTestId={dataTestId}
    />
  );
};

type PlaybookFavoriteButtonBasicProps = {
  value: boolean;
  iconOnly?: boolean;
  dataTestId: string;
  onClick?: () => void;
};

const PlaybookFavoriteButtonBasic = ({
  value,
  iconOnly,
  dataTestId,
  onClick,
}: PlaybookFavoriteButtonBasicProps) => {
  const { saveOrSavedToFavsLabel } = useCommonTranslations();

  return iconOnly ? (
    <IconOnlyFavoriteButton
      dataTestId={dataTestId}
      value={value}
      onClick={onClick}
    />
  ) : (
    <ButtonOutlined sx={btnStyle}>
      <Box
        data-testid={dataTestId}
        display="flex"
        flexWrap="nowrap"
        gap="10.67px"
        alignItems="center"
        py="4px"
        onClick={onClick}
      >
        <FavoriteIcon value={value} />
        <Typography fontSize={14} noWrap>
          {saveOrSavedToFavsLabel(value)}
        </Typography>
      </Box>
    </ButtonOutlined>
  );
};

const IconOnlyFavoriteButton = ({
  value,
  dataTestId,
  onClick,
}: PlaybookFavoriteButtonBasicProps) => (
  <Box
    data-testid={dataTestId}
    color="secondary.main"
    onClick={onClick}
    display="flex"
    alignItems="center"
  >
    <FavoriteIcon value={value} />
  </Box>
);

const btnStyle = {
  color: 'text.default',
  backgroundColor: 'action.hover',
  textTransform: 'none',
  width: 'fit-content',
  pr: '10px',
  border: 'unset',
  '&:hover': {
    border: 'unset',
    backgroundColor: 'action.selected',
  },
};

const FavoriteIcon = ({ value }: { value: boolean }) =>
  value ? (
    <FavoriteFullIcon color="secondary" />
  ) : (
    <FavoriteEmptyIcon color="secondary" />
  );
