import { Paper } from '@mui/material';
import { Container } from '@mui/system';
import { ReactNode } from 'react';

type HeaderWrapperProps = {
  children: ReactNode;
  sx?: object;
};

export const HeaderWrapper = ({ children, sx }: HeaderWrapperProps) => {
  return (
    <Paper elevation={16} sx={sx}>
      <Container maxWidth={false}>{children}</Container>
    </Paper>
  );
};
