import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowHeightParams,
  GridRowParams,
  GridSortModel,
  GridValidRowModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { JSXElementConstructor, ReactNode } from 'react';

export type TableColumn = GridColDef;
export type TableCellParams = GridValueGetterParams;
export type TableSortModel = GridSortModel;
export type SortModelChangeHandler = (model: TableSortModel) => void;
export type TableRow<T extends GridValidRowModel> = GridRowParams<T>;
export type TableRowHeightParams = GridRowHeightParams;

type PlaybookTableProps = {
  disabled?: boolean;
  rows: Array<any>;
  columns: TableColumn[];
  loading?: boolean;
  NoRowsOverlay?: JSXElementConstructor<any>;
  sortModelChangeHandler?: SortModelChangeHandler;
  sortModel?: TableSortModel;
  page?: number;
  onPageChange?: (page: number) => void;
  rowCount?: number;
  onRowClick?: (row: GridRowParams) => void;
  getRowHeight?: (params: TableRowHeightParams) => number | undefined;
  addBelowTable?: ReactNode;
};

// TODO Remove props if we are using client-side
// OR uncomment if we are going server-side
const PlaybookTable = ({
  disabled,
  rows,
  columns,
  loading,
  sortModelChangeHandler,
  sortModel,
  page,
  onPageChange,
  rowCount,
  addBelowTable,
  // onRowClick,
  // getRowHeight,
  NoRowsOverlay,
}: PlaybookTableProps) => {
  return (
    <Box minHeight="600px" sx={disabled ? disabledStyle : undefined}>
      <DataGrid
        sx={tableStyle}
        rows={rows}
        columns={columns}
        sortingMode="server"
        onSortModelChange={loading ? undefined : sortModelChangeHandler}
        sortModel={sortModel}
        components={{ NoRowsOverlay }}
        pageSize={PAGE_SIZE}
        rowsPerPageOptions={[PAGE_SIZE]}
        paginationMode={onPageChange ? 'server' : undefined}
        page={page}
        rowCount={rowCount}
        onPageChange={onPageChange}
        disableSelectionOnClick
        disableColumnMenu
        loading={loading}
        autoHeight
        getRowHeight={() => 'auto'}
      />
      {addBelowTable}
    </Box>
  );
};

export const PAGE_SIZE = 10;

const disabledStyle = {
  opacity: 0.4,
  pointerEvents: 'none',
};

const tableStyle = {
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
    {
      width: 'auto',
      visibility: 'visible',
    },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell--withRenderer': {
    justifyContent: 'end',
  },
  '& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft':
    {
      justifyContent: 'start',
    },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-cell': {
    paddingTop: '10px',
    paddingBottom: '10px',
    minHeight: '60px !important',
  },
  border: 0,
};

export default PlaybookTable;
