import { MarketSizeKeys, VentureBuilderItemAction } from 'common/enums';
import { Slugs } from 'common/ventureBuilderConsts';
import { z } from 'zod';

export function defaultVBListResponse<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    content: schema.optional(),
  });
}

export type VentureBuilderChange<SectionContentObject> = {
  action: VentureBuilderItemAction;
  data: SectionContentObject;
};

export const StakeholderSchema = z.object({
  _id: z.string(),
  name: z.string(),
});
export type Stakeholder = z.infer<typeof StakeholderSchema>;

export const StakeholdersListSchema = defaultVBListResponse(
  StakeholderSchema.array()
);
export type StakeholdersList = z.infer<typeof StakeholdersListSchema>;

export const StakeholderMapSchema = z.object({
  _id: z.string(),
  name: z.string(),
  imageUrl: z.string(),
});
export type StakeholderMap = z.infer<typeof StakeholderMapSchema>;

export const StakeholderMapsListSchema = defaultVBListResponse(
  StakeholderMapSchema.array()
);
export type StakeholderMapsList = z.infer<typeof StakeholderMapsListSchema>;

export const HypothesisSchema = z.object({
  _id: z.string(),
  hypothesis: z.string(),
  stakeholderId: z.string().nullable().optional(),
  status: z.string(),
  newOrderIndex: z.number().optional(),
});
export type Hypothesis = z.infer<typeof HypothesisSchema>;

export const HypothesisListSchema = defaultVBListResponse(
  HypothesisSchema.array()
);
export type HypothesisList = z.infer<typeof HypothesisListSchema>;

export type HypothesisStatusOption = {
  value: string;
  label: string;
};

export const TopValuePropositionSchema = z.object({
  _id: z.string(),
  valuePropositionName: z.string(),
  stakeholderId: z.string().nullable().optional(),
  averageNps: z.number().optional(),
  averagePainScore: z.number().optional(),
});
export type TopValueProposition = z.infer<typeof TopValuePropositionSchema>;

export const TopValuePropositionListSchema = defaultVBListResponse(
  TopValuePropositionSchema.array()
);
export type TopValuePropositionList = z.infer<
  typeof TopValuePropositionListSchema
>;

export const PainPointSchema = z.object({
  _id: z.string(),
  painPoint: z.string(),
  stakeholderId: z.string().nullable().optional(),
});
export type PainPoint = z.infer<typeof PainPointSchema>;

export const PainPointsListSchema = defaultVBListResponse(
  PainPointSchema.array()
);
export type PainPointsList = z.infer<typeof PainPointsListSchema>;

export const TopPrototypeSchema = z.object({
  _id: z.string(),
  prototypeName: z.string(),
  stakeholderId: z.string().nullable().optional(),
  averageNps: z.number().optional(),
});
export type TopPrototype = z.infer<typeof TopPrototypeSchema>;

export const TopPrototypesListSchema = defaultVBListResponse(
  TopPrototypeSchema.array()
);
export type TopPrototypesList = z.infer<typeof TopPrototypesListSchema>;

export type VentureBuilderPageData = {
  phaseId: string;
  phaseTitle: string;
  icon: string;
  sections: Slugs[];
};

export const CurrencySchema = z.object({
  currency: z.string(),
});
export type Currency = z.infer<typeof CurrencySchema>;

export const CurrencyResultSchema = defaultVBListResponse(CurrencySchema);
export type CurrencyResult = z.infer<typeof CurrencyResultSchema>;

export const MarketSizeSchema = z.object({
  currency: z.string().optional(),
  [MarketSizeKeys.TAM]: z.number().optional(),
  [MarketSizeKeys.SAM]: z.number().optional(),
  [MarketSizeKeys.SOM]: z.number().optional(),
});
export type MarketSize = z.infer<typeof MarketSizeSchema>;

export const MarketSizeResultSchema = defaultVBListResponse(MarketSizeSchema);
export type MarketSizeResult = z.infer<typeof MarketSizeResultSchema>;

export const OnlinePersonSingleSchema = z.object({
  userId: z.string(),
  name: z.string(),
  email: z.string(),
  profileImg: z.string().optional(),
});
export type OnlinePersonSingle = z.infer<typeof OnlinePersonSingleSchema>;

export const VentureBuilderInfoDataSchema = z.object({
  lastEdit: z
    .object({
      userId: z.string().nullable().optional(),
      name: z.string(),
      time: z.string(),
    })
    .optional(),
  onlinePeople: z.array(OnlinePersonSingleSchema).optional(),
});
export type VentureBuilderInfoData = z.infer<
  typeof VentureBuilderInfoDataSchema
>;

export const VentureBuilderInfoDataResultSchema = defaultVBListResponse(
  VentureBuilderInfoDataSchema
);
export type VentureBuilderInfoDataResult = z.infer<
  typeof VentureBuilderInfoDataResultSchema
>;

export const FundingRequestSchema = z.object({
  currency: z.string().optional(),
  fundingRequest: z.number().optional(),
});
export type FundingRequest = z.infer<typeof FundingRequestSchema>;

export const FundingRequestResultSchema =
  defaultVBListResponse(FundingRequestSchema);
export type FundingRequestResult = z.infer<typeof FundingRequestResultSchema>;
