import { useQuery } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import useNotifications from 'common/useNotifications';
import { useCallback } from 'react';
import { useFetch } from 'services/api/utils';
import { socketTopics } from 'services/sockets/topics';
import { Client, ClientSchema } from 'types/Client';
import { SocketHandlerProps } from 'types/socket';
import { getClientInfoRequest } from '../client';
import { useSessionData } from '../useSessionData';
import { clientQueryKeys } from './clientQueryKeys';

const schema = ClientSchema.optional();

type ResponseData = undefined | Client;

export const useGetClientInfo = ({
  onSuccess,
  onFailure,
  onEnd,
}: SocketHandlerProps<void, ResponseData>) => {
  const { notify } = useNotifications();
  const { roles } = useSessionData({});

  const enabled = roles.isClientUser;

  const successHandler = useCallback(
    (result?: ResponseData) => {
      onSuccess?.(result);
    },
    [onSuccess]
  );
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      message_key && notify(message_key);
      onFailure?.();
    },
    [notify, onFailure]
  );
  const endHandler = useCallback(() => {
    onEnd?.();
  }, [onEnd]);

  const { fetchFn } = useFetch<void, ResponseData, null | ResponseData>({
    topic: socketTopics.CLIENT_LOAD,
    request: getClientInfoRequest,
    adaptFn,
    schema,
  });

  const { data, isLoading } = useQuery(
    [clientQueryKeys.clientInfo()],
    fetchFn,
    {
      enabled,
      onSuccess: successHandler,
      onError: failureHandler,
      onSettled: endHandler,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return { clientInfo: data, isLoadingClientInfo: isLoading && enabled };
};

const adaptFn = (data: ResponseData) => data ?? null;
