import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Avatar, Box, Grid, Popover, Typography } from '@mui/material';
import { Link } from 'components/Inputs/Link';
import { DeleteAssignmentModal } from 'components/Modal/Delete/DeleteAssignmentModal';
import { addDays, isBefore } from 'date-fns';
import { useModalState } from 'hooks';
import { useRouter } from 'next/router';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useSessionData } from 'services/api/useSessionData';
import { useCommonTranslations } from 'translations/hooks';
import { AssignmentFromTask, AssignmentProgress } from 'types/AssignContent';
import { getInitials } from 'utils';
import { getUTCTimeDiffTextFromNow } from 'utils/time';
import { CardItem, DueDateCardItem } from '../AssignContent/AssignedCard';

export const AssignmentDetailsTaskView = ({
  assignmentDetails,
  unitId,
  unitName,
  openAssignModal,
  setActiveAssignment,
}: {
  assignmentDetails?: AssignmentFromTask;
  unitId: string;
  unitName: string;
  openAssignModal: () => void;
  setActiveAssignment: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const { permissions } = useSessionData({});
  const router = useRouter();
  const {
    assignedByLabel,
    teamProgressInfoLabel,
    yourProgressLabel,
    assignedVideoStatusLabel,
    notesLabel,
    editLabel,
    deleteLabel,
    dueDateLeftNoParenthesesLabel,
  } = useCommonTranslations();

  const {
    arr: [isDeleteModalOpen, openDeleteModal, closeDeleteModal],
  } = useModalState();

  const onDeleted = () => {
    closeDeleteModal();
  };

  const handleEditClick = () => {
    setActiveAssignment(assignmentDetails?.assignmentId);
    openAssignModal();
  };

  const [assignmentDetailsOpen, setAssignmentDetailsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

  const handleOpenDetails = (event: MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
    setAssignmentDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setAssignmentDetailsOpen(false);
    setAnchorEl(null);
  };

  if (!assignmentDetails) return null;

  const dueDateLeft = getUTCTimeDiffTextFromNow({
    locale: router.locale,
    date: new Date(assignmentDetails?.dueDate),
    addSuffix: false,
    strict: true,
  });

  const isDueDatePassed = isBefore(
    addDays(new Date(assignmentDetails?.dueDate), 1),
    new Date()
  );

  return (
    <>
      {permissions.canDeleteAssignments && isDeleteModalOpen && (
        <DeleteAssignmentModal
          assignmentId={assignmentDetails.assignmentId}
          unitId={unitId}
          unitName={unitName}
          open={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onDeleted={onDeleted}
        />
      )}
      {!isDueDatePassed && assignmentDetails && permissions.canViewAssignments && (
        <>
          <Link color="secondary" variant="body2" onClick={handleOpenDetails}>
            {dueDateLeftNoParenthesesLabel(dueDateLeft)}
          </Link>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={popoverAnchorOrigin}
            transformOrigin={popoverTransformOrigin}
            open={assignmentDetailsOpen}
            onClose={handleCloseDetails}
            elevation={24}
            PaperProps={popoverPaperProps}
          >
            <Grid container p="20px 30px" maxWidth="300px">
              <Grid item xs={12}>
                <DueDateCardItem dueDate={assignmentDetails?.dueDate} />
              </Grid>
              <Grid item xs={6}>
                <CardItem name={teamProgressInfoLabel}>
                  <Typography variant="body2">
                    {Math.round(
                      assignmentDetails?.teamProgress * 100
                    ).toString() + '%'}
                  </Typography>
                </CardItem>
              </Grid>
              <Grid item xs={6}>
                <CardItem name={yourProgressLabel}>
                  <Typography
                    variant="body2"
                    fontStyle="italic"
                    display="flex"
                    alignItems="center"
                  >
                    {assignedVideoStatusLabel(assignmentDetails?.progress)}
                    {assignmentDetails?.progress ===
                      AssignmentProgress.watched && (
                      <CheckOutlinedIcon color="success" sx={watchedIconSx} />
                    )}
                  </Typography>
                </CardItem>
              </Grid>
              <Grid item xs={12}>
                <CardItem name={assignedByLabel} mt={2}>
                  <Box display="flex" gap={1.15}>
                    <Avatar
                      src={assignmentDetails?.assignedBy.profileImg}
                      sx={avatarStyle}
                    >
                      {getInitials(assignmentDetails?.assignedBy.name)}
                    </Avatar>
                    <Typography my="auto" variant="body2">
                      {assignmentDetails?.assignedBy.name}
                    </Typography>
                  </Box>
                </CardItem>
              </Grid>
              <Grid item xs={12}>
                <CardItem name={notesLabel}>
                  <Typography variant="body2">
                    {assignmentDetails?.notesPlainText}
                  </Typography>
                </CardItem>
              </Grid>
              {permissions.canEditAssignments && (
                <Grid item xs={6} mt={4}>
                  <Link color="secondary" onClick={handleEditClick}>
                    {editLabel}
                  </Link>
                </Grid>
              )}
              {permissions.canDeleteAssignments && (
                <Grid
                  item
                  xs={6}
                  mt={4}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Link color="error" onClick={openDeleteModal}>
                    {deleteLabel}
                  </Link>
                </Grid>
              )}
            </Grid>
          </Popover>
        </>
      )}
    </>
  );
};

const avatarStyle = { width: 32, height: 32 };
const watchedIconSx = { width: 15, height: 15, ml: 1 };
const popoverAnchorOrigin = {
  vertical: 'bottom' as const,
  horizontal: 'right' as const,
};
const popoverTransformOrigin = {
  vertical: 'top' as const,
  horizontal: 'right' as const,
};
const popoverPaperProps = { sx: { borderRadius: '10px' } };
