import { IncubatePlaybookUnitData } from 'types/incubatePlaybook';
import { convertMilliseconds, millisecondsIn } from 'utils/time';

export const sumVideosDuration = (unit: IncubatePlaybookUnitData) => {
  const videosTime = unit.assignableTasks.reduce(
    (acc, task) =>
      acc +
      convertMilliseconds(
        task.video?.source?.duration ? task.video?.source?.duration * 1000 : 0,
        millisecondsIn.minutes,
        'up'
      ),
    0
  );

  return videosTime;
};
