import { ProductType } from 'common/enums';
import { useGetIncubatePlaybookEnterpriseResources } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookEnterpriseResources';
import { useGetIncubatePlaybookResources } from 'services/api/product/incubatePlaybook/useGetIncubatePlaybookResources';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { IncubatePlaybookResources } from 'types/incubatePlaybook';

export const usePlaybookResources = () => {
  const { productType } = useGetPlaybookInfo({});
  const { resourcesData } = useGetIncubatePlaybookResources({
    enabled: productType === ProductType.playbook,
  });
  const { enterpriseResourcesData } = useGetIncubatePlaybookEnterpriseResources(
    {
      enabled: productType === ProductType.enterprisePlaybook,
    }
  );

  const isEnterprise = productType === ProductType.enterprisePlaybook;

  return {
    resourcesData:
      resourcesData ??
      enterpriseResourcesData ??
      ({} as IncubatePlaybookResources),
    isEnterprise,
  };
};
