import { Box } from '@mui/material';
import {
  allSlugs,
  SectionModes,
  slugToSectionMapper,
} from 'common/ventureBuilderConsts';
import { Link } from 'components/Inputs/Link';
import { useProductFeatures } from 'hooks';
import { useRouter } from 'next/router';
import { useProductTranslations } from 'translations/hooks';
import { VentureBuilder } from 'types/incubatePlaybook';
import { getProcessEnv } from 'utils';
import { addCustomSlugs, getBaseLink } from 'utils/incubatePlaybook';
import { TaskWrapper } from '../../Tasks/TaskWrapper';

const processEnv = getProcessEnv();

const VentureBuilderTaskWrapper = ({
  sections,
}: {
  sections: VentureBuilder[];
}) => {
  const router = useRouter();
  const { goToMyVentureBuilderLabel } = useProductTranslations();

  const productFeatures = useProductFeatures();

  const isVentureBuilderEnabled =
    productFeatures.ventureBuilder &&
    processEnv.NEXT_PUBLIC_DISABLE_VENTURE_BUILDER !== 'true';

  if (!isVentureBuilderEnabled) return <></>;

  const slugs = sections
    ?.map((s) => s?.slug?.current)
    .filter((sectionId) => allSlugs.includes(sectionId));
  const clickHandler = () => {
    const baseLink = getBaseLink(router.asPath);
    router.push(`${baseLink}/venture-builder`);
  };

  return slugs.length ? (
    <TaskWrapper>
      <>
        {addCustomSlugs(slugs).map((slug) => {
          const sectionMapped = slugToSectionMapper[slug];

          return sectionMapped?.(SectionModes.TASK);
        })}
      </>
      <Box marginTop="20px">
        <Link onClick={clickHandler} color="secondary">
          {goToMyVentureBuilderLabel}
        </Link>
      </Box>
    </TaskWrapper>
  ) : (
    <></>
  );
};

export default VentureBuilderTaskWrapper;
