import { Box, Typography } from '@mui/material';
import {
  PortableText,
  PortableTextMarkComponentProps,
} from '@portabletext/react';
import { Link } from 'components/Inputs/Link';
import styles from 'styles/ActivityInstructions.module.scss';
import { useCommonTranslations } from 'translations/hooks';
import { IncubatePlaybookTaskActivity } from 'types/incubatePlaybook';

const ActivityInstructions = ({
  instructions,
  taskVariant = true,
}: {
  instructions: IncubatePlaybookTaskActivity['instructions'];
  taskVariant?: boolean;
}) => {
  const { summaryLabel } = useCommonTranslations();
  if (!instructions) {
    return null;
  }

  return (
    <Box ml="-20px">
      {taskVariant && (
        <Typography ml="20px" fontSize="20px" fontWeight="600" mb="30px">
          {summaryLabel}
        </Typography>
      )}
      <PortableText
        value={instructions}
        components={{
          marks: {
            link: ({ children, value }: PortableTextMarkComponentProps) => {
              return (
                <Link
                  href={value?.href}
                  target="_blank"
                  className={styles['link']}
                >
                  {children}
                </Link>
              );
            },
          },
          list: {
            number: ({ children }) => (
              <ol className={styles['list']}>{children}</ol>
            ),
          },
          listItem: {
            number: (props) => {
              const isNested = props.value.level !== 1;

              const itemClassName = isNested ? 'listSubItem' : 'listItem';

              return (
                <li
                  className={`${styles[itemClassName]} ${
                    !taskVariant ? styles[`${itemClassName}-small`] : ''
                  }`}
                >
                  {props.children}
                </li>
              );
            },
          },
        }}
      />
    </Box>
  );
};

export default ActivityInstructions;
