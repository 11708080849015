import { Box, Paper, Typography } from '@mui/material';
import { useCommonTranslations } from 'translations/hooks';
import { PlaybookButton } from '../../PlaybookButton';

type PlaybookQuizStartScreenProps = {
  unitTitle: string;
  onClick: () => void;
};

export const PlaybookQuizStartScreen = ({
  unitTitle,
  onClick,
}: PlaybookQuizStartScreenProps) => {
  const { quizLabel, startQuizLabel, testYourKnowledgeOfTitleLabel } =
    useCommonTranslations();

  return (
    <Paper elevation={1}>
      <Box p="30px" borderRadius="4px" maxWidth="923px">
        <Typography
          mb="30px"
          fontSize={20}
          fontWeight={600}
          data-testid="task-title"
        >
          {quizLabel}
        </Typography>
        <Typography mb="30px">
          {testYourKnowledgeOfTitleLabel(unitTitle)}
        </Typography>
        <PlaybookButton onClick={onClick}>{startQuizLabel}</PlaybookButton>
      </Box>
    </Paper>
  );
};
