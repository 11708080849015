import { enUS, frFR } from '@mui/material/locale';
import {
  ThemeProvider as MuiThemeProvider,
  Shadows,
  createTheme,
} from '@mui/material/styles';
import { Theme } from 'common/enums';
import { useTheme } from 'next-themes';
import { ReactNode, useMemo } from 'react';
import activityInstructionsStyles from 'styles/ActivityInstructions.module.scss';
import collabStyles from 'styles/CollaborativeEditor.module.scss';
import { dark } from './dark';
import { light } from './light';

import { CssBaseline, GlobalStyles } from '@mui/material';
import { useRouter } from 'next/router';

const muiLocales = {
  fr: frFR,
  'en-US': enUS,
};

const fontFamily = [
  'Mulish',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'sans-serif',
].join(',');

const globalStyles = {
  ':root': {
    body: {
      backgroundColor: light.background.default,
      color: light.text.default,
      fontFamily,
      margin: 0,
      padding: '0 !important',
    },
  },
  '[data-theme="dark"]': {
    body: {
      backgroundColor: dark.background.default,
      color: dark.text.default,
    },
    [`.${activityInstructionsStyles['link']}`]: {
      color: dark.secondary.main,
    },
    [`.${activityInstructionsStyles['listItem']}`]: {
      '&:before': {
        backgroundColor: dark.secondary.main,
      },
    },
    [`.${activityInstructionsStyles['listSubItem']}`]: {
      '&:before': {
        backgroundColor: dark.secondary.main,
      },
    },
    [`.${collabStyles['editor']}`]: {
      '&__disabled': {
        opacity: 1,
        '.menu-item': {
          opacity: 0.5,
        },
      },
      borderColor: 'rgba(255, 255, 255, 0.12)',
      color: dark.text.default,
      '.menu-item': {
        color: dark.text.default,
      },
      '.collaboration-cursor__label': {
        color: dark.text.default,
      },
      [`.${collabStyles['divider']}`]: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
    },
    '.progress-chart': {
      text: {
        fill: dark.text.default,
      },
      '.phase-line': {
        stroke: 'rgba(255, 255, 255, 0.12)',
      },
    },
    '.progress-chart-phases': {
      backgroundColor: dark.background.paper,
      backgroundImage:
        'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
      text: {
        fill: dark.text.default,
      },
    },
  },
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { theme } = useTheme();
  const { locale = 'en-US' } = useRouter();

  const boxShadowElevation1 = useMemo(
    () =>
      theme === Theme.light ? '0px 0px 64px rgba(245, 245, 245, 0.06)' : 'none',
    [theme]
  );

  const currentTheme = useMemo(
    () =>
      createTheme(
        {
          typography: {
            fontFamily,
          },
          shadows: [
            ...createTheme({}).shadows.map((shadow, i) =>
              i === 1 ? boxShadowElevation1 : shadow
            ),
          ] as Shadows,
          breakpoints: {
            values: {
              xs: 0,
              sm: 744,
              md: 1024,
              lg: 1200,
              xl: 1440,
            },
          },
          palette: theme === Theme.light ? light : dark,
          components: {
            MuiPaper: {
              defaultProps: {
                elevation: theme === Theme.light ? 1 : 8,
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                input: {
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: `0 0 0 100px ${
                      theme === Theme.light
                        ? light.background.autofill
                        : dark.background.autofill
                    } inset`,
                  },
                },
              },
            },
            // style overrides to match SHIFT theme
            MuiButton: {
              styleOverrides: {
                text: {
                  color: textColor(theme),
                },
              },
            },
            MuiTab: {
              styleOverrides: {
                root: {
                  '&.Mui-selected': {
                    color: textColor(theme),
                  },
                },
              },
            },
            MuiLink: {
              styleOverrides: {
                root: {
                  color: textColor(theme),
                },
              },
            },
            MuiTypography: {
              styleOverrides: {
                body1: typographyColor(theme),
                body2: typographyColor(theme),
                h1: typographyColor(theme),
                h2: typographyColor(theme),
                h3: typographyColor(theme),
                h4: typographyColor(theme),
                h5: typographyColor(theme),
                h6: typographyColor(theme),
                subtitle1: typographyColor(theme),
                subtitle2: typographyColor(theme),
                caption: typographyColor(theme),
                overline: typographyColor(theme),
                paragraph: typographyColor(theme),
              },
            },
            MuiFormLabel: {
              styleOverrides: {
                root: {
                  '&.Mui-focused': {
                    color: textColor(theme),
                  },
                },
              },
            },
          },
        },
        muiLocales[locale! as keyof typeof muiLocales]
      ),
    [theme, boxShadowElevation1, locale]
  );

  return (
    <>
      <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>
      <CssBaseline />

      <GlobalStyles styles={globalStyles} />
    </>
  );
};

const textColor = (theme?: string) =>
  theme === Theme.light ? '#00B660' : '#22E77F';

const typographyColor = (theme?: string) => (props: any) => {
  return {
    ...(props.ownerState.color
      ?.toString()
      .match(/^(?:primary.main|secondary.main)$/)
      ? { color: theme === Theme.light ? '#00B660 !important' : '#22E77F' }
      : {}),
  };
};
