import { Slugs } from 'common/ventureBuilderConsts';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import {
  TopValueProposition,
  VentureBuilderChange,
} from 'types/ventureBuilder';
import { useGetTopValuePropositionData } from './useGetTopValuePropositionsData';
import { useUpdateTopValuePropositionsData } from './useUpdateTopValuePropositionsData';

export const useTopValuePropositionsData = () => {
  const { playbookInfo } = useGetPlaybookInfo({});

  const defaultParams = {
    clientId: playbookInfo.clientId,
    projectId: playbookInfo.projectId,
    productId: playbookInfo.productId,
    slug: Slugs.TOP_VALUE_PROPOSITIONS,
  };

  const {
    topValuePropositions,
    isLoading: isGetting,
    setTopValuePropositions,
  } = useGetTopValuePropositionData({
    defaultParams,
  });

  const { isLoading: isPosting, updateTopValuePropositionsData } =
    useUpdateTopValuePropositionsData({});

  type UpdateTopValuePropositionsProps = {
    payload: VentureBuilderChange<TopValueProposition>;
    newData: TopValueProposition[];
  };

  const updateTopValuePropositions = ({
    payload,
    newData,
  }: UpdateTopValuePropositionsProps) => {
    const oldData = [...topValuePropositions];

    setTopValuePropositions(newData);
    updateTopValuePropositionsData(
      {
        ...defaultParams,
        data: payload,
      },
      {
        onError: () => {
          setTopValuePropositions(oldData);
        },
      }
    );
  };

  return {
    topValuePropositions,
    updateTopValuePropositions,
    isGetting,
    isPosting,
  };
};
