import Head from 'next/head';
import Script from 'next/script';
import { getProcessEnv } from 'utils';

const processEnv = getProcessEnv();

const googleId = processEnv.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
const hotjarId = processEnv.NEXT_PUBLIC_HOTJAR_ANALYTICS_ID;
const vimeoId = processEnv.NEXT_PUBLIC_VIMEO_ANALYTICS_ID;

export const Analytics = () => {
  const googleAnalyticsHeadContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${googleId}');`;
  const googleAnalyticsHead = (
    <script
      dangerouslySetInnerHTML={{ __html: googleAnalyticsHeadContent }}
    ></script>
  );
  const googleAnalyticsBody = (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${googleId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );

  const hotjarAnalytics = (
    <Script id="hotjar-analytics" strategy="afterInteractive">{`
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:${hotjarId},hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}</Script>
  );
  const vimeoAnalytics = (
    <>
      <Script
        id="vimeo-analytics"
        type="text/javascript"
        defer={true}
        src={`https://extend.vimeocdn.com/ga/${vimeoId}.js`}
      />
      <Script id="vimeo-analytics-refresh">
        {`setTimeout(function(){ 
          if (typeof window.__vimeoRefresh === 'function') {
            window.__vimeoRefresh();
          } else {
            console.error('Vimeo refresh function is not available.');
          }
        }, 3000);`}
      </Script>
    </>
  );

  return (
    <>
      {googleId && <Head>{googleAnalyticsHead}</Head>}
      {googleId && googleAnalyticsBody}
      {hotjarId && hotjarAnalytics}
      {vimeoId && vimeoAnalytics}
    </>
  );
};
