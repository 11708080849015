import { headerSocketTopic } from 'common/consts';
import { Client } from 'types/Client';
import { FileType } from 'types/FileType';
import { DefaultParams, DefaultRequest } from 'types/api';
import { DeepPartial } from 'utils/DeepPartial';
import { API } from '../utils';

export type ClientParams = DefaultRequest<{ clientId: string }>;
export const getClientRequest = ({
  params: { clientId },
  topic,
}: ClientParams) =>
  API.get(`/v2/clients/${clientId}`, {
    headers: { [headerSocketTopic]: topic },
  });
export const deactivateClientRequest = ({
  params: { clientId },
  topic,
}: ClientParams) =>
  API.delete(`/v1/clients/${clientId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export const getClientInfoRequest = ({ topic }: DefaultRequest) =>
  API.get('/v2/clients/users-client', {
    headers: { [headerSocketTopic]: topic },
  });

export type PresignedUrlRequestProps = DefaultRequest<{
  clientId?: string;
  key: string;
  fileType: FileType;
}>;
export const getClientPresignedUrlRequest = ({
  params: { clientId, key, fileType },
  topic,
}: PresignedUrlRequestProps) =>
  API.get(
    `/v1/clients/${clientId}/upload-url/${key}/${encodeURIComponent(fileType)}`,
    {
      headers: { [headerSocketTopic]: topic },
    }
  );
export const getPlatformPresignedUrlRequest = ({
  params: { key, fileType },
  topic,
}: PresignedUrlRequestProps) =>
  API.get(`/v1/platform/upload-url/${key}/${encodeURIComponent(fileType)}`, {
    headers: { [headerSocketTopic]: topic },
  });

export type ClientUpdateRequest = DefaultRequest<{
  clientId: string;
  data: DeepPartial<Client>;
}>;
export const updateClientRequest = ({
  params: { clientId, data },
  topic,
}: ClientUpdateRequest) =>
  API.patch(`/v1/clients/${clientId}`, data, {
    headers: { [headerSocketTopic]: topic },
  });

export type ClientEnterpriseResourcesRequest = DefaultRequest<{
  clientId: string;
  data: { slugId: string; value: string; fileName: string };
}>;
export const updateClientEnterpriseResourcesRequest = ({
  params: { clientId, data },
  topic,
}: ClientEnterpriseResourcesRequest) =>
  API.patch(`/v2/clients/${clientId}/enterprise-resources`, data, {
    headers: { [headerSocketTopic]: topic },
  });

export type ClientEnterpriseResourcesListRequest = DefaultRequest<{
  clientId: string;
  params: DefaultParams;
}>;
export const getClientEnterpriseResourcesRequest = ({
  params: { clientId, params },
  topic,
}: ClientEnterpriseResourcesListRequest) =>
  API.get(`/v2/clients/${clientId}/enterprise-resources`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });
