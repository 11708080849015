import { SearchFieldBasic } from './SearchFieldBasic';

type SearchFieldPlaybookProps = {
  label: string;
  loading?: boolean;
  debounceTime?: number;
  searchLength?: number;
  autoFocus?: boolean;
  onSearch: (value: string) => void;
  searchOnChange?: boolean;
  innerStyle?: {
    mx?: string | number;
    position?: string;
  };
  collapseOnSm?: boolean;
  colorBasedOnTheme?: boolean;
  searchOnClear?: boolean;
};

export const SearchFieldPlaybook = ({
  innerStyle = {},
  ...props
}: SearchFieldPlaybookProps) => (
  <SearchFieldBasic {...props} innerStyle={innerStyle} style={searchStyle} />
);

const searchStyle = {
  width: '100%',
  height: 47,
  borderRadius: '10px',
  primaryBgColor: 'background.playbookSearch',
  borderColor: 'background.playbookSearchBorder',
};
