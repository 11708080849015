import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, Paper, Typography, Zoom } from '@mui/material';
import { mailToSupport } from 'common/consts';
import { HelpDropdown } from 'components/Inputs/Dropdown';
import { SearchFieldPlaybook } from 'components/Inputs/SearchFieldModern';
import {
  PlaybookSidebarHelp,
  PlaybookSidebarLinks,
  PlaybookSidebarLogo,
  PlaybookSidebarPhases,
  PlaybookSidebarProducts,
} from 'components/Playbook';
import { useBrand, useCustomMediaQuery, useProductFeatures } from 'hooks';
import { useTheme } from 'next-themes';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useGetClientProject } from 'services/api/client/project/useGetClientProject';
import { useGetPlaybookInfo } from 'services/api/product/incubatePlaybook/useGetPlaybookInfo';
import { useSessionData } from 'services/api/useSessionData';
import { useIncubatePlaybookData } from 'store/rq/incubatePlaybook/sanityData';
import { useCommonTranslations } from 'translations/hooks';
import { getProcessEnv, getTextColor } from 'utils';
import { getBaseLink } from 'utils/incubatePlaybook';
import { HeaderWrapper } from '../../HeaderWrapper';
import { PlaybookProfile } from './PlaybookProfile';

const processEnv = getProcessEnv();

export const PlaybookHeader = () => {
  const router = useRouter();
  const { theme } = useTheme();
  const { sessionData } = useSessionData({});
  const { playbookInfo, isPlaybookInfoValid } = useGetPlaybookInfo({});
  const productFeatures = useProductFeatures();
  const { data: project } = useGetClientProject({
    defaultParams: {
      clientId: playbookInfo.clientId,
      projectId: playbookInfo.projectId,
    },
    enabled: isPlaybookInfoValid,
  });

  const { lessThenSm } = useCustomMediaQuery();

  const { brand } = useBrand();

  const { colors, logos } = brand;

  const currentPage = router.asPath.split('/')[7];

  const searchHandler = useCallback(
    (value: string) => {
      const baseLink = getBaseLink(router.asPath);
      const link = `${baseLink}/search?searchingFor=${value}`;
      currentPage === 'search' ? router.replace(link) : router.push(link);
    },
    [currentPage, router]
  );

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleClick = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const { sanity } = useIncubatePlaybookData();

  useEffect(() => {
    setMobileNavOpen(false);
  }, [router]);

  const {
    searchPlaybookLabel,
    contactShiftExpertLabel,
    techSupportLabel,
    accountHelpLabel,
    addHelpSuffixLabel,
  } = useCommonTranslations();

  const helpDropdownList = [
    {
      title: contactShiftExpertLabel,
      subtitle: addHelpSuffixLabel(sanity.title),
      link: sanity?.contactUrl ?? '',
      newTab: true,
    },
    {
      title: techSupportLabel,
      subtitle: accountHelpLabel,
      link: mailToSupport,
    },
  ];

  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href={logos?.smallLogo || processEnv.NEXT_PUBLIC_S3_MARK}
        />
      </Head>
      <HeaderWrapper sx={headerWrapperStyle(lessThenSm, colors?.brandColor)}>
        <Box sx={logoWrapperStyle}>
          <PlaybookSidebarLogo isOpen={true} />
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          minHeight="73px"
        >
          <Box sx={burgerMenuWrapperStyle}>
            <Box onClick={handleClick} display="flex" alignItems="center">
              <MenuIcon sx={burgerMenuIconSx(colors?.brandColor, theme)} />
            </Box>
            <Zoom in={isMobileNavOpen}>
              <Box sx={burgerMenuActiveSx} />
            </Zoom>
          </Box>
          <Box flexGrow="1" display={displayBlockGreaterThenMd}></Box>
          <Box sx={searchWrapperStyle}>
            <SearchFieldPlaybook
              label={searchPlaybookLabel}
              autoFocus={false}
              onSearch={searchHandler}
              searchOnChange={false}
            />
          </Box>
          <Box sx={profileWrapperStyle}>
            {productFeatures.contactExpert && (
              <Box mr={lessThenSm ? 1.25 : 3.5}>
                <HelpDropdown list={helpDropdownList} useWithBrand />
              </Box>
            )}
            <Box sx={projectInfoStyle}>
              <Typography
                variant="body1"
                color={getTextColor(colors?.brandColor, theme, true)}
              >
                {sessionData?.user.name}
              </Typography>
              <Typography
                variant="caption"
                color={getTextColor(colors?.brandColor, theme, true)}
              >
                {project?.name}
              </Typography>
            </Box>
            <PlaybookProfile />
          </Box>
        </Box>
      </HeaderWrapper>
      <Paper sx={mobileNavigationStyle(isMobileNavOpen)}>
        <PlaybookSidebarLinks isOpen={true} />
        <Divider sx={dividerStyle} />
        <PlaybookSidebarPhases isOpen={true} />
        <PlaybookSidebarProducts isOpen={true} />
        <PlaybookSidebarHelp
          isOpen={true}
          helpLink={sanity?.contactUrl}
          isMobile
        />
      </Paper>
    </>
  );
};

const burgerMenuIconSx = (color?: string, theme?: string) => ({
  color: getTextColor(color, theme),
  zIndex: 2,
});
const burgerMenuActiveSx = {
  width: '48px',
  height: '48px',
  position: 'absolute',
  left: '-12px',
  borderRadius: '50%',
  backgroundColor: 'secondary.main',
  opacity: '30%',
};

const displayBlockGreaterThenMd = { xs: 'none', sm: 'block' };

const headerWrapperStyle = (lessThenSm: boolean, brandColor?: string) => ({
  backgroundColor: brandColor,
  position: lessThenSm ? 'fixed' : 'unset',
  top: 0,
  borderRadius: 0,
  width: '100%',
  zIndex: 50,
  boxShadow: lessThenSm
    ? '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
    : 'none',
});

const logoWrapperStyle = {
  position: 'absolute',
  transform: 'translate(35px, 13px)',
  zIndex: 1,
  display: { xs: 'block', sm: 'none' },
};

const burgerMenuWrapperStyle = {
  width: '30px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  display: { xs: 'flex', sm: 'none' },
  position: 'relative',
};

const searchWrapperStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  flexGrow: { xs: 2, sm: 3, lg: 2 },
  zIndex: 5,
};

const profileWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexGrow: { xs: 0, sm: 1 },
};

const mobileNavigationStyle = (open: boolean) => ({
  display: { sm: 'none' },
  position: 'fixed',
  top: '40px',
  left: 0,
  width: '100%',
  height: open ? 'calc(100% - 40px)' : 0,
  zIndex: 15,
  overflow: 'hidden',
  overflowY: 'scroll',
  transition: 'height 0.2s linear',
  pb: open ? '20px' : 0,
});

const dividerStyle = {
  marginTop: '10px',
  marginBottom: '10px',
};

const projectInfoStyle = {
  marginRight: 2,
  display: { sm: 'block', xs: 'none' },
};
