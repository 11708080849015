import { useQueryClient } from '@tanstack/react-query';
import { NotificationType } from 'common/notificationConsts';
import { socketListener } from 'common/socketListener';
import useNotifications from 'common/useNotifications';
import useSocket from 'common/useSocket';
import { useCallback, useEffect } from 'react';
import { socketTopics } from 'services/sockets/topics';
import { ZodTypeAny } from 'zod';

type useLiveUpdateProps<Response, AdaptedResponse> = {
  topic: socketTopics;
  queryKeys: unknown[];
  adaptFn?: (data?: Response) => AdaptedResponse;
  onSuccess?: (response?: Response | AdaptedResponse) => void;
  onFailure?: (message_key?: NotificationType) => void;
  onAnyUpdate?: () => void;
  enabled?: boolean;
  schema?: ZodTypeAny;
};

export function useLiveUpdate<Response = void, AdaptedResponse = void>({
  topic,
  queryKeys,
  adaptFn,
  onSuccess,
  onFailure,
  onAnyUpdate,
  enabled = true,
  schema,
}: useLiveUpdateProps<Response, AdaptedResponse>) {
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  const onLiveUpdate = useCallback(
    (result: Response | AdaptedResponse) => {
      queryClient.setQueryData(queryKeys, () => result);
    },
    [queryClient, queryKeys]
  );

  const successHandler = useCallback(
    (response?: Response | AdaptedResponse) => {
      enabled && response && onLiveUpdate(response);
      onSuccess?.(response);
    },
    [enabled, onLiveUpdate, onSuccess]
  );
  const failureHandler = useCallback(
    (message_key?: NotificationType) => {
      onFailure?.(message_key);
    },
    [onFailure]
  );
  const anyUpdateHandler = useCallback(() => {
    onAnyUpdate?.();
  }, [onAnyUpdate]);

  const socketResponseHandler = socketListener({
    onSuccess: successHandler,
    onFailure: failureHandler,
    onEnd: anyUpdateHandler,
    adaptFn,
    schema,
    topic,
  });

  useEffect(() => {
    socket?.removeAllListeners(topic);
    enabled && socket?.on(topic, socketResponseHandler);

    return () => {
      socket?.removeAllListeners(topic);
    };
  }, [socketResponseHandler, socket, topic, enabled]);
}

type UseLiveUpdateBasicProps<Response> = {
  topic: socketTopics;
  onLiveUpdate: (result: Response) => void;
  enabled?: boolean;
  schema?: ZodTypeAny;
};

export function useLiveUpdateBasic<Response = void>({
  topic,
  onLiveUpdate,
  enabled = true,
  schema,
}: UseLiveUpdateBasicProps<Response>) {
  const { socket } = useSocket();
  const { notify } = useNotifications();

  const liveUpdateHandler = useCallback(
    ({ result }: { result: Response }) => {
      if (enabled) {
        const validData = schema?.safeParse?.(result);

        if (validData?.success === false) {
          console.error(topic, validData.error.format());
          notify(NotificationType.INVALID_DATA);

          return;
        }

        onLiveUpdate(result);
      }
    },
    [enabled, notify, onLiveUpdate, schema, topic]
  );

  useEffect(() => {
    socket?.removeAllListeners(topic);
    enabled && socket?.on(topic, liveUpdateHandler);

    return () => {
      socket?.removeAllListeners(topic);
    };
  }, [enabled, liveUpdateHandler, socket, topic]);
}
