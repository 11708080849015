import { headerSocketTopic } from 'common/consts';
import { ClientRoles } from 'common/userConsts';
import { API } from 'services/api/utils';
import { Brand } from 'types/Client';
import { Product } from 'types/Product';
import { ResourceGroup } from 'types/Resources';
import { DefaultParams, DefaultRequest } from 'types/api';

export type ClientProjectRequestProps = DefaultRequest<{
  clientId: string;
  projectId: string;
}>;

export type CreateProjectRequestProps = DefaultRequest<{
  clientId: string;
  data: { name: string; products: string[] };
}>;
export const createProjectRequest = ({
  params: { clientId, data },
  topic,
}: CreateProjectRequestProps) =>
  API.post(`/v1/clients/${clientId}/projects`, data, {
    headers: { [headerSocketTopic]: topic },
  });

export type UpdateProjectRequestProps = DefaultRequest<
  ClientProjectRequestProps['params'] & {
    payload: Partial<{
      name: string;
      status: string;
      products: Pick<Product, 'productId' | 'enabled'>[];
      brand: Brand;
      features: { [productId: string]: { [featureId: string]: boolean } };
    }>;
  }
>;
export const updateProjectRequest = ({
  params: { clientId, projectId, payload },
  topic,
}: UpdateProjectRequestProps) =>
  API.patch(`/v1/clients/${clientId}/projects/${projectId}`, payload, {
    headers: { [headerSocketTopic]: topic },
  });

export type ProjectListRequestParamsProps = DefaultRequest<{
  params: DefaultParams;
  clientId: string;
}>;
export const getClientProjectsRequest = ({
  params: { clientId, params },
  topic,
}: ProjectListRequestParamsProps) =>
  API.get(`/v1/clients/${clientId}/projects`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export type ProjectUsersListRequestProps = DefaultRequest<
  ClientProjectRequestProps['params'] & {
    params: DefaultParams;
  }
>;
export const getProjectUsersListRequest = ({
  params: { clientId, projectId, params },
  topic,
}: ProjectUsersListRequestProps) =>
  API.get(`/v1/clients/${clientId}/projects/${projectId}/users`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export const getClientProjectRequest = ({
  params: { clientId, projectId },
  topic,
}: ClientProjectRequestProps) =>
  API.get(`/v1/clients/${clientId}/projects/${projectId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export type ProjectUserRequest = {
  name: string;
  email: string;
  roles: ClientRoles[];
  isExisting: boolean;
};
export type AddProjectUserRequestProps = DefaultRequest<
  ClientProjectRequestProps['params'] & {
    data: { users: ProjectUserRequest[] };
  }
>;
export const addProjectUserRequest = ({
  params: { clientId, projectId, data },
  topic,
}: AddProjectUserRequestProps) =>
  API.post(`/v2/clients/${clientId}/projects/${projectId}/users`, data, {
    headers: { [headerSocketTopic]: topic },
  });

export type EditProjectUserRequestProps = DefaultRequest<
  ClientProjectRequestProps['params'] & {
    userId: string;
    isProjectAdmin: boolean;
  }
>;
export const editProjectUserRequest = ({
  params: { clientId, projectId, userId, isProjectAdmin },
  topic,
}: EditProjectUserRequestProps) =>
  API.patch(
    `/v1/clients/${clientId}/projects/${projectId}/users/${userId}`,
    {
      isProjectAdmin,
    },
    { headers: { [headerSocketTopic]: topic } }
  );

export type RemoveProjectUserRequestProps = DefaultRequest<
  ClientProjectRequestProps['params'] & {
    userId: string;
  }
>;
export const removeProjectUserRequest = ({
  params: { clientId, projectId, userId },
  topic,
}: RemoveProjectUserRequestProps) =>
  API.delete(`/v1/clients/${clientId}/projects/${projectId}/users/${userId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export type EligibleProjectsRequestProps = DefaultRequest<{
  clientId: string;
  params: Pick<DefaultParams, 'filterBy' | 'filterValue'>;
}>;
export const getEligibleProjectsRequest = ({
  params: { clientId, params },
  topic,
}: EligibleProjectsRequestProps) =>
  API.get(`/v1/clients/${clientId}/projects`, {
    params,
    headers: { [headerSocketTopic]: topic },
  });

export type ProjectResourcesProps = DefaultRequest<
  ClientProjectRequestProps['params'] & {
    productId: string;
  }
>;
export const getProjectResourcesRequest = ({
  params: { clientId, projectId, productId },
  topic,
}: ProjectResourcesProps) =>
  API.get(`/v1/clients/${clientId}/projects/${projectId}/groups/${productId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export type UpdateProjectResourcesProps = DefaultRequest<
  ProjectResourcesProps['params'] & {
    data: ResourceGroup;
  }
>;
export const updateProjectResourcesRequest = ({
  params: { clientId, projectId, productId, data },
  topic,
}: UpdateProjectResourcesProps) =>
  API.patch(
    `/v1/clients/${clientId}/projects/${projectId}/groups/${productId}`,
    data,
    { headers: { [headerSocketTopic]: topic } }
  );

export const deactivateProjectRequest = ({
  params: { clientId, projectId },
  topic,
}: ClientProjectRequestProps) =>
  API.delete(`/v1/clients/${clientId}/projects/${projectId}`, {
    headers: { [headerSocketTopic]: topic },
  });

export const reactivateProjectRequest = ({
  params: { clientId, projectId },
  topic,
}: ClientProjectRequestProps) =>
  API.patch(
    `/v2/clients/${clientId}/projects/${projectId}/reactivate`,
    undefined,
    { headers: { [headerSocketTopic]: topic } }
  );

export const duplicateProjectRequest = ({
  params: { clientId, projectId },
  topic,
}: ClientProjectRequestProps) =>
  API.post(
    `/v2/clients/${clientId}/projects/${projectId}/duplicate`,
    undefined,
    {
      headers: { [headerSocketTopic]: topic },
    }
  );

export type TeamProgressRequestProps = DefaultRequest<{
  clientId: string;
  projectId: string;
  productId: string;
}>;

export const getTeamProgressRequest = ({
  params: { clientId, projectId, productId },
  topic,
}: TeamProgressRequestProps) =>
  API.get(`/v2/analytics/${clientId}/${projectId}/${productId}`, {
    headers: { [headerSocketTopic]: topic },
  });
